import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Image from "../Image";
import { Icon } from "@material-ui/core";

/* Images */
import facebook from "../../../../assets/icon/icon_facebook.svg";
import linkedin from "../../../../assets/icon/icon_linkedin.svg";
import instagram from "../../../../assets/icon/icon_instagram.svg";
import twitter from "../../../../assets/icon/icon_twitter.svg";
import home from "../../../../assets/icon/icon_home.svg";
// import activeHome from "../../../../assets/icon"
import activeBiomaps from "../../../../assets/icon/icon_Biomaps_active.svg";
import bioMAPS from "../../../../assets/icon/icon_Biomaps.svg";
import profile from "../../../../assets/icon/icon_profile.svg";
import plans from "../../../../assets/icon/icon_plans.svg";
import settings from "../../../../assets/icon/icon_settings.svg";
import folder from "../../../../assets/icon/icon_folder_border.svg";
import openFolder from "../../../../assets/icon/icon_folder_invert.svg";
import list from "../../../../assets/icon/icon_menu_plus.svg";
import map from "../../../../assets/icon/icon_map.svg";
import drone from "../../../../assets/icon/icon_drone.svg";
import trap from "../../../../assets/icon/icon_trap_alpha.svg";

import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

interface Props {
  color?: string;
  type:
    | string
    | "facebook"
    | "linkedin"
    | "instagram"
    | "twitter"
    | "icon-whatsapp"
    | "icon-home"
    | "icon-biomaps"
    | "icon-profile"
    | "icon-plans"
    | "icon-settings"
    | "icon-folder"
    | "icon-list"
    | "icon-map"
    | "icon-drone"
    | "open_folder"
    | "icon-trap"
    | "icon-active-home"
    | "icon-active-biomaps"
    | "icon-updates";
}

export default (props: Props) => {
  // Visit this URL (https://material-ui.com/components/material-icons/) when necessary to pass values ​​to the "type" property.
  // Some items momentarily require special calls, as is the case with social icons. Images can also be defined here (panel in most).
  function validateType(value: string) {
    switch (value) {
      case "facebook":
        return <Image src={facebook} alt={value} appearance={"img-icon"} />;
      case "linkedin":
        return <Image src={linkedin} alt={value} appearance={"img-icon"} />;
      case "instagram":
        return <Image src={instagram} alt={value} appearance={"img-icon"} />;
      case "twitter":
        return <Image src={twitter} alt={value} appearance={"img-icon"} />;
      case "icon-whatsapp":
        return <WhatsAppIcon />;
      case "icon-home":
        return <Image src={home} alt={value} appearance={"dashboard-icon"} />;
      case "icon-biomaps":
        return <Image src={bioMAPS} alt={value} appearance={"dashboard-icon"} />;
      case "icon-profile":
        return <Image src={profile} alt={value} appearance={"dashboard-icon"} />;
      case "icon-plans":
        return <Image src={plans} alt={value} appearance={"dashboard-icon"} />;
      case "icon-settings":
        return <Image src={settings} alt={value} appearance={"dashboard-icon"} />;
      case "icon-folder":
        return <Image src={folder} alt={value} appearance={"dashboard-icon"} />;
      case "open-folder":
        return <Image src={openFolder} alt={value} appearance={"project-icon"} />;
      case "icon-list":
        return <Image src={list} alt={value} appearance={"dashboard-icon"} />;
      case "icon-map":
        return <Image src={map} alt={value} appearance={"dashboard-icon"} />;
      case "icon-drone":
        return <Image src={drone} alt={value} appearance={"dashboard-icon"} />;
      case "icon-trap":
        return <Image src={trap} alt={value} appearance={"dashboard-icon"} />;
      case "icon-active-biomaps":
        return <Image src={activeBiomaps} alt={value} appearance={"dashboard-icon"} />;
      case "icon-updates":
        return (
          <TipsAndUpdatesIcon
            style={{
              width: 30,
              height: 30,
              marginLeft: 3,
            }}
          />
        );
      default:
        return <Icon style={{ color: props.color ?? "var(--white)" }}>{value}</Icon>;
    }
  }

  return <>{validateType(props.type)}</>;
};
