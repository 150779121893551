import {
  Area,
  CurveMode,
  DirectClient,
  HeadingMode,
  IndirectClient,
  Input,
  Location,
  Profile,
  Releaser,
  ReleaserConfiguration,
  Role,
} from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ReleaserConfigEditorItem } from "../../../../components/Atomic/Inputs/ReleaserConfigEditor/default";
import importGeographicData, {
  ImportedRouteData,
  importRouteKml,
} from "../../../../services/routePlanner/importGeographicData";
import {
  AreaConfigWithoutId,
  AreaCreationParameters,
} from "../../../../store/actions/projectTreeActions";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { ModalContainer } from "../ModalContainer";
import AreaSourceStage from "./AreaSourceStage";
import ConfigurationStage, { ConfigurationStageProps } from "./ConfigurationStage";
import NameManualDrawingAreaStage from "./NameManualDrawingAreaStage";
import {
  boundingBoxForAreas,
  isPointsInsideBoundingBox,
} from "../../../../core/geometricFunctions";
import { BiohubError, BiohubErrorCode } from "../../../../services/axios/BiohubApi";

/**
 * Props will change depending on the mode.
 */
export type CreationModalProps =
  | {
      mode: "new-project";
      onFinish: (projectInfo: ProjectInfo, areasInfo: AreasInfo) => void;
      onClose: () => void;
    }
  | {
      mode: "edit-project";
      projectId: string;
      onFinish: (info: ProjectInfo) => void;
      onClose: () => void;
    }
  | {
      mode: "add-area";
      projectId: string;
      onFinish: (info: AreasInfo) => void;
      onClose: () => void;
    }
  | {
      mode: "edit-area";
      projectId: string;
      areaId: string;
      onFinish: (
        areaName: string,
        areaConfig: AreaConfigWithoutId,
        configuredReleasers: ReleaserConfiguration[],
        mustReleaseEntireArea: boolean,
        unlockedToExecuteMissionPlanner: boolean
      ) => void;
      onClose: () => void;
    }
  | {
      mode: "import-route-kml";
      projectId: string;
      area: Area;
      onFinish: (route: ImportedRouteData) => void;
      onClose: () => void;
    };

type ProjectInfo = {
  directClientId: string;
  indirectClientId: string | null;
  projectName: string;
  areaConfig: AreaConfigWithoutId;
  configuredReleasers: ReleaserConfiguration[];
};

type AreasInfo =
  | {
      source: "file";
      areas: AreaCreationParameters[];
    }
  | {
      source: "draw";
      areaName: string;
      areaConfig: AreaConfigWithoutId;
      configuredReleasers: ReleaserConfiguration[];
    };

export default function (props: CreationModalProps): JSX.Element {
  const directClient = useSelector((state: SystemState) => state.profile.directClient);
  const indirectClients = useSelector((state: SystemState) => state.profile.indirectClients ?? []);

  const project = useSelector((state: SystemState) => {
    if (props.mode === "new-project") return undefined;

    const projectTreeState = state.projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === props.projectId);

    return project;
  });

  const area = useSelector((state: SystemState) => {
    if (props.mode !== "edit-area") return undefined;

    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    return area;
  });

  const currentProfile = useSelector((state: SystemState) => state.profile.userProfile!);

  const profilePreferences = useSelector((state: SystemState) => {
    const userProfile = state.profile.userProfile;

    if (userProfile === null || userProfile.role === Role.external) return undefined;

    return userProfile.preferences;
  });

  const releasers = useSelector((state: SystemState) => state.collections.releasers);
  const inputs = useSelector((state: SystemState) => state.collections.inputs);

  /**
   * This controls which form will render.
   *
   * Stage 1 is for most fields. When the user clicks on the continue button, that sets the
   * stage to 2, where a smaller form for importing files renders, including also the option
   * to draw an area instead of importing any.
   */
  const [stage, setStage] = useState<number>(1);

  const [selectedIndirectClient, setSelectedIndirectClient] = useState<IndirectClient | undefined>(
    undefined
  );

  const [areaConfig, setAreaConfig] = useState(defaultAreaConfig);
  const [releasersConfiguration, setReleasersConfiguration] = useState<ReleaserConfiguration[]>([]);
  const [mustReleaseEntireArea, setMustReleaseEntireArea] = useState(false);
  const [firstStateName, setFirstStateName] = useState("");

  // Lock button state
  const [locked, setLocked] = useState<boolean | undefined>(props.mode === "edit-area");
  /// TODO: Use that inside area config
  const [polygonOptimizationControl, setPolygonOptimizationControl] = useState<boolean>(true);

  useEffect(() => {
    setStage(1);
    setPolygonOptimizationControl(true);
    setMustReleaseEntireArea(profilePreferences?.mustReleaseEntireArea ?? false);

    if (props.mode === "new-project") {
      setAreaConfig(profilePreferences?.areaPreferences ?? defaultAreaConfig);
      setReleasersConfiguration([]);
      setFirstStateName("");
      setSelectedIndirectClient(undefined);
      setLocked(undefined);
    } else if (props.mode === "add-area") {
      setAreaConfig(project?.areaConfig ?? defaultAreaConfig);
      setReleasersConfiguration(project?.configuredReleasers ?? []);
      setFirstStateName("");
      setLocked(undefined);
    } else if (props.mode === "edit-project") {
      setAreaConfig(project?.areaConfig ?? defaultAreaConfig);
      setReleasersConfiguration(project?.configuredReleasers ?? []);
      setFirstStateName(project?.name ?? "");
      setSelectedIndirectClient(
        indirectClients?.find((indirectClient) => indirectClient.id === project?.indirectClientId)
      );
      setLocked(undefined);
    } else if (props.mode === "edit-area") {
      setAreaConfig(area?.areaConfig ?? defaultAreaConfig);
      setReleasersConfiguration(area?.configuredReleasers ?? []);
      setFirstStateName(area?.name ?? "");
      setLocked(true);
    } else if (props.mode === "import-route-kml") {
      setAreaConfig(props.area.areaConfig);
      setReleasersConfiguration(props.area.configuredReleasers);
      setFirstStateName(props.area.name);
      setLocked(undefined);
      setStage(2);
    }
  }, [
    props.mode,
    props.mode !== "new-project" ? props.projectId : undefined,
    props.mode === "edit-area" ? props.areaId : undefined,
  ]);

  /**
   * Validation region
   */
  const [isValidAreaConfig, setIsValidAreaConfig] = useState<boolean>(false);
  const [isValidReleaserConfig, setIsValidReleaserConfig] = useState<boolean>(false);
  /**
   * end region
   */

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(
      isValidAreaConfig &&
        isValidReleaserConfig &&
        (props.mode === "add-area" || firstStateName.length > 0)
    );
  }, [isValidAreaConfig, isValidReleaserConfig, firstStateName]);

  const intl = useIntl();

  // Localizations
  const _newAreaLocalized: string = intl.formatMessage({ id: "map.newArea" });
  const _editAreaPropertiesLocalized: string = intl.formatMessage({ id: "map.editAreaProperties" });
  const _newProjectTitleLocalized: string = intl.formatMessage({ id: "map.newProject.title" });
  const _createAreaModalTitleLocalized: string = intl.formatMessage({
    id: "map.newArea.createAreaModalTitle",
  });
  const _importAreaModalTitleLocalized: string = intl.formatMessage({
    id: "map.newArea.importAreaModalTitle",
  });
  const _editProjectModalTitleLocalized: string = intl.formatMessage({
    id: "map.newArea.editProjectModalTitle",
  });
  const _importRouteKmlModalTitleLocalized: string = intl.formatMessage({
    id: "map.newRoute.importKmlModalTitle",
  });

  const modalTitle = ((): string => {
    switch (props.mode) {
      case "new-project":
        return _newProjectTitleLocalized;
      case "edit-project":
        return _editProjectModalTitleLocalized + " " + (project?.name ?? "");
      case "add-area":
        return _createAreaModalTitleLocalized;
      case "edit-area":
        return _editAreaPropertiesLocalized + " " + (area?.name ?? "");
      case "import-route-kml":
        return _importRouteKmlModalTitleLocalized;
      default:
        return "";
    }
  })();

  // This is a bad solution... Modals containers should have a dynamic height
  const _modalContainerHeight = (): number | undefined => {
    switch (stage) {
      case 2:
        if (props.mode === "import-route-kml") {
          return 150;
        }
        return 245;
      case 3:
        return 200;
      default:
        return undefined;
    }
  };

  return (
    <div id={"modal-container"}>
      <ModalContainer
        showModal={true}
        onClose={props.onClose}
        title={modalTitle}
        lockedIcon={locked}
        setLocked={setLocked}
        height={_modalContainerHeight()}
        width={stage !== 2 ? 560 : 400}
        modalLeftShift={stage === 2 ? 200 : undefined}
        modalTopShift={stage === 2 ? 125 : undefined}
      >
        {stage === 1 &&
          ((): JSX.Element => {
            let configurationStageProps: ConfigurationStageProps = {
              showClientSelection: false,
              showNameInsertion: false,
              lockedMode: locked ?? false,
              showAreaConfig: true,
              areaConfig: areaConfig,
              onChangedAreaConfig: setAreaConfig,
              validateAreaConfig: setIsValidAreaConfig,
              releasersConfig: releasersConfiguration.map((releaserConfiguration) =>
                convertReleaserConfigurationToConfigurationItem(
                  releaserConfiguration,
                  releasers ?? [],
                  inputs ?? []
                )
              ),
              onChangedReleasersConfig: (releasersConfig) => {
                setReleasersConfiguration(releasersConfig.map(convertReleaserConfiguration));
              },
              validateReleasersConfig: setIsValidReleaserConfig,
              isValidConfiguration: isValid,
              onFinish: () => {
                if (!isValid) return;

                if (props.mode === "new-project") {
                  // Don't add the project yet, move to stage two where we'll ask
                  // the user for an area to import.
                  setStage(2);
                } else if (props.mode === "edit-project") {
                  props.onFinish({
                    projectName: firstStateName,
                    areaConfig: areaConfig,
                    configuredReleasers: releasersConfiguration,
                    directClientId: directClient!.id,
                    indirectClientId: selectedIndirectClient?.id ?? null,
                  });
                } else if (props.mode === "edit-area") {
                  props.onFinish(
                    firstStateName,
                    areaConfig,
                    releasersConfiguration,
                    mustReleaseEntireArea,
                    locked !== undefined ? !locked : false
                  );
                } else if (props.mode === "add-area" || props.mode === "import-route-kml") {
                  // Don't add the project yet, move to stage two where we'll ask
                  // the user for an area to import.
                  setStage(2);
                }
              },
              loading: false,
            };

            if (props.mode === "new-project" || props.mode === "edit-project") {
              configurationStageProps = {
                ...configurationStageProps,
                showClientSelection: true,
                selectedClient: selectedIndirectClient,
                emptyClientText:
                  directClient !== null
                    ? _findDirectClientName(directClient, currentProfile)
                    : " -     ",
                availableClients: indirectClients,
                onSelectClient: setSelectedIndirectClient,
              };
            }
            if (props.mode !== "add-area") {
              configurationStageProps = {
                ...configurationStageProps,
                showNameInsertion: true,
                name: firstStateName,
                nameType: "project",
                onChangeName: setFirstStateName,
              };
            }

            return <ConfigurationStage {...configurationStageProps} />;
          })()}
        {stage === 2 &&
          ((): JSX.Element => {
            return (
              <AreaSourceStage
                onlyKml={props.mode === "import-route-kml"}
                onFinishWithFile={async (file, polygonOptimization) => {
                  if (props.mode === "edit-project") return;
                  else if (props.mode === "edit-area") return;
                  else if (props.mode === "new-project") {
                    props.onFinish(
                      {
                        projectName: firstStateName,
                        areaConfig: areaConfig,
                        configuredReleasers: releasersConfiguration,
                        directClientId: directClient!.id,
                        indirectClientId: selectedIndirectClient?.id ?? null,
                      },
                      {
                        source: "file",
                        areas: await importGeographicData(
                          file!,
                          areaConfig,
                          releasersConfiguration,
                          polygonOptimization
                        ),
                      }
                    );
                  } else if (props.mode === "add-area") {
                    props.onFinish({
                      source: "file",
                      areas: await importGeographicData(
                        file!,
                        areaConfig,
                        releasersConfiguration,
                        polygonOptimization
                      ),
                    });
                  } else if (props.mode === "import-route-kml") {
                    const importResult = await importRouteKml(file!);
                    props.onFinish(importResult || []);
                  }
                }}
                onFinishWithoutFile={(polygonOptimization) => {
                  setPolygonOptimizationControl(polygonOptimization);
                  setStage(3);
                }}
              />
            );
          })()}
        {stage === 3 &&
          ((): JSX.Element => {
            return (
              <NameManualDrawingAreaStage
                loading={false}
                onClickAddProject={async (name) => {
                  if (name.length === 0) return;

                  if (props.mode === "new-project") {
                    props.onFinish(
                      {
                        projectName: firstStateName,
                        areaConfig: areaConfig,
                        configuredReleasers: releasersConfiguration,
                        directClientId: directClient!.id,
                        indirectClientId: selectedIndirectClient?.id ?? null,
                      },
                      {
                        source: "draw",
                        areaName: name,
                        areaConfig: areaConfig,
                        configuredReleasers: releasersConfiguration,
                      }
                    );
                  } else if (props.mode === "add-area") {
                    props.onFinish({
                      source: "draw",
                      areaName: name,
                      areaConfig: areaConfig,
                      configuredReleasers: releasersConfiguration,
                    });
                  }
                }}
              />
            );
          })()}
      </ModalContainer>
    </div>
  );
}

/**
 * Converts a releaser config editor mode to an instance of releaser configuration.
 *
 * This assumes that the input contains valid data, which is not guaranteed. Call this only if
 * the item has been verified to be valid.
 */
function convertReleaserConfiguration(item: ReleaserConfigEditorItem): ReleaserConfiguration {
  return {
    releaserId: item.releaser!.id,
    inputId: item.input?.id ?? "",
    releaseRate: item.releaseRate,
    calibrationFactor: item.calibrationFactor !== undefined ? item.calibrationFactor : undefined,
  };
}

function convertReleaserConfigurationToConfigurationItem(
  releaserConfiguration: ReleaserConfiguration,
  releasers: Releaser[],
  inputs: Input[]
): ReleaserConfigEditorItem {
  return {
    releaser:
      releasers.find((releaser) => releaser.id === releaserConfiguration.releaserId) ?? null,
    input: inputs.find((input) => input.id === releaserConfiguration.inputId) ?? null,
    releaseRate: releaserConfiguration.releaseRate,
    calibrationFactor: releaserConfiguration.calibrationFactor,
  };
}

const defaultAreaConfig: AreaConfigWithoutId = {
  flightHeight: 10,
  areaPadding: 10,
  flightSpeed: 10,
  mustConsiderRelief: true,
  trackWidth: 10,
  curveMode: CurveMode.curved,
  headingMode: HeadingMode.nextWaypoint,
  defaultCurvePercentage: 70,
  useHighestPolygonPointElevationDataAsHomePoint: true,
  canUseOutsidePolygonSegmentsInRoute: false,
};

const _findDirectClientName = (directClient: DirectClient, profile: Profile) => {
  if (directClient.company !== undefined) {
    return directClient.company.name;
  }
  const admin = directClient.profiles.find((profile) => profile.role === Role.admin);
  if (admin !== undefined) return admin.name;

  return profile.name;
};
