import { Project } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { restoreProject } from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import DeleteModal from "./DeleteModal";

type Props = {
  project: Project;
  onCancel: () => void;
  onFinish: () => void;
};

export default function (props: Props): JSX.Element {
  const [stage, setStage] = useState<"confirming" | "restoring">("confirming");

  const restoring = useSelector((state: SystemState) => {
    const projectId = props.project.id;

    const projectTreeState = state.projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === projectId);

    if (project === undefined) return false;

    return project.isUpdating;
  });

  useEffect(() => {
    if (restoring && stage !== "restoring") {
      setStage("restoring");
    }
    if (stage === "restoring" && !restoring) {
      props.onFinish();
    }
  }, [stage, restoring]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const termRestoreProject = intl.formatMessage({
    id: "map.restoreProject",
  });
  const termRestoringProject = intl.formatMessage({
    id: "map.restoringProject",
  });
  const termProceed = intl.formatMessage({ id: "map.restoringProject.question" });

  return (
    <DeleteModal
      title={termRestoreProject}
      label={`${termRestoringProject} ${props.project?.name ?? "??"}. ${termProceed}`}
      loading={restoring}
      onClose={props.onCancel}
      onConfirm={() => {
        dispatch(restoreProject(props.project.id));
      }}
    />
  );
}
