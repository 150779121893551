import React, { useState } from "react";
import {
  ProjectInProjectTree,
  AreaInProjectTree,
} from "../../../store/reducers/projectTreeReducer";
import { Toolbar } from "../styles";
import CollapsibleCard from "../../../components/Atomic/CollapsibleCard";
import ProjectTree from "../../../components/ProjectTree";

type Props = {
  height: number;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  onClickToCreateProject: () => void;
  onClickToEditProjectSettings: (project: ProjectInProjectTree) => void;
  onClickToAddAreaInProject: (projectId: ProjectInProjectTree) => void;
  onClickToDeleteProject: (project: ProjectInProjectTree) => void;
  onClickToRestoreProject: (project: ProjectInProjectTree) => void;
  onClickToEditAreaSettings: (area: AreaInProjectTree) => void;
  onClickToDeleteArea: (area: AreaInProjectTree) => void;
  onClickToRestoreArea: (area: AreaInProjectTree) => void;
  onClickDuplicateArea: (area: AreaInProjectTree) => void;
  onClickCopyArea: (area: AreaInProjectTree) => void;
  onClickPasteCopiedArea: (project: ProjectInProjectTree) => void;
  onClickHomePointModal: (area: AreaInProjectTree) => void;
};

export default function (props: Props): JSX.Element {
  /// State toolbar
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Toolbar isVisible={isVisible}>
        <CollapsibleCard
          expanded={props.expanded}
          setExpanded={props.setExpanded}
          expandRight
          overlayContainerStyle={{
            height: "100%",
            padding: 0,
            paddingLeft: "4px",
          }}
          style={{
            alignItems: "flex-start",
          }}
          chevronContainerStyle={{ marginTop: "50px" }}
        >
          <ProjectTree {...props} />
        </CollapsibleCard>
      </Toolbar>
    </div>
  );
}
