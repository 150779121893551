import { BiohubResponse, extractBiohubErrorResponse } from "./axios/BiohubApi";
import { getGeocodingPlace, GoogleMapsSearchInfo } from "./Geocoder";

export const searchZipCode = async (
  zip: string
): Promise<BiohubResponse<GoogleMapsSearchInfo[]>> => {
  try {
    const response = await getGeocodingPlace(zip);
    if (!response.success) {
      throw response.error;
    }

    return response;
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
};

export const searchPlaces = async (
  placeText: string
): Promise<BiohubResponse<GoogleMapsSearchInfo[]>> => {
  try {
    const response = await getGeocodingPlace(placeText);
    if (!response.success) {
      throw response.error;
    }

    return response;
  } catch (e) {
    console.log(e);
    return extractBiohubErrorResponse(e);
  }
};
