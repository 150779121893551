import { BiohubError } from "../../services/axios/BiohubApi";
import LoginService from "../../services/LoginService";
import { SystemThunk } from "../systemThunk";
import history from "../../navigation/history";
import { loadProfileInformation } from "./profileActions";
import { fetchCollections } from "./collectionsActions";
import { SystemState } from "../reducers/systemReducer";
import ProfileService from "../../services/ProfileService";
import { DirectClient, IndirectClient, Profile, Role } from "biohub-model";
import { CHANGE_LOCALE } from "./localeActions";
import { clearData, getCacheDataService } from "../../services/Persistence/CacheDataService";

// Action constants

export const LOG_IN_REQUEST = "LOG_IN_REQUEST";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILURE = "LOG_IN_FAILURE";
export const RESET_ERROR = "RESET_ERROR";
export const LOG_OUT = "LOG_OUT";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const KEEP_DATA_PERSIST = "KEEP_DATA_PERSIST";

// Action types
export type LoginAction =
  /** Action fired at the start of the login thunk. Should only set a loading flag in the reducer. */
  | {
      type: typeof LOG_IN_REQUEST;
    }
  /** Action fired at the end of the login thunk */
  | {
      type: typeof LOG_IN_SUCCESS;
      payload: {
        userToken: string;
        userId: string;
      };
    }
  /** Action fired when there's an error in the login thunk */
  | {
      type: typeof LOG_IN_FAILURE;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof RESET_PASSWORD_FAILURE;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof LOG_OUT;
    }
  | {
      type: typeof RESET_ERROR;
    }
  | {
      type: typeof RESET_PASSWORD_REQUEST;
    }
  | {
      type: typeof RESET_PASSWORD_SUCCESS;
    }
  | {
      type: typeof KEEP_DATA_PERSIST;
      payload: {
        status: boolean;
      };
    };

// Action functions

export function logIn(username: string, password: string, nextPage?: string): SystemThunk {
  return async (dispatch, getState) => {
    const cacheDataService = getCacheDataService();
    if (cacheDataService === undefined) return;

    dispatch({
      type: LOG_IN_REQUEST,
    });

    const loginResult = await LoginService.login(username, password, true);
    if (!loginResult.success) {
      dispatch({
        type: LOG_IN_FAILURE,
        payload: {
          error: loginResult.error,
        },
      });
    } else {
      await cacheDataService.persistLoginInfo({
        keepLoggedIn: getState().login.keepLoggedIn,
        token: loginResult.data.token,
        userId: loginResult.data.userId,
      });

      dispatch({
        type: LOG_IN_SUCCESS,
        payload: {
          userId: loginResult.data.userId,
          userToken: loginResult.data.token,
        },
      });

      dispatch(finishLoginProcedures());
    }
  };
}

export function finishLoginProcedures(nextPage?: string): SystemThunk {
  return async (dispatch, getState) => {
    const actualPage = history.location.pathname;
    if (actualPage !== "/login") {
      history.push("/login");
    }
  };
}

export function resetError(): LoginAction {
  return {
    type: RESET_ERROR,
  };
}

export function logOut(): SystemThunk {
  return async (dispatch) => {
    clearData();
    // Go to the landing page first. Otherwise, logging out will cause the current page to redirect
    // to the login page, and the login page will come back to where the user was before.
    history.push(`/`);
    dispatch({
      type: LOG_OUT,
    });
  };
}

export function resetPassword(email: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const result = await ProfileService.requestResetPassword(email);
    if (result.success) {
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    } else {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: {
          error: result.error,
        },
      });
    }
  };
}
