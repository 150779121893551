import {
  Box,
  CircularProgress,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ReleaserInfo } from "biohub-model/ts/FlightReport";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import {
  Column,
  Content,
  Description,
  Details,
  Divider,
  Footer,
  HeadColumn,
  Header,
  Image,
  Info,
  Item,
  List,
  ListItem,
  Loading,
  ReportPage,
  Row,
  Subtitle,
  Table,
  Title,
} from "./styles";
import FlightService from "../../services/FlightService";
import UnitConversionHelper from "../../core/helper/UnitConversionHelper";
import {
  ReleaserType,
  FlightReport,
  Role,
  UnitSystem,
  WeatherAndApiInfo,
  WeatherInfo,
} from "biohub-model";
import Button from "../../components/Atomic/BasicComponents/Button";

import iconArea from "../../assets/icon/icon_flight_invert.png";
import iconDrone from "../../assets/icon/icon_drone.png";
import { useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { useHistory, useLocation } from "react-router-dom";

import FlightReportMap from "./containers/FlightReportMap";

import { ReactComponent as ImageTemperature } from "../../assets/icon/icon_temperature_border.svg";
import { ReactComponent as ImageHumidity } from "../../assets/icon/icon_humidity_border.svg";
import { ReactComponent as ImagePressure } from "../../assets/icon/icon_pressure.svg";
import { ReactComponent as ImageSpeedWindIcon } from "../../assets/icon/speed_wind_icon.svg";
import Dropdown from "../../components/Atomic/Inputs/Dropdown";

/**
 * @param sec: Time duration in seconds
 * @returns converted string in hh:mm:ss format
 */
function toHHMMSS(sec: number): string {
  var sec_trunc = Math.trunc(sec);
  var hours = Math.floor(sec_trunc / 3600);
  var minutes = Math.floor((sec_trunc - hours * 3600) / 60);
  var seconds = sec_trunc - hours * 3600 - minutes * 60;

  var hoursOut: string = hours < 10 ? "0" + hours.toString() : hours.toString();
  var minOut: string = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
  var secOut: string = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

  return hoursOut + ":" + minOut + ":" + secOut;
}

export default () => {
  const history = useHistory();
  let { search, pathname } = useLocation();
  const [keepQueryParams, setKeepQueryParams] = useState<URLSearchParams>();
  // The undefined state is maped to a loading component, the null state is maped to a error on
  // Loading component and the FlightReport state stores the actual FlightReport data
  const [flightReports, setFlightReports] = useState<FlightReport | undefined | null>(undefined);

  useEffect(() => {
    async function result(): Promise<void> {
      // Gets the id passed
      const query = new URLSearchParams(search);
      setKeepQueryParams(query);
      const flightId = query.get("flightId");
      const areaId = query.get("areaId");
      const projectId = query.get("projectId");
      let queryParam: string;
      if (flightId !== null) {
        queryParam = `flightId=${flightId}`;
      } else if (areaId !== null) {
        queryParam = `areaId=${areaId}`;
      } else if (projectId !== null) {
        queryParam = `projectId=${projectId}`;
      } else {
        queryParam = "";
      }
      const dataResult = await FlightService.flightReport(queryParam);
      if (dataResult.success) {
        setFlightReports(dataResult.data);
      } else {
        setFlightReports(null);
      }
    }
    result();
  }, []);

  useEffect(() => {
    history.push({
      pathname: pathname,
      search: keepQueryParams?.toString(),
    });
  }, [search]);

  const intl = useIntl();

  const internationalizationTerms = {
    termIssueDate: intl.formatMessage({ id: "report.issueDate" }),
    termArea: intl.formatMessage({ id: "report.area" }),
    termPlannedFlight: intl.formatMessage({ id: "report.plannedFlight" }),
    termDetails: intl.formatMessage({ id: "report.details" }),
    termDateHour: intl.formatMessage({ id: "report.dateHour" }),
    termDrone: intl.formatMessage({ id: "report.drone" }),
    termManufacturer: intl.formatMessage({ id: "report.manufacturer" }),
    termAnac: intl.formatMessage({ id: "report.anac" }),
    termReleasedProduct: intl.formatMessage({ id: "report.releasedProduct" }),
    termReleasing: intl.formatMessage({ id: "report.releasing" }),
    termBandwidth: intl.formatMessage({ id: "report.bandWidth" }),
    termPlannedSpeed: intl.formatMessage({ id: "report.plannedSpeed" }),
    termReleaseRate: intl.formatMessage({ id: "report.releaseRate" }),
    termCalibrationFactor: intl.formatMessage({ id: "map.newProject.calibrationFactor" }),
    termRealFlight: intl.formatMessage({ id: "report.realFlight" }),
    termFlightTime: intl.formatMessage({ id: "report.flightTime" }),
    termAverageReleaseRate: intl.formatMessage({ id: "report.averageReleaseRate" }),
    termAverageSpeed: intl.formatMessage({ id: "report.averageSpeed" }),
    termTotalReleased: intl.formatMessage({ id: "report.totalReleased" }),
    termTotalArea: intl.formatMessage({ id: "report.totalArea" }),
    termTraps: intl.formatMessage({ id: "report.traps" }),
    termSerialNumber: intl.formatMessage({ id: "report.serialNumber" }),
    termCoveredHectares: intl.formatMessage({ id: "report.coveredHectares" }),
    termDateMeasurement: intl.formatMessage({ id: "report.dateMeasurament" }),
    termSpecie: intl.formatMessage({ id: "report.specie" }),
    termNumberAdults: intl.formatMessage({ id: "report.adultsNumber" }),
    termNoAvailable: intl.formatMessage({ id: "report.noAvailable" }),
    termPrint: intl.formatMessage({ id: "report.print" }),
    termInput: intl.formatMessage({ id: "report.input" }),
  };
  const unitSystem = useSelector((state: SystemState) => {
    const profile = state.profile.userProfile;

    if (profile !== null && profile.role !== Role.external) {
      return profile.preferences.unitSystem;
    }

    return UnitSystem.metric;
  });

  return (
    <>
      <ReportPage>
        <Header>
          <Row>
            <HeadColumn left width={50}>
              <Image
                src={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.logoUrl
                    : ""
                }
                alt={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.name
                    : ""
                }
                logo
              />
            </HeadColumn>
            <HeadColumn right width={50}>
              <Info>
                <Title>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.name
                    : ""}
                </Title>
                <Item>
                  CNPJ:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.document
                    : ""}{" "}
                  IE:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.ie
                    : ""}{" "}
                  IM:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.im
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.street
                    : ""}{" "}
                  Nº
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.number
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.complement
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.city
                    : ""}{" "}
                  -{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.state
                    : ""}{" "}
                  -{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.zipCode
                    : ""}
                </Item>
                <Item strong>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.phoneNumber
                    : ""}
                </Item>
              </Info>
            </HeadColumn>
          </Row>
        </Header>
        <Content>
          <Row>
            <Column left width={50} style={{ paddingLeft: 30 }}>
              <Image
                src={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceConsumer.logoUrl
                    : ""
                }
                alt={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceConsumer.name
                    : ""
                }
                logo
              />
            </Column>
            <Column right top width={50}>
              <Item>
                {internationalizationTerms.termIssueDate}{" "}
                {format(new Date(), "dd MMM yyyy hh:mm:ss aaa")}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.name
                  : ""}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.document
                  : ""}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.phoneNumber
                  : ""}
              </Item>
              <Box>
                <Button
                  text={internationalizationTerms.termPrint}
                  color={"primary"}
                  size={"large"}
                  appearance={"button-print"}
                  action={() => {
                    window.print();
                  }}
                />
              </Box>
            </Column>
          </Row>
          {flightReports !== undefined && flightReports !== null ? (
            <>
              {flightReports.map((flightReport, index) => (
                <>
                  <Row noPadding>
                    <Column colSpan={2} width={100}>
                      <Table>
                        <Header gray>
                          <Row>
                            <HeadColumn width={100}>
                              <Image src={iconArea} alt="Area" icon />
                              <Item>{internationalizationTerms.termArea}</Item>
                              <Item>{flightReport.areaName}</Item>
                            </HeadColumn>
                          </Row>
                        </Header>
                        <Content>
                          <Row noPadding>
                            <Column width={30} top>
                              <FlightReportMap
                                dataReport={flightReport}
                                mode="plan"
                                flightToPlot={flightReport.flight}
                              />
                            </Column>
                            <Column width={70} left>
                              <Subtitle>{internationalizationTerms.termPlannedFlight}</Subtitle>
                              <Details>
                                <Description>{internationalizationTerms.termDetails}</Description>
                                <List>
                                  <ListItem>
                                    {internationalizationTerms.termDateHour}{" "}
                                    <b>
                                      {format(
                                        flightReport.flight.flightEnvironmentSnapshot.plannedArea
                                          .generatedAt,
                                        "dd/MM/yyyy hh:mm aaa"
                                      )}
                                    </b>
                                  </ListItem>
                                  {flightReport.drone !== null && (
                                    <ListItem>
                                      {internationalizationTerms.termDrone}{" "}
                                      <b>{flightReport.drone.name}</b>,{" "}
                                      {internationalizationTerms.termManufacturer}{" "}
                                      <b>{flightReport.drone.manufacturer}</b>,{" "}
                                      {internationalizationTerms.termAnac}{" "}
                                      <b>{flightReport.drone.anacNumber}</b>{" "}
                                    </ListItem>
                                  )}
                                </List>
                              </Details>
                              <PlannedReleasersInfoTable
                                flightData={flightReport.flightData}
                                terms={internationalizationTerms}
                                unitSystem={unitSystem}
                              />
                            </Column>
                          </Row>
                        </Content>
                      </Table>
                    </Column>
                  </Row>
                  <Row noPadding>
                    <Column width={100} colSpan={2} center>
                      <Divider />
                    </Column>
                  </Row>
                  <Row noPadding>
                    <Column colSpan={2} width={100}>
                      <Table>
                        <Content>
                          <Row noPadding>
                            <Column width={30} top>
                              <FlightReportMap
                                dataReport={flightReport}
                                flightToPlot={flightReport.flight}
                                mode="flight"
                              />
                            </Column>
                            <Column width={70} left>
                              <Subtitle>{internationalizationTerms.termRealFlight}</Subtitle>
                              <Details>
                                <Description>{internationalizationTerms.termDetails}</Description>
                                <List>
                                  <ListItem>
                                    {internationalizationTerms.termDateHour}{" "}
                                    <b>
                                      {format(
                                        flightReport.flight.startedAt,
                                        "dd/MM/yyyy hh:mm aaa"
                                      )}
                                    </b>
                                  </ListItem>
                                  {flightReport.drone !== null && (
                                    <ListItem>
                                      {internationalizationTerms.termDrone}{" "}
                                      <b>{flightReport.drone.name}</b>,{" "}
                                      {internationalizationTerms.termManufacturer}{" "}
                                      <b>{flightReport.drone.manufacturer}</b>,{" "}
                                      {internationalizationTerms.termAnac}{" "}
                                      <b>{flightReport.drone.anacNumber}</b>{" "}
                                    </ListItem>
                                  )}
                                  <ListItem>
                                    <FlightClimaticInfo
                                      initialWeatherData={flightReport.flight.initialWeatherData}
                                      finalWeatherData={flightReport.flight.finalWeatherData}
                                      unitSystem={unitSystem}
                                    />
                                  </ListItem>
                                </List>
                              </Details>
                              <RealReleasersInfoTable
                                flightData={flightReport.flightData}
                                terms={internationalizationTerms}
                                unitSystem={unitSystem}
                              />
                            </Column>
                          </Row>
                        </Content>
                      </Table>
                    </Column>
                  </Row>
                </>
              ))}
            </>
          ) : flightReports === undefined ? (
            <Loading>
              <CircularProgress />
            </Loading>
          ) : (
            <div>Error.</div>
          )}
        </Content>
        <Footer breakPage>
          <Row noPadding>
            <Column width={100} colSpan={2} center>
              <Divider />
            </Column>
          </Row>
        </Footer>
      </ReportPage>
    </>
  );
};

function PlannedReleasersInfoTable(props: {
  flightData: ReleaserInfo;
  unitSystem: UnitSystem;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
  };
}): JSX.Element {
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let allReleasersTypes: ReleaserType[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    allReleasersTypes.push(props.flightData[releaserSerialNumber].releaserType);
  }
  const uniqueReleasersTypes = [...new Set(allReleasersTypes)];
  const terms = props.terms;
  let tables: JSX.Element[] = [];
  for (let releaserType of uniqueReleasersTypes) {
    tables.push(
      <Table key={`flight-report-planned-releasers-info-tablee-${releaserType}`}>
        <Header>
          <PlannedReleaserInfoHeader
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Header>
        <Content striped>
          <PlannedReleaserInfoRows
            flightData={props.flightData}
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Content>
      </Table>
    );
  }

  return <>{tables.map((table) => table)}</>;
}

function PlannedReleaserInfoHeader(props: {
  releaserType: ReleaserType;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  return (
    <>
      <Row noPadding>
        <HeadColumn width={10}>
          {terms.termReleasing}
          <small></small>
        </HeadColumn>
        <HeadColumn width={12}>
          {terms.termInput}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termSerialNumber}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termBandwidth}
          <small>{`(${UnitConversionHelper.distanceUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termPlannedSpeed}
          <small>{`(${UnitConversionHelper.speedUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType == ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerSpaceUnit(props.unitSystem)
                : UnitConversionHelper.cupPerSpaceUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        {props.releaserType !== ReleaserType.bioCOTe && (
          <HeadColumn width={15}>{terms.termCalibrationFactor}</HeadColumn>
        )}
      </Row>
    </>
  );
}

function PlannedReleaserInfoRows(props: {
  flightData: ReleaserInfo;
  releaserType: ReleaserType;
  terms: {
    termDrone: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  const releaserTypeName = (releaserType: ReleaserType) =>
    releaserType === ReleaserType.bioBOT
      ? "bioBOT"
      : releaserType === ReleaserType.bioCOTe
      ? "bioCOTe"
      : "bioMITe";
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let rows: JSX.Element[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    const releaserInfo = props.flightData[releaserSerialNumber];
    if (releaserInfo.releaserType !== props.releaserType) {
      continue;
    }
    rows.push(
      <Row key={`flight-report-releaser-info-row-${releaserSerialNumber}`} noPadding>
        <Column left width={10}>
          <Tooltip title={releaserTypeName(releaserInfo.releaserType)}>
            <Image src={iconDrone} alt={terms.termDrone} iconXS />
          </Tooltip>
        </Column>
        <Column left width={15}>
          {releaserInfo.real.input}
        </Column>
        <Column left width={15}>
          {releaserSerialNumber}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.distanceValue(releaserInfo.planned.trackWidth, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.speedValue(releaserInfo.planned.speed, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerSpaceValue(
                releaserInfo.planned.releaseRate,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerSpaceValue(
                releaserInfo.planned.releaseRate,
                props.unitSystem
              )}
        </Column>
        {props.releaserType !== ReleaserType.bioCOTe && (
          <Column left width={25}>
            {releaserInfo.planned.calibrationFactor !== undefined
              ? releaserInfo.planned.calibrationFactor.toFixed(3)
              : ""}
          </Column>
        )}
      </Row>
    );
  }

  return <>{rows.map((row) => row)}</>;
}

function RealReleasersInfoTable(props: {
  flightData: ReleaserInfo;
  unitSystem: UnitSystem;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
    termFlightTime: string;
    termAverageReleaseRate: string;
    termAverageSpeed: string;
    termTotalReleased: string;
    termTotalArea: string;
  };
}): JSX.Element {
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let allReleasersTypes: ReleaserType[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    allReleasersTypes.push(props.flightData[releaserSerialNumber].releaserType);
  }
  const uniqueReleasersTypes = [...new Set(allReleasersTypes)];
  const terms = props.terms;
  let tables: JSX.Element[] = [];
  for (let releaserType of uniqueReleasersTypes) {
    tables.push(
      <Table key={`flight-report-info-table-${releaserType}`}>
        <Header>
          <RealReleaserInfoHeader
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Header>
        <Content striped>
          <RealReleaserInfoRows
            flightData={props.flightData}
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Content>
      </Table>
    );
  }

  return <>{tables.map((table) => table)}</>;
}

function RealReleaserInfoHeader(props: {
  releaserType: ReleaserType;
  terms: {
    termReleasing: string;
    termFlightTime: string;
    termAverageReleaseRate: string;
    termAverageSpeed: string;
    termTotalReleased: string;
    termTotalArea: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const intl = useIntl();

  const terms = props.terms;
  return (
    <>
      <Row noPadding>
        <HeadColumn width={10}>
          {terms.termReleasing}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termFlightTime}
          <small>(00h:00min:00s)</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType == ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerTimeUnit(props.unitSystem)
                : UnitConversionHelper.cupPerTimeUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType === ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerSpaceUnit(props.unitSystem)
                : UnitConversionHelper.cupPerSpaceUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageSpeed}
          <small>{`(${UnitConversionHelper.speedUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termTotalReleased}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType === ReleaserType.bioMITE
                ? UnitConversionHelper.volumeUnit(props.unitSystem)
                : UnitConversionHelper.cupUnit(intl)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termTotalArea}
          <small>{`(${UnitConversionHelper.areaUnit(props.unitSystem)})`}</small>
        </HeadColumn>
      </Row>
    </>
  );
}

function RealReleaserInfoRows(props: {
  flightData: ReleaserInfo;
  releaserType: ReleaserType;
  terms: { termDrone: string };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  const releaserTypeName = (releaserType: ReleaserType) =>
    releaserType === ReleaserType.bioBOT
      ? "bioBOT"
      : releaserType === ReleaserType.bioCOTe
      ? "bioCOTe"
      : "bioMITe";
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let rows: JSX.Element[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    const releaserInfo = props.flightData[releaserSerialNumber];
    if (releaserInfo.releaserType !== props.releaserType) {
      continue;
    }
    rows.push(
      <Row key={`flight-report-info-row-${releaserSerialNumber}`} noPadding>
        <Column left width={10}>
          <Tooltip title={releaserTypeName(releaserInfo.releaserType)}>
            <Image src={iconDrone} alt={terms.termDrone} iconXS />
          </Tooltip>
        </Column>
        <Column left width={15}>
          {toHHMMSS(releaserInfo.real.duration)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerTimeValue(
                releaserInfo.real.averageReleaseRateMinutes,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerTimeValue(
                releaserInfo.real.averageReleaseRateMinutes,
                props.unitSystem
              )}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerSpaceValue(
                releaserInfo.real.averageReleaseRateHectare,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerSpaceValue(
                releaserInfo.real.averageReleaseRateHectare,
                props.unitSystem
              )}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.speedValue(releaserInfo.real.averageSpeed, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumeValue(releaserInfo.real.totalReleased, props.unitSystem)
            : UnitConversionHelper.cupValue(releaserInfo.real.totalReleased)}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.areaValue(releaserInfo.real.totalAreaOverflown, props.unitSystem)}
        </Column>
      </Row>
    );
  }

  return <>{rows.map((row) => row)}</>;
}

function FlightClimaticInfo(props: {
  initialWeatherData: WeatherAndApiInfo[] | undefined;
  finalWeatherData: WeatherAndApiInfo[] | undefined;
  unitSystem: UnitSystem;
}): JSX.Element {
  const intl = useIntl();

  const initialWeatherTerm = intl.formatMessage({ id: "report.initialWeatherTitle" });
  const finalWeatherTerm = intl.formatMessage({ id: "report.finalWeatherTitle" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "black",
      }}
    >
      <InitialOrFinalFlightClimaticInfo
        title={initialWeatherTerm}
        data={props.initialWeatherData}
        unitSystem={props.unitSystem}
      />
      <InitialOrFinalFlightClimaticInfo
        title={finalWeatherTerm}
        data={props.finalWeatherData}
        unitSystem={props.unitSystem}
      />
    </div>
  );
}

type ClimaticInfoSource =
  | "openWeather"
  | "visualCrossing"
  | "weatherApi"
  | "tomorrowIo"
  | "optimized"
  | "manual";

function InitialOrFinalFlightClimaticInfo(props: {
  title: string;
  data: WeatherAndApiInfo[] | undefined;
  unitSystem: UnitSystem;
}): JSX.Element {
  const [data, setData] = useState(getClimaticInfoSourceAndData(props.data));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 100,
        }}
      >
        {props.title}:
      </div>
      <div style={{ width: 10 }} />
      <ClimaticInfo
        data={data}
        unitSystem={props.unitSystem}
        source={data?.source}
        editData={setData}
      />
      {data !== undefined && <ClimaticInfoSource source={data.source} />}
      <div style={{ width: 10 }} />
      <ClimaticInfoSourceSelector
        actualSource={data?.source}
        availableSources={props.data?.map((value) => value.apiName) ?? []}
        onChangeSource={(source) => {
          const findData = props.data?.find((e) => e.apiName === source);
          if (findData !== undefined) {
            setData({ ...findData, source: source });
          } else {
            setData({
              ...(data ?? {
                temperature: 0,
                pressure: 0,
                windSpeed: 0,
                windDegree: 0,
                humidity: 0,
                timeReference: new Date(),
              }),
              source: source,
            });
          }
        }}
      />
    </div>
  );
}

function ClimaticInfoSourceSelector(props: {
  actualSource: ClimaticInfoSource | undefined;
  availableSources: WeatherAndApiInfo["apiName"][];
  onChangeSource: (source: ClimaticInfoSource) => void;
}): JSX.Element {
  const intl = useIntl();

  const optimizedTerm = intl.formatMessage({ id: "report.source.optimized" });
  const editTerm = intl.formatMessage({ id: "action.edit" });

  return (
    <Dropdown<ClimaticInfoSource>
      controlPadding="0 0 0 0"
      arrowColor="black"
      listItems={[
        ...props.availableSources.map((source) => {
          let text = (() => {
            switch (source) {
              case "openWeather":
                return "OpenWeather";
              case "tomorrowIo":
                return "TomorrowIo";
              case "visualCrossing":
                return "VisualCrossing";
              case "weatherApi":
                return "WeatherAPI";
              case "optimized":
                return optimizedTerm;
            }
          })();

          return {
            value: source,
            text: text,
          };
        }),
        {
          value: "manual",
          text: editTerm,
        },
      ]}
      appearance={"img-icon"}
      onSelect={props.onChangeSource}
    />
  );
}

function ClimaticInfoSource(props: { source: ClimaticInfoSource }): JSX.Element {
  const intl = useIntl();

  const termSource = intl.formatMessage({ id: "report.source" });

  const termManual = intl.formatMessage({ id: "report.manualSource" });
  const optimizedUsingTerm = intl.formatMessage({ id: "report.optimizedUsing" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 20,
      }}
    >
      {termSource}: {props.source === "optimized" && optimizedUsingTerm}
      {(props.source === "optimized" || props.source === "openWeather") && <OpenWeatherCopy />}
      {(props.source === "optimized" || props.source === "tomorrowIo") && <TomorrowIoCopy />}
      {(props.source === "optimized" || props.source === "visualCrossing") && (
        <VisualCrossingCopy />
      )}
      {(props.source === "optimized" || props.source === "weatherApi") && <WeatherAPICopy />}
      {props.source === "manual" && termManual}
    </div>
  );
}

function OpenWeatherCopy(): JSX.Element {
  return (
    <a href="https://openweathermap.org/api" style={{ paddingLeft: 2.5 }}>
      ©OpenWeather
    </a>
  );
}

function TomorrowIoCopy(): JSX.Element {
  return (
    <a href="https://docs.tomorrow.io/reference/welcome" style={{ paddingLeft: 2.5 }}>
      ©TomorrowIo
    </a>
  );
}

function VisualCrossingCopy(): JSX.Element {
  return (
    <a href="https://www.visualcrossing.com/weather-api" style={{ paddingLeft: 2.5 }}>
      ©VisualCrossing
    </a>
  );
}

function WeatherAPICopy(): JSX.Element {
  return (
    <a href="https://www.weatherapi.com/" style={{ paddingLeft: 2.5 }}>
      ©WeatherAPI
    </a>
  );
}

const getClimaticInfoSourceAndData = (
  climaticInfo: WeatherAndApiInfo[] | undefined
):
  | (Omit<WeatherAndApiInfo, "apiName" | "location"> & { source: ClimaticInfoSource })
  | undefined => {
  const orderedSources: WeatherAndApiInfo["apiName"][] = [
    "optimized",
    "openWeather",
    "tomorrowIo",
    "visualCrossing",
    "weatherApi",
  ];

  for (const source of orderedSources) {
    const sourceData = climaticInfo?.find((e) => e.apiName === source);

    if (sourceData !== undefined) {
      return {
        ...sourceData,
        source: source,
      };
    }
  }

  return undefined;
};

function ClimaticInfo(props: {
  data:
    | (Omit<WeatherAndApiInfo, "apiName" | "location"> & { source: ClimaticInfoSource })
    | undefined;
  source: ClimaticInfoSource | undefined;
  editData: (
    newData: Omit<WeatherAndApiInfo, "apiName" | "location"> & { source: ClimaticInfoSource }
  ) => void;
  unitSystem: UnitSystem;
}): JSX.Element {
  const intl = useIntl();

  if (props.data === undefined || props.source === undefined) {
    return <div>{intl.formatMessage({ id: "generic.N/A" })}</div>;
  }

  const elementsSize = {
    height: 18,
    width: 18,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ImageTemperature height={elementsSize.height} width={elementsSize.width} />
      <div style={{ width: 2.5 }} />

      {props.source === "manual" ? (
        <NumberInput
          width={45}
          height={elementsSize.height}
          value={UnitConversionHelper.temperatureValue(props.data.temperature, props.unitSystem)}
          unit={UnitConversionHelper.temperatureUnit(props.unitSystem)}
          onChanged={(temperature) =>
            props.editData({
              ...props.data!,
              temperature: temperature,
            })
          }
        />
      ) : (
        <div style={{ width: 45 }}>{formatTemperature(props.data, props.unitSystem)}</div>
      )}

      <ImageHumidity height={elementsSize.height} width={elementsSize.width} />
      <div style={{ width: 2.5 }} />
      {props.source === "manual" ? (
        <NumberInput
          width={40}
          height={elementsSize.height}
          value={UnitConversionHelper.humidityValue(props.data.humidity)}
          unit={UnitConversionHelper.humidityUnit()}
          onChanged={(humidity) =>
            props.editData({
              ...props.data!,
              humidity: humidity,
            })
          }
        />
      ) : (
        <div style={{ width: 40 }}>{formatHumidity(props.data)}</div>
      )}

      <ImagePressure height={elementsSize.height} width={elementsSize.width} />
      <div style={{ width: 2.5 }} />
      {props.source === "manual" ? (
        <NumberInput
          width={55}
          height={elementsSize.height}
          value={UnitConversionHelper.pressureValue(props.data.pressure, props.unitSystem)}
          unit={UnitConversionHelper.pressureUnit(props.unitSystem)}
          onChanged={(pressure) =>
            props.editData({
              ...props.data!,
              pressure: pressure,
            })
          }
        />
      ) : (
        <div style={{ width: 55 }}>{formatPressure(props.data, props.unitSystem)}</div>
      )}

      <WindSpeedIcon
        height={elementsSize.height}
        width={elementsSize.width}
        angle={getWindDirectionAngle(props.data.windDegree)}
      />
      <div style={{ width: 2.5 }} />
      {props.source === "manual" ? (
        <NumberInput
          width={75}
          height={elementsSize.height}
          value={UnitConversionHelper.speedValue(props.data.windSpeed, props.unitSystem)}
          unit={UnitConversionHelper.speedUnit(props.unitSystem)}
          onChanged={(windSpeed) =>
            props.editData({
              ...props.data!,
              windSpeed: windSpeed,
            })
          }
        />
      ) : (
        <div style={{ width: 75 }}>{formatWind(props.data, props.unitSystem)}</div>
      )}
      {props.source === "manual" ? (
        <NumberInput
          width={57}
          height={elementsSize.height}
          value={props.data.windDegree.toFixed(3)}
          unit={"º"}
          onChanged={(windDegree) =>
            props.editData({
              ...props.data!,
              windDegree: windDegree,
            })
          }
        />
      ) : (
        <div style={{ width: 57, textAlign: "right" }}>{props.data.windDegree.toFixed(3)}º</div>
      )}
      <div style={{ width: 23 }}>{getWindDirectionAngleAcronym(props.data.windDegree, intl)}</div>
    </div>
  );
}

const NumberInput = (props: {
  height: number;
  width: number;
  value: string;
  onChanged: (value: number) => void;
  unit: string;
}): JSX.Element => {
  const [error, setError] = useState(false);

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const styles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        color: "black",
        fontSize: 13,
        // - The Input-root, inside the TextField-root
        "& fieldset": {
          // - The <fieldset> inside the Input-root
          borderColor: "transparent", // - Set the Input border
        },
        "&:hover fieldset": {
          borderColor: "transparent", // - Set the Input border when parent has :hover
        },
        "&.Mui-focused fieldset": {
          // - Set the Input border when parent is focused
          borderColor: "transparent",
        },
      },
    },
  })();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        color: "black",
        height: props.height,
      }}
    >
      <TextField
        classes={styles}
        style={{
          width: props.width,
        }}
        error={error}
        size="small"
        variant="outlined"
        value={value}
        onChange={(e) => {
          const source = e.target.value;

          let error = false;

          if (source === null || source === undefined) {
            error = true;
          } else {
            const parsedValue = parseInt(value);

            if (Number.isNaN(parsedValue)) {
              error = true;
            }
          }

          setError(error);
          setValue(source);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            const parsedValue = parseInt(value);

            if (!Number.isNaN(parsedValue)) {
              props.onChanged(parsedValue);
            }
          }
        }}
        inputProps={{
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "left",
          },
        }}
      />
      {props.unit}
    </div>
  );
};

function WindSpeedIcon(props: { height: number; width: number; angle: number }): JSX.Element {
  return (
    <div
      style={{
        height: props.height,
        width: props.width,
      }}
    >
      <div
        style={{
          display: "flex",
          transform: `rotate(${props.angle}deg)`,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <ImageSpeedWindIcon
          height={props.height - 5}
          width={props.width - 5}
          style={{
            color: "black",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        />
      </div>
    </div>
  );
}

function getWindDirectionAngle(direction: number): number {
  // The input wind direction is represented in meteorological form
  // (see: https://apollo.nvu.vsc.edu/classes/met130/notes/chapter8/wdir.html).
  // Here we convert that direction to a angular representation considering
  // that the image we need to rotate won't have rotation when the direction is 0º.

  return (direction + 180) % 360;
}

function formatTemperature(
  data: Omit<WeatherInfo, "location"> | undefined,
  unitSystem: UnitSystem
): string {
  if (!data || data.temperature === undefined) {
    return `N/A`;
  }
  return (
    UnitConversionHelper.temperatureValue(data.temperature, unitSystem) +
    UnitConversionHelper.temperatureUnit(unitSystem)
  );
}

function formatHumidity(data: Omit<WeatherInfo, "location"> | undefined): string {
  if (!data || data.humidity === undefined) {
    return `N/A`;
  }
  return UnitConversionHelper.humidityValue(data.humidity) + UnitConversionHelper.humidityUnit();
}

function formatPressure(
  data: Omit<WeatherInfo, "location"> | undefined,
  unitSystem: UnitSystem
): string {
  if (!data || data.pressure === undefined) {
    return `N/A`;
  }
  return (
    UnitConversionHelper.pressureValue(data.pressure, unitSystem) +
    UnitConversionHelper.pressureUnit(unitSystem)
  );
}

function getWindDirectionAngleAcronym(windDirection: number, intl: IntlShape): string {
  /// Following https://www.researchgate.net/figure/Classification-of-wind-directions-in-a-four-sectors-b-eight-sectors-c-sixteen_fig1_221914541#:~:text=The%20usual%20eight%20divisions%20for,NW%20(292.5%20to%20337.5).
  if (windDirection >= -22.5 && windDirection <= 22.5) {
    return intl.formatMessage({ id: "geographic.direction.northAcronym" });
  } else if (windDirection >= 22.5 && windDirection <= 67.5) {
    return intl.formatMessage({ id: "geographic.direction.northeastAcronym" });
  } else if (windDirection >= 67.5 && windDirection <= 112.5) {
    return intl.formatMessage({ id: "geographic.direction.eastAcronym" });
  } else if (windDirection >= 112.5 && windDirection <= 157.5) {
    return intl.formatMessage({ id: "geographic.direction.southeastAcronym" });
  } else if (windDirection >= 157.5 && windDirection <= 202.5) {
    return intl.formatMessage({ id: "geographic.direction.southAcronym" });
  } else if (windDirection >= 202.5 && windDirection <= 247.5) {
    return intl.formatMessage({ id: "geographic.direction.southWestAcronym" });
  } else if (windDirection >= 247.5 && windDirection <= 292.5) {
    return intl.formatMessage({ id: "geographic.direction.westAcronym" });
  } else if (windDirection >= 292.5 && windDirection <= 337.5) {
    return intl.formatMessage({ id: "geographic.direction.northwestAcronym" });
  } else {
    return intl.formatMessage({ id: "geographic.direction.northAcronym" });
  }
}

function formatWind(
  data: Omit<WeatherInfo, "location"> | undefined,
  unitSystem: UnitSystem
): string {
  if (!data || data.windSpeed === undefined || data.windDegree === undefined) {
    return `N/A`;
  }

  return (
    UnitConversionHelper.speedValue(data.windSpeed, unitSystem) +
    UnitConversionHelper.speedUnit(unitSystem)
  );
}
