import React from "react";
import Typography from "@material-ui/core/Typography";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ThemeProvider,
  createMuiTheme,
  Tooltip,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Theme } from "react-toastify";

export type ListItemAction = {
  text: string;
  action: () => void;
  disabled?: boolean;
};

type Props = {
  /** Provide a unique id for this node of the tree. */
  id: string;
  content: string;
  /**
   * When provided, this component will render in place of everything else, and will always ignore
   * all other props. onClick will still work, and will fire when the element is clicked anywhere.
   * When this is specified, this item will not expand.
   * */
  overrideBody?: React.ReactNode;
  subtitle?: string;
  /** True will make the text render with a highlight color. */
  highlighted?: boolean;
  /** True will make this item appear with a spinning icon and disable all actions. */
  loading?: boolean;
  /** Action to execute when this item is pressed. */
  onClick?: () => void;
  /** If provided, this will be rendered in a menu button. */
  actions?: ListItemAction[];
  /** If provided, these elements will be rendered to the right. */
  trailing?: React.ReactNode;
  /** Provide children of the same type. */
  children?: React.ReactNode;

  totalizer?: number;

  onOpenAction?: () => void;

  deleted: boolean;
};

const useStyle = makeStyles((theme: Theme) => ({
  iconContainer: (props: { totalizer?: number }) => ({
    marginBottom: props.totalizer !== undefined ? "11px" : "7.5x",
    marginRight: "1px",
  }),
}));

// Theme applied to the menu thar appears when clicking on the "three dots" button
const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiMenu: {
      list: {
        color: "var(--back)",
        padding: 0,
      },
      paper: {
        backgroundColor: "var(--dashboard-background)",
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "6px",
        paddingRight: "6px",
        "&:hover": {
          backgroundColor: "var(--dashboard-background)",
        },
      },
    },
  },
});

/**
 * Item for any of the tree view items, at any level.
 * Can be customized with any actions.
 * Note that the open and close state of each item is controlled by the top-level
 * tree component only.
 */
export function CustomTreeItem(props: Props): JSX.Element {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setMenuAnchor(null);
  };

  const classes = useStyle(props);
  const intl = useIntl();

  if (props.overrideBody) {
    return (
      <TreeItem
        nodeId={props.id}
        onClick={(event) => {
          event.preventDefault();
          props.onClick?.();
        }}
        label={props.overrideBody}
      />
    );
  }

  return (
    <TreeItem
      onIconClick={() => {
        if (props.onOpenAction !== undefined) {
          props.onOpenAction();
        } else {
          props.onClick?.();
        }
      }}
      classes={classes}
      nodeId={props.id}
      onLabelClick={(event) => {
        event.preventDefault();
      }}
      label={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: props.totalizer !== undefined ? 40 : 35,
            background: props.totalizer !== undefined ? "" : "",
            paddingBottom: props.totalizer !== undefined ? "" : "1px",
          }}
        >
          {props.subtitle ? (
            <div
              style={{
                display: "flex",
                flex: "1 1 content",
                flexDirection: "column",
                marginRight: "1em",
              }}
            >
              <Typography
                onClick={() => {
                  props.onClick?.();
                }}
                variant="body2"
                style={{
                  color: props.highlighted ? "#ef7622" : undefined,
                  opacity: props.deleted ? 0.5 : 1,
                }}
              >
                {props.content}
              </Typography>
              <Typography
                onClick={() => {
                  props.onClick?.();
                }}
                variant="body2"
                style={{
                  color: "#808080",
                  fontSize: "0.75rem",
                  lineHeight: "0.83",
                }}
              >
                {props.subtitle}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flex: "1 1 content",
                flexDirection: "column",
                marginRight: "1em",
              }}
            >
              <Typography
                onClick={() => {
                  props.onClick?.();
                }}
                style={{
                  color: props.highlighted ? "#ef7622" : undefined,
                  opacity: props.deleted ? 0.5 : 1,
                  marginTop: 2,
                }}
              >
                {props.content}
              </Typography>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: props.totalizer !== undefined ? "-0.7em" : "",
            }}
          >
            {props.loading ? (
              <CircularProgress size={18} style={{ marginRight: -10, marginLeft: 20 }} />
            ) : (
              <>
                {props.totalizer !== undefined && (
                  <p
                    style={{
                      marginLeft: 5,
                      marginRight: 0,
                      color: "#151515",
                      fontSize: 12.5,
                      marginTop: "1.2em",
                    }}
                  >
                    {props.totalizer}
                  </p>
                )}
              </>
            )}
            {props.trailing !== undefined && props.trailing}
            {props.actions && props.actions.length > 0 ? (
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Tooltip title={intl.formatMessage({ id: "map.moreOptions" })}>
                  <IconButton
                    onClick={handleOpenMenu}
                    style={{ padding: "0px", color: "var(--black)" }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <ThemeProvider theme={theme}>
                  <Menu
                    id={`${props.id}-actions-menu`}
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={handleCloseMenu}
                  >
                    {props.actions.map((item, i) => (
                      <MenuItem
                        key={i}
                        onClick={(event) => {
                          event.preventDefault();

                          if (item.disabled) return;

                          setMenuAnchor(null);
                          item.action();
                        }}
                        disabled={item.disabled}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Menu>
                </ThemeProvider>
              </div>
            ) : (
              <div style={{ paddingRight: 28 }}></div>
            )}
          </div>
        </div>
      }
    >
      {props.children}
    </TreeItem>
  );
}
