import React from "react";
import L from "leaflet";

type Props = {
  children: (registerControl: {
    setPolygonOrPolylineRef: (
      id: string,
      ref: L.Polygon | L.Polyline | null,
      zIndex: number
    ) => void;
    onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
  }) => JSX.Element;
};

export default (props: Props): JSX.Element => {
  let controlZIndex: {
    [id: string]: {
      zIndex: number;
      ref: L.Polyline | L.Polygon;
    };
  } = {};

  const onRenderPolygonOrPolyline = (id: string, zIndex: number) => {
    const element = controlZIndex[id];
    if (element === undefined) return;

    controlZIndex[id] = {
      ...element,
      zIndex: zIndex,
    };

    element.ref.bringToBack();

    /// We need to put every other elements that have an zIndex lower than that element to get back
    const otherElements = Object.values(controlZIndex).filter((e) => e.zIndex < zIndex);
    otherElements.sort((a, b) => (a.zIndex > b.zIndex ? -1 : 1));

    for (const otherElement of otherElements) {
      otherElement.ref.bringToBack();
    }

    return;
  };

  const setPolygonOrPolylineRef = (
    id: string,
    ref: L.Polygon | L.Polyline | null,
    zIndex: number
  ) => {
    if (ref === null) {
      delete controlZIndex[id];
    } else {
      controlZIndex = {
        ...controlZIndex,
        [id]: {
          zIndex: zIndex,
          ref: ref,
        },
      };
    }
    onRenderPolygonOrPolyline(id, zIndex);
  };

  return props.children({
    setPolygonOrPolylineRef: setPolygonOrPolylineRef,
    onRenderPolygonOrPolyline: onRenderPolygonOrPolyline,
  });
};
