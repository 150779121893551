import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import CurrentLocation from "../../components/current_location";

export default (): JSX.Element => {
  const currentLocation = useSelector((state: SystemState) => state.projectTree.userLocation);

  if (currentLocation === null || currentLocation === undefined) return <></>;

  return <CurrentLocation location={currentLocation} />;
};
