import React, { useEffect, useState } from "react";
import { boundingBoxDiagonalSize } from "../../../../core/geometricFunctions";
import { useMapEvents } from "react-leaflet";

export default (props: { getChild: (pixelsPerMeters: number) => JSX.Element }): JSX.Element => {
  const [pixelsPerMeter, setPixelsPerMeter] = useState<number>(0);

  const map = useMapEvents({
    zoomend: () => fetchMapDiagonalSize(),
    moveend: () => fetchMapDiagonalSize(),
    resize: () => fetchMapDiagonalSize(),
  });

  const fetchMapDiagonalSize = () => {
    const pixelBounds = map.getPixelBounds();
    if (pixelBounds.max === undefined || pixelBounds.min === undefined) {
      setPixelsPerMeter(0);

      return;
    }
    const diagonalSizeInPixels = Math.sqrt(
      Math.pow(pixelBounds.max.x - pixelBounds.min.x, 2) +
        Math.pow(pixelBounds.max.y - pixelBounds.min.y, 2)
    );

    const mapBounds = map.getBounds();
    const diagonalSizeInMeters = boundingBoxDiagonalSize({
      north: mapBounds.getNorth(),
      south: mapBounds.getSouth(),
      east: mapBounds.getEast(),
      west: mapBounds.getWest(),
    });

    const newPixelsPerMeter = diagonalSizeInPixels / diagonalSizeInMeters;

    if (newPixelsPerMeter === pixelsPerMeter) return;

    setPixelsPerMeter(newPixelsPerMeter);
  };

  useEffect(() => fetchMapDiagonalSize(), []);

  console.log(pixelsPerMeter);

  return props.getChild(pixelsPerMeter);
};
