import { Flight, ReleaserAction, Waypoint } from "biohub-model";
import React from "react";
import FlightRoute from "../components/flight_route";
import FlightReleaseTracks from "../components/flight_release_tracks";
import OpenedAreaPolygon from "../components/opened_area_polygon";
import PlannedAreaRoute from "../components/planned_area_route";
import WaypointComponent from "../components/waypoint";
import L from "leaflet";
import ControlPolylineAndPolygonZIndex from "./ControlPolylineAndPolygonZIndex";

export default (props: {
  flight: Flight;
  mode: "plan" | "flight";
  map: L.Map;
  mapRotation: number;
}): JSX.Element => {
  const flightStartedAt = props.flight.startedAt;
  const flightStartedAtInMs = flightStartedAt.getTime();
  const releaseTracksZIndex = flightStartedAtInMs;
  const flightRouteBorderZIndex = flightStartedAtInMs + 1;
  const flightRouteZIndex = flightStartedAtInMs + 2;

  const areaPolygonId = `${props.flight.id}-area-polygon`;
  const areaPlannedPolygonBaseId = `${props.flight.id}-area-planned-route`;

  return (
    <ControlPolylineAndPolygonZIndex>
      {(registerControl) => (
        <>
          <OpenedAreaPolygon
            polygon={props.flight.flightEnvironmentSnapshot.plannedArea.polygon}
            onRender={() => registerControl.onRenderPolygonOrPolyline(areaPolygonId, 0)}
            setPolygonRef={(ref) => registerControl.setPolygonOrPolylineRef(areaPolygonId, ref, 0)}
          />
          <PlannedAreaRoute
            mapScaleSource="map"
            waypointsLocations={props.flight.flightEnvironmentSnapshot.plannedArea.route.waypoints.map(
              (waypoint) => waypoint.location
            )}
            map={props.map}
            polylineBaseId={areaPlannedPolygonBaseId}
            setPlannedRouteBorderRef={(id, ref) =>
              registerControl.setPolygonOrPolylineRef(id, ref, 1)
            }
            setPlannedRouteRef={(id, ref) => registerControl.setPolygonOrPolylineRef(id, ref, 2)}
            onRenderPlannedRoute={(id) => registerControl.onRenderPolygonOrPolyline(id, 2)}
            onRenderPlannedRouteBorder={(id) => registerControl.onRenderPolygonOrPolyline(id, 1)}
          />
          <FlightWaypoints flight={props.flight} map={props.map} mapRotation={props.mapRotation} />
          {props.mode === "flight" && (
            <>
              <FlightRoute
                flowRoute={props.flight.realData?.completeRoute ?? []}
                setReleaseRouteBorderRef={(ref) =>
                  registerControl.setPolygonOrPolylineRef(
                    `${props.flight.id}-route-border`,
                    ref,
                    flightRouteBorderZIndex
                  )
                }
                setReleaseRouteRef={(ref) =>
                  registerControl.setPolygonOrPolylineRef(
                    `${props.flight.id}-route`,
                    ref,
                    flightRouteZIndex
                  )
                }
                onRender={() => {
                  registerControl.onRenderPolygonOrPolyline(
                    `${props.flight.id}-route-border`,
                    flightRouteBorderZIndex
                  );
                  registerControl.onRenderPolygonOrPolyline(
                    `${props.flight.id}-route`,
                    flightRouteZIndex
                  );
                }}
              />
              <FlightReleaseTracks
                mapScaleSource="map"
                trackWidth={props.flight.flightEnvironmentSnapshot.trackWidth}
                flightReleaseSegments={
                  props.flight.realData?.logData.map((log) => log.releaseRoute) ?? []
                }
                releaseTracksBaseId={`${props.flight.id}-release-tracks`}
                setReleaseTracksRef={(id, ref) =>
                  registerControl.setPolygonOrPolylineRef(id, ref, releaseTracksZIndex)
                }
                onRenderReleaseTrack={(id) =>
                  registerControl.onRenderPolygonOrPolyline(id, releaseTracksZIndex)
                }
              />
            </>
          )}
        </>
      )}
    </ControlPolylineAndPolygonZIndex>
  );
};

const FlightWaypoints = (props: {
  flight: Flight;
  map: L.Map;
  mapRotation: number;
}): JSX.Element => {
  const homePointElevation = props.flight.flightEnvironmentSnapshot.plannedArea.homePointElevation;
  const waypoints: (Waypoint & { activeLiberation: boolean })[] = [];

  let activeLiberation = false;

  const flightWaypoints = props.flight.flightEnvironmentSnapshot.plannedArea.route.waypoints;

  for (let i = 0; i < flightWaypoints.length; i++) {
    const waypoint = flightWaypoints[i];

    let hasAnyReleasingChange = false;

    for (const [key, value] of Object.entries(waypoint.releaserActions)) {
      if (!hasAnyReleasingChange) {
        if (!activeLiberation && value.type === ReleaserAction.release) {
          hasAnyReleasingChange = true;
          activeLiberation = true;
        } else if (activeLiberation && value.type === ReleaserAction.stopRelease) {
          hasAnyReleasingChange = true;
          activeLiberation = false;
        }
      }
    }

    waypoints.push({
      ...waypoint,
      activeLiberation: activeLiberation,
    });
  }

  const usingOnlineElevation = props.flight.flightEnvironmentSnapshot.mustConsiderRelief;

  return (
    <>
      {waypoints.map((waypoint, index) => (
        <WaypointComponent
          mapScaleSource="map"
          isActiveLiberation={waypoint.activeLiberation}
          isSelected={false}
          waypointIndex={index}
          waypointLocation={waypoint.location}
          waypointHeading={waypoint.orientation}
          waypointHeight={waypoint.height}
          homePointElevation={homePointElevation}
          waypointElevation={usingOnlineElevation ? waypoint.elevation : undefined}
          mapRotation={props.mapRotation}
          map={props.map}
        />
      ))}
    </>
  );
};
