import React from "react";
import { Location } from "biohub-model";
import Marker from "./marker";

export default (props: {
  points: Location[];
  onVertexMovementStart: () => void;
  onVertexMoved: (index: number, newLocation: Location) => void;
}): JSX.Element => {
  return (
    <>
      {props.points.map((vertexPoint, vertexIndex) => (
        <Marker
          position={vertexPoint}
          draggable={true}
          onDragStart={props.onVertexMovementStart}
          onDragEnd={(newLocation) => props.onVertexMoved(vertexIndex, newLocation)}
          zIndex={vertexIndex}
        />
      ))}
    </>
  );
};
