import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";

export default (props: { getChild: (pixelsPerMeters: number) => JSX.Element }): JSX.Element => {
  const pixelsPerMeter = useSelector(
    (state: SystemState) => {
      const mapState = state.projectTree.mapState;

      return mapState.visibleRegionDiagonalInPixels / mapState.boundingBoxDiagonalSize;
    },
    (a, b) => a.toFixed(18) === b.toFixed(18)
  );

  return props.getChild(pixelsPerMeter);
};
