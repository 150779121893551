import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { stat } from "fs";
import { EditingAreaType } from "../../../store/reducers/projectTreeReducer";
import { Box } from "@material-ui/core";
import CompleteNumberEditor from "../../../components/Atomic/Inputs/CompleteNumberEditor";
import { changeEditingAreaMissionPlannerRotation } from "../../../store/actions/projectTreeActions";
import { ContainerMapInfosOnTheMap, DataInfoContainer } from "../modals/styles";
import { useIntl } from "react-intl";

export default (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const rotationAngle = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;
    switch (editingArea?.type) {
      case EditingAreaType.MissionPlannerRotation:
        return editingArea.routeAngle;
      default:
        return undefined;
    }
  });

  if (rotationAngle === undefined) return <></>;

  return (
    <Box
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "flex-end",
        flex: 1,
        alignItems: "flex-end",
        flexDirection: "column",
        zIndex: 20,
        backgroundColor: "#ffffff00", // transparent
        pointerEvents: "none",
        top: 0,
        left: 0,
        right: 5,
        bottom: 120,
      }}
    >
      <ContainerMapInfosOnTheMap>
        <DataInfoContainer style={{ zIndex: 2 }}>
          <CompleteNumberEditor
            initialValue={rotationAngle}
            unitText={"º"}
            label={intl.formatMessage({ id: "map.plannedRoute.routeAngle" })}
            minValue={-90}
            maxValue={90}
            onChange={(rotation) => {
              dispatch(changeEditingAreaMissionPlannerRotation(rotation));
            }}
            colorScheme={"white"}
            gridScheme={"default"}
            inComingConversionFactor={1}
            outComingConversionFactor={1}
            validateValue={() => true}
          />
        </DataInfoContainer>
      </ContainerMapInfosOnTheMap>
    </Box>
  );
};
