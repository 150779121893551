import React from "react";
import { Location } from "biohub-model";
import { Polyline } from "react-leaflet";

export default (props: { points: Location[] }): JSX.Element => {
  return (
    <>
      <Polyline
        ref={(polyline) => polyline?.bringToFront()}
        positions={props.points}
        pathOptions={{
          color: "black",
        }}
      />
      {props.points.length >= 3 && (
        <Polyline
          ref={(polyline) => polyline?.bringToFront()}
          positions={[props.points[props.points.length - 1], props.points[0]]}
          pathOptions={{
            dashArray: "20 20",
            color: "black",
          }}
        />
      )}
    </>
  );
};
