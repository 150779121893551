import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

type DesignerSize = {
  direction?: string;
  margin?: string;
  width?: string;
  alignSelf?: string;
  objectFit?: string;
};

type SizeContainerImage = {
  width: string | number;
  height: string | number;
  marginBottom?: string | number;
  position?: string;
  margin?: string;
};

type DesignerLine = {
  bgColor?: string;
  color?: string;
  width?: string;
  fontSize?: string;
};

type SizeParagraphs = {
  fontSize?: string;
  fontSizeTitle?: string;
};

type Props = {
  idImage?: string;
  children: React.ReactNode;
  children2?: React.ReactNode;
  height?: number;
  width?: string;
};

type ImageElements = {
  urlImage: string;
  text?: string;
};

const size = {
  height: "50px",
  width: "50px",
};

const sizeWidth = window.innerWidth;
const sizeHeight = window.innerHeight;
let fontSize: any;
let fontSizeTitle;
let containerImageHeight;
let containerImageWidth;
let waypointSize;
let arrow;
let margin;

if (sizeWidth < 600) {
  size.height = "35px";
  size.width = "35px";
  fontSize = "10px";
  fontSizeTitle = "14px";
  containerImageHeight = "40px";
  containerImageWidth = "56px";
  waypointSize = "40px";
  arrow = "35px";
  margin = "5px";
} else {
  size.height = "50px";
  size.width = "50px";
  fontSize = "12px";
  fontSizeTitle = "17px";
  containerImageHeight = "70px";
  containerImageWidth = "98px";
  waypointSize = "60px";
  arrow = "55px";
  margin = "15px";
}

export default function LegendForMapElementComponents(props: Props): JSX.Element {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const hasSubElements = props.children2 !== undefined;

  return (
    <EachLegendElementContainer
      direction="column"
      style={
        hasSubElements
          ? {
              cursor: "pointer",
            }
          : {}
      }
      onClick={(e) => {
        e.preventDefault();

        if (!hasSubElements) return;

        setExpanded(!expanded);
      }}
    >
      <DivElements direction="row">
        <ContainerImage
          height={props.height ? props.height + "px" : ""}
          width={props.width ? props.width : size.width}
        >
          {props.children}
        </ContainerImage>
        <Paragraph>{props.idImage ? intl.formatMessage({ id: props.idImage }) : null}</Paragraph>
        {props.children2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "relative",
              paddingRight: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "30px",
                  width: "30px",
                  paddingLeft: "15px",
                  marginBottom: "9px",
                  transition: "all 0.15s linear",
                }}
              >
                <ToggleButton expanded={expanded} />
              </div>
            </div>
          </div>
        )}
      </DivElements>
      <>
        {expanded ? (
          <div
            style={{
              display: "flex",
              backgroundColor: "#efefef",
              boxShadow: "none",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {props.children2}
          </div>
        ) : null}
      </>
    </EachLegendElementContainer>
  );
}

export function DrawingElementsInformation(props: ImageElements): JSX.Element {
  return (
    <>
      <Image src={props.urlImage} height="100%" width="57%" />
      <TextUponImage fontSize={fontSize} bgColor="black">
        {props.text !== undefined ? props.text : null}
      </TextUponImage>
    </>
  );
}

const DivElements = styled.div<DesignerSize>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => props.direction};
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`;

const EachLegendElementContainer = styled.div<DesignerSize>`
  display: flex;
  width: 95%;
  flex-direction: ${(props) => props.direction};
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  align-self: ${(props) => props.alignSelf || ""};
  border-radius: 5px;
  border-width: 1px;
  justify-content: flex-end;
  background: var(--dashboard-background);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
  padding: 5px;
`;

const ContainerImage = styled.div<SizeContainerImage>`
  display: flex;
  flex-direction: row;
  position: ${(props) => props.position || "relative"};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  align-items: center;
  touch-action: none;
  justify-content: center;
  z-index: 9;
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin: ${(props) => props.margin || ""};
`;

const Image = styled.img<DesignerSize>`
  position: absolute;
  user-select: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  opacity: 0.95;
  object-fit: ${(props) => props.objectFit || "cover"};
`;

const TextUponImage = styled.p<DesignerLine>`
  position: relative;
  margin: 0 auto;
  color: ${(props) => props.bgColor || "#fff"}; /* Cor do texto */
  font-size: ${(props) => props.fontSize}; /* Tamanho da fonte do texto */
  font-weight: bold; /* Peso da fonte do texto */
  margin-top: 0px;
`;

const Paragraph = styled.p<SizeParagraphs>`
  font-size: ${(props) => props.fontSize || "12px"};
  margin: 0 auto;
  margin-left: 15px;
  width: 100%;
  position: relative;
`;

type ToggleButtonProps = {
  expanded: boolean;
};

export function ToggleButton(props: ToggleButtonProps): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        width: "24px",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
        padding: "5px",
        pointerEvents: "auto",
      }}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {!props.expanded ? (
        <KeyboardArrowDown style={{ color: "var(--black)" }} />
      ) : (
        <KeyboardArrowUp style={{ color: "var(--black)" }} />
      )}
    </div>
  );
}
