import { Location, Role, UnitSystem } from "biohub-model";
import React from "react";
import { distanceBetween } from "../../../../core/geometricFunctions";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { formatDistance } from "../utils/format";
import DistanceLabel from "./distance_label";

export default (props: {
  locationA: Location;
  locationB: Location;
  mapScaleSource: "map" | "state";
  map: L.Map;
  zIndex: number;
}): JSX.Element => {
  const distance = distanceBetween(props.locationA, props.locationB);
  const intermediatePoint: Location = {
    lat: (props.locationA.lat + props.locationB.lat) / 2,
    lng: (props.locationA.lng + props.locationB.lng) / 2,
  };
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const formattedDistance = formatDistance(distance, unitSystem);

  return (
    <DistanceLabel
      labelLines={[formattedDistance]}
      location={intermediatePoint}
      mapScaleSource={props.mapScaleSource}
      map={props.map}
      zIndex={props.zIndex}
    />
  );
};
