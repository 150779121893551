import { BoundingBox, Location } from "biohub-model";
import BaseMapController from "./BaseMapController";
import MapImplLeaflet from "./impl/MapImplLeaflet";
import React from "react";
import L from "leaflet";

export type BaseMapProps = {
  onClick: (location: Location) => void;
  /** Callback with a controller that can be used to programmatically affect the map. */
  onInitialized: (controller: BaseMapController) => void;
  onZoomChanged: (newValue: number) => void;
  onCurrentCenterChanged: (newValue: Location) => void;
  onMapBoundsChanged: (newBounds: BoundingBox) => void;
  onMapDigitalSizeChanged: (height: number, width: number) => void;

  getChildren?: (map: L.Map) => JSX.Element;
};

export type BaseMapTypeId = "satellite" | "roadmap";

export default (props: BaseMapProps) => {
  return <MapImplLeaflet {...props} />;
};
