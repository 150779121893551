import React, { useEffect, useState } from "react";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";
import Constants from "../../../../Constants";
import Axios from "axios";
import { TileLayer } from "react-leaflet";
import { mapMaximumZoom } from "../MapImplLeaflet";

export type MapType = "roadmap" | "satellite" | "hybrid";

export default (props: { mapType: MapType; localeCode: BiohubLocale }): JSX.Element => {
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const [usingFallbackImplementation, setUsingFallbackImplementation] = useState<boolean>(false);

  const testApiLimits = async (sessionToken: string) => {
    try {
      const url = `https://tile.googleapis.com/v1/2dtiles/0/0/0?session=${sessionToken}&key=${Constants.GOOGLE_MAPS_TILES_API_KEY}`;

      const response = await Axios.get(url, { validateStatus: () => true });
      if (response.status === 429) {
        setUsingFallbackImplementation(true);
      }
    } catch (e) {
      setUsingFallbackImplementation(true);
    }
  };
  const getSessionToken = async () => {
    const url = `https://tile.googleapis.com/v1/createSession?key=${Constants.GOOGLE_MAPS_TILES_API_KEY}`;

    let requestBody: any = {
      imageFormat: "png",
      language: props.localeCode,
      region: (() => {
        switch (props.localeCode) {
          case "en":
            return "EN";
          case "pt-BR":
            return "BR";
          case "es":
            return "CO";
          case "fr":
            return "FR";
        }
      })(),
      scale: "scaleFactor2x",
      highDpi: true,
    };
    if (props.mapType === "hybrid" || props.mapType === "satellite") {
      requestBody = {
        ...requestBody,
        mapType: "satellite",
      };
    } else {
      requestBody = {
        ...requestBody,
        mapType: props.mapType,
      };
    }

    if (props.mapType === "hybrid") {
      requestBody = {
        ...requestBody,
        layerTypes: ["layerRoadmap"],
      };
    }

    const createSession = await Axios.post(url, requestBody, { validateStatus: () => true });

    if (createSession.status >= 200 && createSession.status < 300) {
      const sessionToken = createSession.data["session"] as string;

      setSessionToken(sessionToken);
      testApiLimits(sessionToken);
    } else {
      setUsingFallbackImplementation(true);
    }
  };

  useEffect(() => {
    getSessionToken();
  }, [props.mapType, props.localeCode]);

  if (sessionToken === null) {
    return <></>;
  }

  let url = `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${sessionToken}&key=${Constants.GOOGLE_MAPS_TILES_API_KEY}`;
  if (usingFallbackImplementation) {
    if (props.mapType === "hybrid") {
      url = "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}";
    } else if (props.mapType === "roadmap") {
      url = "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";
    } else if (props.mapType === "satellite") {
      url = "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
    }
  }

  return (
    <TileLayer
      key={url}
      url={url}
      subdomains={["mt0", "mt1", "mt2", "mt3"]}
      maxZoom={mapMaximumZoom}
    />
  );
};
