import React from "react";
import WithFontSizeInMetersPattern from "./with_font_size_in_meters_pattern";
import DistanceLabel from "./distance_label";
import { Tooltip } from "react-leaflet";
import { Location, Role, UnitSystem } from "biohub-model";
import L from "leaflet";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { formatDistance } from "../utils/format";
import { useSelector } from "react-redux";
import Marker, { DraggableProps } from "./marker";
import _ from "lodash";

type MarkerProps = {
  mapScaleSource: "map" | "state";
  waypointLocation: Location;
  waypointHeading: number | undefined;
  mapRotation: number;
  isActiveLiberation: boolean;
  isSelected: boolean;
  waypointIndex: number;
  waypointHeight: number | undefined;
  waypointElevation?: number;
  homePointElevation?: number;
  showHeading?: false;
  onClick?: () => void;
  map: L.Map;
} & DraggableProps;

const MarkerImplementation = (props: MarkerProps): JSX.Element => {
  const markerIconUrl = ((): string => {
    if (props.isActiveLiberation) {
      if (props.isSelected) {
        return "waypoint_green_circle_external_green";
      }

      return "waypoint_blue_circle_external_green";
    } else {
      if (props.isSelected) {
        return "waypoint_green_circle_external_red";
      }

      return "waypoint_blue_circle_external_red";
    }
  })();

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  let waypointHeightLines: string[] | undefined;
  if (props.waypointHeight !== undefined) {
    if (props.homePointElevation !== undefined && props.waypointElevation !== undefined) {
      const fittedWaypointHeight = formatDistance(
        props.waypointHeight - props.homePointElevation + props.waypointElevation,
        unitSystem
      );

      waypointHeightLines = [fittedWaypointHeight];
    } else {
      const waypointFormattedHeight = formatDistance(props.waypointHeight, unitSystem);

      waypointHeightLines = [waypointFormattedHeight];
    }
  }

  return (
    <WithFontSizeInMetersPattern
      scaleSource={props.mapScaleSource}
      location={props.waypointLocation}
      getChild={(waypointLocation, fontSizeInPixels, fontSizeInMeters) => {
        const size = fontSizeInPixels * 4;

        const waypointHeadingHeight = size * 0.45;
        const waypointHeadingWidth = (size * 3) / 4;

        const headingRegionHeight = size + waypointHeadingHeight + size * 0.2;
        const headingRegionWidth = waypointHeadingWidth;

        const heightRegionOffsetInMeters = fontSizeInMeters * 3.5;

        let headingRotation: number | undefined;
        if (props.waypointHeading !== undefined) {
          headingRotation = props.waypointHeading + props.mapRotation;
        }
        return (
          <>
            {waypointHeightLines !== undefined && (
              <DistanceLabel
                mapScaleSource={props.mapScaleSource}
                location={waypointLocation}
                labelLines={waypointHeightLines}
                offsetInMeters={heightRegionOffsetInMeters}
                map={props.map}
                zIndex={5 + props.waypointIndex * 2}
              />
            )}
            <Marker
              {...props}
              position={waypointLocation}
              icon={
                new L.Icon({
                  iconUrl: `/${markerIconUrl}.svg`,
                  iconSize: [size, size],
                })
              }
              onClick={props.onClick}
              zIndex={4 + props.waypointIndex * 2}
            >
              <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
                <div
                  style={{
                    height: headingRegionHeight,
                    width: headingRegionWidth,
                    position: "relative",
                    transform: `rotate(${headingRotation}deg)`,
                  }}
                >
                  <div
                    style={{
                      fontSize: fontSizeInPixels,
                      height: headingRegionHeight,
                      width: headingRegionWidth,
                      textAlign: "center",
                      alignContent: "center",
                      position: "absolute",
                      transform: `rotate(-${headingRotation}deg)`,
                    }}
                  >
                    {`${props.waypointIndex + 1}`}
                  </div>
                  {headingRotation !== undefined && (
                    <div
                      style={{
                        height: headingRegionHeight,
                        width: headingRegionWidth,
                        textAlign: "center",
                        alignContent: "baseline",
                        position: "absolute",
                      }}
                    >
                      <img
                        src="/waypoint_direction.png"
                        style={{
                          width: waypointHeadingWidth,
                          height: waypointHeadingHeight,
                          verticalAlign: "top",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Tooltip>
            </Marker>
          </>
        );
      }}
    />
  );
};

export default React.memo(MarkerImplementation, (prevProps, newProps) => {
  if (
    !_.isEqual(
      removeMarkerPropsOnClickMapAndDraggableFunctions(prevProps),
      removeMarkerPropsOnClickMapAndDraggableFunctions(newProps)
    )
  ) {
    return false;
  }

  return true;
});

function removeMarkerPropsOnClickMapAndDraggableFunctions(
  props: MarkerProps
): Omit<MarkerProps, "map" | "onClick"> {
  const { map, onClick, ...restProps } = props;
  if (restProps.draggable) {
    const { onDragStart, onDragEnd, ...restProps2 } = restProps;

    return restProps2;
  }

  return restProps;
}
