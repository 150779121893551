import { Location } from "biohub-model";
import React from "react";
import { Polyline } from "react-leaflet";
import WithPixelsPerMeters from "./with_pixels_per_meters";
import L from "leaflet";

export default (props: {
  mapScaleSource: "map" | "state";
  releaseTracksBaseId: string;
  setReleaseTracksRef: (id: string, ref: L.Polyline | null) => void;
  onRenderReleaseTrack: (id: string) => void;
  flightReleaseSegments: Location[][][];
  trackWidth: number;
}): JSX.Element => {
  return (
    <>
      {props.flightReleaseSegments.map((flightReleaseSegments, i1) => (
        <>
          {flightReleaseSegments.map((releaserSegment, i2) => (
            <WithPixelsPerMeters
              source={props.mapScaleSource}
              getChild={(pixelsPerMeter) => (
                <ShowFlightReleaseTracksForASegment
                  setPolylineRef={(ref) =>
                    props.setReleaseTracksRef(`${props.releaseTracksBaseId}-${i1}-${i2}`, ref)
                  }
                  segment={releaserSegment}
                  trackWidth={props.trackWidth}
                  pixelsPerMeter={pixelsPerMeter}
                  onRender={() => {
                    props.onRenderReleaseTrack(`${props.releaseTracksBaseId}-${i1}-${i2}`);
                  }}
                />
              )}
            />
          ))}
        </>
      ))}
    </>
  );
};

const ShowFlightReleaseTracksForASegment = (props: {
  setPolylineRef: (ref: L.Polyline | null) => void;
  segment: Location[];
  trackWidth: number;
  pixelsPerMeter: number;
  onRender: () => void;
}) => {
  const weight = props.pixelsPerMeter * props.trackWidth;

  props.onRender();

  return (
    <Polyline
      ref={(ref) => props.setPolylineRef(ref)}
      positions={props.segment}
      pathOptions={{
        color: "rgba(50, 205, 50, 128)",
        opacity: 0.4,
        weight: weight,
      }}
    />
  );
};
