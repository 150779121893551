import { SystemAction } from "../actions/systemActions";
import { ADD_PLAN, CLEAR_PLANS } from "../actions/plansActions";

// State

/** TODO: Probably get this type from somewhere else (declare it in a service) */
export type Plan = any;

export type PlansState = {
  data: Plan[];
};

const INITIAL_STATE: PlansState = {
  data: [],
};

// Reducer
export function plansReducer(state = INITIAL_STATE, action: SystemAction): PlansState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case ADD_PLAN:
      return {
        ...effectiveState,
        data: [action.payload],
      };
    case CLEAR_PLANS:
      return {
        ...effectiveState,
        data: [],
      };
    default:
      return effectiveState;
  }
}
