import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Address, Company, DirectClient, Profile, ProfilePreferences, Role } from "biohub-model";
import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import UserTableComponent from "../../../components/Atomic/UserTable";
import { TableList, Modal } from "../styles";
import { v4 as uuid } from "uuid";
import { searchZipCode } from "../../../services/SearchPlaces";
import { LocationSearchingTwoTone } from "@material-ui/icons";
import _ from "lodash";
import { isValidPhone, isValidEmail } from "../../../services/FormValidatorsService";
import InsertPhone from "../../../components/Atomic/Inputs/Phone";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Country, loadLocales, getCountries } from "../../../services/NationalityService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { addDirectClient } from "../../../store/actions/masterActions";

export default function () {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((state: SystemState) => state.master.readindDirectClients);
  const directClients = useSelector((state: SystemState) => state.master.directClients ?? []);

  const [open, setOpen] = useState<boolean>(false);
  const [editingDirectClient, setEditingDirectClient] = useState<DirectClient>();

  const localeSystem = useSelector((state: SystemState) => state.locale.localeCode);
  const [countries, setCountries] = useState<Country[]>([]);
  const [locale, setLocale] = React.useState("pt");

  const loadCountries = () => {
    loadLocales();
    if (localeSystem.length > 2) {
      setLocale(localeSystem.split("-")[0]);
    } else {
      setLocale(localeSystem);
    }
    setCountries(getCountries(locale));
  };

  useEffect(() => {
    loadCountries();
  }, [localeSystem]);

  const handleClickOpen = (directClient: DirectClient) => {
    setEditingDirectClient(directClient);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingDirectClient(undefined);
  };

  const handleAddDirectClient = (form: DirectClientFormState) => {
    const adminProfile = form.profiles[0] as Profile & { role: Role.admin };
    dispatch(
      addDirectClient(
        {
          id: uuid(),
          contract: {
            id: uuid(),
            name: adminProfile.name,
            documentUrl: "...url...",
            createdAt: new Date(),
            addendums: [],
          },
          company: form.isCompany ? form.company : undefined,
        },
        adminProfile
      )
    );
    handleClose();
  };

  const classes = useStyles();

  //terms
  const tableName = intl.formatMessage({ id: "info.directClients" });
  const termCompany = intl.formatMessage({ id: "placeholder.company" });
  const termAdministrator = intl.formatMessage({ id: "info.role.administrator" });
  const termUsers = intl.formatMessage({ id: "info.users" });
  const addClientTerm = intl.formatMessage({ id: "info.directClient.add" });
  //TODO: Put emergency unblock request timestamp in that table after creating that feature
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <UserTableComponent<DirectClient>
          titleTerm={tableName}
          items={directClients}
          addTerm={addClientTerm}
          onAddFunction={() => setOpen(true)}
          classes={["id", termCompany, termAdministrator, termUsers]}
          formatItems={function (item: DirectClient): string[] {
            return [
              item.id,
              item.company?.name ?? "",
              item.profiles.find(
                (profile) => profile.role === Role.master || profile.role === Role.admin
              )?.name ?? "",
              directClientSummaryUsers(intl, item),
            ];
          }}
          onViewItem={function (itemId: string): void {
            const search = directClients.find((directClient) => directClient.id === itemId);
            if (search !== undefined) {
              handleClickOpen(search);
            }
          }}
        />
      )}
      <div>
        <DirectClientForm
          classes={classes}
          intl={intl}
          open={open}
          editing={editingDirectClient !== undefined}
          directClient={editingDirectClient}
          cancelCallback={handleClose}
          finishCallback={editingDirectClient === undefined ? handleAddDirectClient : (_) => {}}
          countries={countries}
        />
      </div>
    </div>
  );
}

type DirectClientFormProps = {
  intl: IntlShape;
  open: boolean;
  editing: boolean;
  directClient?: DirectClient;
  cancelCallback: () => void;
  finishCallback: (form: DirectClientFormState) => void;
  classes: ClassNameMap<"menuPaper">;
  countries: Country[];
};

type DirectClientFormState = {
  isCompany: boolean;
  company: Company;
  profiles: Profile[];
};

class DirectClientForm extends React.Component<DirectClientFormProps, DirectClientFormState> {
  _extractProps(props: DirectClientFormProps): DirectClientFormState {
    if (props.editing && props.directClient !== undefined) {
      return {
        isCompany: props.directClient.company !== undefined,
        company: props.directClient.company ?? emptyCompany(),
        profiles: props.directClient.profiles,
      };
    } else {
      const adminProfile: Profile & { role: Role.admin } = {
        id: uuid(),
        role: Role.admin,
        name: "",
        email: "",
        cellphoneNumber: "",
        address: emptyAddress(),
        document: "",
        landlineNumber: "",
        aviationNumber: "",
        agricultureNumber: "",
        nationality: "",
        rg: "",
        crea: "",
        profileImageUrl: "",
        preferences: {} as ProfilePreferences,
        isRegistrationCompleted: false,
        directClientId: "",
      };
      return {
        isCompany: false,
        company: emptyCompany(),
        profiles: [adminProfile],
      };
    }
  }

  constructor(props: DirectClientFormProps) {
    super(props);
    this._extractProps = this._extractProps.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.state = this._extractProps(props);
  }

  componentWillReceiveProps(props: DirectClientFormProps) {
    this.setState(this._extractProps(props));
  }

  render(): React.ReactNode {
    const intl = this.props.intl;

    const termDirectClientRegistration = intl.formatMessage({ id: "info.client.add" });
    const termEditDirectClient = intl.formatMessage({ id: "info.client.edit" });

    const termAddDirectClientDetails = intl.formatMessage({ id: "info.client.add.message" });
    const termEditDirectClientDetails = intl.formatMessage({ id: "info.client.edit.message" });

    const { classes } = this.props;

    const fields = getFormsStructures(intl, this.state, this.props.countries);
    const notValid =
      fields.filter(
        (field) =>
          field.type !== FormStructureType.Component &&
          field.type !== FormStructureType.Checkbox &&
          !field.valid
      ).length > 0;

    return (
      <Modal
        open={this.props.open}
        onClose={(e) => this.props.cancelCallback()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.editing ? termEditDirectClient : termDirectClientRegistration}
          <Icon onClick={(e) => this.props.cancelCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.editing ? termEditDirectClientDetails : termAddDirectClientDetails}
          </DialogContentText>
          <Grid container spacing={1}>
            {fields.map((structure, fieldIndex) => (
              <Grid item lg={12} xs={12}>
                {structure.type === FormStructureType.AutoComplete ? (
                  <Autocomplete<string>
                    id={"add-direct-client-autocomplete-" + fieldIndex.toString()}
                    disabled={this.props.editing}
                    fullWidth
                    options={structure.options.map((c: Country) => c.alpha2Code)}
                    size="small"
                    value={structure.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={structure.label}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    renderOption={(option) =>
                      structure.options.find((v: Country) => v.alpha2Code === option)
                        ?.countryName ?? ""
                    }
                    getOptionLabel={(option) =>
                      structure.options.find((v: Country) => v.alpha2Code === option)
                        ?.countryName ?? ""
                    }
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        this.setState({
                          ...this.state,
                          ...structure.onChangeSetState({
                            ...(e as any),
                            target: { ...e.target, value: newValue },
                          }),
                        });
                      }
                    }}
                  />
                ) : structure.type === FormStructureType.Component ? (
                  structure.component
                ) : structure.type === FormStructureType.Text ? (
                  <TextField
                    id={"add-direct-client-text-field-" + fieldIndex.toString()}
                    disabled={this.props.editing}
                    error={!structure.valid}
                    margin="dense"
                    label={structure.label}
                    fullWidth
                    value={structure.value}
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({
                        ...this.state,
                        ...structure.onChangeSetState(e),
                      });
                    }}
                    onBlur={(e) => {
                      if (structure.onBlurAsyncSetStateCallback !== undefined) {
                        const callback = async () => {
                          const asyncData = await structure.onBlurAsyncSetStateCallback!(
                            structure.value
                          );
                          if (asyncData !== undefined) {
                            this.setState(asyncData as any);
                          }
                        };
                        callback();
                      }
                    }}
                  />
                ) : structure.type === FormStructureType.Checkbox ? (
                  <FormControlLabel
                    id={"add-direct-client-form-checkbox-" + fieldIndex.toString()}
                    control={
                      <Checkbox
                        disabled={this.props.editing}
                        defaultChecked={structure.value}
                        value={structure.value}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            ...structure.onChangeSetState(e),
                          })
                        }
                      />
                    }
                    label={structure.label}
                  />
                ) : (
                  <Grid container xs={12} style={{ paddingRight: 4, paddingLeft: 4 }}>
                    <InsertPhone
                      disabled={this.props.editing}
                      fieldId="cellphoneNumber"
                      labelId={"country-code-label" + fieldIndex.toString()}
                      label={structure.label}
                      error={!structure.valid}
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          ...structure.onChangeSetState(e),
                        })
                      }
                      paperClass={classes.menuPaper}
                      phone={structure.value}
                      codeGridSize={4}
                      inputGridSize={8}
                      gridPaddingTop={8}
                      countryCodeStyle={{ height: 40 }}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => this.props.cancelCallback()}
            className="cancel-button"
            startIcon={<Icon>close</Icon>}
          >
            {intl.formatMessage({ id: "generic.close" })}
          </Button>
          {!this.props.editing && (
            <Button
              disabled={notValid}
              onClick={(e) => this.props.finishCallback(this.state)}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage({ id: "action.save" })}
            </Button>
          )}
        </DialogActions>
      </Modal>
    );
  }
}

enum FormStructureType {
  Text,
  PhoneNumber,
  AutoComplete,
  Checkbox,
  Component,
}

type GeneralFormStructure = {
  label: string;
  onChangeSetState: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => Partial<DirectClientFormState>;
  onBlurAsyncSetStateCallback?: (value: any) => Promise<Partial<DirectClientFormState> | undefined>;
};

type FormTextStructure = GeneralFormStructure & {
  type: FormStructureType.Text;
  value: string;
  valid: boolean;
};

type FormCheckBoxStructure = GeneralFormStructure & {
  type: FormStructureType.Checkbox;
  value: boolean;
};

type FormPhoneStructure = GeneralFormStructure & {
  type: FormStructureType.PhoneNumber;
  value: string;
  valid: boolean;
};

type FormArbitraryComponent = {
  type: FormStructureType.Component;
  component: JSX.Element;
};

type FormAutoComplementStructure<T> = GeneralFormStructure & {
  type: FormStructureType.AutoComplete;
  value: string;
  valid: boolean;
  options: T[];
  optionName: (option: T) => string;
  optionField: (option: T) => string;
};

type FormStructure =
  | FormTextStructure
  | FormCheckBoxStructure
  | FormPhoneStructure
  | FormArbitraryComponent
  | FormAutoComplementStructure<any>;

const getFormsStructures = (
  intl: IntlShape,
  actualState: DirectClientFormState,
  countries: Country[]
): FormStructure[] => {
  let forms: FormStructure[] = [];

  //terms
  const companySectionTerm = intl.formatMessage({ id: "report.company" });
  const isCompanyQuestionTerm = intl.formatMessage({ id: "placeholder.isCompanyQuestion" });

  const nameTerm = intl.formatMessage({ id: "placeholder.name" });

  const companyTradeNameTerm = intl.formatMessage({ id: "placeholder.trade.name" });
  const aviationNumberTerm = intl.formatMessage({ id: "placeholder.aviation.reg" });
  const agricultureNumberTerm = intl.formatMessage({ id: "placeholder.agriculture.reg" });

  const addressZipCodeTerm = intl.formatMessage({ id: "placeholder.zip" });
  const addressStreetTerm = intl.formatMessage({ id: "placeholder.street" });
  const addressNumberTerm = intl.formatMessage({ id: "placeholder.number" });
  const addressComplementTerm = intl.formatMessage({ id: "placeholder.complement" });
  const addressCountryTerm = intl.formatMessage({ id: "placeholder.country" });
  const addressStateTerm = intl.formatMessage({ id: "placeholder.state.department" });
  const addressCityTerm = intl.formatMessage({ id: "placeholder.city" });

  const documentTerm = intl.formatMessage({ id: "placeholder.document" });
  const emailTerm = intl.formatMessage({ id: "placeholder.email" });
  const numberTerm = intl.formatMessage({ id: "placeholder.phone" });

  const profilesTerm = intl.formatMessage({ id: "info.profiles" }) + ":";
  const masterTerm = intl.formatMessage({ id: "info.role.master" }) + ":";
  const administratorTerm = intl.formatMessage({ id: "info.role.administrator" }) + ":";
  const managerTerm = intl.formatMessage({ id: "info.role.manager" }) + ":";
  const operatorTerm = intl.formatMessage({ id: "info.role.operator" }) + ":";
  const externalTerm = intl.formatMessage({ id: "info.role.external" }) + ":";

  const landlineNumberTerm = intl.formatMessage({ id: "placeholder.ladlineNumber" });
  const nationalityTerm = intl.formatMessage({ id: "info.profile.nationality" });
  const rgTerm = intl.formatMessage({ id: "placeholder.rg" });
  const enginnerNumberTerm = intl.formatMessage({ id: "placeholder.crea" });

  forms = [
    ...forms,
    {
      type: FormStructureType.Component,
      component: <h3>{companySectionTerm}</h3>,
    },
    {
      type: FormStructureType.Checkbox,
      label: isCompanyQuestionTerm,
      value: actualState.isCompany,
      onChangeSetState: (e) => ({ isCompany: !actualState.isCompany }),
    },
  ];

  if (actualState.isCompany) {
    const updateCompany = (value: Partial<Company>): DirectClientFormState => {
      return { ...actualState, company: { ...actualState.company, ...value } };
    };
    const updateCompanyAddress = (value: Partial<Address>): DirectClientFormState => {
      return {
        ...actualState,
        company: { ...actualState.company, address: { ...actualState.company.address, ...value } },
      };
    };
    const companyFields: FormStructure[] = [
      {
        type: FormStructureType.Text,
        label: nameTerm,
        value: actualState.company.name,
        onChangeSetState: (e) => updateCompany({ name: e.target.value }),
        valid: actualState.company.name.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: documentTerm,
        value: actualState.company.document,
        onChangeSetState: (e) => updateCompany({ document: e.target.value }),
        valid: actualState.company.document.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: companyTradeNameTerm,
        value: actualState.company.tradeName ?? "",
        onChangeSetState: (e) => updateCompany({ tradeName: e.target.value }),
        valid: true,
      },
      {
        type: FormStructureType.Text,
        label: aviationNumberTerm,
        value: actualState.company.aviationNumber ?? "",
        onChangeSetState: (e) => updateCompany({ aviationNumber: e.target.value }),
        valid: true,
      },
      {
        type: FormStructureType.Text,
        label: agricultureNumberTerm,
        value: actualState.company.agricultureNumber ?? "",
        onChangeSetState: (e) => updateCompany({ agricultureNumber: e.target.value }),
        valid: true,
      },
      {
        type: FormStructureType.PhoneNumber,
        label: numberTerm,
        value: actualState.company.commercialPhoneNumber ?? "",
        onChangeSetState: (e) => updateCompany({ commercialPhoneNumber: e.target.value }),
        valid: !isValidPhone(actualState.company.commercialPhoneNumber),
      },
      {
        type: FormStructureType.Text,
        label: addressZipCodeTerm,
        value: actualState.company.address.zipCode,
        onChangeSetState: (e) => updateCompanyAddress({ zipCode: e.target.value }),
        onBlurAsyncSetStateCallback: async (zipCode) => {
          const searchAddress = await searchZipCode(zipCode);
          if (searchAddress.success && searchAddress.data.length > 0) {
            return updateCompanyAddress({
              street: searchAddress.data[0].formattedAddress,
              country: searchAddress.data[0].country,
              state: searchAddress.data[0].state,
              city: searchAddress.data[0].city,
            });
          }
          return undefined;
        },
        valid: actualState.company.address.zipCode.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: addressStreetTerm,
        value: actualState.company.address.street,
        onChangeSetState: (e) => updateCompanyAddress({ street: e.target.value }),
        valid: actualState.company.address.street.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: addressNumberTerm,
        value: actualState.company.address.number,
        onChangeSetState: (e) => updateCompanyAddress({ number: e.target.value }),
        valid: actualState.company.address.number.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: addressComplementTerm,
        value: actualState.company.address.complement ?? "",
        onChangeSetState: (e) => updateCompanyAddress({ complement: e.target.value }),
        valid: true,
      },
      {
        type: FormStructureType.Text,
        label: addressCountryTerm,
        value: actualState.company.address.country,
        onChangeSetState: (e) => updateCompanyAddress({ country: e.target.value }),
        valid: actualState.company.address.country.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: addressStateTerm,
        value: actualState.company.address.state,
        onChangeSetState: (e) =>
          updateCompanyAddress({
            state: e.target.value,
          }),
        valid: actualState.company.address.state.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: addressCityTerm,
        value: actualState.company.address.city,
        onChangeSetState: (e) => updateCompanyAddress({ city: e.target.value }),
        valid: actualState.company.address.city.length > 0,
      },
    ];

    forms = [...forms, ...companyFields];
  }

  forms = [
    ...forms,
    {
      type: FormStructureType.Component,
      component: <h3>{profilesTerm}</h3>,
    },
  ];

  const orderedProfiles = _.cloneDeep(actualState.profiles).sort((a, b) =>
    a.role > b.role ? 1 : a.role === b.role ? 0 : -1
  );
  const updateProfile = (value: Partial<Profile>, index: number): DirectClientFormState => {
    const profiles = actualState.profiles;
    profiles[index] = {
      ...profiles[index],
      ...(value as any),
    };
    return {
      ...actualState,
      profiles: profiles,
    };
  };
  orderedProfiles.forEach((profile, index) => {
    let profileForm: FormStructure[] = [
      {
        type: FormStructureType.Component,
        component: (
          <b>
            {profile.role === Role.master
              ? masterTerm
              : profile.role === Role.admin
              ? administratorTerm
              : profile.role === Role.manager
              ? managerTerm
              : profile.role === Role.operator
              ? operatorTerm
              : externalTerm}
          </b>
        ),
      },
      {
        type: FormStructureType.Text,
        label: nameTerm,
        value: profile.name,
        onChangeSetState: (e) => updateProfile({ name: e.target.value }, index),
        valid: profile.name.length > 0,
      },
      {
        type: FormStructureType.Text,
        label: emailTerm,
        value: profile.email,
        onChangeSetState: (e) => updateProfile({ email: e.target.value }, index),
        valid: !isValidEmail(profile.email),
      },
      {
        type: FormStructureType.PhoneNumber,
        label: numberTerm,
        value: profile.cellphoneNumber,
        onChangeSetState: (e) => updateProfile({ cellphoneNumber: e.target.value }, index),
        valid: !isValidPhone(profile.cellphoneNumber),
      },
    ];

    forms = [...forms, ...profileForm];

    if (profile.role !== Role.external) {
      const updateProfileAddress = (value: Partial<Address>): DirectClientFormState => {
        return updateProfile({ address: { ...(profile as any).address, ...value } }, index);
      };
      let internalProfileFields: FormStructure[] = [
        {
          type: FormStructureType.Text,
          label: documentTerm,
          value: profile.document,
          onChangeSetState: (e) => updateProfile({ document: e.target.value }, index),
          valid: profile.document.length > 0,
        },
        {
          type: FormStructureType.PhoneNumber,
          label: landlineNumberTerm,
          value: profile.landlineNumber,
          onChangeSetState: (e) => updateProfile({ landlineNumber: e.target.value }, index),
          valid: profile.landlineNumber.length === 0 || !isValidPhone(profile.landlineNumber),
        },
        {
          type: FormStructureType.Text,
          label: agricultureNumberTerm,
          value: profile.agricultureNumber,
          onChangeSetState: (e) => updateProfile({ agricultureNumber: e.target.value }, index),
          valid: profile.agricultureNumber.length > 0,
        },
        {
          type: FormStructureType.AutoComplete,
          label: nationalityTerm,
          value: profile.nationality,
          onChangeSetState: (e) => updateProfile({ nationality: e.target.value }, index),
          valid: profile.nationality.length > 0,
          options: countries,
          optionName: (option: Country) => option.countryName,
          optionField: (option: Country) => option.alpha2Code,
        },
        profile.nationality === "BR"
          ? {
              type: FormStructureType.Text,
              label: rgTerm,
              value: profile.rg,
              onChangeSetState: (e) => updateProfile({ rg: e.target.value }, index),
              valid: profile.rg.length > 0,
            }
          : {
              type: FormStructureType.Component,
              component: <div></div>,
            },
        {
          type: FormStructureType.Text,
          label: enginnerNumberTerm,
          value: profile.crea,
          onChangeSetState: (e) => updateProfile({ crea: e.target.value }, index),
          valid: profile.crea.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: aviationNumberTerm,
          value: profile.aviationNumber,
          onChangeSetState: (e) => updateProfile({ aviationNumber: e.target.value }, index),
          valid: profile.aviationNumber.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressZipCodeTerm,
          value: profile.address.zipCode,
          onChangeSetState: (e) => updateProfileAddress({ zipCode: e.target.value }),
          onBlurAsyncSetStateCallback: async (zipCode) => {
            const searchAddress = await searchZipCode(zipCode);
            if (searchAddress.success && searchAddress.data.length > 0) {
              return updateProfileAddress({
                street: searchAddress.data[0].formattedAddress,
                country: searchAddress.data[0].country,
                state: searchAddress.data[0].state,
                city: searchAddress.data[0].city,
              });
            }
            return undefined;
          },
          valid: profile.address.zipCode.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressStreetTerm,
          value: profile.address.street,
          onChangeSetState: (e) => updateProfileAddress({ street: e.target.value }),
          valid: profile.address.street.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressNumberTerm,
          value: profile.address.number,
          onChangeSetState: (e) => updateProfileAddress({ number: e.target.value }),
          valid: profile.address.number.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressComplementTerm,
          value: profile.address.complement ?? "",
          onChangeSetState: (e) => updateProfileAddress({ complement: e.target.value }),
          valid: true,
        },
        {
          type: FormStructureType.Text,
          label: addressCountryTerm,
          value: profile.address.country,
          onChangeSetState: (e) => updateProfileAddress({ country: e.target.value }),
          valid: profile.address.country.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressStateTerm,
          value: profile.address.state,
          onChangeSetState: (e) =>
            updateProfileAddress({
              state: e.target.value,
            }),
          valid: profile.address.state.length > 0,
        },
        {
          type: FormStructureType.Text,
          label: addressCityTerm,
          value: profile.address.city,
          onChangeSetState: (e) => updateProfileAddress({ city: e.target.value }),
          valid: profile.address.city.length > 0,
        },
      ];

      forms = [...forms, ...internalProfileFields];
    }
  });

  return forms;
};

const directClientSummaryUsers = (intl: IntlShape, directClient: DirectClient): string => {
  let summary = "";

  const masterTerm = intl.formatMessage({ id: "info.role.master" });
  const administratorTerm = intl.formatMessage({ id: "info.role.administrator" });
  const managerTerm = intl.formatMessage({ id: "info.role.manager" });
  const operatorTerm = intl.formatMessage({ id: "info.role.operator" });
  const externalTerm = intl.formatMessage({ id: "info.role.external" });

  const masterAmount = directClient.profiles.filter(
    (profile) => profile.role === Role.master
  ).length;
  const administratorAmount = directClient.profiles.filter(
    (profile) => profile.role === Role.admin
  ).length;
  const managerAmount = directClient.profiles.filter(
    (profile) => profile.role === Role.manager
  ).length;
  const operatorAmount = directClient.profiles.filter(
    (profile) => profile.role === Role.operator
  ).length;
  const externalAmount = directClient.profiles.filter(
    (profile) => profile.role === Role.external
  ).length;

  if (masterAmount > 0) {
    summary = summary + masterAmount.toString() + "x " + masterTerm + " ";
  }
  if (administratorAmount > 0) {
    summary = summary + administratorAmount.toString() + "x " + administratorTerm + " ";
  }
  if (managerAmount > 0) {
    summary = summary + managerAmount.toString() + "x " + managerTerm + " ";
  }
  if (operatorAmount > 0) {
    summary = summary + operatorAmount.toString() + "x " + operatorTerm + " ";
  }
  if (externalAmount > 0) {
    summary = summary + externalAmount.toString() + "x " + externalTerm + " ";
  }

  return summary;
};

const emptyCompany = (): Company => {
  return {
    id: uuid(),
    document: "",
    name: "",
    tradeName: "",
    aviationNumber: "",
    commercialPhoneNumber: "",
    address: emptyAddress(),
  };
};

const emptyAddress = (): Address => {
  return {
    id: uuid(),
    zipCode: "",
    street: "",
    number: "",
    country: "",
    state: "",
    city: "",
  };
};

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
    top: "200px !important",
  },
}));
