import {
  Area,
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Input,
  Project,
  Flight,
  Releaser,
  ReleaserModel,
} from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "../axios/BiohubApi";

export type CachedDataRequestResult<T> = {
  actualTimeReference: Date;
  data: T[];
  remainingIdIfThereWasARemotion?: string[];
};

export abstract class CacheDataRequestsRepository {
  static getInstance(): CacheDataRequestsRepository {
    return new CacheDataRequestsRepositoryImpl();
  }

  abstract getProjects(
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Project>>>;

  abstract getProjectAreas(
    projectId: string,
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Area>>>;

  abstract getFlights(
    projectId: string | undefined,
    areaId: string | undefined,
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Flight>>>;

  abstract getCpus(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Cpu>>>;

  abstract getCpuModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<CpuModel>>>;

  abstract getDeviceModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<DeviceModel>>>;

  abstract getDroneModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<DroneModel>>>;

  abstract getInputs(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Input>>>;

  abstract getDrones(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Drone>>>;

  abstract getReleasers(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Releaser>>>;

  abstract getReleaserModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<ReleaserModel>>>;
}

class CacheDataRequestsRepositoryImpl implements CacheDataRequestsRepository {
  getProjects(
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Project>>> {
    return executeCachedDataRequest("projects", lastTimeReference, [
      {
        key: "mode",
        value: readMode,
      },
    ]);
  }

  getProjectAreas(
    projectId: string,
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Area>>> {
    return executeCachedDataRequest(`areas/${projectId}`, lastTimeReference, [
      {
        key: "mode",
        value: readMode,
      },
    ]);
  }

  getFlights(
    projectId: string | undefined,
    areaId: string | undefined,
    readMode: "not_deleted" | "deleted" | "all",
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Flight>>> {
    const parameters: { key: string; value: any }[] = [
      {
        key: "mode",
        value: readMode,
      },
    ];
    if (projectId !== undefined) {
      parameters.push({
        key: "projectId",
        value: projectId,
      });
    }
    if (areaId !== undefined) {
      parameters.push({
        key: "areaId",
        value: areaId,
      });
    }
    return executeCachedDataRequest(`flights`, lastTimeReference, parameters);
  }

  getCpus(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Cpu>>> {
    return executeCachedDataRequest(
      "/cpus",
      lastTimeReference,
      directClientId !== undefined
        ? [
            {
              key: "directClientId",
              value: directClientId,
            },
          ]
        : undefined
    );
  }

  getCpuModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<CpuModel>>> {
    return executeCachedDataRequest("/cpu-models", lastTimeReference);
  }

  getDeviceModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<DeviceModel>>> {
    return executeCachedDataRequest("/device-models", lastTimeReference);
  }

  getDroneModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<DroneModel>>> {
    return executeCachedDataRequest("/drone-models", lastTimeReference);
  }

  getInputs(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Input>>> {
    return executeCachedDataRequest("/inputs", lastTimeReference);
  }

  getDrones(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Drone>>> {
    return executeCachedDataRequest(
      "/drones",
      lastTimeReference,
      directClientId !== undefined
        ? [
            {
              key: "directClientId",
              value: directClientId,
            },
          ]
        : undefined
    );
  }

  getReleasers(
    directClientId: string | undefined,
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<Releaser>>> {
    return executeCachedDataRequest(
      "/releasers",
      lastTimeReference,
      directClientId !== undefined
        ? [
            {
              key: "directClientId",
              value: directClientId,
            },
          ]
        : undefined
    );
  }

  getReleaserModels(
    lastTimeReference: Date | undefined
  ): Promise<BiohubResponse<CachedDataRequestResult<ReleaserModel>>> {
    return executeCachedDataRequest("/releaser-models", lastTimeReference);
  }
}

const executeCachedDataRequest = async <T>(
  endpoint: string,
  lastTimeReference?: Date,
  parameters?: { key: string; value: any }[]
): Promise<BiohubResponse<CachedDataRequestResult<T>>> => {
  let url = `cache-data/${endpoint}`;

  let effectiveParameters: { key: string; value: any }[] = [];
  if (lastTimeReference !== undefined) {
    /// Is necessary to create an effective last time reference because the Date could not be a Date here
    /// because it is stored like a string
    const effetiveLastTimeReference = new Date(lastTimeReference);
    effectiveParameters = [
      {
        key: "lastTimeReference",
        value: effetiveLastTimeReference.toISOString(),
      },
    ];
  }
  if (parameters !== undefined) {
    effectiveParameters = [...effectiveParameters, ...parameters];
  }

  if (effectiveParameters.length > 0) {
    url = `${url}?${effectiveParameters.map((param) => `${param.key}=${param.value}`).join("&")}`;
  }

  try {
    const response = await BiohubApi.get(url, {
      validateStatus(status) {
        if (status === 200) {
          return true;
        }
        return false;
      },
    });

    const body = response.data;

    const data = body["data"] as T[];
    const actualTimeReference = body["actualTimeReference"] as Date;
    const remainingIdIfThereWasARemotion = body["remainingIdIfThereWasARemotion"] as
      | string[]
      | undefined;

    return newBiohubSuccess({
      actualTimeReference: actualTimeReference,
      data: data,
      remainingIdIfThereWasARemotion: remainingIdIfThereWasARemotion,
    });
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
};
