import { DirectClient, PreRegisterForm } from "biohub-model";
import { BiohubError } from "../../services/axios/BiohubApi";
import { LOG_OUT } from "../actions/loginActions";
import {
  ADD_DIRECT_CLIENT,
  ADD_DIRECT_CLIENT_ERROR,
  ADD_DIRECT_CLIENT_SUCCESS,
  READ_DIRECT_CLIENTS,
  READ_DIRECT_CLIENTS_ERROR,
  READ_DIRECT_CLIENTS_SUCCESS,
  READ_PRE_REGISTRATIONS,
  READ_PRE_REGISTRATIONS_ERROR,
  READ_PRE_REGISTRATIONS_SUCCESS,
} from "../actions/masterActions";
import { SystemAction } from "../actions/systemActions";

export type MasterState = {
  key: "master";
  error: BiohubError | null;
  directClients: Array<DirectClient> | null;
  readindDirectClients: boolean;
  preRegistrations: PreRegisterForm[];
  loadingPreRegistrations: boolean;
};

const INITIAL_STATE: MasterState = {
  key: "master",
  error: null,
  directClients: null,
  readindDirectClients: false,
  loadingPreRegistrations: false,
  preRegistrations: [],
};

export function masterReducer(state = INITIAL_STATE, action: SystemAction): MasterState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case LOG_OUT:
      return INITIAL_STATE;
    case READ_DIRECT_CLIENTS:
      return {
        ...effectiveState,
        readindDirectClients: true,
      };

    case READ_DIRECT_CLIENTS_SUCCESS:
      return {
        ...effectiveState,
        error: null,
        readindDirectClients: false,
        directClients: action.payload.directClients,
      };

    case READ_DIRECT_CLIENTS_ERROR:
      return {
        ...effectiveState,
        readindDirectClients: false,
        error: action.payload.error,
      };

    case READ_PRE_REGISTRATIONS:
      return {
        ...effectiveState,
        loadingPreRegistrations: true,
      };
    case READ_PRE_REGISTRATIONS_ERROR:
      return {
        ...effectiveState,
        loadingPreRegistrations: false,
        error: action.payload.error,
      };
    case READ_PRE_REGISTRATIONS_SUCCESS:
      return {
        ...effectiveState,
        loadingPreRegistrations: false,
        preRegistrations: action.payload.preRegistrations,
      };
    case ADD_DIRECT_CLIENT:
      return {
        ...effectiveState,
        readindDirectClients: true,
      };
    case ADD_DIRECT_CLIENT_ERROR:
      return {
        ...effectiveState,
        readindDirectClients: false,
        error: action.payload.error,
      };
    case ADD_DIRECT_CLIENT_SUCCESS:
      return {
        ...effectiveState,
        readindDirectClients: true,
      };
    default:
      return effectiveState;
  }
}
