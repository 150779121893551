import React, { useEffect, useState } from "react";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Button,
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  Slider,
} from "@material-ui/core";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import { setZoom, zoomIn, zoomOut } from "../../../../../store/actions/projectTreeActions";
import { getMapZoomLevelPercentage } from "../../../../../components/map/impl/MapImplLeaflet";
import CompleteNumberEditor from "../../../../../components/Atomic/Inputs/CompleteNumberEditor";

export default (): JSX.Element => {
  const zoomLevel = useSelector((state: SystemState) => {
    const zoom = state.projectTree.mapState.zoom;

    return getMapZoomLevelPercentage(zoom);
  });
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ZoomLevel zoom={zoomLevel} onChanged={(zoomLevel) => dispatch(setZoom(zoomLevel))} />
    </div>
  );
};

const ZoomLevel = (props: { zoom: number; onChanged: (zoom: number) => void }): JSX.Element => {
  const [error, setError] = useState(false);

  const [value, setValue] = useState(props.zoom.toFixed(0));

  useEffect(() => {
    setValue(props.zoom.toFixed(0));
  }, [props.zoom]);

  const styles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        color: "white",
        // - The Input-root, inside the TextField-root
        "& fieldset": {
          // - The <fieldset> inside the Input-root
          borderColor: "transparent", // - Set the Input border
        },
        "&:hover fieldset": {
          borderColor: "transparent", // - Set the Input border when parent has :hover
        },
        "&.Mui-focused fieldset": {
          // - Set the Input border when parent is focused
          borderColor: "transparent",
        },
      },
    },
  })();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        color: "white",
      }}
    >
      <TextField
        classes={styles}
        style={{
          width: 40,
        }}
        error={error}
        size="small"
        variant="outlined"
        value={value}
        onChange={(e) => {
          const source = e.target.value;

          let error = false;

          if (source === null || source === undefined) {
            error = true;
          } else {
            const parsedValue = parseInt(value);

            if (Number.isNaN(parsedValue)) {
              error = true;
            }
          }

          setError(error);
          setValue(source);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            const parsedValue = parseInt(value);

            if (!Number.isNaN(parsedValue)) {
              props.onChanged(parsedValue);
            }
          }
        }}
        inputProps={{
          style: {
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: "right",
          },
        }}
      />
      %
    </div>
  );
};
