import { Location, Role, UnitSystem } from "biohub-model";
import L from "leaflet";
import React from "react";
import Marker, { DraggableProps } from "./marker";
import WithFontSizeInMetersPattern from "./with_font_size_in_meters_pattern";

export default (
  props: {
    mapScaleSource: "map" | "state";
    basePointLocation?: Location;
    homePointElevation?: number;
    map: L.Map;
  } & DraggableProps
): JSX.Element => {
  const location = props.basePointLocation;

  if (location === undefined) return <></>;

  return (
    <WithFontSizeInMetersPattern
      scaleSource={props.mapScaleSource}
      location={location}
      getChild={(centerLocation, fontSizeInPixels, fontSizeInMeters) => {
        const size = fontSizeInPixels * 4;

        return (
          <Marker
            {...props}
            position={centerLocation}
            icon={
              new L.Icon({
                iconUrl: "/target_icon.png",
                iconSize: [size, size],
              })
            }
            zIndex={1}
          />
        );
      }}
    />
  );
};
