import React from "react";
import { ContainerMapInfosOnTheMap } from "../../modals/styles";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import {
  Area,
  Location,
  Project,
  ReleaserAction,
  ReleaseRateUnit,
  Role,
  UnitSystem,
} from "biohub-model";
import { castProjectInProjectTreeToProject } from "../../../../store/reducers/projectTreeReducer";
import _ from "lodash";
import { useIntl } from "react-intl";
import {
  calculateAreaHa,
  polygonPerimeter,
  totalPathLength,
} from "../../../../core/geometricFunctions";
import {
  plannedCoverageArea,
  plannedPathTime,
  polygonAvailableAreaForPlanning,
} from "../../../../core/areaPlanning";
import { timeToHMS } from "../../../../core/formatter";
import UnitConversionHelper from "../../../../core/helper/UnitConversionHelper";
import CollapsibleCard from "../../../../components/Atomic/CollapsibleCard";
import FolderOverlayComponent from "../../../../components/Atomic/FolderOverlayComponent";
import { BarChart, OpenInNew } from "@material-ui/icons";
import ReleaserModelHelper from "../../../../core/helper/ReleaserModelHelper";

type Props = {
  isExpandedProjectTree: boolean;
  setExpanded: (value: boolean) => void;
  expanded: boolean;
  onClickOpenDialog: (project: Project, area: Area | undefined) => void;
};

export default function AreaInfosOverlay(props: Props): JSX.Element {
  const project = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return null;

    const project = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );

    if (project === undefined) return null;

    return castProjectInProjectTreeToProject(project);
  });

  const area = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return null;

    const project = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (project === undefined) return null;

    const selectedAreaId = project.selectedAreaId;
    if (selectedAreaId === null) return null;

    const area = project.areas?.find((area) => area.id === selectedAreaId);
    if (area === undefined) return null;

    return area;
  });

  return (
    <div
      style={{
        position: "absolute",
        left: props.isExpandedProjectTree ? 350 : 10,
        bottom: 3,
      }}
    >
      <ContainerMapInfosOnTheMap style={{ display: "flex" }}>
        <FolderOverlayComponent setExpanded={props.setExpanded} expanded={props.expanded}>
          <InfoPanel
            area={
              area === null
                ? null
                : {
                    ...area,
                    deletedAt: area.deletedAt ?? undefined,
                  }
            }
            project={project}
            onClickOpenDialog={props.onClickOpenDialog}
          />
        </FolderOverlayComponent>
      </ContainerMapInfosOnTheMap>
    </div>
  );
}

type InfoPanelProps = {
  project: Project | null;
  area: Area | null;
  onClickOpenDialog: (project: Project, area: Area | undefined) => void;
};

const InfoPanel = React.memo(_InfoPanel, (prevProps, newProps) => {
  if (typeof prevProps.project !== typeof newProps.project) return false;
  if (prevProps.project?.name !== newProps.project?.name) return false;
  if (typeof prevProps.area !== typeof newProps.area) return false;
  if (prevProps.area?.name !== newProps.area?.name) return false;
  if (!_.isEqual(prevProps.area?.planned, newProps.area?.planned)) return false;
  return true;
});

function _InfoPanel(props: InfoPanelProps): JSX.Element {
  const project = props.project;
  const area = props.area;

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const releasers = useSelector((state: SystemState) => state.collections.releasers);

  const intl = useIntl();
  const noProjectOpenTerm = intl.formatMessage({ id: "map.footer.noProjectOpen" });

  const column1: string[] = [];
  column1.push(project?.name ?? noProjectOpenTerm);
  if (area !== null) {
    column1.push(area.name);
  }

  let column2: string[] | undefined;
  if (area !== null) {
    column2 = [];

    const areaHa = calculateAreaHa(area?.planned.polygon);

    const areaFormattedArea =
      UnitConversionHelper.areaValue(areaHa, unitSystem, 2) +
      UnitConversionHelper.areaUnit(unitSystem);

    let firstLine = areaFormattedArea;

    let areaWaypointsAndFlightTime: string = "";

    const waypoints = area.planned.route?.waypoints;
    if (waypoints !== undefined) {
      const missionDistance = totalPathLength(
        waypoints.map((e) => e.location),
        UnitSystem.metric
      );
      const formattedMissionDistance =
        UnitConversionHelper.distanceValue(missionDistance, unitSystem) +
        UnitConversionHelper.distanceUnit(unitSystem);

      firstLine = `${firstLine} - ${formattedMissionDistance}`;

      const trackWidth = area.areaConfig.trackWidth;
      const releaseEstimation = area.configuredReleasers.map((configuredReleaser) => {
        const locationsToComputeLinearDistance: Location[] = [];

        let releasing = false;

        for (const waypoint of waypoints) {
          const releaserAction = waypoint.releaserActions[configuredReleaser.releaserId];

          if (releasing) {
            locationsToComputeLinearDistance.push(waypoint.location);
          }
          if (releaserAction !== undefined) {
            if (releaserAction.type === ReleaserAction.release) {
              if (releasing === false) {
                locationsToComputeLinearDistance.push(waypoint.location);
              }

              releasing = true;
            } else {
              releasing = false;
            }
          }
        }

        const releaseDistance = totalPathLength(
          locationsToComputeLinearDistance,
          UnitSystem.metric
        );
        const releaseAreaHa = (releaseDistance * trackWidth) / 10000;

        const releaseEstimation = releaseAreaHa * configuredReleaser.releaseRate;

        const releaser = releasers.find((r) => r.id === configuredReleaser.releaserId);

        const releaseRateUnit =
          releaser !== undefined
            ? ReleaserModelHelper.releaserModelIdReleaseRateUnit(releaser.releaserModelId)
            : ReleaseRateUnit.volume;

        switch (releaseRateUnit) {
          case ReleaseRateUnit.cup:
            return (
              UnitConversionHelper.cupValue(releaseEstimation) + UnitConversionHelper.cupUnit(intl)
            );
          case ReleaseRateUnit.volume:
            return (
              UnitConversionHelper.volumeValue(releaseEstimation, unitSystem, 2) +
              UnitConversionHelper.volumeUnit(unitSystem)
            );
        }
      });

      if (releaseEstimation.length > 0) {
        firstLine = `${firstLine} - ${releaseEstimation.reduce((a, b) => a + " " + b)}`;
      }

      const flightTime = plannedPathTime(waypoints, area.areaConfig.flightSpeed);
      const flightTimeFormatted = timeToHMS(
        flightTime.hours,
        flightTime.minutes,
        flightTime.seconds
      );

      areaWaypointsAndFlightTime = `${waypoints.length} waypoints - ${flightTimeFormatted}`;
    }

    column2.push(firstLine);
    column2.push(areaWaypointsAndFlightTime);
  }

  const fontSize = 14;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {column1.map((line) => (
          <p style={{ color: "white", margin: 0, fontSize: fontSize }}>{line}</p>
        ))}
      </div>

      {column2 !== undefined && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: 5,
              height: 30,
              width: 30,
              marginLeft: 20,
            }}
          >
            <BarChart style={{ height: 30, width: 30 }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
              width: 200,
            }}
          >
            {column2.map((line) => (
              <p style={{ color: "white", margin: 0, fontSize: fontSize }}>{line}</p>
            ))}
          </div>
        </>
      )}

      {props.project !== null && (
        <div
          onClick={() => props.onClickOpenDialog(props.project!, props.area ?? undefined)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
            width: 30,
            marginLeft: 20,
            cursor: "pointer",
          }}
        >
          <OpenInNew style={{ height: 30, width: 30, color: "white" }} />
        </div>
      )}
    </div>
  );
}
