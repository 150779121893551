import React from "react";
import { ModalContainer } from "../modals/ModalContainer";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Navigation } from "@material-ui/icons";
import LegendForMapElementComponents, {
  DrawingElementsInformation,
} from "./LegendForMapElementComponents";

// Modal designer
type Props = {
  onClose: () => void;
};

type DesignerSize = {
  direction?: string;
  margin?: string;
  width?: string;
  alignSelf?: string;
  objectFit?: string;
  opacity?: string;
  borderRadius?: string;
};

type DesignerLine = {
  bgColor?: string;
  color?: string;
  width?: string;
  fontSize?: string;
  opacity?: string;
};

type SizeContainerImage = {
  width: string | number;
  height: string | number;
  marginBottom?: string | number;
  position?: string;
  margin?: string;
};

type SizeParagraphs = {
  fontSize?: string;
  fontSizeTitle?: string;
};

const size = {
  height: "50px",
  width: "65px",
};

let height: any;
let width: any;
const sizeWidth = window.innerWidth;
const sizeHeight = window.innerHeight;
let fontSize: any;
let fontSizeTitle: any;
let containerImageHeight: any;
let containerImageWidth: any;
let waypointSize: any;
let arrow: any;
let margin;

if (sizeWidth < 600) {
  width = 280;
  height = 300;
  size.height = "35px";
  size.width = "35px";
  fontSize = "10px";
  fontSizeTitle = "14px";
  containerImageHeight = "40px";
  containerImageWidth = "56px";
  waypointSize = "40px";
  arrow = "35px";
  margin = "5px";
} else {
  width = 535;
  height = 350;
  size.height = "50px";
  size.width = "50px";
  fontSize = "12px";
  fontSizeTitle = "17px";
  containerImageHeight = "70px";
  containerImageWidth = "98px";
  waypointSize = "60px";
  arrow = "55px";
  margin = "15px";
}
export default function (props: Props): JSX.Element {
  const intl = useIntl();

  return (
    <ModalContainer
      height={height}
      showModal={true}
      onClose={props.onClose}
      title=""
      width={width}
      modalLeftShift={width / 2}
    >
      <ContainerLegendsForMapElements>
        <TitleContainer>
          <Title fontSize={fontSizeTitle}>
            {intl.formatMessage({ id: "map.legends.modal.title" })}
          </Title>
        </TitleContainer>
        <div
          style={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LegendForMapElementComponents
            height={50}
            width="25%"
            idImage="map.legends.modal.marker.cluster.pink"
          >
            <DrawingElementsInformation urlImage="/marker_cluster_pink.png" text="n" />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            height={50}
            width="25%"
            idImage="map.legends.modal.marker.cluster.yellow"
          >
            <DrawingElementsInformation urlImage="/marker_cluster_yellow.png" text="n" />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            height={50}
            width="25%"
            idImage="map.legends.modal.marker.cluster.red"
          >
            <DrawingElementsInformation urlImage="/marker_cluster_red.png" text="n" />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            height={50}
            width="25%"
            idImage="map.legends.modal.marker.cluster.blue"
          >
            <DrawingElementsInformation urlImage="/marker_cluster_blue.png" text="n" />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            height={50}
            width="25%"
            idImage="map.legends.modal.icon.homepoint"
          >
            <DrawingElementsInformation urlImage="/icon_homepoint.png" />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            width="25%"
            idImage="map.legends.modal.icon.marker.thick.blue.green"
            children2={<DrawingWaypioints waypoitWithRelease={false} />}
          >
            <ContainerImagesWaypoints>
              <ContainerImage
                height={containerImageHeight}
                width={containerImageWidth}
                marginBottom="-25px"
              >
                <Image
                  objectFit="fill"
                  src="/icon_marker_distance.png"
                  height="70%"
                  width="90%"
                  opacity="0.7"
                />
                <ContainerTextsUponTheImage>
                  <TextUponImage fontSize={fontSize}>30</TextUponImage>
                  <TextUponImage fontSize={fontSize}>(30,2m)</TextUponImage>
                </ContainerTextsUponTheImage>
              </ContainerImage>
              <ContainerImage height={waypointSize} width={waypointSize} marginBottom="-25px">
                <Image
                  src="/icon_marker_thick_blue_green_border.png"
                  alt=""
                  height="80%"
                  width="80%"
                />
                <TextUponImage fontSize={fontSize} bgColor="white">
                  n
                </TextUponImage>
              </ContainerImage>
              <Navigation
                style={{
                  color: "orange",
                  transform: "rotate(90deg)",
                  height: `50px`,
                  width: `50px`,
                  alignSelf: "center",
                }}
              />
            </ContainerImagesWaypoints>
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            width="130px"
            idImage="map.legends.modal.icon.marker.thick.blue.red"
            children2={<DrawingWaypioints waypoitWithRelease={true} />}
          >
            <ContainerImagesWaypoints>
              <ContainerImage
                height={containerImageHeight}
                width={containerImageWidth}
                marginBottom="-20px"
              >
                <Image
                  objectFit="fill"
                  src="/icon_marker_distance.png"
                  height="70%"
                  width="90%"
                  opacity="0.7"
                />
                <ContainerTextsUponTheImage>
                  <TextUponImage fontSize={fontSize}>30</TextUponImage>
                  <TextUponImage fontSize={fontSize}>(30,2m)</TextUponImage>
                </ContainerTextsUponTheImage>
              </ContainerImage>
              <ContainerImage height={size.height} width={size.width} marginBottom="-20px">
                <Image
                  src="/icon_marker_thick_blue_red_border.png"
                  alt=""
                  height="100%"
                  width="100%"
                />
                <TextUponImage fontSize={fontSize} bgColor="white">
                  n
                </TextUponImage>
              </ContainerImage>
              <Navigation
                style={{
                  color: "orange",
                  transform: "rotate(90deg)",
                  height: `50px`,
                  width: `50px`,
                  alignSelf: "center",
                }}
              />
            </ContainerImagesWaypoints>
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            idImage="map.legends.modal.drawn.area.selected"
            height={65}
            width="25%"
          >
            <RectangularSelectedArea />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            idImage="map.legends.modal.drawn.area.notSelected"
            height={65}
            width="25%"
          >
            <RectangularNotSelectedArea />
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            width="30%"
            height={50}
            idImage="map.legends.modal.paragraph.waypoints"
            children2={<GreenLineElements />}
          >
            <ContainerElementsWaypoint margin="0px">
              <OrangeLineContainer>
                <Line color="orange" />
                <ContainerImage height="36px" width="58px" position="absolute">
                  <Image
                    src="/icon_marker_distance.png"
                    height="31px"
                    width="50px"
                    opacity="0.65"
                    borderRadius="8px"
                  />
                  <TextUponImage fontSize={fontSize}>159m</TextUponImage>
                </ContainerImage>
              </OrangeLineContainer>
            </ContainerElementsWaypoint>
          </LegendForMapElementComponents>

          <LegendForMapElementComponents
            width="30%"
            height={50}
            idImage="map.legends.modal.green.line"
            children2={<OrangeLineElements />}
          >
            <ContainerElementsWaypoint>
              <LineContainer bgColor="#7aac5f" opacity="0.8">
                <WhiteContourLine>
                  <Line color="#42A5F5" />
                </WhiteContourLine>
              </LineContainer>
            </ContainerElementsWaypoint>
          </LegendForMapElementComponents>
        </div>
      </ContainerLegendsForMapElements>
    </ModalContainer>
  );
}

function DrawingWaypioints({ waypoitWithRelease }: { waypoitWithRelease: boolean }): JSX.Element {
  const intl = useIntl();

  return (
    <>
      {waypoitWithRelease === true ? (
        <EachLegendElement>
          <ContainerImageOnOverlay height={size.height} width="25%">
            <Image
              src="/icon_marker_thick_blue_red_border.png"
              alt=""
              height="100%"
              width={size.width}
            />
            <TextUponImage fontSize={fontSize} bgColor="white">
              n
            </TextUponImage>
          </ContainerImageOnOverlay>
          <Paragraph fontSize={fontSize}>
            {intl.formatMessage({
              id: "map.legends.modal.icon.marker.thick.blue.red.border",
            })}
          </Paragraph>
        </EachLegendElement>
      ) : (
        <EachLegendElement>
          <ContainerImageOnOverlay height={size.height} width="25%">
            <Image
              src="/icon_marker_thick_blue_green_border.png"
              alt=""
              height="100%"
              width={size.width}
            />
            <TextUponImage fontSize={fontSize} bgColor="white">
              n
            </TextUponImage>
          </ContainerImageOnOverlay>
          <Paragraph fontSize={fontSize}>
            {intl.formatMessage({
              id: "map.legends.modal.icon.marker.thick.blue.green.border",
            })}
          </Paragraph>
        </EachLegendElement>
      )}
      <EachLegendElement>
        <div style={{ display: "flex", width: "25%", justifyContent: "center" }}>
          <Navigation
            style={{
              color: "orange",
              transform: "rotate(90deg)",
              height: `${arrow}`,
              width: `${arrow}`,
            }}
          />
        </div>
        <Paragraph fontSize={fontSize}>
          {intl.formatMessage({ id: "map.legends.modal.orange.arrow" })}
        </Paragraph>
      </EachLegendElement>
      <EachLegendElement>
        <ContainerImageOnOverlay height={containerImageHeight} width={containerImageWidth}>
          <Image
            objectFit="fill"
            src="/icon_marker_distance.png"
            height="70%"
            width="100%"
            opacity="0.7"
          />
          <ContainerTextsUponTheImage>
            <TextUponImage fontSize={fontSize}>30</TextUponImage>
            <TextUponImage fontSize={fontSize}>(30,2m)</TextUponImage>
          </ContainerTextsUponTheImage>
        </ContainerImageOnOverlay>
        <ContainerTextsUponTheImage>
          <Paragraph fontSize={fontSize}>
            {intl.formatMessage({ id: "map.legends.modal.icon.marker.distance.top" })}
          </Paragraph>
          <Paragraph fontSize={fontSize}>
            {intl.formatMessage({
              id: "map.legends.modal.icon.marker.distance.bottom",
            })}
          </Paragraph>
        </ContainerTextsUponTheImage>
      </EachLegendElement>
    </>
  );
}

function GreenLineElements(): JSX.Element {
  const intl = useIntl();
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <EachLegendElement>
        <ContainerImage height="36px" width="25%">
          <Image
            src="/icon_marker_distance.png"
            height="36px"
            width="58px"
            opacity="0.65"
            borderRadius="15px"
          />
          <TextUponImage fontSize={fontSize}>159m</TextUponImage>
        </ContainerImage>
        <Paragraph fontSize={fontSize}>
          {intl.formatMessage({ id: "map.legends.modal.line.area" })}
        </Paragraph>
      </EachLegendElement>
      <ContainerElementsWaypoint margin="10px">
        <Line color="orange" width="25%" />
        <Paragraph fontSize={fontSize}>
          {intl.formatMessage({ id: "map.legends.modal.drawn.planning.area" })}
        </Paragraph>
      </ContainerElementsWaypoint>
    </div>
  );
}

function OrangeLineElements(): JSX.Element {
  const intl = useIntl();
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ContainerElementsWaypoint margin="10px">
        <LineContainer bgColor="#7aac5f" opacity="0.8" width="25%" />
        <Paragraph>{intl.formatMessage({ id: "map.legends.modal.drawn.drone.work" })}</Paragraph>
      </ContainerElementsWaypoint>
      <ContainerElementsWaypoint margin="10px" width="100%">
        <WhiteContourLine width="30%">
          <Line color="#42A5F5" />
        </WhiteContourLine>
        <Paragraph>
          {intl.formatMessage({ id: "map.legends.modal.where.the.drone.passed" })}{" "}
        </Paragraph>
      </ContainerElementsWaypoint>
    </div>
  );
}

const ContainerLegendsForMapElements = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const EachLegendElement = styled.div<DesignerSize>`
  display: flex;
  width: ${(props) => props.width || "100%"};
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  // margin-left: 15px;
  align-self: ${(props) => props.alignSelf || ""};
`;

const ContainerElementsWaypoint = styled.div<DesignerSize>`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  margin-top: ${(props) => props.margin || 0};
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Paragraph = styled.p<SizeParagraphs>`
  font-size: ${(props) => props.fontSize || "12px"};
  margin: 0 auto;
  margin-left: 15px;
  width: 100%;
  position: relative;
`;

const LineContainer = styled.div<DesignerLine>`
  display: flex;
  width: 130px;
  height: 9px;
  border: 15px solid ${(props) => props.bgColor || "black"}; /* Cor do contorno mais forte */
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => props.opacity || ""};
  margin: 0;
  position: relative;
`;

const WhiteContourLine = styled.div<DesignerLine>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.width || "100%"};
  height: 3px;
  position: relative;
  background-color: white;
`;

const Line = styled.div<DesignerLine>`
  display: flex;
  position: relative;
  width: ${(props) => props.width || "100%"};
  height: 3px;
  background-color: ${(props) => props.color || "black"};
  margin: 0;
  border-radius: 5px;
`;

const RectangularNotSelectedArea = styled.div`
  width: 130px;
  height: 65px;
  background-color: rgba(239, 83, 80, 0.5); /* Cor da área interna */
  border: 4px solid rgba(239, 83, 80, 0.9); /* Cor do contorno mais forte */
`;

const RectangularSelectedArea = styled.div`
  width: 130px;
  height: 65px;
  background-color: rgba(128, 222, 234, 0.5); /* Cor da área interna */
  border: 4px solid rgba(128, 222, 234, 0.9); /* Cor do contorno mais forte */
`;

const ContainerImage = styled.div<SizeContainerImage>`
  display: flex;
  flex-direction: column;
  position: ${(props) => props.position || "relative"};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  align-items: center;
  touch-action: none;
  justify-content: center;
  z-index: 9;
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin: ${(props) => props.margin || ""};
`;

const ContainerImageOnOverlay = styled.div<SizeContainerImage>`
  display: flex;
  flex-direction: column;
  position: ${(props) => props.position || "relative"};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  align-items: center;
  touch-action: none;
  justify-content: center;
  z-index: 9;
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

const TextUponImage = styled.p<DesignerLine>`
  position: relative;
  margin: 0 auto;
  color: ${(props) => props.bgColor || "#fff"};
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  margin-top: 0px;
`;

const ContainerTextsUponTheImage = styled.div`
  display: flex;
  height: 70%;
  width: 90%;
  flex-direction: column;
  justify-content: space-around;
`;

const Image = styled.img<DesignerSize>`
  position: absolute;
  user-select: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  height: ${(props) => props.height || ""};
  width: ${(props) => props.width || ""};
  opacity: ${(props) => props.opacity || 0.95};
  object-fit: ${(props) => props.objectFit || "cover"};
  border-radius: ${(props) => props.borderRadius || 0};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p<SizeParagraphs>`
  margin: 0 auto;
  font-size: ${(props) => props.fontSizeTitle || "17px"};
  font-weight: bolder;
  color: black;
`;

const ContainerImagesWaypoints = styled.div`
  display: flex;
  position: relative;
  width: 15%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
`;

const OrangeLineContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
