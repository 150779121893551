import React from "react";
import WithMapPixelsPerMeter from "./with_map_pixels_per_meters";
import WithStatePixelsPerMeter from "./with_state_pixels_per_meters";

export default (props: {
  source: "map" | "state";
  getChild: (pixelsPerMeters: number) => JSX.Element;
}): JSX.Element => {
  if (props.source === "map") {
    return <WithMapPixelsPerMeter getChild={props.getChild} />;
  } else {
    return <WithStatePixelsPerMeter getChild={props.getChild} />;
  }
};
