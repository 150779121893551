import { Location } from "biohub-model";
import React from "react";
import { Polygon } from "react-leaflet";
import L from "leaflet";

export default (props: {
  polygon: Location[];
  onClick: () => void;
  setPolygonRef: (ref: L.Polygon | null) => void;
  onRender: () => void;
}): JSX.Element => {
  props.onRender();

  return (
    <Polygon
      ref={(ref) => props.setPolygonRef(ref)}
      positions={props.polygon}
      pathOptions={{
        color: "rgba(239, 83, 80, 102)",
        fillColor: "rgba(239, 83, 80, 102)",
      }}
      eventHandlers={{
        click: (_) => props.onClick(),
      }}
    />
  );
};
