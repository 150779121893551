import Marker from "./marker";
import React from "react";
import L from "leaflet";
import { Location } from "biohub-model";

export default (props: { location: Location }): JSX.Element => {
  return (
    <Marker
      position={props.location}
      icon={
        new L.Icon({
          iconUrl: "/user_icon.svg",
          iconSize: [31, 31],
        })
      }
      zIndex={0}
    />
  );
};
