import { Icon, InputAdornment, makeStyles, TextField, Tooltip } from "@material-ui/core";
import React from "react";

interface Props {
  /**
   * Text to display at the far right of the text input.
   */
  unit: string;
  value: string;
  label?: string;
  legend?: string;
  isOutOfRange?: boolean;
  /**
   * If specified, the number inserted into the text field will be multipled by this
   * in order to produce the numeric value.
   */
  conversionFactor?: number;

  step?: number;
  /**
   * Callback invoked whenever the content of this text field changes. The first parameter
   * is the raw string, which is always present, and the second is the numeric value
   * already parsed and multiplied by the conversion factor. If the text isn't a valid
   * number, then the second parameter will be null.
   */
  onChange: (s: string, n: number | null) => void;

  onBlur?: () => void;

  disabled?: boolean;

  color?: string;
}

export default (props: Props) => {
  /**
   * TextField with some text after it. This is intended to be used for fields that display
   * a unit.
   * Currently, this doesn't support negative numbers.
   */
  const useStyles = makeStyles((theme) => ({
    outOfRangeInputColor: {
      color: "#fb0a0a !important",
    },
    defaultInputColor: {
      color: `${props.color ?? "black"} !important`,
    },
    floatingLabelFocusStyle: {
      color: props.color !== undefined ? `${props.color} !important` : undefined,
    },
  }));
  const classes = useStyles(props.color);

  return (
    <TextField
      disabled={props.disabled}
      size="small"
      error={props.isOutOfRange ? true : false}
      label={
        props.legend !== undefined ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {props.label}
            <Tooltip title={props.legend} style={{ pointerEvents: "fill", marginLeft: "5px" }}>
              <Icon>info</Icon>
            </Tooltip>
          </div>
        ) : (
          props.label
        )
      }
      type="number"
      variant="outlined"
      value={props.value}
      onBlur={() => {
        if (props.onBlur !== undefined) props.onBlur();
      }}
      onChange={(e) => {
        // Special case: Permit empty string and consider its value zero.
        if (e.target.value.length === 0) {
          props.onChange("", 0);
          return;
        }

        // Also send the parsed value.
        var parsed = null;
        try {
          // Remove trailing dots and commas (at most one). Then replace commas with dots.
          const source: string = e.target.value;
          const correctedText = source.replace(/[\.,]$/, "").replaceAll(",", ".");
          parsed = parseFloat(correctedText);
        } catch (_) {}
        if (parsed != null) {
          parsed *= props.conversionFactor ?? 1;
        }
        props.onChange(e.target.value, parsed);
      }}
      inputProps={{
        step: props.step !== undefined ? props.step.toString() : "1",
        className: props.isOutOfRange ? classes.outOfRangeInputColor : classes.defaultInputColor,
      }}
      InputProps={{
        endAdornment: <InputAdornment position="start">{props.unit}</InputAdornment>,
      }}
      InputLabelProps={{
        className: classes.floatingLabelFocusStyle,
      }}
    />
  );
};
