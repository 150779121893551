/**
 * English (en)
 */
const messagesMap: { [key: string]: string } = {
  "app.title": "bioHUB",

  // --- Página de login ---
  "login.title.login": "Login",
  "login.form.email": "Email",
  "login.form.password": "Password",
  "login.resetPassword": "I forgot my password",
  "login.resetPassword.success":
    "Password recovery process done successfully, check your email inbox to proceed.",
  "login.resetPassword.error":
    "Error while recovering your account. Please check your details or try again in a moment.",

  "profile.login.currentPassword": "Current password",
  "profile.login.newPassword": "New password",
  "profile.login.confirmNewPassword": "Confirm new password",

  "profile.registrationStatus.name": "Registration status",
  "profile.registrationStatus.completed": "Complete",
  "profile.registrationStatus.pending": "Pending",

  // --- Overlays do mapa ---
  "map.overlays.weather.temperature": "Temperature",
  "map.overlays.weather.pressure": "Pressure",
  "map.overlays.weather.humidity": "Humidity",
  "map.overlays.weather.wind": "Wind",
  "map.overlays.weather.windSpeed": "Wind Speed",
  "map.overlays.weather.windDirection": "Wind Direction",
  "map.overlays.weather.percentageClouds": "Clouds",
  "map.overlays.weather.searchTime": "Search Time",
  "map.overlays.weather.visibility": "Visibility",
  "map.overlays.weather.sunrise": "Sunrise",
  "map.overlays.weather.sunset": "Sunset",
  "map.overlays.weather.timezone": "Time Zone",
  "map.overlays.weather.optimizePolygon": "Optimize Polygon",
  "map.overlays.weather.optimizePolygonTooltip": `Optimizes the generation of imported polygons via Kml/Shp. 
  THE OPTIMIZATION MAY NOT GENERATE THE EXPECTED RESULTS, MAY CAUSE DISTORTIONS IN THE GENERATED POLYGONS`,
  // --- Textos a serem usados de maneira geral no app (por exemplo, em botões) ---
  "generic.ok": "OK",
  "generic.cancel": "Cancel",
  "generic.close": "Close",
  "generic.yes": "Yes",
  "generic.no": "No",
  "generic.next": "Next",
  "generic.previous": "Previous",
  "generic.continue": "Continue",
  "generic.closeConfirmation.title": "Confirm",
  "generic.closeConfirmation.discardChangesAndClose": "Discard changes and close?",
  "generic.N/A": "None",
  "generic.copy": "Copy",

  "general.year": "year",
  "general.month": "month",
  "general.hectares": "hectares",

  // --- Textos a serem usados em erros ---
  "generic.error.unknown": "An error has occurred.",

  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Precise by Nature",
  "info.title": "The first and only HUB \nof technologies for \nPrecision Biological Control",
  "info.description": "Coming Soon!",
  "info.newsletter": "Subscribe our Newsletter",
  "info.about": "bioHUB by NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. All rights reserved.",
  "info.address":
    "Parque Tecnológico de São José dos Campos - CEI - Sala 207 Estrada Doutor Altino Bondensan, 500 Distrito Eugênio de Mello São José dos Campos - SP - 12.247-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "+55 (41) 98891-4324",
  "info.terms":
    "By accepting the present term, the user consents and agrees that the company NCB, hereinafter referred to as the Controller, makes decisions regarding the treatment of their personal data, as well as carrying out the treatment of their personal data, involving operations such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, dissemination or extraction. The Controller is authorized to share the Holder's personal data with other data processing agents, if necessary for the purposes listed in this term, subject to the principles and guarantees established by Law No. 13.709.",
  "info.summary": "Summary",
  "info.register.information": "Register information",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Legal representative information",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Plans information",
  "info.amount": "Amount",
  "info.banner.plan.title": "Plans and prices",
  "info.plan": "Plan",
  "info.plan.name.bioHUB": "bioHUB",
  "info.plan.name.bioMAPS": "bioMAPS",
  "info.plan.name.bioBOT": "bioBOT",
  "info.plan.subTitle.bioMAPS": "Flight mission planning app",
  "info.plan.description.bioMAPS":
    "Mission planner for DRONES focused on precision biological control. This product requires an active bioHUB subscription to function.",
  "info.plan.type.monthly": "Monthly",
  "info.plan.type.yearly": "Yearly",
  "info.plan.perDevice": "per device",
  "info.plan.ofDiscount": "of discount",
  "info.mode": "Mode",
  "info.price": "Price",
  "info.per.year": "Per Year",
  "info.total": "Total",
  "info.users": "Users",
  "info.preregistrations": "Pre-Registrations",
  "info.preregistration": "Pre-registration",
  "info.preregistrations.viewdetails": "These are the information from this pre-registration.",
  "info.contract": "Contract",
  "info.add.new": "Enter the data bellow to add a new user.",
  "info.clients": "Customers",
  "info.termError": "Your message could not be sent at this time.",
  "info.menuOptions": "Menu Options",
  "info.addCart": "Add to cart",
  "info.purchase": "Purchase",

  "info.client.add": "Add Client",
  "info.directClient.add": "Add Direct Client",
  "info.client.edit": "Edit Client",
  "info.client.add.message": "Enter the data below to add a new client.",
  "info.client.edit.message": "Edit the data below to update your client.",
  "info.client.personalInformations": "Personal Information:",
  "info.client.companyInformation": "Company information:",
  "info.client.documentType": "Document Type",
  "info.client.monetaryConfiguration": "Billing Settings:",
  "info.client.currencyCode": "Currency",
  "info.client.nextDueDate": "Next Due Date:",

  "info.none": "None",
  "info.releaser": "Releaser",
  "info.releasers": "Releasers",
  "info.releaser.edit": "Edit Releaser",
  "info.releaser.add": "Add Releaser",
  "info.releaser.add.message": "Fill in the information below to add a new releaser.",
  "info.releaser.edit.message": "Edit the information below to update your releaser.",
  "info.releaser.selectdrone": "Select Drone:",
  "info.releaser.liberatormodel": "Releaser Model:",
  "info.releaser.models": "Models",
  "info.releaser.selectclient": "Client name:",

  "info.drone": "Drone",
  "info.drones": "Drones",
  "info.manufacturer": "Manufacturer",
  "info.drone.edit": "Edit Drone",
  "info.drone.add": "Add Drone",
  "info.drone.add.message": "Fill in the information below to add a new drone.",
  "info.drone.edit.message": "Edit the information below to update your drone.",
  "info.drone.selectdrone": "Select Drone:",
  "info.drone.selectclient": "Client name:",
  "info.drone.manufacturer": "Drone manufacturer",
  "info.drone.dronemodel": "Drone Model:",

  "info.cpu": "CPU",
  "info.cpus": "CPUs",
  "info.model": "Model",
  "info.serialNumber": "Serial Number",
  "info.firmwareVersion": "Firmware Version",
  "info.lastFirmware": "Latest Firmware",
  "info.client": "Client",
  "info.cpu.add": "Add CPU",
  "info.cpu.viewing": "Viewing CPU",
  "info.cpu.model": "CPU Model",
  "info.cpu.addingMessage": "Fill the fields below to add a new CPU",
  "info.cpu.viewingMessage": "Viewing the added CPU",
  "info.releaser.addDevice": "Add device",
  "info.releaser.removeDevice": "Remove device",
  "info.devices": "Devices",
  "info.device.model": "Model",

  "info.profiles": "Profiles",
  "info.role.master": "Master",
  "info.role.administrator": "Administrator",
  "info.role.manager": "Manager",
  "info.role.operator": "Operator",
  "info.role.external": "External",

  "info.profile.add": "Add User",
  "info.profile.edit": "Edit User",
  "info.profile.add.message": "Enter the data below to add a new user.",
  "info.profile.edit.message": "Edit the data below to update your user.",
  "info.profile.userType": "Type of User",
  "info.profile.userType.manager": "Manager",
  "info.profile.userType.operator": "Operator",
  "info.profile.userType.external": "External",
  "info.profile.personalInformations": "Personal Informations:",
  "info.profile.legalInformations": "Legal informations:",
  "info.profile.agricultureNumber": "Agriculture Number",
  "info.profile.crea": "CREA",
  "info.profile.ava": "AVA",

  "info.profile.edit.image": "Change profile picture",
  "info.profile.edit.image.dialog": "The photo must be in jpg, png or jpeg format.",
  "info.profile.edit.image.buttom.choose": "Choose photo",
  "info.profile.edit.image.buttom.send": "Upload",
  "info.profile.edit.image.buttom.cancel": "Cancel",
  "info.profile.edit.image.invalid": "Unable to upload your photo.",

  "info.profile.edit.logo": "Change your logo",
  "info.profile.edit.logo.dialog": "The logo must be in jpg, png or jpeg format.",
  "info.profile.edit.logo.buttom.choose": "Choose logo",
  "info.profile.edit.logo.buttom.send": "Upload",
  "info.profile.edit.logo.buttom.cancel": "Cancel",
  "info.profile.edit.logo.invalid": "Unable to load your logo.",

  "info.profile.nationality": "Nationality",

  "info.directClients": "Clients",

  //-- Pages --//
  "info.subtitleTerms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.pageTerms":
    "By accepting the present term, the user consents and agrees that the company NCB, hereinafter referred to as the Controller, makes decisions regarding the treatment of their personal data, as well as carrying out the treatment of their personal data, involving operations such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, dissemination or extraction. The Controller is authorized to share the Holder's personal data with other data processing agents, if necessary for the purposes listed in this term, subject to the principles and guarantees established by Law No. 13.709.",
  "info.subtitlePrivacy": "Lorem ipsum dolor sit amet consecter",
  "info.privacy":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleSupport": "Lorem ipsum dolor sit amet consecter",
  "info.support":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleFaq": "Lorem ipsum dolor sit amet consecter",
  "info.faq":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion1": "Question 1 ?",
  "info.faqQuestion1Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion2": "Question 2 ?",
  "info.faqQuestion2Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion3": "Question?",
  "info.faqQuestion3Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleWhy": "Lorem ipsum dolor sit amet consecter",
  "info.why":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleResources": "Lorem ipsum dolor sit amet consecter",
  "info.resources":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",

  "info.aboutTitle": "What is NCB?",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub is a holistic digital platform that enables effective pest and disease management through drones and software with a focus on business intelligence.",
  "info.aboutVisionTitle": "Our vision",
  "info.aboutVisionSub": "The reality that is sought",
  "info.aboutVisionText":
    "Transforming the agricultural sector by promoting data management and the application of technology and sustainable innovation for the creation of strategies and the assertive use of biological control. In three years, we want Bi All to be the reference in the symbiosis between agriculture, science and technology, after having operated three million hectares of crops.",
  "info.aboutMissionTitle": "Our mission",
  "info.aboutMissionSub": "The reason for being",
  "info.aboutMissionText":
    "Catalyze nature through the digitization of data that allows you to create strategies for making decisions, ensuring the best management of resources. Provide solutions in agricultural ecosystems through precision biological control.",
  "info.aboutValues": "Our values",
  "info.aboutPrecisionTitle": "Precision",
  "info.aboutPrecisionText":
    "We execute all our processes and services with rigor and according to the strategy with which they were planned.",
  "info.aboutTraceabilityTitle": "Traceability",
  "info.aboutTraceabilityText":
    "We guarantee visibility and control at every step of the processes to obtain the best results.",
  "info.aboutTeamTitle": "Our team",
  "info.aboutTeamRoleCEO": "CEO and Founder",
  "info.aboutTeamRoleManager": "Management",
  "info.aboutTeamRoleEmployee": "Engineering",
  "info.aboutTeamDescriptionCEO":
    "Electrical Engineering (FEI 2002)\nMaster Electronics (ITA 2007)\nDoctorate in Aerospace Systems (ITA 2013)",
  "info.aboutTeamDescriptionManager":
    "Administration (UNILESTE 2005)\nEntrepreneurship Management (UNIPAC)",
  "info.aboutTeamDescriptionEmployee":
    "Integrated hardware and software\nElectrical Engineering (UNESP 2005)",
  "info.contactTitle": "Contact us",
  "info.contactSubtitle": "Contact information by country",
  "info.contactDescription":
    "Fill in your details in the form below and wait. We will contact you as soon as possible!",
  "info.termTitle": "The bioMAPS plan",
  "info.coin": "$",
  "info.termPriceMonth": "per month / per device",
  "info.termPriceYear": "per year / per device (15% off)",
  "info.termSpecifications": "Specifications:",
  "info.termSpecificationsDesc":
    "It is DRONES' first mission planner with a focus on biological precision control. You can plan routes from anywhere in the world through the cloud and synchronized with a mobile application to launch 100% GPS tracked and controlled bios.",
  "info.termClassifications": "Classifications: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Choose plan",
  "info.termComparisonTitle": "Compare Features",
  "info.termComparisonMonth": "month",
  "info.termComparisonYear": "year",
  "info.termComparisonEquipment": "equipment",
  "info.termComparisonPerEquipment": "by equipment",
  "info.termComparisonMonthDevice": "month per device",
  "info.termComparisonYearDevice": "year per device",
  "info.termComparisonHectare": "hectare per month",
  "info.termComparisonDescription": "Includes license for 1 device.",
  "info.termComparison.accessTitleBioHUB": "Access to the platform",
  "info.termComparison.accessTitleBioMAPS": "Access to the app",
  "info.termComparison.accessTitleBioBOT": "Equipment",
  "info.termComparisonDescriptionBioHUB":
    "Includes 5 operator licenses or 4 operator licenses and 1 manager license. $15 for each extra operator.",
  "info.introducing": "Introducing",
  "info.cameraQuality": "Camera Quality",
  "info.flightTime": "Flight Time",
  "info.capacity": "Capacity",
  "info.minutes": "minutes",
  "info.verificationTitle": "E-mail Verification",
  "info.verificationDescription":
    "Verification requires a valid email address. \nWe will send a confirmation code to the email address you entered in order to start the validation process. \nIf the address given below is incorrect, click on the icon and change your details.",
  "info.emailAddress": "E-mail Address",
  //TODO: Check that and other terms in the wrong language
  "info.verificationCodeDescription": "Informe o código enviado para seu e-mail:",
  "info.confirmationCode": "Código de verificación",
  "info.newPassword": "New Password",
  "info.newPasswordDescription": "Please set a new password to access our platform.",
  "info.newPasswordDetails": "Enter the new password",
  "info.newPasswordConfirm": "Confirm your new password",

  "setupAccount.title": "Complete your Registration",
  "setupAccount.created": "Account successfully created",
  "setupAccount.error":
    "Something wrong happened in the process of finalizing your registration. Please try again.",
  "setupAccount.error.tryAgain": "Try again",
  "setupAccount.error.wrongSmsCode": "Wrong SMS Code",
  "setupAccount.verifiedEmail": "Email verified successfully",
  "setupAccount.verification.code": "SMS Code",
  "setupAccount.verification.description":
    "Verification requires a valid phone number. \nWe will send a confirmation code to your phone and you need enter the correct code to finish the validation process and create your account password.",
  "setupAccount.verification.codeDescription": "Enter the 6 digit code that was send to your phone",
  "setup.password": "Enter your password",
  "setup.confirmPassword": "Confirm your password",
  "setup.password.different": "Password doesn't match",
  "setup.password.needUpperCase": "Necessary an element in upper case",
  "setup.password.needSpecialElement": "Necessary a special character",
  "setup.password.lenght": "Necessary a minimum length of 6 characters",

  "drone.usagePhantom4": "Phantom 4",
  "drone.usageDroneMatrix100": "Matrix 100",
  "drone.usageDroneMatrix200": "Matrix 200",

  "prod.productTitle": "Products",
  "prod.bioBotSubTitle": "Biological Input Launcher",
  "prod.bioBotDescription":
    "It is a bulk parasite and egg releaser, used primarily for pest control. With it, it is possible to launch biological inputs such as Trichogramma, Telenomus and Chrysopa (all tested and in commercial operation with DRONES in Brazil, Colombia and Central America).",
  "prod.bioBotWorksBioMaps": "Works with bioMAPS app",
  "prod.bioBotWeatherProof": "Weather proof",
  "prod.bioBotAutonomy": "+5 hours of autonomy",
  "prod.equipmentTitle": "Or more versatile equipment",
  "prod.equipmentDescription":
    "With it it is possible to release biological inputs such as Trichogramma, Telenomus and Chrysopa (all tested and in commercial operation with DRONES in Brazil, Colombia and Central America).",
  "prod.biocoteDescription":
    "It is a biodegradable cup releaser with cothesia mass used mainly in the population control of caterpillars. Sugarcane is the main crop treated. (Biocontrol's exclusive development). 100% operational and commercial. Your version of parasitized unique pasta has been frozen by we cannot continue field testing, we will resume from January 2021.",

  "prod.cultivationEquipmentTitle": "Main crops benefited",
  "prod.cultivationSugarCane": "Sugar Cane",
  "prod.cultivationSoy": "Soy",
  "prod.cultivationBeans": "Beans",
  "prod.cultivationCorn": "Corn",
  "prod.cultivationBanana": "Banana",
  "prod.cultivationAvocado": "Avocado",
  "prod.cultivationOilPalm": "Oil Palm",
  "prod.cultivationCoffee": "Coffee",
  "prod.cultivationForest": "Forest",
  "prod.cultivationStrawberry": "Strawberry",
  "prod.cultivationMelon": "Melon",
  "prod.cultivationPapaya": "Papaya",
  "prod.cultivationTulip": "Tulip",
  "prod.cultivationCotton": "Cotton",
  "prod.cultivationOthers": "Others",
  "prod.cultivationContact":
    "If the cultivation of your company is not shown here, contact us for a personalized advice",

  "prod.usageSugarcane": "Sugarcane",
  "prod.usageForest": "Forest",
  "prod.usageCotton": "Cotton",
  "prod.usageCorn": "Corn",
  "prod.usageSoybeans": "Soybeans",
  "prod.usageCoffee": "Coffee",
  "prod.usageStrawberry": "Strawberry",
  "prod.usageMelon": "Melon",
  "prod.usagePapaya": "Papaya",
  "prod.usageTulip": "Tulip",
  "prod.usageAvocado": "Avocado",
  "prod.usageBeans": "Beans",
  "prod.usageBanana": "Banana",
  "prod.usageOilPalm": "Oil Palm",

  "prod.specificationTitle": "Technical specifications",
  "prod.specificationSubTitle": "Access to bioHUB platform",
  "prod.specificationInfo":
    "Includes 5 operator licenses or 4 operator licenses and 1 manager license; $ 15 for each extra operator.",

  "bio.usageTrichogrammaExiguum": "Trichogramma Exiguum",
  "bio.usageTrichogrammaGalloi": "Trichogramma Galloi",
  "bio.usageTrichogrammaAtopovirilia": "Trichogramma Atopovirilia",
  "bio.usageTrichogrammaBrassicae": "Trichogramma Brassicae",
  "bio.usageTrichogrammaPretiosum": "Trichogramma Pretiosum",
  "bio.usageChrysoperlaCarnea": "Chrysoperla Carnea",
  "bio.usageChrysoperlaExterna": "Chrysoperla Externa",
  "bio.usageStratiolaelapsScimitus": "Stratiolaelaps Scimitus",
  "bio.usageNeoseiulusCalifornicus": "Neoseiulus Californicus",
  "bio.usageTelenomusPodisi": "Telenomus Podisi",
  "bio.usageTelenomusRemus": "Telenomus Remus",
  "bio.usagePhytoseiulusPersimilis": "Phytoseiulus Persimilis",
  "bio.usageCotesiaFlavipes": "Cotesia Flavipes",
  "bio.usageCeraeochrysaCincta": "Ceraeochrysa Cincta",
  "bio.usageTetrastichusHowardi": "Tetrastichus Howardi",
  "bio.usageAmblyseiusCalifornicus": "Amblyseius Californicus",
  "bio.usageMetarhiziumAnisopliae": "Metarhizium Anisopliae",
  "bio.usageBeauveriaBassiana": "Beauveria Bassiana",

  "cart.title": "My cart",
  "cart.title.general": "General",
  "cart.title.address": "Personal address and contact",
  "cart.title.address.company": "Company address and contact",
  "cart.title.representative": "Legal representative",
  "cart.title.step1": "Step 1: Company data",
  "cart.title.step2": "Step 2: Terms",
  "cart.title.step3": "Step 3: Pay",
  "cart.title.number.order": "Order No.: ",
  "cart.title.billing.address": "Billing address",
  "cart.title.shipping.address": "Shipping address",
  "cart.title.payments.methods": "Payments methods",
  "cart.title.modal.billing.address": "Add billing address",
  "cart.title.modal.shipping.address": "Add shipping address",
  "cart.title.discount.coupon": "Discount coupon: ",
  "cart.title.modal.add.discount.coupon": "Add new discount coupon",
  "cart.title.subtotal": "SubTotal",
  "cart.title.discounts": "Discounts",
  "cart.title.taxes": "Taxes",
  "cart.button.download": "Download",
  "cart.terms.information.use": "Use of personal information",
  "cart.terms.mandatory.contract": "Mandatory Contracts",
  "cart.info.payments.paypal": "Paypal",
  "cart.info.payments.pix": "Pix",
  "cart.info.payments.ticket": "Ticket",
  "cart.action.empty": "Empty cart",
  "cart.message.error.CPF": "Invalid CPF",
  "cart.message.error.CNPJ": "Invalid CNPJ",
  "cart.message.error.fields.filled": "Make sure all fields are filled in correctly!",
  "cart.message.error.checked": "Make sure all Terms have been selected!",
  "cart.message.error.extra.address": "Add at least 1 shipping or billing address",
  "cart.message.error.item.cart": "There are no items in your cart!",
  "cart.message.error.document.type": "Select some type of document! (CPF, CNPJ, ID)",
  "cart.message.error.coupon.invalid": "Invalid coupon, try another one!",
  "cart.message.error.coupon.add.exists": "This coupon has already been added!",
  "cart.alreadyIncluded": "Already included:",
  "cart.chooseOneVersionOfTheProductMessage": "Choose a version to add to cart:",
  "cart.product": "Product:",

  //-- Landing Page --//
  "page.title": "Digitize your field, \nthe first step towards \nthe future of farming",
  "page.description":
    "bioHUB is a holistic digital platform that alows effective management of pests and diseases through the use of drones and software focused on business intelligence.",
  // Sections
  "section.about.title": "Our Products",
  "section.about.category": "Software",
  "section.about.info.title": "About bioHUB",
  "section.about.info.subtitle": "BioHub is a precision biological control management system",
  "section.about.info.description":
    "An integrated cloud platform that assists the entire biological control chain in monitoring pests, accurately releasing, generating reports and monitoring decision-making...",
  "section.biomaps.title": "About BioMAPS:",
  "section.biomaps.subtitle": "BioMAPS is an autonomous flight mission planner",
  "section.biomaps.description":
    "It is the first drone mission planner focused on precision biological control. It is used to plan flight routes for pest identification and treatment.",
  "section.biomaps.required": "BioMAPS requires an active bioHUB subscription",
  "section.how.works.title": "How does it work?",
  "section.products.category": "Hardware",
  "section.products.description":
    "Each crop can suffer from different pests that need specialized treatment. That is why we have created ideal biological solutions for your cultivation and your business model.",
  "section.products.subDescriptionCultivation": "I grow ",
  "section.products.subDescriptionDrone": "I Have the drone ",
  "section.products.subDescriptionBiological": "I want to apply ",
  "section.card.product.description.bioBOT":
    "It is a bulk parasite and egg releaser used primarily for 𝐩𝐞𝐬𝐭 𝐜𝐨𝐧𝐭𝐫𝐨𝐥. With it it is possible to release biological inputs such as Trichogramma, Telenomus and Chrysopa",
  "section.card.product.description.bioCOTE":
    "It is a biodegradable vessel releaser with biological inputs used in 𝐜𝐚𝐭𝐞𝐫𝐩𝐢𝐥𝐥𝐚𝐫𝐬 population control.",
  "section.card.product.description.PHERObio":
    "It is a capsule launcher with pheromones. It is used to control 𝐌𝐢𝐠𝐝𝐨𝐥𝐮𝐬 through sexual confusion.",
  "section.products.required": "All of our hardware products require",
  "section.prices.category": "Pricing",
  "section.prices.title": "The right plan for your business",
  "section.prices.description":
    "We offer several powerful plans to showcase your business and be discovered as a creative entrepreneur.",
  "section.clients.category": "Clients",
  "section.clients.title": "Opinions of our clients",
  "section.clients.opinions.men":
    "“I received great customer service from the specialists who helped me. I would recommend to anyone who wants great dashboard that has great quality”",
  "section.clients.opinions.women":
    "“It really boils down to this: that all life is interrelated. We are all caught in an inescapable network of mutuality, tied into a single garment of destiny”",
  "section.clients.role.men": "Product Designer",
  "section.clients.role.women": "Web Developer",
  "section.partners.category": "Partners",
  "section.buy": "Buy",

  // Section Product
  "section.product.benefited.crops.title": "Benefited Harvests",
  "section.product.compatible.drone.title": "Compatible Drones",
  "section.product.highlight": "Highlights",
  "section.product.biological.title": "Biologicals",

  // Sections Product [bioBOT]
  "section.product.bioBOT.title": "bioBOT",
  "section.product.bioBOT.subtitle": "Biological Input Liberators",
  "section.product.bioBOT.description":
    "It is a bulk parasitized egg dispenser primarily used for pest population control. With it, it is possible to release biological inputs of the Trichogramma, Telenomus, and Chrysopa genera. Sugarcane, corn, beans, soybeans, cotton, coffee, banana, palm, and avocado are the main treated crops.",
  "section.product.bioBOT.how_it_works.title": "How does BioBOT work?",

  // Sections Product [bioCOTE]
  "section.product.bioCOTE.title": "bioCOTE",
  "section.product.bioCOTE.subtitle": "Biological Input Liberators",
  "section.product.bioCOTE.description":
    "It is a mass dispenser of the Cotesia genus primarily used for population control of caterpillars. Sugarcane is the main treated crop.",
  "section.product.bioCOTE.how_it_works.title": "How does BioCOTE work?",

  // Sections Product [bioMITE]
  "section.product.bioMITE.title": "bioMITE",
  "section.product.bioMITE.subtitle": "Biological Input Liberators",
  "section.product.bioMITE.description":
    "It is a dispenser of predatory mites Phytoseiulus and Neoseiulus. After release, they attack and control infestations of other pest mites in the field. Strawberry, tomato, flowers, tobacco, and mushroom are the main crops.",
  "section.product.bioMITE.how_it_works.title": "How does BioMITE work?",

  //-- Dashboard Information --//
  "report.input": "Input",
  "dashboard.welcome": "Welcome back, ",
  "dashboard.clients": "Clients",
  "dashboard.users": "Users",
  "dashboard.total.users": "Total Users",
  "dashboard.total.reports": "Reports",
  "dashboard.total.releasedFlight": "Flight",
  "dashboard.total.operations": "Operations",
  "dashboard.total.operation": "Operation",
  "dashboard.total.releasedFlights": "Flights",
  "dashboard.total.monthFlightYield": "Released Area",
  "dashboard.leggend.plannedReleased": "Planned / Released",
  "dashboard.total.clients": "Total Clients",
  "dashboard.total.drones": "Total Drones",
  "dashboard.total.projects": "Total Projects",
  "dashboard.total.sales": "Client Releases",
  "dashboard.total.area": "Total Hectares Covered",
  "dashboard.total.yearMonthLegend": "Month / Year",

  "dashboard.total": "Totalizers",
  "dashboard.start.date": "Start",
  "dashboard.end.date": "End",
  "dashboard.area.drone.time": "Area and Drone by Time",
  "dashboard.area.drone.time.no.data": "Select a range with available drone data",
  "dashboard.area.input.time": "Area and Input by Time",
  "dashboard.area.input.time.no.data": "Select a range with available input data",

  "dashboard.hectares.covered": "Hectares Covered",
  "dashboard.new.company": "New companies",
  "dashboard.flight.client": "Flights per customer",
  "dashboard.coverage.graph": "Coverage Area Graph",
  "dashboard.scale": "Scale",
  "dashboard.graphmodeview": "View by",
  "dashboard.filter": "Filter",
  "dashboard.time.30minutes": "30 minutes",
  "dashboard.time.1hour": "1 hour",
  "dashboard.time.8hours": "8 hours",
  "dashboard.time.1day": "1 day",
  "dashboard.time.1week": "1 week",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Profile",
  "dashboard.view.client": "Client",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "About NCB",
  "menu.products": "Products",
  "menu.solutions": "Solutions",
  "menu.pricing": "Pricing",
  "menu.contact": "Contact",
  "menu.privacy": "Privacy Policy",
  "menu.terms": "Terms & Conditions",
  "menu.title": "Company",
  "menu.support": "Support",
  "menu.faq": "FAQ's",
  "menu.resources": "Resources",
  "menu.why": "Why bioHUB?",
  "menu.home": "Home",
  "menu.biomaps": "BioMAPS",
  "menu.profile": "Profile",
  "menu.plans": "Plans",
  "menu.settings": "Settings",
  "menu.updates": "Updates",
  "menu.open.project": "Open Project",
  "menu.new.project": "New Project",
  "menu.new.monitoring": "New Monitoring",
  "menu.flight.monitoring": "Flight Monitoring",
  "menu.trap.monitoring": "Trap Monitoring",
  "menu.biomapsInfo":
    "BioMAP visualizes the areas where biological controllers are released through the mission planner.",
  "menu.licenses": "Licenses",
  "menu.traps": "Traps",

  //-- Generic Actions --//
  "action.usageChoose": "Select...",
  "action.ContactUs": "I want to order specific hardware",
  "action.login": "Login",
  "action.signUp": "Sign Up",
  "action.subscribe": "Subscribe",
  "action.register": "Pre-register",
  "action.makeRegistration": "Make your pre-registration!",
  "action.success": "Success",
  "action.error": "We're sorry!",
  "action.msgSuccess":
    "Your pre-registration was successful in our database! \nWe'll notify you soon ✓",
  "action.msgError": "There was an error during your pre-registration! Please try again later.",
  "action.back": "Back",
  "action.getStarted": "Get Started",
  "action.learnMore": "Learn more",
  "action.learnMore.prices": "Know the plans and prices",
  "action.more": "More information...",
  "action.keep.connected": "Keep connected",
  "action.login.message": "Enter your details to login",
  "action.login.msgError": "Error signing in. Please check your data or try again in a moment.",
  "action.continue": "Continue",
  "action.accept": "Accept the terms",
  "action.indicator": "Register as: ",
  "action.signup.company": "Company",
  "action.signup.single": "Single person",
  "action.accept.signup.terms": "I Accept the general terms of the LGPD data protection law.",
  "action.pay.now": "Pay Now",
  "action.add.new": "Add new",
  "action.edit": "Edit",
  "action.clean": "Clean",
  "action.edit.picture": "Change picture",
  "action.edit.logo": "Change logo",
  "action.remove": "Remove",
  "aciton.restore": "Restore",
  "action.save": "Save",
  "action.add": "Add",
  "action.cancel": "Cancel",
  "action.undo": "Undo",
  "action.redo": "Redo",
  "action.finish": "Finish",
  "action.settings": "Settings",
  "action.income": "Income",
  "action.send": "Send",
  "action.confirm": "Confirm",
  "action.sendVerification": "Send code verification",
  "action.saveData": "Save data",
  "action.confirmAction": "Are you sure you want to complete this action?",
  "action.warn": "Warning",
  "action.cart.add": "Item added to cart.",
  "action.cart.remove": "Item removed from cart.",
  "action.cart.changedQuantity": "Changed Quantity.",

  //-- Placeholders --//
  "placeholder.profile": "Profile",
  "placeholder.email": "E-mail",
  "placeholder.name": "Name",
  "placeholder.phone": "Phone number",
  "placeholder.ladlineNumber": "Landline number",
  "placeholder.company": "Company",
  "placeholder.isCompanyQuestion": "Is company?",
  "placeholder.data.collect": "How did you hear about NCB?",
  "placeholder.password": "Password",
  "placeholder.search": "Search",
  "placeholder.iva": "VAT",
  "placeholder.company.name": "Company name",
  "placeholder.zip": "ZIP",
  "placeholder.address": "Address",
  "placeholder.number": "Number",
  "placeholder.cellphone": "Cell phone",
  "placeholder.complement": "Complement",
  "placeholder.country": "Country",
  "placeholder.state.department": "State",
  "placeholder.city": "City",
  "placeholder.street": "Street",
  "placeholder.comercial.phone": "Comercial phone",
  "placeholder.aviation.reg": "Aviation Registration",
  "placeholder.agriculture.reg": "Agriculture Registration",
  "placeholder.role": "Role",
  "placeholder.document": "Document",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Trade Name",
  "placeholder.type": "Type",
  "placeholder.contact": "Contact",
  "placeholder.description": "Bio",
  "placeholder.subject": "Subject",
  "placeholder.message": "Message",
  "placeholder.project": "Project",
  "placeholder.area": "Area",
  "placeholder.aircraft": "Aircraft",
  "placeholder.code.country": "Country Code",
  "placeholder.name.street": "Name of the street:",
  "placeholder.street.examples": "Apartment, unit, building, floor, etc",
  "placeholder.phone.mask": "(000) 0000-0000",
  "placeholder.CPF.mask": "XXX.XXX.XXX-XX",
  "placeholder.CNPJ.mask": "XX.XXX.XXX/0001-XX",

  "placeholder.releaser.serialnumber": "Serial number",
  "placeholder.releaser.nickname": "Nickname",
  "placeholder.releaser.releasermodels": "Models",
  "placeholder.releaser.tablemodel": "Model",
  "placeholder.releaser.tableserialnumber": "Serial No.",
  "placeholder.releaser.searchclient": "Enter client name",
  "placeholder.profile.select.picture": "No image selected...",

  "placeholder.drone.selectclient": "Client...",
  "placeholder.drone.selectcpu": "CPU...",
  "placeholder.drone.selectmanufacturer": "Drone manufacturer...",
  "placeholder.drone.selectdronemodel": "Drone model...",
  "placeholder.drone.clientcpu": "Client CPU",

  "placeholder.settings.insufficientpermissions":
    "You do not have sufficient permissions to change these settings.",

  "profile.userTableComponent.selection.selected": "selected",
  "profile.userTableComponent.selection.clear": "Clear selection",
  "profile.userTableComponent.selection.remove": "Remove selected data",

  "profile.account": "My Account",
  "profile.profile": "My Profile",
  "profile.settings.updated": "Updated profile preferences",

  //-- Labels --//
  "label.document.ID": "ID",
  "label.document.CPF": "CPF",
  "label.document.CNPJ": "CNPJ",
  "label.document.RG": "General Registration (RG)",
  "label.document.IM": "Municipal registration",
  "label.name.company": "Company Name",
  "label.trade.name": "Social Reason",
  "label.direction": "Address",
  "label.code.postal": "Code Postal",
  "label.fullname": "Full name",
  "label.phone": "Landline",
  "label.cellphone": "Cell phone",
  "label.select.country": "Select a Country",

  //-- Options --//
  "option.preferences.defaultCurvePercentage": "Curvature (%)",
  "option.preferences.mustReleaseEntireArea":
    "Activate equipment in the first waypoint and deactivate in the last",
  "option.preferences.generalinfo": "General information",
  "option.preferences.unitsystem": "Unit system",
  "option.preferences.twofactorauth": "Two-factor authentication",
  "option.preferences.sexagesimalcoordinates": "Use Sexagesimal Coordinates",
  "option.preferences.separatelybatteries": "Show Batteries Separately",
  "option.preferences.metric": "Metric",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Search engine",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recommendations",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Events",
  "option.others": "Others",
  "option.which": "Which?",

  //-- Map --//
  "map.title": "Map",
  "map.defaultRegion": "US",
  "map.editMode.enterManualRoute": "Manual Route",
  "map.editMode.editRoute": "Edit Route",
  "map.editMode.manualRoute":
    "Tap the area to insert a new waypoint or drag ones that have already been entered to modify the route. ",
  "map.editMode.addPolygon":
    "Touch the map to insert a point; long-press a marker to change its position.",
  "map.editMode.error.theePointsMinimum": "Please, input at least three points.",
  "map.editMode.error.nearbyPoints":
    "Please, each waypoint must be at least 1 meter away from the others.",
  "map.editMode.error.interesection":
    "Inserted polygon has one or more intersections. Please draw a simple polygon.",
  "map.editMode.reverseRoute": "Reverse route",
  "map.removeArea": "Remove this area?",
  "map.newArea": "New area",
  "map.loadingList": "loading...",
  "map.loadingFlights": "loading...",
  "map.noFlightsToShow": "No flights.",
  "map.editPolygonPoints": "Edit polygon",
  "map.editPlan": "Edit area plan",
  "map.importRouteKml": "Import route (KML)",
  "map.goToArea": "Go to the Area",
  "map.replanRoute": "Generate new route",
  "map.editAreaProperties": "Settings",
  "map.deleteArea": "Delete area",
  "map.restoreArea": "Restore area",
  "map.duplicateArea": "Create area copy",
  "map.kml.exportArea": "Export area as KML",
  "map.kml.exportRoute": "Export route as KML",
  "map.kml.exportFlight": "Export flight as KML",
  "map.copyArea": "Copy area",
  "map.pasteCopiedArea": "Paste copied area",
  "map.deletingArea": "Deleting area",
  "map.restoringArea": "Restoring area",
  "map.deletingArea.question": "Proceed?",
  "map.restoringArea.question": "Proceed?",
  "map.deleteProject": "Delete project",
  "map.restoreProject": "Restore project",
  "map.deletingProject": "Deleting project",
  "map.restoringProject": "Restoring project",
  "map.deletingProject.question": "Proceed?",
  "map.restoringProject.question": "Proceed?",
  "map.importAreas.importAreasAction": "Import areas",
  "map.newArea.createAreaModalTitle": "Create new area",
  "map.newArea.curveMode": "Curve Mode",
  "map.newArea.curveMode.curved": "Curved",
  "map.newArea.curveMode.normal": "Normal",
  "map.newArea.headingMode": "Heading Mode",
  "map.newArea.headingMode.nextWaypoint": "Next Waypoint",
  "map.newArea.headingMode.manual": "Manual",
  "map.newArea.importAreaModalTitle": "Create new area",
  "map.newArea.editProjectModalTitle": "Edit project",
  "map.newRoute.importKmlModalTitle": "Import route",
  "map.newArea.areaName": "Area name",
  "map.newProject.title": "New project",
  "map.newProject.indirectClientName": "Client",
  "map.newProject.projectName": "Project name",
  "map.newProject.optionalImportKml": "Import KML",
  "map.newProject.optionalImportShapefile": "Import shapefile",
  "map.newProject.skipImportingFiles": "Draw area manually",
  "map.newProject.kmlFileChoose": "Choose...",
  "map.newProject.shapefileFileChoose": "Choose...",
  "map.newProject.releaser": "Releaser",
  "map.newProject.input": "Input",
  "map.newProject.releaseRate": "Releasing rate",
  "map.newProject.instantaneousReleaseRate": "Instant releasing rate",
  "map.newProject.calibrationFactor": "Calibration factor",
  "map.type.roadmap": "Default",
  "map.type.satellite": "Satellite",
  "map.type.hybrid": "Hybrid",
  "map.areasAmountSing": "{amount} area",
  "map.areasAmountPl": "{amount} areas",
  "map.enableLabels": "Labels",
  "map.closeProject": "Close Project",
  "map.closeArea": "Close area",
  "map.toggleVisibility": "Toggle visibility",
  "map.toggleVisibility.disabled": "Disabled",
  "map.openFlightReport": "Open flight report",
  "map.moreOptions": "More options",

  "map.search": "Address Search",
  "map.search.noOptions": "No address option",

  "map.plannedRoutes.title": "Area planned routes",
  "map.plannedRoutes.flightAmount": "Flight Amount",
  "map.plannedRoutes.plannedData": "Planned Date",
  "map.plannedRoutes.untilToday": " - Today",
  "map.plannedRoutes.flightDate": "Flight Date",
  "map.plannedRoutes.termPerformedFlights": "Performed Flights",
  "map.plannedRoutes.areaSize": "Area Size",
  "map.plannedRoutes.flightTime": "Flight Time",
  "map.plannedRoutes.viewingOldPlan":
    'You are viewing an old plan for the area. \nClick "Exit" to return to view the current planning. \nClick "Replace" to replace the current schedule with the schedule you are viewing now.',
  "map.plannedRoute.select": "Replace",
  "map.planedRoute.exit": "Exit",
  "map.plannedRoute.routeAngle": "Route angle",
  "map.editMode.routeAngle":
    "Tap to select a point to use the nearest polygon point as base to create route. You can also change the rotation angle.",

  "map.footer.noProjectOpen": "No project open",
  "map.footer.possibleCoverageArea": "Possible coverage area: {areaSize}",
  "map.footer.coveredArea": "Coverage area: {areaSize} ({percentage}%)",

  "map.unlinkedFlights.unlinkedFlights": "Unlinked Flights",
  "map.unlinkedFlights.cpuSerialNumber": "Log File:",
  "map.unlinkedFlights.action.linked": "Link to a flight",
  "map.unlinkedFlights.action.linked.for.prefix": "Link flight ",
  "map.unlinkedFlights.action.linked.for.sufix": " to area: ",
  "map.unlinkedFlights.modal.project": "Project",
  "map.unlinkedFlights.modal.area": "Area",
  "map.unlinkedFlights.modal.drone": "Drone",
  "map.unlinkedFlights.modal.cancel": "Cancel",
  "map.unlinkedFlights.modal.link": "Link",

  // Modal map legends
  "map.legends.modal.marker.cluster.pink":
    "Group of 'n' projects that at that given zoom level are represented near each other",
  "map.legends.modal.marker.cluster.red":
    "Represents a project at a far zoom level where 'n' means the amount of areas it contains",
  "map.legends.modal.marker.cluster.yellow":
    "Group of 'n' areas that are inside a project that at that given zoom level are represented near each other",
  "map.legends.modal.marker.cluster.blue":
    "Represents an area at a far zoom level where the number 'n' means the amount of waypoints",
  "map.legends.modal.icon.homepoint": "Homepoint - Planned initial point to start the flight",
  "map.legends.modal.icon.marker.thick.blue.green":
    "Waypoint - Reference point to execute the route with the equipment turned on",
  "map.legends.modal.icon.marker.thick.blue.red":
    "Waypoint - Reference point to execute the route without with equipment turned off",
  "map.legends.modal.icon.marker.thick.blue.green.border":
    "Represents the waypoint 'n' when the drone will pass with equipment on",
  "map.legends.modal.icon.marker.thick.blue.red.border":
    "Represents the waypoint 'n' when the drone will pass with equipment turned off",
  "map.legends.modal.orange.arrow": "Arrow indicating the direction that the drone must follow",
  "map.legends.modal.icon.marker.distance.top": "First line: Waypoint height from the ground",
  "map.legends.modal.icon.marker.distance.bottom":
    "Second line: Optional information - Waypoint height from the home point. When if not appear that means that the waypoint has the same height from the ground and from the home point",
  "map.legends.modal.drawn.planning.area": "Planned route for the flight",
  "map.legends.modal.line.area": "Line between waypoints indicating the route",
  "map.legends.modal.green.line": "Flight Route",
  "map.legends.modal.title": "Map elements legend",
  "map.legends.modal.paragraph.waypoints": "Distance between two waypoints",
  "map.legends.modal.where.the.drone.passed": "Line showing where the drone will fly",
  "map.legends.modal.drawn.drone.work": "Contour indicating area that was sprayed",
  "map.legends.modal.drawn.area.selected": "Polygon representation of a selected area",
  "map.legends.modal.drawn.area.notSelected": "Polygon representation of an unselected area",

  "map.openedProjectAndAreaActions": "Project {projectName} {and Area {areaName}} settings",
  "map.editProjectSettings": "Project settings",
  "map.editAreaSettings": "Area settings",

  "map.openedProjectAndAreaActions.reverseRoute": "Reverse area route",
  "map.openedProjectAndAreaActions.editPolygonPoints": "Edit area polygon",
  "map.openedProjectAndAreaActions.editMode.editRoute": "Edit area route",
  "map.openedProjectAndAreaActions.editMode.enterManualRoute": "Manual area route",
  "map.openedProjectAndAreaActions.editPlan": "Edit area plan",
  "map.openedProjectAndAreaActions.replanRoute": "Generate new area route",
  "map.openedProjectAndAreaActions.plannedRoutes.title": "Area planned routes",
  "map.openedProjectAndAreaActions.importRouteKml": "Import area route (KML)",

  //-- Route planning --//
  "routePlanning.notFoundReleaser": "Releaser not found",

  "routePlanning.editWaypoint": "Edit Waypoint",
  "routePlanning.param.areaPadding": "Area indentation",
  "routePlanning.param.flightHeight": "Height",
  "routePlanning.param.flightSpeed": "Speed",
  "routePlanning.param.flightSpeed.legend":
    "Speed that the drone will accelerate to reach between waypoints (since it is necessary to decelerate to make turns it will not necessarily be reached)",
  "routePlanning.param.trackWidth": "Line width",
  "routePlanning.param.considerRelief": "Online elevation",
  "routePlanning.param.useHighestPolygonPointElevationAsHomePoint":
    "Find highest area border point to use as home point",
  "routePlanning.param.canUseOutsidePolygonSegmentsInRoute":
    "Can use outside polygon segments in route",

  "routePlanning.param.waypoint.height": "Height",
  "routePlanning.param.waypoint.curveRadius": "Curve radius",
  "routePlanning.param.waypoint.speed": "Speed",
  "routePlanning.param.waypoint.speed.legend":
    "Speed that the drone will accelerate to reach the next waypoint (since it is necessary to decelerate to make turns it will not necessarily be reached)",
  "routePlanning.param.waypoint.orientation": "Orientation",
  "routePlanning.param.waypoint.altitudeAboveHome": "{elevation}{unit} above homepoint",
  "routePlanning.param.waypoint.actions": "Actions",
  "routePlanning.param.waypoint.action.enableEquipment": "Enable Equipment",
  "routePlanning.param.waypoint.action.disableEquipment": "Disable Equipment",
  "routePlanning.param.waypoint.action.none": "None",
  "routePlanning.param.waypoint.location.legend.lat": "Latitude",
  "routePlanning.param.waypoint.location.legend.lng": "Longitude",
  "routePlanning.param.waypoint.location.legend.elevation": "Elevation",

  // -- Regions -- //
  "region.brazil": "Brazil",
  "region.europe": "Europe",
  "region.other": "Other",

  //-- LANGUAGES --//
  language: "Language",
  "language.pt": "Brazilian Portuguese",
  "language.es": "Spanish",
  "language.en": "English",
  "language.fr": "French",
  "language.name": "English",
  "language.region": "EN",
  "language.decimalSeparator": ".",
  "language.country": "Brazil",

  //- Reports -//
  "report.issueDate": "Issue Date:",
  "report.area": "Area",
  "report.plannedFlight": "Planned Flight",
  "report.details": "Details",
  "report.dateHour": "Date and Hour:",
  "report.drone": "Drone:",
  "report.manufacturer": "Manufacturer:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Released Product:",
  "report.releasing": "Releaser",
  "report.bandWidth": "Line Width",
  "report.plannedSpeed": "Planned Speed",
  "report.releaseRate": "Release Rate",
  "report.realFlight": "Real Flight",
  "report.flightTime": "Flight Time",
  "report.averageReleaseRate": "Average Release Rate",
  "report.averageSpeed": "Average Speed",
  "report.totalReleased": "Total Released",
  "report.totalArea": "Total Area",
  "report.traps": "Traps",
  "report.serialNumber": "Serial Number",
  "report.coveredHectares": "Covered Hectares",
  "report.dateMeasurament": "Last Measurement Date",
  "report.specie": "Species",
  "report.adultsNumber": "Adults Number",
  "report.noAvailable": "No available items",
  "report.company": "Company:",
  "report.mapRecord": "Map Record:",
  "report.contractor": "Contractor:",
  "report.property": "Property:",
  "report.localization": "Localization:",
  "report.county": "County:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Service Type:",
  "report.product": "Product",
  "report.formulation": "Formulation",
  "report.dosage": "Dosage",
  "report.toxicClass": "Toxic. Class",
  "report.adjuvant": "Adjuvant",
  "report.culture": "Culture: ",
  "report.volume": "Volume (liters or kg / ha):",
  "report.others": "Outhers: ",
  "report.agronomicalRecipe": "Agronomical Recipe n:",
  "report.issuedOn": "Issued on:",
  "report.basicParameter": "Basic Application Parameters",
  "report.maxTemperature": "Max. Temperature.=",
  "report.relativeUnitMin": "Min Relative Unit.=",
  "report.equipment": "Equipment=",
  "report.model": "Model=",
  "report.type": "Type=",
  "report.angle": "Angle=",
  "report.flightHeight": "Flight height=",
  "report.bandwidth": "Bandwidth (m)=",
  "report.date": "Date",
  "report.signatureCREA": "Name, signature and CREA of the professional",
  "report.weatherConditions": "Weather Conditions in the Application",
  "report.morning": "Morning",
  "report.evening": "Evening",
  "report.applicationStart": "Application start: hours and end: hours",
  "report.initial": "Initial",
  "report.final": "Final",
  "report.temperature": "Temperature",
  "report.relativeHumidity": "Relative Humidity",
  "report.aircraftPrefixe": "Prefixe - Aircraft:",
  "report.dgpsUse": "DGPS Use: Yes or No",
  "report.windSpeed": "Wind Speed",
  "report.trackCoordinates": "Track Coordinates:",
  "report.observation": "Observation:",
  "report.signatureEng": "Name, signature and CREA of Eng. Agronomo:",
  "report.print": "Print",
  "report.initialWeatherTitle": "Initial weather",
  "report.finalWeatherTitle": "Final weather",
  "report.source": "Source",
  "report.manualSource": "Manual",
  "report.optimizedUsing": "optimized using",
  "report.source.optimized": "Optimized",

  // Pre-registration research
  "preregistration.interest.question":
    "What do you think is most important in\nPrecision Biological Control?",
  "preregistration.interest.financial": "Financial",
  "preregistration.interest.financial.cost": "Operation cost",
  "preregistration.interest.financial.savings": "Input savings",
  "preregistration.interest.financial.pest": "Pest reduction",
  "preregistration.interest.financial.production": "Production increase",

  "preregistration.interest.sustainability": "Sustainability",
  "preregistration.interest.sustainability.carbonfootprint": "Reduction of carbon footprint",
  "preregistration.interest.sustainability.pesticides": "Reduction of pesticides",
  "preregistration.interest.sustainability.environment": "Environment",
  "preregistration.interest.sustainability.water": "Water economy",

  "preregistration.interest.reliability": "Reliability",
  "preregistration.interest.reliability.precision": "Precision",
  "preregistration.interest.reliability.releasingrate": "Releasing rate",
  "preregistration.interest.reliability.tracking": "Route tracking",
  "preregistration.interest.reliability.coverage": "Area coverage",

  // Notifications
  "notif.newmessages": "New Messages",
  "notif.pendencies": "Pendencies",
  "notif.error": "Failures",
  "notif.success": "Confirmations",

  // Header dropdown
  "headerDropdown.logout": "Logout",

  "map.projectTree.projects.loading": "Loading projects",
  "map.projectTree.projects.empty": "Empty project list",

  "map.projectTree.areas.loading": "Loading project areas",
  "map.projectTree.areas.empty": "Empty area list",

  "map.projectTree.flights.loading": "Loading area flights",
  "map.projectTree.flights.empty": "Empty flight list",

  "map.projectTree.projects": "Projects",

  "map.projectTree.visualizationMode": "Visualization mode",
  "map.projectTree.visualizationMode.notDeleted": "Not deleted",
  "map.projectTree.visualizationMode.deleted": "Deleted",
  "map.projectTree.visualizationMode.all": "All",
  "map.projectTree.visualizationModeConsideringSubItems": "Mode on sub-items",

  "cpu.block.blocked.general": "CPU is blocked. Please contact the administrators.",
  "cpu.block.blocked.master": "CPU is blocked",
  "cpu.block.notBlocked.general": "CPU released for use",
  "cpu.block.notBlocked.master": "CPU isn't blocked",
  "cpu.block.cpuTitle": "CPU {cpuSerialNumber} blocks",
  "cpu.block.addBlock": "Add block",
  "cpu.block.reason": "Block reason",
  "cpu.block.createdAt": "Created at",
  "cpu.block.appliedAt": "Applied at",
  "cpu.block.notApplied": "Not applied",
  "cpu.block.unblockedAt": "Unblocked at",
  "cpu.block.reason.default": "Payment problems",
  "cpu.block.reason.idleness": "Idleness",
  "cpu.block.reason.manually": "NCB",
  "cpu.block.removeBlock": "Unblock CPU",

  "biohubError.unimplemented.error": "Unexpected error.",
  "biohubError.unkown.error": "Unknown error. Please check your internet connection.",
  "biohubError.internal.server.error": "Server connection problems.",
  "biohubError.validation.error":
    "Incompatible data. Please refresh this page to load the latest version of the site.",
  "biohubError.login.incorrect.user.password": "Login: Incorrect username or password.",
  "biohubError.preregister.email.already.registered": "Pre-registration: Email already registered.",
  "biohubError.preregister.phone.number.already.registered":
    "Pre-registration: Phone already registered.",
  "biohubError.login.token.not.received": "Error logging into the system.",
  "biohubError.logion.unrecgnized.client":
    "Unauthorized platform. Make sure you are using the official url.",
  "biohubError.project.not.found": "Project not found.",
  "biohubError.elevation.invalid.request": "Error getting elevation data.",
  "biohubError.elevation.over.daily.limit": "Daily elevation data request limit exceeded.",
  "biohubError.elevation.over.query.limit": "Amount of requested elevation data exceeds the limit.",
  "biohubError.elevation.request.denied": "Access denied to elevation data.",
  "biohubError.elevation.unknown.error": "Unexpected error.",
  "biohubError.geocoder.unknown.error": "Error in the process of obtaining data from a location.",
  "biohubError.sms.validation.error": "Error sending validation SMS.",
  "biohubError.kml.route.out.of.area.bounds": "Route out of area bounds.",
  "biohubError.kml.import.invalid.selected.area.bounds": "Invalid area bounds.",
  "biohubError.toBigArea": "To big area",

  "purchase.order.intent.number": "Purchase intent identifier",
  "purchase.order.intent.detail": "Purchase intent details",
  "purchase.order.intent.message": "Additional Note",
  "purchase.order.intent.phone": "Phone",

  "purchase.order.intent.contactTitle": "Purchase Interest Submission",
  "purchase.order.intent.contactDescription":
    "Fill in your details in the form below and wait for us to contact you shortly.",

  "purchase.order.intent.mail.error": "Failed to send purchase intent data. Please try again!",
  "purchase.order.intent.mail.validation.error": "Fill in all required form data",
  "purchase.order.intent.mail.success": "Purchase intent successfully sent",

  "purchase.order.mail.content.product": "Product",
  "purchase.order.mail.content.quantity": "Quantity",
  "purchase.order.mail.content.unit.prices": "Purchase Unit Prices",

  "geographic.direction.northAcronym": "N",
  "geographic.direction.southAcronym": "S",
  "geographic.direction.eastAcronym": "E",
  "geographic.direction.westAcronym": "W",

  "geographic.direction.northwestAcronym": "NW",
  "geographic.direction.northeastAcronym": "NE",
  "geographic.direction.southeastAcronym": "SE",
  "geographic.direction.southWestAcronym": "SW",

  "unit.cup": "cup",
};

export default messagesMap;
