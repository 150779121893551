import React from "react";

export default (): JSX.Element => {
  return (
    <p
      style={{
        color: "#000",
      }}
    >
      © Google
    </p>
  );
};
