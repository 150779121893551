import React from "react";
import { InitialPage } from "../styles";
import DashboardGraphComponent from "./dashboardGraphs";

interface Props {
  userName: string;
  userCompany: string;
  userPicture?: string;
  userList: Array<{
    userId: string;
    userName: string;
    userPicture: string | "url";
    userCompany: string;
  }>;
}

export default (props: Props) => {
  return (
    <>
      <InitialPage>
        <div>
          <DashboardGraphComponent />
        </div>
      </InitialPage>
    </>
  );
};
