import { Project } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject } from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import DeleteModal from "./DeleteModal";

type Props = {
  project: Project;
  onCancel: () => void;
  onFinish: () => void;
};

export default function (props: Props): JSX.Element {
  const [stage, setStage] = useState<"confirming" | "deleting">("confirming");

  const deleting = useSelector((state: SystemState) => {
    const projectId = props.project.id;

    const projectTreeState = state.projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === projectId);

    if (project === undefined) return false;

    return project.beingDeleted || project.isUpdating;
  });

  useEffect(() => {
    if (deleting && stage !== "deleting") {
      setStage("deleting");
    }
    if (stage === "deleting" && !deleting) {
      props.onFinish();
    }
  }, [stage, deleting]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const termDeleteProject = intl.formatMessage({
    id: "map.deleteProject",
  });
  const termDeletingProject = intl.formatMessage({
    id: "map.deletingProject",
  });
  const termProceed = intl.formatMessage({ id: "map.deletingProject.question" });

  return (
    <DeleteModal
      title={termDeleteProject}
      label={`${termDeletingProject} ${props.project?.name ?? "??"}. ${termProceed}`}
      loading={deleting}
      onClose={props.onCancel}
      onConfirm={() => {
        dispatch(deleteProject(props.project.id));
      }}
    />
  );
}
