import { Location } from "biohub-model";
import React from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";

export default (props: {
  flowRoute: Location[];
  setReleaseRouteBorderRef: (ref: L.Polyline | null) => void;
  setReleaseRouteRef: (ref: L.Polyline | null) => void;
  onRender: () => void;
}): JSX.Element => {
  props.onRender();

  return (
    <>
      {/* Flight route border */}
      <Polyline
        ref={(ref) => props.setReleaseRouteBorderRef(ref)}
        positions={props.flowRoute}
        pathOptions={{
          color: "rgba(65, 165, 245, 255)",
          weight: 5,
          opacity: 0.7,
        }}
      />
      {/* Flight route */}
      <Polyline
        ref={(ref) => props.setReleaseRouteRef(ref)}
        positions={props.flowRoute}
        pathOptions={{
          color: "rgba(21, 101, 192, 255)",
          weight: 2,
          opacity: 0.8,
        }}
      />
    </>
  );
};
