import Axios from "axios";
import Constants from "../Constants";
import { BiohubErrorCode, BiohubResponse } from "./axios/BiohubApi";
import { Location } from "biohub-model";

export type GoogleMapsSearchInfo = {
  placeId: string;
  state: string;
  address: string;
  city: string;
  district: string;
  country: string;
  location: Location;
  formattedAddress: string;
  postalCode: string;
  countryShort: string;
};

function getData(objs: any, value: string): string {
  switch (value) {
    case "district":
      for (const obj of objs) {
        if (
          obj["types"].includes("administrative_area_level_4") ||
          obj["types"].includes("administrative_area_level_3") ||
          obj["types"].includes("sublocality") ||
          obj["types"].includes("sublocality_level_1") ||
          obj["types"].includes("sublocality_level_5")
        ) {
          return obj["long_name"];
        }
      }
      return "";

    case "address":
      for (const obj of objs) {
        if (obj["types"].includes("route")) {
          return obj["long_name"];
        }
      }
      return "";

    case "city":
      for (const obj of objs) {
        if (
          obj["types"].includes("administrative_area_level_2") ||
          obj["types"].includes("locality")
        ) {
          return obj["long_name"];
        }
      }
      return "";

    case "state":
      for (const obj of objs) {
        if (obj["types"].includes("administrative_area_level_1")) {
          return obj["long_name"];
        }
      }
      return "";

    case "country":
      for (const obj of objs) {
        if (obj["types"].includes("country")) {
          return obj["long_name"];
        }
      }
      return "";

    case "country_short":
      for (const obj of objs) {
        if (obj["types"].includes("country")) {
          return obj["short_name"];
        }
      }
      return "";

    case "postal_code":
      for (const obj of objs) {
        if (obj["types"].includes("postal_code")) {
          return obj["long_name"];
        }
      }
      return "";

    default:
      return "";
  }
}

export const getGeocodingPlace = async (
  input: string
): Promise<BiohubResponse<GoogleMapsSearchInfo[]>> => {
  try {
    const result = await Axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${input.replace(" ", "+")}&key=${
        Constants.GOOGLE_MAPS_API_KEY
      }`
    );

    const body = result.data;
    const results = body["results"] as any[];

    return {
      success: true,
      data: results.map((placeData) => ({
        placeId: placeData["place_id"] as string,
        state: getData(placeData["address_components"], "state"),
        address: getData(placeData["address_components"], "address"),
        city: getData(placeData["address_components"], "city"),
        district: getData(placeData["address_components"], "district"),
        country: getData(placeData["address_components"], "country"),
        countryShort: getData(placeData["address_components"], "country_short"),
        postalCode: getData(placeData["address_components"], "postal_code"),
        formattedAddress: placeData["formatted_address"] as string,
        location: {
          lat: placeData["geometry"]["location"]["lat"],
          lng: placeData["geometry"]["location"]["lng"],
        },
      })),
    };
  } catch (e) {
    return {
      success: false,
      error: {
        errorCode: BiohubErrorCode.GEOCODER_UNKNOWN_ERROR,
        message: `${e}`,
      },
    };
  }
};
