import React from "react";
import { withStyles } from "@material-ui/core/styles"; //set custom color required
import { teal } from "@material-ui/core/colors"; //set custom colors
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { Label } from "./styles";
import { useDispatch } from "react-redux";

interface Props {
  label?: string;
  color?: "light";
  secondary?: boolean;
  required?: boolean;
  onClick?: (clicked: boolean) => void;
  initialState?: boolean;
}

export default (props: Props) => {
  const [state, setState] = React.useState({ checked: props.initialState ?? false });

  const dispatch = useDispatch();

  //custom colors
  const CustomCheckbox = withStyles({
    root: {
      color: props.secondary ? "#ef7622" : teal[50],
      "&$checked": {
        color: props.secondary ? "#ef7622" : teal["A400"],
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  //event for checked item
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onClick !== undefined) {
      props.onClick(state.checked);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <Label
        color={props.color}
        control={
          <CustomCheckbox
            checked={state.checked}
            onChange={handleChange}
            name="checked"
            required={props.required}
          />
        }
        label={props.label}
      />
    </>
  );
};
