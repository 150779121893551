export const projectSummarySize = 60;
export const projectsClusterSize = 75;
export const areaSummarySize = 45;
export const areasClusterSize = 50;

export const isProjectAsSummary = (boundingBoxDiagonalSizeInPixels: number): boolean => {
  return boundingBoxDiagonalSizeInPixels < projectSummarySize;
};

export const isAreaAsSummary = (boundingBoxDiagonalSizeInPixels: number): boolean => {
  return boundingBoxDiagonalSizeInPixels < areaSummarySize;
};
