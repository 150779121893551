import { Area } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { restoreArea } from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import DeleteModal from "./DeleteModal";

type Props = {
  area: Area;
  onCancel: () => void;
  onFinish: () => void;
};

export default function (props: Props): JSX.Element {
  const [stage, setStage] = useState<"confirmation" | "restoring">("confirmation");

  const restoring = useSelector((state: SystemState) => {
    const projectId = props.area.projectId;
    const areaId = props.area.id;

    const projectTreeState = state.projectTree;
    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);

    if (area === undefined) return false;

    return area.isUpdating;
  });

  useEffect(() => {
    if (restoring && stage !== "restoring") {
      setStage("restoring");
    }
    if (stage === "restoring" && !restoring) {
      props.onFinish();
    }
  }, [stage, restoring]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const termRestoreArea = intl.formatMessage({ id: "map.restoreArea" });
  const termRestoringArea = intl.formatMessage({ id: "map.restoringArea" });
  const termProceed = intl.formatMessage({ id: "map.restoringArea.question" });

  return (
    <DeleteModal
      title={termRestoreArea}
      label={`${termRestoringArea} ${props.area?.name ?? "??"}. ${termProceed}`}
      loading={restoring}
      onClose={props.onCancel}
      onConfirm={() => {
        dispatch(restoreArea(props.area.projectId, props.area.id));
      }}
    />
  );
}
