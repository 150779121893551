import localForage from "localforage";

export abstract class PersistenceRepository {
  static getInstance(): PersistenceRepository {
    return new PersistenceRepositoryImpl();
  }

  abstract clear(): Promise<void>;

  abstract setItem<T>(key: string, value: T): Promise<void>;

  abstract getItem<T>(key: string): Promise<T | undefined>;

  abstract clearItem(key: string): Promise<void>;
}

class PersistenceRepositoryImpl implements PersistenceRepository {
  storage: LocalForage;

  constructor() {
    this.storage = localForage.createInstance({
      name: "persistence",
      storeName: "persistence",
    });
  }

  async clear(): Promise<void> {
    await this.storage.clear();
  }

  async setItem<T>(key: string, value: T): Promise<void> {
    await this.storage.setItem(key, value);
  }

  async getItem<T>(key: string): Promise<T | undefined> {
    try {
      const storageValue = await this.storage.getItem(key);

      if (storageValue === undefined || storageValue === null) return undefined;

      return storageValue as T;
    } catch (e) {
      return undefined;
    }
  }

  async clearItem(key: string): Promise<void> {
    await this.storage.removeItem(key);
  }
}
