import { Area, Location, Project, Role } from "biohub-model";
import React from "react";
import { ModalContainer } from "./ModalContainer";
import { Attachment, Fields } from "./styles";
import { Button, Grid, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { EditingAreaType } from "../../../store/reducers/projectTreeReducer";
import {
  closeArea,
  closeProject,
  editingAreaOnReverseRoute,
  enterEditAreaManualRouteMode,
  enterEditAreaMissionPlannerRotation,
  enterEditAreaPointsMode,
  enterEditAreaPolygonMode,
  enterEditAreaRouteMode,
  runMissionPlanner,
} from "../../../store/actions/projectTreeActions";
import { ReportProblemSharp } from "@material-ui/icons";

export default (props: {
  project: Project;
  area: Area | undefined;
  onClose: () => void;
  openProjectSettings: (project: Project) => void;
  openAreaSettings: (area: Area) => void;
  onClickToSelectPlannedRoute: (area: Area) => void;
  onClickToImportKmlRoute: (area: Area) => void;
}): JSX.Element => {
  const isAreaInEdition = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;

    switch (editingArea?.type) {
      case EditingAreaType.EditingPlanPoints:
      case EditingAreaType.EditingPolygon:
      case EditingAreaType.EditingEverything:
        return editingArea.area.id === props.area?.id;
      default:
        return false;
    }
  });

  const mustReleaseEntireArea = useSelector((state: SystemState) => {
    const userProfile = state.profile.userProfile;
    if (userProfile === null || userProfile.role === Role.external) return false;

    return userProfile.preferences.mustReleaseEntireArea;
  });

  const intl = useIntl();

  const areaModalTitleRegexOnTerm = /{.*{areaName}}/g;
  let modalTitle = intl
    .formatMessage({
      id: "map.openedProjectAndAreaActions",
    })
    .replace("{projectName}", props.project.name);
  const modalTitleAreaTerm = modalTitle.match(areaModalTitleRegexOnTerm)![0];
  let modalTitleAreaTermField: string;
  if (props.area === undefined) {
    modalTitleAreaTermField = "";
  } else {
    /// Remove the { and }
    modalTitleAreaTermField = modalTitleAreaTerm.substring(1, modalTitleAreaTerm.length - 1);
    modalTitleAreaTermField = modalTitleAreaTermField.replace("{areaName}", props.area.name);
  }
  modalTitle = modalTitle.replace(modalTitleAreaTerm, modalTitleAreaTermField);

  const projectSettingsTerm = intl.formatMessage({
    id: "map.editProjectSettings",
  });
  const areaSettingsTerm = intl.formatMessage({
    id: "map.editAreaSettings",
  });
  const areaInReverseRouteTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.reverseRoute",
  });
  const editAreaPolygonTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.editPolygonPoints",
  });
  const editAreaRouteTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.editMode.editRoute",
  });
  const manualAreaRouteTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.editMode.enterManualRoute",
  });
  const editAreaPlanTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.editPlan",
  });
  const replanAreRouteTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.replanRoute",
  });
  const areaPlannedRoutesTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.plannedRoutes.title",
  });
  const importRouteUsingKmlTerm = intl.formatMessage({
    id: "map.openedProjectAndAreaActions.importRouteKml",
  });
  const closeProjectTerm = intl.formatMessage({
    id: "map.closeProject",
  });
  const closeAreaTerm = intl.formatMessage({
    id: "map.closeArea",
  });
  const rotateMissionPlanTerm = intl.formatMessage({ id: "map.plannedRoute.routeAngle" });

  const area = props.area;

  const dispatch = useDispatch();

  let actions: {
    name: string;
    onClick: () => void;
  }[] = [
    {
      name: closeProjectTerm,
      onClick: () => {
        dispatch(closeProject(props.project.id));
        props.onClose();
      },
    },
    {
      name: projectSettingsTerm,
      onClick: () => props.openProjectSettings(props.project),
    },
  ];
  if (area !== undefined) {
    actions = [
      ...actions,
      {
        name: closeAreaTerm,
        onClick: () => {
          dispatch(closeArea(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: areaSettingsTerm,
        onClick: () => props.openAreaSettings(area),
      },
    ];
    if (isAreaInEdition) {
      actions = [
        ...actions,
        {
          name: areaInReverseRouteTerm,
          onClick: () => {
            dispatch(editingAreaOnReverseRoute());
            props.onClose();
          },
        },
      ];
    }

    actions = [
      ...actions,
      {
        name: editAreaPolygonTerm,
        onClick: () => {
          dispatch(enterEditAreaPolygonMode(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: editAreaRouteTerm,
        onClick: () => {
          dispatch(enterEditAreaRouteMode(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: manualAreaRouteTerm,
        onClick: () => {
          dispatch(enterEditAreaManualRouteMode(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: editAreaPlanTerm,
        onClick: () => {
          dispatch(enterEditAreaPointsMode(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: rotateMissionPlanTerm,
        onClick: () => {
          dispatch(enterEditAreaMissionPlannerRotation(props.project.id, area.id));
          props.onClose();
        },
      },
      {
        name: replanAreRouteTerm,
        onClick: () => {
          let homePoint: Location | undefined;
          if (
            area.areaConfig.useHighestPolygonPointElevationDataAsHomePoint &&
            area.polygonElevationData !== null
          ) {
            const highestElevationPoint = area.polygonElevationData.reduce((a, b) => {
              if (a.elevation > b.elevation) {
                return a;
              }
              return b;
            });

            homePoint = {
              lat: highestElevationPoint.lat,
              lng: highestElevationPoint.lng,
            };
          }

          dispatch(
            runMissionPlanner({
              projectId: area.projectId,
              areaId: area.id,
              areaConfig: area.areaConfig,
              areaPolygon: area.planned.polygon,
              configuredReleasers: area.configuredReleasers,
              homePoint: homePoint,
              mustReleaseEntireArea: mustReleaseEntireArea,
            })
          );

          props.onClose();
        },
      },
      {
        name: areaPlannedRoutesTerm,
        onClick: () => props.onClickToSelectPlannedRoute(area),
      },
      {
        name: importRouteUsingKmlTerm,
        onClick: () => props.onClickToImportKmlRoute(area),
      },
    ];
  }

  const height = 45 * actions.length + 62;

  return (
    <div id="modal-container">
      <ModalContainer
        showModal={true}
        onClose={props.onClose}
        title={modalTitle}
        titleFontSize={14}
        height={height}
        width={560}
        modalTopShift={height / 2}
      >
        <Fields maxHeight={height}>
          <Grid>
            {actions.map((action) => (
              <Grid item xs={12}>
                <Attachment>
                  <TextButton variant="outlined" onClick={action.onClick}>
                    {action.name}
                  </TextButton>
                </Attachment>
              </Grid>
            ))}
          </Grid>
        </Fields>
      </ModalContainer>
    </div>
  );
};

const TextButton = withStyles({
  root: {
    width: "100%",
    borderRadius: 100,
    color: "var(--white)",
    fontSize: 15,
    backgroundColor: "var(--dashboard-secondary)",
    "&:hover": {
      backgroundColor: "var(--icon-focus)",
    },
  },
})(Button);
