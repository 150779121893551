import React from "react";
import { BoundingBox } from "biohub-model";
import _ from "lodash";
import { couldMapElementBeVisibleAccordingMapBounds } from "../../../../core/geometricFunctions";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";

export default (props: { elementBounds: BoundingBox; children: JSX.Element }): JSX.Element => {
  const mapBounds = useSelector((state: SystemState) => state.projectTree.mapState.bounds);

  const isElementInsideBounds = couldMapElementBeVisibleAccordingMapBounds(
    props.elementBounds,
    mapBounds
  );

  if (!isElementInsideBounds) return <></>;

  return props.children;
};
