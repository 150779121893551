/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box } from "@material-ui/core";
import { Area, Project, Waypoint } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import BaseMap from "../../components/map/BaseMap";
import {
  changeEditingAreaMissionPlannerBasePoint,
  clearSelectedWaypointIndexes,
  copyArea,
  createAreaDrawingArea,
  createAreaDuplicate,
  createAreas,
  createProject,
  createProjectDrawingArea,
  editAreaParameters,
  fetchMapPixelsDimensions,
  handlePressingCtrlKey,
  handleUnPressCtrlKey,
  initMap,
  loadProjects,
  moveToBoundingBox,
  notifyFinishDraggingMaker,
  notifyStartDraggingMaker,
  onClickHomePoint,
  onClickPolygonEditingArea,
  onClickWaypoint,
  onClickWaypointEditingArea,
  onEditingAreaVertexChanged,
  onEditingAreaWaypointLocationChanged,
  onEditingClickPlannedPathLine,
  onHomePointEditingAreaChanged,
  onMapBoundsChanged,
  onMapCenterChanged,
  onMapClick,
  onMapZoomChanged,
  pasteCopiedArea,
  processImportedRoute,
  selectArea,
  updateDiagonalScreenSize,
  updateProject,
} from "../../store/actions/projectTreeActions";
import { castProjectInProjectTreeToProject } from "../../store/reducers/projectTreeReducer";
import CreationModal from "./modals/CreationModalComponents";
import DeleteAreaModal from "./modals/DeleteAreaModal";
import DeleteProjectModal from "./modals/DeleteProjectModal";
import EditWaypointModal from "./modals/EditWaypointModal";
import PlannedRoutes from "./modals/PlannedRoutes";
import OverlayGroup from "./overlays/OverlayGroup";
import ProjectOverlay from "./overlays/ProjectOverlay";
import SpecialAreaPlanActionsOverlay from "./overlays/SpecialAreaPlanActionsOverlay";
import LoadingAreasOverLay from "./overlays/LoadingAreasOverLay";
import { Map } from "./styles";
import LegendForMapElements from "./modals/LegendForMapElements";
import MapStateLayers from "../../components/map/impl/map_layers/from_state";
import OpenedProjectAndAreaModal from "./modals/OpenedProjectAndAreaModal";
import MapCompass from "./overlays/MapCompass";
import RotateMissionPlan from "./overlays/RotateMissionPlan";
import RestoreProjectModal from "./modals/RestoreProjectModal";
import RestoreAreaModal from "./modals/RestoreAreaModal";
import HomePointModal from "./modals/HomepointModal";

export default function (): JSX.Element {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const width = dimensions.width;
  const height = dimensions.height;

  const updateWidthAndHeight = () => {
    if (window.innerHeight !== dimensions.height || window.innerWidth !== dimensions.width)
      setDimensions({ height: window.innerHeight, width: window.innerWidth });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Control") {
      dispatch(handlePressingCtrlKey());
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Control") {
      dispatch(handleUnPressCtrlKey());
    }
  };

  useEffect(() => {
    updateWidthAndHeight();
  });

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("resize", updateWidthAndHeight);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProjects());
  }, []);

  const _initialOverlayExpansion: OverlayExpansionType = {
    isExpanded: false,
    expansionMoment: 0,
  };
  const [overlaysExpansion, _setOverlaysExpansion] = useState<OverlaysExpansionType>({
    projectTree: _initialOverlayExpansion,
    overlayProjectInfos: _initialOverlayExpansion,
  });

  const _getElementExpansion = (elementType: keyof OverlaysExpansionType): OverlayExpansionType => {
    return overlaysExpansion[elementType];
  };

  const _getElementExpansionAndDecreaseExpansionMoment = (
    elementType: keyof OverlaysExpansionType
  ): OverlayExpansionType => {
    const element = _getElementExpansion(elementType);

    if (element.isExpanded) {
      return {
        isExpanded: element.isExpanded,
        expansionMoment: element.expansionMoment + 1,
      };
    }

    return element;
  };

  const _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing = (
    processingElementType: keyof OverlaysExpansionType,
    processingElement: OverlayExpansionType,
    targetElementType: keyof OverlaysExpansionType
  ): OverlayExpansionType => {
    if (targetElementType === processingElementType) return processingElement;

    if (processingElement.isExpanded) {
      return _getElementExpansionAndDecreaseExpansionMoment(targetElementType);
    }

    return _getElementExpansion(targetElementType);
  };

  const _setIsExpandedElement = (
    elementType: keyof OverlaysExpansionType,
    value: boolean
  ): OverlaysExpansionType => {
    const previousElement = _getElementExpansion(elementType);
    const newElement: OverlayExpansionType = {
      isExpanded: value,
      expansionMoment: value ? 0 : previousElement.expansionMoment,
    };

    const projectTree = _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
      elementType,
      newElement,
      "projectTree"
    );
    const overlayProjectInfos =
      _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
        elementType,
        newElement,
        "overlayProjectInfos"
      );

    return {
      projectTree: projectTree,
      overlayProjectInfos: overlayProjectInfos,
    };
  };

  const _getOpenedOverlays = (
    overlaysExpansion: OverlaysExpansionType
  ): {
    project: boolean;
    overlayProjectInfos: boolean;
  } => {
    return {
      project: overlaysExpansion.projectTree.isExpanded,
      overlayProjectInfos: overlaysExpansion.overlayProjectInfos.isExpanded,
    };
  };

  const _lastOpenedOverlay = (
    overlaysExpansion: OverlaysExpansionType
  ): keyof OverlaysExpansionType => {
    const elements: (OverlayExpansionType & { elementType: keyof OverlaysExpansionType })[] = [];
    const keys = Object.keys(overlaysExpansion);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i] as keyof OverlaysExpansionType;
      const overlayExpansion = overlaysExpansion[key];
      elements.push({
        elementType: key,
        isExpanded: overlayExpansion.isExpanded,
        expansionMoment: overlayExpansion.isExpanded ? overlayExpansion.expansionMoment : 9999,
      });
    }

    elements.sort((a, b) => {
      if (a.expansionMoment > b.expansionMoment) return 1;
      else if (a.expansionMoment === b.expansionMoment) return 0;
      return -1;
    });

    return elements[0].elementType;
  };

  const _closeOverlay = (
    elementType: keyof OverlaysExpansionType,
    overlaysExpansion: OverlaysExpansionType
  ): OverlaysExpansionType => {
    return {
      ...overlaysExpansion,
      [elementType]: {
        ...overlaysExpansion[elementType],
        isExpanded: false,
      },
    };
  };

  const _processElementExpansionAccordingScreenSize = (
    _overlaysExpansion: OverlaysExpansionType
  ): OverlaysExpansionType => {
    let overlaysExpansion = {
      ..._overlaysExpansion,
    };

    /**
     * Here we need to control which elements are expanded or not according the actual screen size.
     *
     * Those are the rules:
     *
     * - Small and medium screen width:
     *    1. Project tree and project info overlay can't stay opened at the same time
     */

    const openedOverlays = _getOpenedOverlays(overlaysExpansion);
    if (width < mediumScreenWidth) {
      if (openedOverlays.project && openedOverlays.overlayProjectInfos) {
        const lastOpenedOverlay = _lastOpenedOverlay(overlaysExpansion);
        if (lastOpenedOverlay === "projectTree") {
          _closeOverlay("overlayProjectInfos", overlaysExpansion);
        } else if (lastOpenedOverlay === "overlayProjectInfos") {
          _closeOverlay("projectTree", overlaysExpansion);
        }
      }
    }

    return overlaysExpansion;
  };

  useEffect(() => {
    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(overlaysExpansion));
    dispatch(updateDiagonalScreenSize({ width, height }));
  }, [height, width]);

  const setIsExpandedProjectTree = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("projectTree", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const setIsExpandedProjectInfosOverlay = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("overlayProjectInfos", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const [modalController, setModalController] = useState<MapModalController | undefined>(undefined);

  const intl = useIntl();

  const openedOverlays = _getOpenedOverlays(overlaysExpansion);

  return (
    <Map color="dark">
      {/** Workaround - normally the height considers the toolbar's height, and then the map overflows. */}
      <div
        style={{ height: "100%", position: "relative" }}
        onClick={() => {
          setIsExpandedProjectInfosOverlay(false);
        }}
      >
        <BaseMap
          onInitialized={(controller) => {
            dispatch(initMap(controller));
          }}
          onClick={(location) => {
            dispatch(
              onMapClick({
                location: location,
              })
            );
          }}
          onZoomChanged={() => {
            dispatch(onMapZoomChanged());
          }}
          onCurrentCenterChanged={(mapCenter) => {
            dispatch(onMapCenterChanged(mapCenter));
          }}
          onMapBoundsChanged={(mapBounds) => {
            dispatch(onMapBoundsChanged(mapBounds));
          }}
          onMapDigitalSizeChanged={(height, width) => {
            dispatch(fetchMapPixelsDimensions(width, height));
          }}
          getChildren={(map) => (
            <MapStateLayers
              moveToBoundingBox={(boundingBox) => {
                dispatch(moveToBoundingBox(boundingBox));
              }}
              onClickNotSelectedArea={(projectId, areaId) => {
                dispatch(selectArea(projectId, areaId));
              }}
              onClickHomePoint={(projectId, areaId) => {
                dispatch(
                  onClickHomePoint({
                    projectId: projectId,
                    areaId: areaId,
                    openHomePointSettings: (area) =>
                      setModalController({
                        type: MapModalControllerType.homePointModal,
                        area: area,
                      }),
                  })
                );
              }}
              onClickWaypoint={(projectId, areaId, waypointIndex) => {
                dispatch(
                  onClickWaypoint({
                    projectId: projectId,
                    areaId: areaId,
                    waypointIndex: waypointIndex,
                    openWaypointSettings: (area, waypoint) =>
                      setModalController({
                        type: MapModalControllerType.editingWaypoint,
                        waypoint: waypoint,
                        area: area,
                      }),
                    openWaypointsSettings: (area, waypoints) =>
                      setModalController({
                        type: MapModalControllerType.editingWaypoints,
                        area: area,
                        waypoints: waypoints,
                      }),
                  })
                );
              }}
              onEditingClickWaypoint={(waypointIndex) => {
                dispatch(onClickWaypointEditingArea(waypointIndex));
              }}
              onEditingClickPolygon={(location) => {
                dispatch(onClickPolygonEditingArea(location));
              }}
              onEditingClickPlannedPath={(previousPointIndex, location) => {
                dispatch(onEditingClickPlannedPathLine(previousPointIndex, location));
              }}
              onEditingWaypointMovementStart={() => {
                dispatch(notifyStartDraggingMaker());
              }}
              onEditingWaypointMoved={async (index, location) => {
                dispatch(onEditingAreaWaypointLocationChanged(index, location));

                await delay(200);
                dispatch(notifyFinishDraggingMaker());
              }}
              onEditingPolygonVertexMovementStart={() => {
                dispatch(notifyStartDraggingMaker());
              }}
              onEditingPolygonVertexMoved={async (index, location) => {
                dispatch(onEditingAreaVertexChanged(index, location));

                await delay(200);
                dispatch(notifyFinishDraggingMaker());
              }}
              onEditingHomePointMovementStart={() => {
                dispatch(notifyStartDraggingMaker());
              }}
              onEditingHomePointMoved={async (location) => {
                dispatch(onHomePointEditingAreaChanged(location));

                await delay(200);
                dispatch(notifyFinishDraggingMaker());
              }}
              onMissionPlannerBasePointMovementStart={() => {
                dispatch(notifyStartDraggingMaker());
              }}
              onMissionPlannerBasePointMoved={async (location) => {
                dispatch(changeEditingAreaMissionPlannerBasePoint(location));

                await delay(200);
                dispatch(notifyFinishDraggingMaker());
              }}
              map={map}
            />
          )}
        />

        {/** Left drawer */}
        <ProjectOverlay
          height={height}
          expanded={openedOverlays.project}
          setExpanded={setIsExpandedProjectTree}
          onClickToCreateProject={() => {
            setModalController({
              type: MapModalControllerType.createProject,
            });
          }}
          onClickToEditProjectSettings={(project) => {
            setModalController({
              type: MapModalControllerType.editProject,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToAddAreaInProject={(project) => {
            setModalController({
              type: MapModalControllerType.createArea,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToDeleteProject={(project) => {
            setModalController({
              type: MapModalControllerType.deleteProject,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToRestoreProject={(project) => {
            setModalController({
              type: MapModalControllerType.restoreProject,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToEditAreaSettings={(area) => {
            setModalController({
              type: MapModalControllerType.editArea,
              area: {
                ...area,
                deletedAt: area.deletedAt ?? undefined,
              },
            });
          }}
          onClickToDeleteArea={(area) => {
            setModalController({
              type: MapModalControllerType.deleteArea,
              area: {
                ...area,
                deletedAt: area.deletedAt ?? undefined,
              },
            });
          }}
          onClickToRestoreArea={(area) => {
            setModalController({
              type: MapModalControllerType.restoreArea,
              area: {
                ...area,
                deletedAt: area.deletedAt ?? undefined,
              },
            });
          }}
          onClickDuplicateArea={(area) => {
            dispatch(
              createAreaDuplicate(
                {
                  ...area,
                  deletedAt: area.deletedAt ?? undefined,
                },
                intl
              )
            );
          }}
          onClickCopyArea={(area) => {
            dispatch(copyArea(area));
          }}
          onClickPasteCopiedArea={(project) => {
            dispatch(pasteCopiedArea(project.id));
          }}
          onClickHomePointModal={(area) => {
            setModalController({
              type: MapModalControllerType.homePointModal,
              area: {
                ...area,
                deletedAt: area.deletedAt ?? undefined,
              },
            });
          }}
        />

        {/** Map overlays */}
        <Box
          style={{
            position: "absolute",
            backgroundColor: "#ffffff00",
            top: -8,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none", // Let all clicks through
          }}
          flexDirection="column"
          justifyContent="space-between"
        >
          {((): JSX.Element => {
            if (modalController?.type === MapModalControllerType.editingWaypoint) {
              return (
                <EditWaypointModal
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  usingOnlineElevation={modalController.area.areaConfig.mustConsiderRelief}
                  plannedArea={modalController.area.planned}
                  releasersConfiguration={modalController.area.configuredReleasers}
                  onFinish={() => setModalController(undefined)}
                  onClose={() => setModalController(undefined)}
                  type="single"
                  waypoint={modalController.waypoint}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editingWaypoints) {
              return (
                <EditWaypointModal
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  usingOnlineElevation={modalController.area.areaConfig.mustConsiderRelief}
                  plannedArea={modalController.area.planned}
                  releasersConfiguration={modalController.area.configuredReleasers}
                  onFinish={() => {
                    dispatch(clearSelectedWaypointIndexes());
                    setModalController(undefined);
                  }}
                  onClose={() => setModalController(undefined)}
                  type="multiple"
                  waypoints={modalController.waypoints}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.selectPlannedRoute) {
              return (
                <PlannedRoutes
                  projectId={modalController.projectId}
                  areaId={modalController.areaId}
                  onClose={() => setModalController(undefined)}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.deleteProject) {
              return (
                <DeleteProjectModal
                  project={modalController.project}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.restoreProject) {
              return (
                <RestoreProjectModal
                  project={modalController.project}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.deleteArea) {
              return (
                <DeleteAreaModal
                  area={modalController.area}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.restoreArea) {
              return (
                <RestoreAreaModal
                  area={modalController.area}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.createProject) {
              return (
                <CreationModal
                  mode={"new-project"}
                  onClose={() => setModalController(undefined)}
                  onFinish={(projectInfo, areasInfo) => {
                    if (areasInfo.source === "file") {
                      dispatch(
                        createProject({
                          directClientId: projectInfo.directClientId,
                          indirectClientId: projectInfo.indirectClientId,
                          projectName: projectInfo.projectName,
                          areaConfig: projectInfo.areaConfig,
                          areas: areasInfo.areas,
                          configuredReleasers: projectInfo.configuredReleasers,
                        })
                      );
                    } else {
                      dispatch(createProjectDrawingArea(projectInfo, areasInfo.areaName));
                    }

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editProject) {
              return (
                <CreationModal
                  mode={"edit-project"}
                  projectId={modalController.project.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(projectInfo) => {
                    dispatch(
                      updateProject({
                        ...modalController.project,
                        indirectClientId: projectInfo.indirectClientId,
                        name: projectInfo.projectName,
                        areaConfig: {
                          ...modalController.project.areaConfig,
                          ...projectInfo.areaConfig,
                        },
                        configuredReleasers: projectInfo.configuredReleasers,
                      })
                    );
                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.createArea) {
              return (
                <CreationModal
                  mode={"add-area"}
                  projectId={modalController.project.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(areasInfo) => {
                    if (areasInfo.source === "file") {
                      dispatch(createAreas(modalController.project.id, areasInfo.areas));
                    } else {
                      dispatch(
                        createAreaDrawingArea(modalController.project.id, {
                          areaName: areasInfo.areaName,
                          areaConfig: areasInfo.areaConfig,
                          configuredReleasers: areasInfo.configuredReleasers,
                        })
                      );
                    }

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editArea) {
              return (
                <CreationModal
                  mode={"edit-area"}
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(
                    areaName,
                    areaConfig,
                    configuredReleasers,
                    mustReleaseEntireArea,
                    unlockedToExecuteMissionPlanner
                  ) => {
                    dispatch(
                      editAreaParameters(
                        modalController.area.projectId,
                        modalController.area.id,
                        areaName,
                        areaConfig,
                        configuredReleasers,
                        mustReleaseEntireArea,
                        unlockedToExecuteMissionPlanner
                      )
                    );

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.importRouteKml) {
              return (
                <CreationModal
                  mode="import-route-kml"
                  projectId={modalController.area.projectId}
                  area={modalController.area}
                  onClose={() => setModalController(undefined)}
                  onFinish={(route) => {
                    dispatch(
                      processImportedRoute({
                        areaId: modalController.area.id,
                        projectId: modalController.area.projectId,
                        route: route,
                        areaConfig: modalController.area.areaConfig,
                        configuredReleasers: modalController.area.configuredReleasers,
                        homePoint: modalController.area.planned.homePoint,
                        mustReleaseEntireArea: true,
                      })
                    );
                    setModalController(undefined);
                  }}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.showModalLegends) {
              return <LegendForMapElements onClose={() => setModalController(undefined)} />;
            }

            if (modalController?.type === MapModalControllerType.openedProjectAndArea) {
              return (
                <OpenedProjectAndAreaModal
                  project={modalController.project}
                  area={modalController.area}
                  openProjectSettings={(project) => {
                    setModalController({
                      type: MapModalControllerType.editProject,
                      project: project,
                    });
                  }}
                  openAreaSettings={(area) => {
                    setModalController({
                      type: MapModalControllerType.editArea,
                      area: area,
                    });
                  }}
                  onClickToImportKmlRoute={(area) => {
                    setModalController({
                      type: MapModalControllerType.importRouteKml,
                      area,
                    });
                  }}
                  onClickToSelectPlannedRoute={(area) => {
                    setModalController({
                      type: MapModalControllerType.selectPlannedRoute,
                      projectId: area.projectId,
                      areaId: area.id,
                    });
                  }}
                  onClose={() => setModalController(undefined)}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.homePointModal) {
              return (
                <HomePointModal
                  area={modalController.area}
                  onClose={() => setModalController(undefined)}
                />
              );
            }

            return <></>;
          })()}
        </Box>
        <OverlayGroup
          heightScreenType={
            height <= smallScreenHeight
              ? "small"
              : height <= mediumScreenHeight
              ? "medium"
              : "regular"
          }
          widthScreenType={
            width <= smallScreenWidth ? "small" : width <= mediumScreenWidth ? "medium" : "regular"
          }
          mediumScreenWeatherOverlayMarginBottom={5 + height - smallScreenHeight}
          isExpandedProjectTree={openedOverlays.project}
          overlayProjectInfos={setIsExpandedProjectInfosOverlay}
          isExpandedProjectInfosOverlay={openedOverlays.overlayProjectInfos}
          onClickModalLegendInformation={() => {
            setModalController({
              type: MapModalControllerType.showModalLegends,
            });
          }}
          onClickOpenedProjectAndAreaDialog={(project, area) => {
            setModalController({
              type: MapModalControllerType.openedProjectAndArea,
              project: project,
              area: area,
            });
          }}
        />

        <MapCompass />

        <LoadingAreasOverLay />

        <SpecialAreaPlanActionsOverlay />

        <RotateMissionPlan />
      </div>
    </Map>
  );
}

type OverlaysExpansionType = {
  projectTree: OverlayExpansionType;
  overlayProjectInfos: OverlayExpansionType;
};

type OverlayExpansionType = {
  isExpanded: boolean;
  expansionMoment: number;
};

enum MapModalControllerType {
  createProject,
  editProject,
  deleteProject,
  restoreProject,
  createArea,
  editArea,
  deleteArea,
  restoreArea,
  selectPlannedRoute,
  editingWaypoint,
  editingWaypoints,
  importRouteKml,
  showModalLegends,
  openedProjectAndArea,
  homePointModal,
}

type MapModalController =
  | {
      type: MapModalControllerType.createProject;
    }
  | {
      type: MapModalControllerType.editProject;
      project: Project;
    }
  | {
      type: MapModalControllerType.deleteProject;
      project: Project;
    }
  | {
      type: MapModalControllerType.restoreProject;
      project: Project;
    }
  | {
      type: MapModalControllerType.createArea;
      project: Project;
    }
  | {
      type: MapModalControllerType.editArea;
      area: Area;
    }
  | {
      type: MapModalControllerType.deleteArea;
      area: Area;
    }
  | {
      type: MapModalControllerType.restoreArea;
      area: Area;
    }
  | {
      type: MapModalControllerType.selectPlannedRoute;
      projectId: string;
      areaId: string;
    }
  | {
      type: MapModalControllerType.editingWaypoint;
      area: Area;
      waypoint: Waypoint & { index: number };
    }
  | {
      type: MapModalControllerType.editingWaypoints;
      area: Area;
      waypoints: (Waypoint & { index: number })[];
    }
  | {
      type: MapModalControllerType.importRouteKml;
      area: Area;
    }
  | {
      type: MapModalControllerType.showModalLegends;
    }
  | {
      type: MapModalControllerType.openedProjectAndArea;
      project: Project;
      area?: Area;
    }
  | {
      type: MapModalControllerType.homePointModal;
      area: Area;
    };

/**
 * If the window has a width below that value the user can open or the map controls or the footer or the project tree.
 */
const smallScreenWidth = 700;
/**
 * If the window has a width below that value the user can open the map controls and the project tree together.
 */
const mediumScreenWidth = 1200;

/**
 * If the window has a height below that value the user can open or the search tool in the map controls or the weather overlay.
 */
const smallScreenHeight = 400;
/**
 * If the window has a height below that value the user can open the map controls and the weather overlay but we need to control each position.
 */
const mediumScreenHeight = 600;

function delay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
