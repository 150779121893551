import React, { useState } from "react";
import ChangeMapTypesSelector from "./MapTypeButton";
import MapControls from "./MapControls";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMapType,
  setShowingMapLabels,
} from "../../../../../store/actions/projectTreeActions";
import { ContainerMapInfosOnTheMap } from "../../../modals/styles";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { Info } from "@material-ui/icons";

type Props = {
  modalLegendInformation: () => void;
};

export default function MapControlsOverlay(props: Props): JSX.Element {
  const mapTypeId = useSelector((state: SystemState) => state.projectTree.mapState.mapTypeId);
  const showingMapLabels = useSelector(
    (state: SystemState) => state.projectTree.mapState.showingMapLabels
  );

  const dispatch = useDispatch();

  const [openSearch, setOpenSearch] = useState(false);

  return (
    <ContainerMapInfosOnTheMap
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: 7,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
        }}
      >
        <MapControls openSearch={openSearch} setOpenSearch={setOpenSearch} />
        <ChangeMapTypesSelector
          mapType={mapTypeId}
          onChangedMapType={(mapType) => dispatch(changeMapType(mapType))}
          showingMapLabels={showingMapLabels}
          onChangedShowingMapLabels={(showingMapLabels) =>
            dispatch(setShowingMapLabels(showingMapLabels))
          }
        />

        <Info
          style={{
            cursor: "pointer",
            color: "white",
          }}
          onClick={props.modalLegendInformation}
        />
      </div>
    </ContainerMapInfosOnTheMap>
  );
}
