import {
  Cpu,
  CpuBlock,
  CpuBlockReason,
  CpuBlockStatus,
  CpuModel,
  DirectClient,
  Drone,
  Role,
} from "biohub-model";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import UserTableComponent from "../../../components/Atomic/UserTable";
import { IntlShape, useIntl } from "react-intl";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Modal } from "../styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuid } from "uuid";
import {
  updateCpu,
  addCpu,
  addCpuBlock,
  removeCpuBlocks,
} from "../../../store/actions/collectionsActions";

export default function CpuTable(): JSX.Element {
  const dispatch = useDispatch();

  const userProfile = useSelector((systemState: SystemState) => systemState.profile.userProfile);

  const [openedCpuForm, setOpenedCpuForm] = useState(false);
  const [viewingCpuBlocks, setViewingCpuBlocks] = useState(false);
  const [actingCpu, setActingCpu] = React.useState<Cpu>();

  const loading = useSelector(
    (systemState: SystemState) =>
      systemState.collections.loadingCpus || systemState.collections.loadingCpuModels
  );

  const cpus = useSelector((systemState: SystemState) => systemState.collections.cpus);

  const cpuModels = useSelector((systemState: SystemState) => systemState.collections.cpuModels);

  const drones = useSelector((systemState: SystemState) => systemState.collections.drones);

  const mapCpuModel = useSelector((systemState: SystemState) => {
    const cpuModels = systemState.collections.cpuModels;
    let map: { [modelId: string]: CpuModel } = {};
    cpuModels.forEach((cpuModel) => {
      map = {
        ...map,
        [cpuModel.id]: cpuModel,
      };
    });
    return map;
  });

  const mapDrones = useSelector((systemState: SystemState) => {
    const drones = systemState.collections.drones;
    let map: { [droneId: string]: Drone } = {};
    drones.forEach((drone) => {
      map = {
        ...map,
        [drone.id]: drone,
      };
    });
    return map;
  });

  const directClients = useSelector(
    (systemState: SystemState) => systemState.master.directClients ?? []
  );
  const mapDirectClients = useSelector((systemState: SystemState) => {
    const directClientList = systemState.master.directClients ?? [];
    let map: { [directClientId: string]: DirectClient } = {};

    directClientList.forEach((directClient) => {
      map = {
        ...map,
        [directClient.id]: directClient,
      };
    });
    return map;
  });

  const handleAddCpu = (form: EditState) => {
    const cpu: Omit<Cpu, "serialNumber"> = {
      id: uuid(),
      cpuModelId: form.selectedCpuModelId,
      firmwareVersion: form.firmwareVersion,
      block: [],
      directClientId: form.selectedDirectClientId,
      droneId: form.droneId,
    };

    dispatch(addCpu(cpu));
    setOpenedCpuForm(false);
  };

  const handleUpdateCpu = (form: EditState) => {
    let updatedCpu: Partial<Cpu> = {};
    if (form.droneId !== actingCpu?.droneId) {
      updatedCpu = { ...updatedCpu, droneId: form.droneId };
    }
    if (form.firmwareVersion !== actingCpu?.firmwareVersion) {
      updatedCpu = { ...updatedCpu, firmwareVersion: form.firmwareVersion };
    }
    if (form.selectedCpuModelId !== actingCpu?.cpuModelId) {
      updatedCpu = { ...updatedCpu, cpuModelId: form.selectedCpuModelId };
    }
    if (form.selectedDirectClientId !== actingCpu?.directClientId) {
      updatedCpu = { ...updatedCpu, directClientId: form.selectedDirectClientId };
    }
    if (form.serialNumber !== actingCpu?.serialNumber) {
      updatedCpu = { ...updatedCpu, serialNumber: form.serialNumber };
    }

    dispatch(updateCpu(updatedCpu, actingCpu!.id));
    setOpenedCpuForm(false);
  };

  const intl = useIntl();

  const termCpus = intl.formatMessage({ id: "info.cpus" });
  const termModel = intl.formatMessage({ id: "info.model" });
  const termDrones = intl.formatMessage({ id: "info.drones" });
  const termSerialNumber = intl.formatMessage({ id: "info.serialNumber" });
  const termFirmwareVersion = intl.formatMessage({ id: "info.firmwareVersion" });
  const termLastFirmwareVersion = intl.formatMessage({ id: "info.lastFirmware" });
  const termDirectClient = intl.formatMessage({ id: "info.client" });
  const addCpuTerm = intl.formatMessage({ id: "info.cpu.add" });

  const blockedCpuTerm = intl.formatMessage({
    id:
      userProfile?.role === Role.master ? "cpu.block.blocked.master" : "cpu.block.blocked.general",
  });
  const notBlockedCpuTerm = intl.formatMessage({
    id:
      userProfile?.role === Role.master
        ? "cpu.block.notBlocked.general"
        : "cpu.block.notBlocked.master",
  });

  const getFormatedDroneFromCpu = (cpu: Cpu): string => {
    const droneId = cpu.droneId;
    if (droneId === null) return "-";
    if (!Object.keys(mapDrones).includes(droneId)) return "";
    const drone = mapDrones[droneId];
    return `${drone.model?.modelName ?? "-"} - ${drone.serialNumber}`;
  };

  return (
    <div>
      {userProfile !== null && userProfile.role <= Role.manager && (
        <>
          <div>
            {viewingCpuBlocks ? (
              <CpuBlocksTable
                cpuId={actingCpu!.id}
                onClose={() => {
                  setViewingCpuBlocks(false);
                  setActingCpu(undefined);
                }}
              />
            ) : !loading ? (
              <UserTableComponent<Cpu>
                items={cpus}
                titleTerm={termCpus}
                addTerm={addCpuTerm}
                onAddFunction={
                  userProfile !== null && userProfile.role === Role.master
                    ? () => setOpenedCpuForm(true)
                    : undefined
                }
                classes={
                  userProfile !== null && userProfile.role === Role.master
                    ? [
                        "id",
                        termModel,
                        termSerialNumber,
                        termFirmwareVersion,
                        termLastFirmwareVersion,
                        termDirectClient,
                        termDrones,
                      ]
                    : ["id", termSerialNumber, termFirmwareVersion, termDrones]
                }
                formatItems={(cpu: Cpu) =>
                  userProfile !== null && userProfile.role === Role.master
                    ? [
                        cpu.id,
                        mapCpuModel[cpu.cpuModelId]?.name ?? "",
                        cpu.serialNumber,
                        cpu.firmwareVersion,
                        mapCpuModel[cpu.cpuModelId]?.latestFirmwareVersion ?? "",
                        mapDirectClients[cpu.directClientId]?.company?.name ??
                          mapDirectClients[cpu.directClientId]?.profiles.find(
                            (profile) => profile.role === Role.admin || profile.role === Role.master
                          )?.name ??
                          "",
                        getFormatedDroneFromCpu(cpu),
                      ]
                    : [cpu.id, cpu.serialNumber, cpu.firmwareVersion, getFormatedDroneFromCpu(cpu)]
                }
                onViewItem={(itemId) => {
                  const cpu = cpus.find((cpu) => cpu.id === itemId);
                  if (cpu !== undefined) {
                    setActingCpu(cpu);
                    setOpenedCpuForm(true);
                  }
                }}
                onRemoveFunction={undefined}
                additionalIcon={(cpu) => (
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    disabled={userProfile.role !== Role.master}
                    onClick={() => {
                      setActingCpu(cpu);
                      setViewingCpuBlocks(true);
                    }}
                  >
                    <CpuLockedIconTypography
                      isBlocked={getIsCpuBlocked(cpu)}
                      blockedText={blockedCpuTerm}
                      notBlockedText={notBlockedCpuTerm}
                    />
                  </IconButton>
                )}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <CpuForm
            intl={intl}
            open={openedCpuForm}
            add={actingCpu === undefined}
            profileRole={userProfile?.role}
            cpu={actingCpu}
            directClients={directClients}
            cpuModels={cpuModels}
            finishCallback={actingCpu === undefined ? handleAddCpu : handleUpdateCpu}
            cancelCallback={() => {
              setOpenedCpuForm(false);
              setActingCpu(undefined);
            }}
            drones={drones}
          />
        </>
      )}
    </div>
  );
}

const CpuLockedIconTypography = (props: {
  isBlocked: boolean;
  blockedText: string;
  notBlockedText: string;
}): JSX.Element => {
  return (
    <Tooltip title={props.isBlocked ? props.blockedText : props.notBlockedText}>
      <Icon fontSize="inherit">{props.isBlocked ? "lock" : "lock_open"}</Icon>
    </Tooltip>
  );
};

type EditProps = {
  open: boolean;
  profileRole?: Role;
  finishCallback: (info: EditState) => void;
  cancelCallback: () => void;
  intl: IntlShape;
  directClients: DirectClient[];
  cpuModels: CpuModel[];
  drones: Drone[];
  cpu: Cpu | undefined;
  add: boolean;
};

type EditState = {
  selectedDirectClientId: string;
  selectedCpuModelId: string;
  serialNumber: string;
  firmwareVersion: string;
  droneId: string | null;
};

class CpuForm extends React.Component<EditProps, EditState> {
  _extractProps(props: EditProps): EditState {
    if (!props.add && props.cpu !== undefined) {
      return {
        selectedDirectClientId: props.cpu.directClientId,
        selectedCpuModelId: props.cpu.cpuModelId,
        serialNumber: props.cpu.serialNumber,
        firmwareVersion: props.cpu.firmwareVersion,
        droneId: props.cpu.droneId,
      };
    } else {
      return {
        selectedDirectClientId: "",
        selectedCpuModelId: "",
        serialNumber: "",
        firmwareVersion: "",
        droneId: null,
      };
    }
  }

  constructor(props: EditProps) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.state = this._extractProps(props);
  }

  componentWillReceiveProps(newProps: EditProps) {
    this.setState(this._extractProps(newProps));
  }

  handleChange(event: React.ChangeEvent<any>, newValue?: any) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  validate(): boolean {
    const { selectedDirectClientId, selectedCpuModelId, serialNumber, firmwareVersion } =
      this.state;
    const { cpuModels, directClients } = this.props;
    if (cpuModels.find((element) => element.id === selectedCpuModelId) === undefined) return false;
    if (directClients.find((element) => element.id === selectedDirectClientId) === undefined)
      return false;
    // if (serialNumber.length === 0) return false;
    if (firmwareVersion.length === 0) return false;

    return true;
  }

  render(): React.ReactNode {
    const intl = this.props.intl;

    const titleAddTerm = intl.formatMessage({ id: "info.cpu.add" });
    const titleViewTerm = intl.formatMessage({ id: "info.cpu.viewing" });
    const addInfoMessage = intl.formatMessage({ id: "info.cpu.addingMessage" });
    const viewInfoMessage = intl.formatMessage({ id: "info.cpu.viewingMessage" });

    return (
      <Modal
        open={this.props.open}
        onClose={(e) => this.props.cancelCallback()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!this.props.add ? titleViewTerm : titleAddTerm}
          <Icon onClick={(e) => this.props.cancelCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.add ? addInfoMessage : viewInfoMessage}</DialogContentText>
          <Grid container spacing={1}>
            {!this.props.add && (
              <>
                <Grid item lg={4} xs={12}>
                  <b>{intl.formatMessage({ id: "info.serialNumber" }) + ":"}</b>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    disabled={true}
                    id="serialNumber"
                    label={intl.formatMessage({ id: "placeholder.releaser.serialnumber" })}
                    value={this.state.serialNumber}
                    fullWidth
                    name="serialNumber"
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={4} xs={12}>
              <b>{intl.formatMessage({ id: "info.firmwareVersion" }) + ":"}</b>
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                autoFocus
                margin="dense"
                disabled={!this.props.add}
                id="firmwareVersion"
                label={intl.formatMessage({ id: "info.firmwareVersion" })}
                value={this.state.firmwareVersion}
                fullWidth
                name="firmwareVersion"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            {this.props.directClients.length > 0 && (
              <>
                <Grid item lg={4} xs={12}>
                  <b>{intl.formatMessage({ id: "info.drone.selectclient" })}</b>
                </Grid>
                <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
                  <Autocomplete
                    id="selectedDirectClientId"
                    fullWidth
                    disabled={!this.props.add}
                    options={this.props.directClients}
                    size="small"
                    value={this.props.directClients.find(
                      (c) => c.id === this.state.selectedDirectClientId
                    )}
                    autoHighlight
                    onChange={(event, value) => {
                      if (value != null) {
                        this.setState({
                          ...this.state,
                          selectedDirectClientId: (value as DirectClient).id,
                        });
                      }
                    }}
                    getOptionLabel={(option) => {
                      let value: string = "";
                      if (option.company !== undefined) {
                        value = option.company.name + " - ";
                      }
                      const search = option.profiles.find(
                        (p) => p.role === Role.admin || p.role === Role.master
                      );
                      if (search !== undefined) {
                        value = value + search.name;
                      }
                      return value;
                    }}
                    renderOption={(option) => {
                      let value: string = "";
                      if (option.company !== undefined) {
                        value = option.company.name + " - ";
                      }
                      const search = option.profiles.find(
                        (p) => p.role === Role.admin || p.role === Role.master
                      );
                      if (search !== undefined) {
                        value = value + search.name;
                      }
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({ id: "info.client" })}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {this.props.cpuModels.length > 0 && (
              <>
                <Grid item lg={4} xs={12}>
                  <b>{intl.formatMessage({ id: "info.cpu.model" }) + ":"}</b>
                </Grid>
                <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
                  <Autocomplete
                    id="selectedCpuModelId"
                    fullWidth
                    disabled={!this.props.add}
                    options={this.props.cpuModels}
                    size="small"
                    value={this.props.cpuModels.find((c) => c.id === this.state.selectedCpuModelId)}
                    autoHighlight
                    onChange={(event, value) => {
                      if (value != null) {
                        this.setState({
                          ...this.state,
                          selectedCpuModelId: (value as CpuModel).id,
                        });
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({ id: "info.cpu.model" })}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {this.props.drones.length > 0 && (
              <>
                <Grid item lg={4} xs={12}>
                  <b>{intl.formatMessage({ id: "info.drone" })}</b>
                </Grid>
                <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
                  <Autocomplete
                    id="drones"
                    fullWidth
                    options={this.props.drones.filter((drone) => {
                      const selectedDirectClientId = this.state.selectedDirectClientId;
                      if (selectedDirectClientId.length === 0) return true;

                      return drone.directClientId === selectedDirectClientId;
                    })}
                    size="small"
                    value={this.props.drones.find((c) => c.id === this.state.droneId)}
                    autoHighlight
                    onChange={(event, value) => {
                      if (value != null) {
                        const drone = value as Drone;
                        this.setState({
                          ...this.state,
                          droneId: drone.id,
                          selectedDirectClientId: drone.directClientId,
                        });
                      } else {
                        this.setState({
                          ...this.state,
                          droneId: null,
                        });
                      }
                    }}
                    getOptionLabel={(drone) => {
                      return `${drone.model?.modelName ?? "-"} - ${drone.serialNumber}`;
                    }}
                    renderOption={(drone) => {
                      return `${drone.model?.modelName ?? "-"} - ${drone.serialNumber}`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({ id: "info.drone" })}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        {
          <DialogActions>
            <Button
              onClick={(e) => this.props.cancelCallback()}
              className="cancel-button"
              startIcon={<Icon>close</Icon>}
            >
              {intl.formatMessage({ id: "generic.cancel" })}
            </Button>
            {
              <Button
                disabled={!this.validate()}
                onClick={(e) => this.props.finishCallback(this.state)}
                variant="contained"
                color="primary"
              >
                {intl.formatMessage({ id: "action.save" })}
              </Button>
            }
          </DialogActions>
        }
      </Modal>
    );
  }
}

const getIsCpuBlocked = (cpu: Cpu): boolean => cpu.block.filter(getCanRemoveCpuBlock).length > 0;

const getCanRemoveCpuBlock = (cpuBlock: CpuBlock): boolean => {
  const blockStatus = cpuBlock.blockStatus;
  return blockStatus === CpuBlockStatus.blockApplied || blockStatus === CpuBlockStatus.pendingBlock;
};

const CpuBlocksTable = (props: { cpuId: string; onClose: () => void }): JSX.Element => {
  const cpu = useSelector((state: SystemState) => {
    const collectionState = state.collections;
    const cpuList = collectionState.cpus;

    return cpuList.find((cpu) => cpu.id === props.cpuId);
  });

  const dispatch = useDispatch();
  const intl = useIntl();

  if (cpu === undefined) return <></>;

  const isCpuBlocked = getIsCpuBlocked(cpu);

  const blockedCpuTerm = intl.formatMessage({ id: "cpu.block.blocked.master" });
  const notBlockedCpuTerm = intl.formatMessage({ id: "cpu.block.notBlocked.general" });
  const cpuBlockTableTitle = intl
    .formatMessage({ id: "cpu.block.cpuTitle" })
    .replace("{cpuSerialNumber}", cpu.serialNumber);
  const cpuBlockTableAddTerm = intl.formatMessage({ id: "cpu.block.addBlock" });
  const cpuBlockReasonTerm = intl.formatMessage({ id: "cpu.block.reason" });
  const cpuBlockCreationMomentTerm = intl.formatMessage({ id: "cpu.block.createdAt" });
  const cpuBlockAppliedMomentTerm = intl.formatMessage({ id: "cpu.block.appliedAt" });
  const cpuBlockUnblockMomentTerm = intl.formatMessage({ id: "cpu.block.unblockedAt" });
  const cpuBlockUnblockAppliedMomentTerm = cpuBlockAppliedMomentTerm;

  const cpuBlockDefaultBlockReason = intl.formatMessage({ id: "cpu.block.reason.default" });
  const cpuBlockIdlenessBlockReason = intl.formatMessage({ id: "cpu.block.reason.idleness" });
  const cpuBlockManuallyBlockReason = intl.formatMessage({ id: "cpu.block.reason.manually" });

  const notAppliedTerm = intl.formatMessage({ id: "cpu.block.notApplied" });

  const removeCpuBlocksTerm = intl.formatMessage({ id: "cpu.block.removeBlock" });

  const formatDate = (date: Date) =>
    intl.formatDate(date, {
      weekday: "long",
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });

  if (cpu.loading) {
    return <CircularProgress />;
  }

  return (
    <UserTableComponent<CpuBlock>
      items={cpu.block}
      titleTerm={cpuBlockTableTitle}
      addTerm={cpuBlockTableAddTerm}
      onAddFunction={() => {
        dispatch(addCpuBlock(cpu.id));
      }}
      classes={[
        "id",
        cpuBlockReasonTerm,
        cpuBlockCreationMomentTerm,
        cpuBlockAppliedMomentTerm,
        cpuBlockUnblockMomentTerm,
        cpuBlockUnblockAppliedMomentTerm,
      ]}
      formatItems={(cpuBlock) => [
        cpuBlock.id,
        cpuBlock.blockReason === CpuBlockReason.default
          ? cpuBlockDefaultBlockReason
          : cpuBlock.blockReason === CpuBlockReason.idleness
          ? cpuBlockIdlenessBlockReason
          : cpuBlockManuallyBlockReason,
        formatDate(cpuBlock.createdAt),
        cpuBlock.blockAppliedAt === undefined
          ? notAppliedTerm
          : formatDate(cpuBlock.blockAppliedAt),
        cpuBlock.unblockedAt === undefined ? notAppliedTerm : formatDate(cpuBlock.unblockedAt),
        cpuBlock.unblockAppliedAt === undefined
          ? notAppliedTerm
          : formatDate(cpuBlock.unblockAppliedAt),
      ]}
      headerActionIcon={
        <>
          <Button
            onClick={() => {
              props.onClose();
            }}
            color="primary"
          >
            <Icon>cancel</Icon>
          </Button>
          <CpuLockedIconTypography
            isBlocked={isCpuBlocked}
            blockedText={blockedCpuTerm}
            notBlockedText={notBlockedCpuTerm}
          />
        </>
      }
      enableToMarkItem={getCanRemoveCpuBlock}
      onRemoveFunction={(blockIds) => {
        dispatch(removeCpuBlocks(props.cpuId, blockIds));
      }}
      remotionTerm={removeCpuBlocksTerm}
    />
  );
};
