import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import FlightFromState from "./flight_from_state";

export default (props: {
  setPolygonOrPolylineRef: (id: string, ref: L.Polyline | L.Polygon | null, zIndex: number) => void;
  onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
}): JSX.Element => {
  const projectIds = useSelector(
    (state: SystemState) => state.projectTree.projectList?.map((project) => project.id) ?? []
  );

  return (
    <>
      {projectIds.map((projectId) => (
        <ProjectFlights
          projectId={projectId}
          setPolygonOrPolylineRef={props.setPolygonOrPolylineRef}
          onRenderPolygonOrPolyline={props.onRenderPolygonOrPolyline}
        />
      ))}
    </>
  );
};

const ProjectFlights = (props: {
  projectId: string;
  setPolygonOrPolylineRef: (id: string, ref: L.Polyline | L.Polygon | null, zIndex: number) => void;
  onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
}): JSX.Element => {
  const areaIds = useSelector(
    (state: SystemState) =>
      state.projectTree.projectList
        ?.find((project) => project.id === props.projectId)
        ?.areas?.map((area) => area.id) ?? []
  );

  return (
    <>
      {areaIds.map((areaId) => (
        <AreaFlights
          projectId={props.projectId}
          areaId={areaId}
          setPolygonOrPolylineRef={props.setPolygonOrPolylineRef}
          onRenderPolygonOrPolyline={props.onRenderPolygonOrPolyline}
        />
      ))}
    </>
  );
};

const AreaFlights = (props: {
  projectId: string;
  areaId: string;
  setPolygonOrPolylineRef: (id: string, ref: L.Polyline | L.Polygon | null, zIndex: number) => void;
  onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
}): JSX.Element => {
  const flightsIds = useSelector(
    (state: SystemState) =>
      state.projectTree.projectList
        ?.find((project) => project.id === props.projectId)
        ?.areas?.find((area) => area.id === props.areaId)
        ?.flightList?.map((flight) => flight.id) ?? []
  );

  return (
    <>
      {flightsIds.map((flightId) => (
        <FlightFromState
          {...props}
          flightId={flightId}
          setPolygonOrPolylineRef={props.setPolygonOrPolylineRef}
          onRenderPolygonOrPolyline={props.onRenderPolygonOrPolyline}
        />
      ))}
    </>
  );
};
