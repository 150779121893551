import { BoundingBox, Location } from "biohub-model";
import React from "react";
import useSuperCluster from "use-supercluster";
import L from "leaflet";
import Marker from "./marker";
import Supercluster from "supercluster";
import { Tooltip } from "react-leaflet";
import { boundingBoxOfBoundingBoxes } from "../../../../core/geometricFunctions";

type ClusterItemPoint = Supercluster.PointFeature<{
  cluster: boolean;
  child: JSX.Element;
  boundingBox: BoundingBox;
}>;

type ClusterProperties =
  | {
      cluster: true;
      cluster_id: number;
      point_count: number;
    }
  | {
      cluster: false;
      child: JSX.Element;
      boundingBox: BoundingBox;
    };

export default (props: {
  mapBounds: BoundingBox;
  mapZoom: number;
  clusterRadios: number;
  clusterIcon: L.Icon;
  clusterZIndex: number;
  items: {
    center: Location;
    boundingBox: BoundingBox;
    child: JSX.Element;
  }[];
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
}): JSX.Element => {
  const { clusters, supercluster } = useSuperCluster({
    points: props.items.map((item): ClusterItemPoint => {
      return {
        type: "Feature",
        properties: { cluster: false, child: item.child, boundingBox: item.boundingBox },
        geometry: {
          type: "Point",
          coordinates: [item.center.lng, item.center.lat],
        },
      };
    }),
    bounds: [
      props.mapBounds.west,
      props.mapBounds.south,
      props.mapBounds.east,
      props.mapBounds.north,
    ],
    zoom: props.mapZoom,
    options: { radius: props.clusterRadios, maxZoom: 22 },
  });

  return (
    <>
      {clusters.map((clusterItem) => {
        const [longitude, latitude] = clusterItem.geometry.coordinates;
        const position: Location = {
          lat: latitude,
          lng: longitude,
        };

        const clusterProperties = clusterItem.properties as ClusterProperties;

        // we have a cluster to render
        if (clusterProperties.cluster) {
          return (
            <Marker
              position={position}
              icon={props.clusterIcon}
              onClick={() => {
                const boundingBoxes = getBoundingBoxesOfClusteredItemChildren(
                  clusterProperties.cluster_id,
                  supercluster
                );
                const boundingBox = boundingBoxOfBoundingBoxes(boundingBoxes);
                if (boundingBox === undefined) return;

                props.moveToBoundingBox(boundingBox);
              }}
              zIndex={props.clusterZIndex}
            >
              <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
                <div
                  style={{
                    color: "black",
                    verticalAlign: "middle",
                    display: "inline-block",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {clusterProperties.point_count}
                </div>
              </Tooltip>
            </Marker>
          );
        }

        return clusterProperties.child;
      })}
    </>
  );
};

function getBoundingBoxesOfClusteredItemChildren(
  clusterId: number,
  superCluster?: Supercluster<
    {
      cluster: boolean;
      child: JSX.Element;
      boundingBox: BoundingBox;
    },
    Supercluster.AnyProps
  >
): BoundingBox[] {
  if (superCluster === undefined) return [];

  const children = superCluster.getChildren(clusterId);

  if (children.length === 0) return [];

  let boundingBoxes: BoundingBox[] = [];

  for (const child of children) {
    const properties = child.properties as ClusterProperties;
    if (properties.cluster) {
      boundingBoxes = [
        ...boundingBoxes,
        ...getBoundingBoxesOfClusteredItemChildren(properties.cluster_id, superCluster),
      ];
    } else {
      boundingBoxes.push(properties.boundingBox);
    }
  }

  return boundingBoxes;
}
