/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { CustomTreeItem } from "./CustomTreeItem";
import { Area, CpuLog, Profile } from "biohub-model";
import { calculateAreaHa } from "../../../core/geometricFunctions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ProjectService from "../../../services/ProjectService";
import CpuLogService from "../../../services/CpuLogService";
import CustomExpandIcon from "./CustomExpandIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  expandCpuLogs,
  loadAreaFlights,
  loadCpuLogs,
} from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { Link } from "@material-ui/icons";

import { useIntl } from "react-intl";
import {
  CpuLogInProjectTree,
  ProjectInProjectTree,
} from "../../../store/reducers/projectTreeReducer";
import { simplifyDroneNames } from "../../../core/simplifyNames";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid",
    marginBottom: theme.spacing(0.5),
    backgroundColor: "#f5f5f5",
    fontSize: 22,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 18,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    textAlign: "center",
    width: 500,
    height: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    marginTop: "8px",
    textAlign: "start",
  },
  grayText: {
    fontSize: 12,
    color: "#888",
  },
  nameLog: {
    fontSize: 15,
  },
  nameLogHeader: {
    fontSize: 18,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    backgroundColor: "#fff",
  },
  closeButton: {
    marginLeft: "auto",
    padding: "5px",
  },
  expansionPanel: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  summary: {
    cursor: "pointer",
  },
  button: {
    color: "#333",
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  cpuLogs: CpuLogInProjectTree[];
  projectList: ProjectInProjectTree[];
}

const CpuLogsTree: React.FC<Props> = ({ cpuLogs, projectList }) => {
  const classes = useStyles();

  const intl = useIntl();

  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [areasClient, setAreasClient] = useState<Area[]>([]);
  const isPanelExpanded = useSelector(
    (state: SystemState) => state.projectTree.cpuLogsList.expanded
  );

  const [selectedProject, setSelectedProject] = useState("");
  const [selectedLogFileName, setSelectedLogFileName] = useState("");
  const [selectedDrone, setSelectedDrone] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedLogFileId, setSelectedLogFileId] = useState("");

  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const dispatch = useDispatch();
  const drones = useSelector((state: SystemState) => state.collections.drones);
  const dronesList = simplifyDroneNames(drones);

  const dronesWithSimplifiedNames = drones.map((drone) => {
    const simplifiedName = dronesList.find((item) => item.id === drone.id)?.simplifiedName || "";
    return {
      ...drone,
      simplifiedName: simplifiedName,
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      const responseAreasOfProjectClient = await ProjectService.listAreas(selectedProject);

      if (responseAreasOfProjectClient.success) {
        setAreasClient(responseAreasOfProjectClient.data);
      } else {
        console.error("Erro ao buscar dados da API:", responseAreasOfProjectClient.error);
      }
    };

    fetchData();
  }, [selectedProject]);

  const handleCpuLogToFligth = (log: CpuLog) => {
    setSelectedLogFileName(log.logFileName);
    setSelectedLogFileId(log.id);
    setLinkModalOpen(true);
  };

  function clearModalFields() {
    setSelectedDrone("");
    setSelectedProject("");
    setSelectedArea("");
  }

  function refreshCpuLogs() {
    dispatch(loadCpuLogs(systemProfile?.directClientId as string));
  }

  const handleLinkClick = async () => {
    try {
      const cpuLogId = selectedLogFileId;
      const droneSerialNumber = selectedDrone;
      const areaId = selectedArea;
      const projectId = selectedProject;

      await CpuLogService.updateCpuLog(cpuLogId, droneSerialNumber, areaId, projectId);

      setLinkModalOpen(false);
      clearModalFields();
      refreshCpuLogs();
      dispatch(loadAreaFlights(projectId, areaId));
    } catch (error) {
      console.error(error);
    }
  };

  const cpuLogsTreeItems = cpuLogs
    ? cpuLogs.map((log) => {
        const finishedAtDate = new Date(log.startedAt);
        const formattedDate = `${String(finishedAtDate.getDate()).padStart(2, "0")}/${String(
          finishedAtDate.getMonth() + 1
        ).padStart(2, "0")}/${finishedAtDate.getFullYear()} ${String(
          finishedAtDate.getHours()
        ).padStart(2, "0")}:${String(finishedAtDate.getMinutes()).padStart(2, "0")}`;

        return (
          <div key={log.id} style={{ display: "block" }}>
            <CustomTreeItem
              id={log.id}
              deleted={false}
              content={`${calculateAreaHa(log.flowRoute).toFixed(2)} ha | ${formattedDate}`}
              subtitle={`${intl.formatMessage({ id: "map.unlinkedFlights.cpuSerialNumber" })} ${
                log.logFileName
              }`}
              trailing={
                <div
                  style={{
                    marginLeft: 5,
                    marginRight: 0,
                  }}
                >
                  <button
                    style={{
                      color: "black",
                      textAlign: "center",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCpuLogToFligth(log)}
                  >
                    <Tooltip
                      title={intl.formatMessage({ id: "map.unlinkedFlights.action.linked" })}
                    >
                      <Link onClick={() => handleCpuLogToFligth(log)}></Link>
                    </Tooltip>
                  </button>
                </div>
              }
            />
          </div>
        );
      })
    : null;

  return (
    <div>
      <Accordion
        expanded={isPanelExpanded}
        onChange={() => {
          dispatch(expandCpuLogs());
        }}
        style={{
          background: "rgba(237,237,237,1)",
          border: "0.1px solid #d3d3d3",
          padding: 0,
          margin: 0,
          borderRadius: "4px",
        }}
      >
        <AccordionSummary style={{ borderRadius: "4px !important" }}>
          <CustomExpandIcon expanded={isPanelExpanded} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>
              <div style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                {intl.formatMessage({ id: "map.unlinkedFlights.unlinkedFlights" })}
              </div>
            </Typography>
            <Typography style={{ marginTop: "2px" }}>
              <span
                style={{
                  marginLeft: "auto",
                  marginRight: 0,
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }}
              >
                {cpuLogsTreeItems?.length}
              </span>
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails
          style={{ marginTop: "-0.1em", padding: 0, display: "flex", flexDirection: "column" }}
        >
          {cpuLogsTreeItems}
        </AccordionDetails>
      </Accordion>

      <Modal
        open={linkModalOpen}
        onClose={() => {
          setLinkModalOpen(false);
          clearModalFields();
        }}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" gutterBottom>
              <b className={classes.nameLogHeader}>
                {intl.formatMessage({ id: "map.unlinkedFlights.action.linked.for.prefix" })}
                {selectedLogFileName.toLocaleLowerCase()}
                {intl.formatMessage({ id: "map.unlinkedFlights.action.linked.for.sufix" })}
              </b>
            </Typography>
            <IconButton
              onClick={() => {
                setLinkModalOpen(false);
                clearModalFields();
              }}
              className={classes.closeButton}
            >
              <Close />
            </IconButton>
          </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="project-label">
              {intl.formatMessage({ id: "map.unlinkedFlights.modal.project" })}
            </InputLabel>
            <Select
              labelId="project-label"
              id="project-select"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value as string);
              }}
            >
              {projectList.map((project: ProjectInProjectTree) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="area-label">
              {intl.formatMessage({ id: "map.unlinkedFlights.modal.area" })}
            </InputLabel>
            <Select
              labelId="area-label"
              id="area-select"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value as string)}
              disabled={selectedProject ? false : true}
            >
              {areasClient.map((area: Area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="drone-label">
              {intl.formatMessage({ id: "map.unlinkedFlights.modal.drone" })}
            </InputLabel>
            <Select
              labelId="drone-label"
              id="drone-select"
              value={selectedDrone}
              onChange={(e) => setSelectedDrone(e.target.value as string)}
              disabled={selectedArea ? false : true}
            >
              {dronesWithSimplifiedNames.map((drone) => (
                <MenuItem key={drone.id} value={drone.serialNumber}>
                  {`${drone.simplifiedName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
            <Button
              color="default"
              onClick={() => {
                clearModalFields();
                setLinkModalOpen(false);
              }}
            >
              {intl.formatMessage({ id: "map.unlinkedFlights.modal.cancel" })}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleLinkClick();
              }}
              style={{ marginLeft: "8px" }}
              disabled={selectedDrone ? false : true}
            >
              {intl.formatMessage({ id: "map.unlinkedFlights.modal.link" })}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CpuLogsTree;
