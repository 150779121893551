import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import arrowIconCompass from "../../../assets/icon/arrow_icon_compass.svg";
import { useIntl } from "react-intl";
import CircularSlider from "react-circular-slider-svg";
import { rotateMap } from "../../../store/actions/projectTreeActions";

export default (): JSX.Element => {
  const [openControl, setOpenControl] = useState<boolean>(false);

  if (!openControl) {
    return <MapCompassIcon setOpen={() => setOpenControl(true)} />;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
      onClick={() => setOpenControl(false)}
    >
      <MapCompassControl />
    </div>
  );
};

const MapCompassIcon = (props: { setOpen: () => void }): JSX.Element => {
  const rotation = useSelector((state: SystemState) => state.projectTree.mapState.rotation);

  return (
    <div
      onClick={() => props.setOpen()}
      style={{
        position: "absolute",
        top: 5,
        right: 5,
      }}
    >
      <div
        style={{
          position: "relative",
          width: 40,
          height: 40,
          borderWidth: 1,
          borderRadius: 7,
          backgroundColor: "rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={arrowIconCompass}
          style={{
            height: 30,
            width: 30,
            transform: `rotate(${rotation}deg)`,
          }}
        />
      </div>
    </div>
  );
};

const MapCompassControl = (): JSX.Element => {
  const stateRotation = useSelector((state: SystemState) => state.projectTree.mapState.rotation);

  const [rotation, setRotation] = useState<number>(stateRotation);

  useEffect(() => {
    setRotation(stateRotation);
  }, [stateRotation]);

  const dispatch = useDispatch();

  const changeRotation = (newRotation: number) => {
    setRotation(newRotation);
    dispatch(rotateMap(newRotation));
  };

  const intl = useIntl();

  const borderPadding = 5;
  const height = 175;
  const width = 170;

  const convertRotationToCircleSliderAngle = (rotation: number) => {
    return (rotation + 180) % 360;
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 5,
        right: 5,
        color: "white",
      }}
    >
      <div
        onClick={(e) => e.preventDefault()}
        style={{
          height: height,
          width: width,
          position: "relative",
          borderWidth: 1,
          borderRadius: 7,
          backgroundColor: "rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(5px)",
        }}
      >
        <div
          style={{
            height: height,
            width: width,
            textAlign: "center",
            alignContent: "center",
            position: "absolute",
          }}
        >
          <img
            src={arrowIconCompass}
            style={{
              height: height * 0.4,
              width: width * 0.4,
              alignSelf: "center",
              transform: `rotate(${rotation}deg)`,
            }}
          />
        </div>
        <div
          style={{
            height: height,
            width: width,
            textAlign: "center",
            alignContent: "center",
            position: "absolute",
          }}
        >
          <CircularSlider
            arcColor="#FFFFFF"
            arcBackgroundColor="#FFFFFF"
            handle1={{
              value: convertRotationToCircleSliderAngle(rotation),
              onChange: (value) => {
                changeRotation(convertRotationToCircleSliderAngle(value));
              },
            }}
            minValue={0}
            maxValue={359}
            size={height * 0.8}
          />
        </div>

        <div
          style={{
            position: "absolute",
            top: borderPadding,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: width,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              changeRotation(0);
            }}
          >
            {intl.formatMessage({
              id: "geographic.direction.northAcronym",
            })}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: borderPadding,
            top: borderPadding,
            display: "flex",
            alignItems: "center",
            height: height,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              changeRotation(270);
            }}
          >
            {intl.formatMessage({
              id: "geographic.direction.westAcronym",
            })}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            right: borderPadding,
            top: borderPadding,
            display: "flex",
            alignItems: "center",
            height: height,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              changeRotation(90);
            }}
          >
            {intl.formatMessage({
              id: "geographic.direction.eastAcronym",
            })}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: borderPadding,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: width,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              changeRotation(180);
            }}
          >
            {intl.formatMessage({
              id: "geographic.direction.southAcronym",
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useOutsideClick = (callback: Function) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      callback();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return ref;
};
