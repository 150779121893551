import { DashboardGraphResponse } from "biohub-model";
import { sub } from "date-fns";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  DASHBOARD_LOAD_HECTARES_SUCCESS,
  DASHBOARD_LOAD_HECTARES,
  DASHBOARD_LOAD_HECTARES_ERROR,
  DASHBOARD_LOAD_INCOME,
  DASHBOARD_LOAD_INCOME_ERROR,
  DASHBOARD_LOAD_INCOME_SUCCESS,
} from "../actions/dashboardActions";
import { LOG_OUT } from "../actions/loginActions";
import { SystemAction } from "../actions/systemActions";

export enum DashboardSelectedGraph {
  Income,
  Area,
}

export type DashboardState = {
  key: "dashboard";
  isLoading: boolean;
  graphData?: DashboardGraphResponse;
  error: BiohubError | null;
  selectedGraph: DashboardSelectedGraph;
  initialDate: Date;
  finalDate: Date;
  scale: number;
};

const INITIAL_STATE: DashboardState = {
  key: "dashboard",
  isLoading: true,
  error: null,
  selectedGraph: DashboardSelectedGraph.Area,
  initialDate: sub(new Date(), { days: 30 }),
  finalDate: new Date(),
  scale: 30,
};

export function dashboardReducer(state = INITIAL_STATE, action: SystemAction): DashboardState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case LOG_OUT:
      return INITIAL_STATE;
    case DASHBOARD_LOAD_HECTARES:
      return {
        ...effectiveState,
        isLoading: true,
      };
    case DASHBOARD_LOAD_HECTARES_ERROR:
      return {
        ...effectiveState,
        isLoading: false,
        error: action.payload.error,
      };
    case DASHBOARD_LOAD_HECTARES_SUCCESS:
      return {
        ...effectiveState,
        isLoading: false,
        error: null,
        graphData: action.payload.data,
        selectedGraph: DashboardSelectedGraph.Area,
        initialDate: action.payload.initialDate,
        finalDate: action.payload.finalDate,
        scale: action.payload.scale !== undefined ? action.payload.scale : effectiveState.scale,
      };

    case DASHBOARD_LOAD_INCOME:
      return {
        ...effectiveState,
        isLoading: true,
      };
    case DASHBOARD_LOAD_INCOME_ERROR:
      return {
        ...effectiveState,
        isLoading: false,
        error: action.payload.error,
      };
    case DASHBOARD_LOAD_INCOME_SUCCESS:
      return {
        ...effectiveState,
        isLoading: false,
        error: null,
        graphData: action.payload.data,
        selectedGraph: DashboardSelectedGraph.Income,
        initialDate: action.payload.initialDate,
        finalDate: action.payload.finalDate,
        scale: action.payload.scale !== undefined ? action.payload.scale : effectiveState.scale,
      };
    default:
      return effectiveState;
  }
}
