import { Flight, CompletePlannedArea, PlannedArea } from "biohub-model";
import React, { useState } from "react";
import { ModalContainer } from "./ModalContainer";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
  IconButton,
  Collapse,
  Typography,
  Radio,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { TableList } from "./styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { differenceInSeconds, format } from "date-fns";
import {
  quitViewFlightPlan,
  selectToViewFlightPlan,
} from "../../../store/actions/projectTreeActions";

type Props = {
  projectId: string;
  areaId: string;
  onClose: () => void;
};

export default function (props: Props): JSX.Element {
  const styleClasses = useStyles();

  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [sortItem, setSortItem] = useState<SortItem>("selected");

  const actualPlannedArea = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);
    if (area === undefined) return undefined;

    return area.planned;
  });

  const selectedFlightPlan = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    return area?.viewingFlightPlan ?? undefined;
  });

  const flightPlans: CompletePlannedArea[] = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    const flightPlans: CompletePlannedArea[] = [];

    if (area !== undefined) {
      const plannedArea = area.planned;

      if (
        plannedArea.homePoint !== undefined &&
        plannedArea.homePointElevation !== undefined &&
        plannedArea.route !== undefined
      ) {
        flightPlans.push({
          id: plannedArea.id,
          generatedAt: plannedArea.generatedAt,
          polygon: plannedArea.polygon,
          homePoint: plannedArea.homePoint,
          route: plannedArea.route,
          homePointElevation: plannedArea.homePointElevation,
          basePoint: plannedArea.basePoint,
          routeAngle: plannedArea.routeAngle,
        });
      }
    }

    const flightList = area?.flightList ?? [];

    flightList.forEach((flight) => {
      const plannedFlightArea = flight.flightEnvironmentSnapshot.plannedArea;

      if (flightPlans.find((plan) => plan.id === plannedFlightArea.id) === undefined) {
        flightPlans.push(plannedFlightArea);
      }
    });

    return flightPlans;
  });

  const routesAndFlightsMap: { [plannedAreaId: string]: Flight[] } = useSelector(
    (state: SystemState) => {
      const projectTreeState = state.projectTree;

      const flightList =
        projectTreeState.projectList
          ?.find((project) => project.id === props.projectId)
          ?.areas?.find((area) => area.id === props.areaId)?.flightList ?? [];

      let routesAndFlightsMap: { [plannedAreaId: string]: Flight[] } = {};

      flightList.forEach((flight) => {
        const plannedFlightArea = flight.flightEnvironmentSnapshot.plannedArea;

        if (routesAndFlightsMap[plannedFlightArea.id] === undefined) {
          routesAndFlightsMap = {
            ...routesAndFlightsMap,
            [plannedFlightArea.id]: [flight],
          };
        } else {
          routesAndFlightsMap[plannedFlightArea.id].push(flight);
        }
      });

      return routesAndFlightsMap;
    }
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  const termTitle = intl.formatMessage({ id: "map.plannedRoutes.title" });
  const termFlightAmount = intl.formatMessage({ id: "map.plannedRoutes.flightAmount" });
  const termPlannedDate = intl.formatMessage({ id: "map.plannedRoutes.plannedData" });

  return (
    <ModalContainer showModal={true} onClose={props.onClose} title={termTitle} width={600}>
      <TableList>
        <TableContainer>
          <div
            style={{
              flex: 1,
              overflowY: "scroll",
              height: 330,
            }}
          >
            <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
              <EnhancedTableHead
                styleClasses={styleClasses}
                headers={["", termFlightAmount, termPlannedDate]}
                sortOrder={sortOrder}
                orderClassIndex={sortItem === "selected" ? 0 : sortItem === "flightAmount" ? 1 : 2}
                onRequestSort={(classIndex: number) => {
                  setSortItem(
                    classIndex === 0 ? "selected" : classIndex === 1 ? "flightAmount" : "generateAt"
                  );
                }}
                onRevertOrder={(newOrder: SortOrder) => {
                  setSortOrder(newOrder);
                }}
              />
              {orderAndFormatItems(
                actualPlannedArea,
                selectedFlightPlan,
                flightPlans,
                routesAndFlightsMap,
                sortOrder,
                sortItem,
                flightPlans.length,
                0
              ).map((item) => (
                <Row
                  item={item}
                  onSelected={(plannedArea) => {
                    if (plannedArea.id === actualPlannedArea?.id) {
                      dispatch(quitViewFlightPlan(props.projectId, props.areaId));
                    } else {
                      dispatch(
                        selectToViewFlightPlan(props.projectId, props.areaId, plannedArea.id)
                      );
                    }
                  }}
                />
              ))}
            </Table>
          </div>
        </TableContainer>
      </TableList>
    </ModalContainer>
  );
}

function EnhancedTableHead(props: {
  styleClasses: ClassNameMap<"table" | "root" | "paper" | "visuallyHidden">;
  headers: Array<string>;
  sortOrder: SortOrder;
  orderClassIndex: number;
  onRevertOrder: (order: SortOrder) => void;
  onRequestSort: (classIndex: number) => void;
}) {
  const { styleClasses, headers, orderClassIndex, sortOrder, onRequestSort, onRevertOrder } = props;

  const headCells: Array<{
    index: number;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
  }> = [];

  headers.forEach((value, index) => {
    if (index === 0) {
      headCells.push({ index: index, numeric: false, disablePadding: true, label: "" });
    } else {
      headCells.push({ index: index, numeric: false, disablePadding: false, label: value });
    }
  });

  const reverseOrder: SortOrder = sortOrder === "asc" ? "desc" : "asc";

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.index.toString()}
            align={"center"}
            padding={index === 0 ? "checkbox" : undefined}
            sortDirection={orderClassIndex === headCell.index ? sortOrder : false}
          >
            <TableSortLabel
              active={orderClassIndex === headCell.index}
              direction={orderClassIndex === headCell.index ? sortOrder : reverseOrder}
              onClick={(e) => {
                if (orderClassIndex === headCell.index) {
                  onRevertOrder(reverseOrder);
                } else {
                  onRequestSort(headCell.index);
                }
              }}
            >
              {headCell.label}
              {orderClassIndex === headCell.index ? (
                <span className={styleClasses.visuallyHidden}>
                  {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function orderAndFormatItems(
  actualPlannedArea: PlannedArea | undefined,
  selectedPlannedArea: CompletePlannedArea | undefined,
  items: CompletePlannedArea[],
  routesAndFlightsMap: { [plannedAreaId: string]: Flight[] },
  sortOrder: SortOrder,
  sortItem: SortItem,
  itemsPerPage: number,
  viewPage: number
): {
  id: string;
  isSelected: boolean;
  flightAmount: number;
  generateAt: Date;
  hasActualPlan: boolean;
  flights: Flight[];
  plannedArea: CompletePlannedArea;
}[] {
  const itemList = items.map((item) => ({
    id: item.id,
    isSelected:
      item.id === selectedPlannedArea?.id ||
      (item.id === actualPlannedArea?.id && selectedPlannedArea === undefined),
    flightAmount: routesAndFlightsMap[item.id]?.length ?? 0,
    generateAt: item.generatedAt,
    hasActualPlan: item.id === actualPlannedArea?.id,
    flights: routesAndFlightsMap[item.id] ?? [],
    plannedArea: item,
  }));

  itemList.sort((a, b) => {
    let aCompareField: boolean | number | Date;
    let bCompareField: boolean | number | Date;

    switch (sortItem) {
      case "selected":
        aCompareField = a.isSelected;
        bCompareField = b.isSelected;
        break;
      case "flightAmount":
        aCompareField = a.flightAmount;
        bCompareField = b.flightAmount;
        break;
      case "generateAt":
        aCompareField = a.generateAt;
        bCompareField = b.generateAt;
        break;
    }

    if (sortOrder === "asc") {
      if (aCompareField > bCompareField) return 1;
      else if (aCompareField === bCompareField) return 0;
      else return -1;
    } else {
      if (bCompareField > aCompareField) return 1;
      else if (aCompareField === bCompareField) return 0;
      else return -1;
    }
  });

  const ret = [];
  for (let i = 0; i < itemsPerPage && itemsPerPage * viewPage + i < itemList.length; i++) {
    ret.push(itemList[itemsPerPage * viewPage + i]);
  }

  return ret;
}

function Row(props: {
  item: {
    id: string;
    isSelected: boolean;
    flightAmount: number;
    generateAt: Date;
    hasActualPlan: boolean;
    flights: Flight[];
    plannedArea: CompletePlannedArea;
  };
  onSelected: (plannedArea: CompletePlannedArea) => void;
}): JSX.Element {
  const intl = useIntl();

  const termUntilToday = intl.formatMessage({ id: "map.plannedRoutes.untilToday" });
  const termDate = intl.formatMessage({ id: "map.plannedRoutes.flightDate" });
  const termPerformedFlights = intl.formatMessage({ id: "map.plannedRoutes.termPerformedFlights" });
  const termFlightTime = intl.formatMessage({ id: "map.plannedRoutes.flightTime" });

  const [open, setOpen] = useState<boolean>(false);

  const { id, isSelected, flightAmount, generateAt, hasActualPlan } = props.item;

  const labelId = `enhanced-table-checkbox-${id}`;

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-aria-checked={isSelected}
        tabIndex={-1}
        key={id}
        selected={isSelected}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Radio
            checked={isSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={() => {
              props.onSelected(props.item.plannedArea);
            }}
          />
        </TableCell>
        <TableCell align="center" component="th" id={labelId} scope="row">
          <div
            style={{
              marginLeft: -32,
            }}
          >
            {flightAmount}
          </div>
        </TableCell>
        <TableCell align="center">
          <div
            style={{
              marginLeft: -32,
            }}
          >
            {((): string => {
              const formattedGeneratedAt = format(generateAt, "dd MMM yyyy hh:mm:ss aaa");

              if (!hasActualPlan) return formattedGeneratedAt;

              return formattedGeneratedAt + termUntilToday;
            })()}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ paddingTop: 10, paddingLeft: 10 }}>
              <Typography variant="h6" gutterBottom component="div">
                {termPerformedFlights}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{termDate}</TableCell>
                    <TableCell align="right">{termFlightTime}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.item.flights.map((flight) => {
                    return (
                      <TableRow key={flight.id}>
                        <TableCell align="left">
                          {format(flight.startedAt, "dd/MM/yyyy hh:mm aaa")}
                        </TableCell>
                        <TableCell align="right">
                          {toHHMMSS(differenceInSeconds(flight.finishedAt, flight.startedAt))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function toHHMMSS(sec: number): string {
  var sec_trunc = Math.trunc(sec);
  var hours = Math.floor(sec_trunc / 3600);
  var minutes = Math.floor((sec_trunc - hours * 3600) / 60);
  var seconds = sec_trunc - hours * 3600 - minutes * 60;

  var hoursOut: string = hours < 10 ? "0" + hours.toString() : hours.toString();
  var minOut: string = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
  var secOut: string = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

  return hoursOut + "h:" + minOut + "min:" + secOut + "s";
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

type SortOrder = "asc" | "desc";

type SortItem = "selected" | "flightAmount" | "generateAt";
