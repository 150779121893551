import { Location } from "biohub-model";
import React, { useEffect, useState } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";

export type DraggableProps =
  | {
      draggable?: false;
    }
  | {
      draggable: true;
      onDragStart: () => void;
      onDragEnd: (newLocation: Location) => void;
    };

export default (
  props: {
    position: Location;
    icon?: L.Icon;
    children?: JSX.Element;
    onClick?: () => void;
    zIndex: number;
  } & DraggableProps
) => {
  const [position, setPosition] = useState<Location>(props.position);
  useEffect(() => {
    setPosition(props.position);
  }, [props.position]);

  const icon =
    props.icon ??
    new L.Icon({
      iconUrl: "/generic_marker.png",
      iconSize: [35, 49],
      iconAnchor: [17.5, 49],
    });

  let eventHandlers: L.LeafletEventHandlerFnMap | undefined;
  if (props.draggable) {
    eventHandlers = {
      dragstart: (e) => props.onDragStart(),
      dragend: (e) => {
        const newPosition = e.target.getLatLng();
        const location: Location = {
          lat: newPosition.lat,
          lng: newPosition.lng,
        };
        setPosition(location);
        props.onDragEnd(location);
      },
    };
  }
  const onClick = props.onClick;
  if (onClick !== undefined) {
    if (eventHandlers === undefined) {
      eventHandlers = {};
    }
    eventHandlers = {
      ...eventHandlers,
      click: (_) => onClick(),
    };
  }

  return (
    <Marker
      position={position}
      draggable={props.draggable}
      icon={icon}
      eventHandlers={eventHandlers}
      zIndexOffset={props.zIndex}
    >
      {props.children}
    </Marker>
  );
};
