import React from "react";
import CurrentLocation from "./current_location";
import ProjectsFromState from "./projects_from_state";
import FlightsFromState from "./flights_from_state";
import EditingAreaFromState from "./editing_area_from_state";
import L from "leaflet";
import { BoundingBox, Location } from "biohub-model";
import ProjectsAndAreasSummariesCluster from "./projects_and_areas_summaries_and_clusters";
import ControlPolylineAndPolygonZIndex from "../ControlPolylineAndPolygonZIndex";
import MapTilesFromState from "./MapTilesFromState";

export default (props: {
  onClickNotSelectedArea: (projectId: string, areaId: string) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickWaypoint: (projectId: string, areaId: string, waypointIndex: number) => void;
  onEditingClickWaypoint: (waypointIndex: number) => void;
  onEditingClickPolygon: (location: Location) => void;
  onEditingClickPlannedPath: (previousPointIndex: number, location: Location) => void;
  onEditingWaypointMovementStart: () => void;
  onEditingWaypointMoved: (waypointIndex: number, location: Location) => void;
  onEditingPolygonVertexMovementStart: () => void;
  onEditingPolygonVertexMoved: (vertexIndex: number, location: Location) => void;
  onEditingHomePointMovementStart: () => void;
  onEditingHomePointMoved: (newLocation: Location) => void;
  onMissionPlannerBasePointMovementStart: () => void;
  onMissionPlannerBasePointMoved: (newLocation: Location) => void;
  onClickHomePoint: (projectId: string, areaId: string) => void;
  map: L.Map;
}): JSX.Element => {
  return (
    <>
      <MapTilesFromState />
      <CurrentLocation />

      <RenderControllingZIndex {...props} />

      <ProjectsAndAreasSummariesCluster moveToBoundingBox={props.moveToBoundingBox} />
    </>
  );
};

const RenderControllingZIndex = (props: {
  onClickNotSelectedArea: (projectId: string, areaId: string) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickWaypoint: (projectId: string, areaId: string, waypointIndex: number) => void;
  onEditingClickWaypoint: (waypointIndex: number) => void;
  onClickHomePoint: (projectId: string, areaId: string) => void;
  onEditingClickPolygon: (location: Location) => void;
  onEditingClickPlannedPath: (previousPointIndex: number, location: Location) => void;
  onEditingWaypointMovementStart: () => void;
  onEditingWaypointMoved: (waypointIndex: number, location: Location) => void;
  onEditingPolygonVertexMovementStart: () => void;
  onEditingPolygonVertexMoved: (vertexIndex: number, location: Location) => void;
  onEditingHomePointMovementStart: () => void;
  onEditingHomePointMoved: (newLocation: Location) => void;
  onMissionPlannerBasePointMovementStart: () => void;
  onMissionPlannerBasePointMoved: (newLocation: Location) => void;
  map: L.Map;
}): JSX.Element => {
  return (
    <ControlPolylineAndPolygonZIndex>
      {(registerControl) => (
        <>
          <ProjectsFromState
            onClickNotSelectedArea={props.onClickNotSelectedArea}
            moveToBoundingBox={props.moveToBoundingBox}
            onClickWaypoint={props.onClickWaypoint}
            onClickHomePoint={props.onClickHomePoint}
            map={props.map}
            setPolygonOrPolylineRef={registerControl.setPolygonOrPolylineRef}
            onRenderPolygonOrPolyline={registerControl.onRenderPolygonOrPolyline}
          />
          <FlightsFromState
            setPolygonOrPolylineRef={registerControl.setPolygonOrPolylineRef}
            onRenderPolygonOrPolyline={registerControl.onRenderPolygonOrPolyline}
          />

          <EditingAreaFromState
            onClickWaypoint={props.onEditingClickWaypoint}
            onClickPolygon={props.onEditingClickPolygon}
            onClickPlannedPathLine={props.onEditingClickPlannedPath}
            onWaypointMovementStart={props.onEditingWaypointMovementStart}
            onWaypointMoved={props.onEditingWaypointMoved}
            onVertexMovementStart={props.onEditingPolygonVertexMovementStart}
            onPolygonVertexMoved={props.onEditingPolygonVertexMoved}
            onHomePointMovementStart={props.onEditingHomePointMovementStart}
            onHomePointMoved={props.onEditingHomePointMoved}
            onMissionPlannerBasePointMoved={props.onMissionPlannerBasePointMoved}
            onMissionPlannerBasePointMovementStart={props.onMissionPlannerBasePointMovementStart}
            onRenderPolygonOrPolyline={registerControl.onRenderPolygonOrPolyline}
            setPolygonOrPolylineRef={registerControl.setPolygonOrPolylineRef}
            map={props.map}
          />
        </>
      )}
    </ControlPolylineAndPolygonZIndex>
  );
};
