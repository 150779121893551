import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Modal,
  TextField,
  Button as MaterialButton,
  Typography,
} from "@material-ui/core";
import {
  Area,
  DroneAction,
  PlannedArea,
  ReleaserAction,
  ReleaserConfiguration,
  Role,
  UnitSystem,
  Waypoint,
} from "biohub-model";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Atomic/Button";
import CompleteNumberEditor from "../../../components/Atomic/Inputs/CompleteNumberEditor";
import Dropdown from "../../../components/Atomic/Inputs/Dropdown";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import {
  minMaxWaypointCurveRadius,
  plannedRouteControlUpdate,
} from "../../../services/ProjectService";
import { updateArea } from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { Actions, Fields, ModalStyle, ModalTitle } from "./styles";

import NumericInputColor from "../../../components/Atomic/BasicComponents/NumericInputColor";

type Props = {
  areaId: string;
  projectId: string;
  releasersConfiguration: ReleaserConfiguration[];
  usingOnlineElevation: boolean;
  plannedArea: PlannedArea;
  onClose: () => void;
  onFinish: () => void;
} & (
  | {
      type: "single";
      waypoint: Waypoint & { index: number };
    }
  | {
      type: "multiple";
      waypoints: (Waypoint & { index: number })[];
    }
);

export default function (props: Props): JSX.Element {
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    const currentProfile = state.profile.userProfile;
    if (currentProfile !== null && currentProfile.role !== Role.external)
      return currentProfile.preferences.unitSystem;
    return UnitSystem.metric;
  });

  const profileRole = useSelector((state: SystemState) =>
    state.profile.userProfile != null ? state.profile.userProfile.role : undefined
  );

  const [savingEdition, setSavingEdition] = useState(false);

  const [height, setHeight] = useState<number | undefined>(0.0);
  const [curveRadius, setCurveRadius] = useState<number | undefined>(0.0);
  const [flightSpeed, setFlightSpeed] = useState<number | undefined>(0.0);
  const [orientation, setOrientation] = useState<number | undefined>(0.0);
  const [selectedAction, setSelectedAction] = useState<number | undefined>(undefined);
  useEffect(() => {
    setSavingEdition(false);
    if (props.type === "multiple") {
      const waypoints = props.waypoints;
      const firstWaypoint = waypoints[0];
      const height = waypoints
        .map((waypoint) => waypoint.height)
        .reduce<number | undefined>((a, b) => (a === b ? a : undefined), firstWaypoint.height);
      setHeight(height);
      const speed = waypoints
        .map((waypoint) => waypoint.speed)
        .reduce<number | undefined>((a, b) => (a === b ? a : undefined), firstWaypoint.speed);
      setFlightSpeed(speed);
      const orientation = waypoints
        .map((waypoint) => waypoint.orientation)
        .reduce<number | undefined>((a, b) => (a === b ? a : undefined), firstWaypoint.orientation);
      setOrientation(orientation);
      const action = waypoints
        .map(extractActualWaypointAction)
        .reduce<number | undefined>(
          (a, b) => (a === b ? a : undefined),
          extractActualWaypointAction(firstWaypoint)
        );
      setSelectedAction(action);
    } else {
      const waypoint = props.waypoint;
      setHeight(waypoint.height);
      setCurveRadius(waypoint.curveRadius);
      setFlightSpeed(waypoint.speed);
      setOrientation(waypoint.orientation);
      setSelectedAction(extractActualWaypointAction(waypoint));
    }
  }, [
    props.type,
    props.areaId,
    props.type === "single"
      ? props.waypoint.index
      : props.waypoints.map((waypoint) => waypoint.index),
  ]);

  const [isValidHeight, setIsValidHeight] = useState<boolean>(false);
  const [isValidCurveRadius, setIsValidCurveRadius] = useState<boolean>(false);
  const [isValidSpeed, setIsValidSpeed] = useState<boolean>(false);
  const [isValidOrientation, setIsValidOrientation] = useState<boolean>(false);
  useEffect(() => {
    if (props.type === "multiple") {
      setIsValidCurveRadius(true);
    }
  }, [props.type]);

  const [validConfig, setValidConfig] = useState<boolean>(false);

  useEffect(() => {
    setValidConfig(isValidHeight && isValidCurveRadius && isValidSpeed && isValidOrientation);
  }, [isValidHeight, isValidCurveRadius, isValidSpeed, isValidOrientation]);

  const isUpdatingArea = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);
    if (area === undefined) return false;

    return area.isUpdating;
  });

  const usingOnlineElevation = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);
    if (area === undefined) return false;

    return area.areaConfig.mustConsiderRelief;
  });

  useEffect(() => {
    if (!savingEdition) return;

    if (!isUpdatingArea) {
      setSavingEdition(false);
      props.onFinish();
    }
  }, [isUpdatingArea]);

  const intl = useIntl();
  const dispatch = useDispatch();

  // terms
  const termHeight = intl.formatMessage({ id: "routePlanning.param.waypoint.height" });
  const termCurveRadius = intl.formatMessage({ id: "routePlanning.param.waypoint.curveRadius" });
  const termFlightSpeed = intl.formatMessage({ id: "routePlanning.param.waypoint.speed" });
  const termFlightSpeedLegend = intl.formatMessage({
    id: "routePlanning.param.waypoint.speed.legend",
  });
  const termOrientation = intl.formatMessage({ id: "routePlanning.param.waypoint.orientation" });
  const titleTerm = intl.formatMessage({ id: "routePlanning.editWaypoint" });

  const distanceConversionFactor = UnitConversionHelper.distanceConversion(unitSystem);
  const speedConversionFactor = UnitConversionHelper.speedConversion(unitSystem);

  const modalTitle = ((): string => {
    const waypointsIndexes = (props.type === "single" ? [props.waypoint] : props.waypoints).map(
      (waypoint) => waypoint.index
    );

    return `${titleTerm} ${waypointsIndexes.map((index) => index + 1).join(", ")}`;
  })();

  const homePointElevation = props.plannedArea.homePointElevation;
  const waypointElevation =
    props.type === "single" && props.usingOnlineElevation ? props.waypoint.elevation : undefined;

  const elevationDifference =
    waypointElevation !== undefined && homePointElevation !== undefined
      ? waypointElevation - homePointElevation
      : undefined;

  return (
    <Modal open={true} hideBackdrop>
      <ModalStyle width={450}>
        <MaterialButton onClick={props.onClose} size="small" className="close-modal">
          <Icon fontSize="small">close</Icon>
        </MaterialButton>
        <ModalTitle>{modalTitle}</ModalTitle>
        <Fields>
          {props.type === "single" && (
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <NumericInputColor
                disabled={true}
                label={intl.formatMessage({
                  id: "routePlanning.param.waypoint.location.legend.lat",
                })}
                unit=""
                value={props.waypoint.location.lat.toString()}
                onChange={(n) => {}}
              />
              <NumericInputColor
                disabled={true}
                label={intl.formatMessage({
                  id: "routePlanning.param.waypoint.location.legend.lng",
                })}
                unit=""
                value={props.waypoint.location.lng.toString()}
                onChange={(n) => {}}
              />
            </div>
          )}
          <CompleteNumberEditor
            initialValue={height}
            unitText={UnitConversionHelper.distanceUnit(unitSystem)}
            label={termHeight}
            minValue={usingOnlineElevation ? 0.1 : -200}
            maxValue={usingOnlineElevation ? 120 : 200}
            inComingConversionFactor={distanceConversionFactor.incoming}
            outComingConversionFactor={distanceConversionFactor.outgoing}
            onChange={(n) => setHeight(n)}
            validateValue={setIsValidHeight}
            disabled={profileRole !== undefined && profileRole > Role.operator}
            colorScheme="default"
            gridScheme="default"
          />
          {elevationDifference !== undefined && (
            <Box mt={-2} mb={1} ml={0.5}>
              <Typography variant="body2" color="textSecondary" style={{ fontStyle: "italic" }}>
                {intl.formatMessage(
                  { id: "routePlanning.param.waypoint.altitudeAboveHome" },
                  {
                    elevation: UnitConversionHelper.distanceValue(elevationDifference, unitSystem),
                    unit: UnitConversionHelper.distanceUnit(unitSystem),
                  }
                )}
              </Typography>
            </Box>
          )}
          {props.type === "single" &&
            ((): JSX.Element => {
              const waypoint = props.waypoint;
              const waypointCurveRadiusInterval = minMaxWaypointCurveRadius(
                waypoint.index,
                props.plannedArea.route!.waypoints
              );

              return (
                <CompleteNumberEditor
                  initialValue={curveRadius}
                  unitText={UnitConversionHelper.distanceUnit(unitSystem)}
                  label={termCurveRadius}
                  minValue={waypointCurveRadiusInterval.minValue}
                  maxValue={waypointCurveRadiusInterval.maxValue}
                  inComingConversionFactor={distanceConversionFactor.incoming}
                  outComingConversionFactor={distanceConversionFactor.outgoing}
                  onChange={(n) => setCurveRadius(n)}
                  validateValue={setIsValidCurveRadius}
                  disabled={profileRole !== undefined && profileRole > Role.operator}
                  colorScheme="default"
                  gridScheme="default"
                />
              );
            })()}
          <CompleteNumberEditor
            initialValue={flightSpeed}
            unitText={UnitConversionHelper.speedUnit(unitSystem)}
            label={termFlightSpeed}
            legend={termFlightSpeedLegend}
            minValue={0}
            maxValue={54}
            inComingConversionFactor={speedConversionFactor.incoming}
            outComingConversionFactor={speedConversionFactor.outgoing}
            onChange={(n) => setFlightSpeed(n)}
            validateValue={setIsValidSpeed}
            disabled={profileRole !== undefined && profileRole > Role.operator}
            colorScheme="default"
            gridScheme="default"
          />
          <CompleteNumberEditor
            initialValue={orientation}
            unitText={"º"}
            label={termOrientation}
            fieldStep={0.01}
            sliderStep={0.01}
            minValue={0}
            maxValue={360}
            inComingConversionFactor={1}
            outComingConversionFactor={1}
            onChange={(n) => setOrientation(n)}
            validateValue={setIsValidOrientation}
            disabled={profileRole !== undefined && profileRole > Role.operator}
            colorScheme="default"
            gridScheme="default"
          />
          <Grid container justify="center" spacing={1} xs={12}>
            <Grid item xs={8}>
              <>
                <Box height={6} />
                <TextField
                  size="small"
                  label={""}
                  variant="outlined"
                  value={
                    selectedAction === undefined
                      ? "-"
                      : intl.formatMessage({ id: listOfActions[selectedAction].intlTag })
                  }
                />
              </>
            </Grid>
            <Grid item xs={4}>
              <Dropdown<number>
                disabled={profileRole !== undefined && profileRole > Role.operator}
                label={intl.formatMessage({ id: "routePlanning.param.waypoint.actions" })}
                appearance="button-more"
                listItems={listOfActions.map((item, index) => {
                  let value = index;
                  let text = intl.formatMessage({ id: item.intlTag });
                  return {
                    value: value,
                    text: text,
                  };
                })}
                onSelect={(index: number) => {
                  setSelectedAction(index);
                }}
                anchor={{
                  vertical: 100,
                  horizontal: 115,
                }}
              />
            </Grid>
          </Grid>
          <Actions>
            <Button
              disabled={!validConfig || (profileRole !== undefined && profileRole > Role.operator)}
              onClick={async () => {
                if (savingEdition) {
                  return;
                }

                setSavingEdition(true);

                let plannedArea = _.cloneDeep(props.plannedArea);
                let waypoints = plannedArea.route?.waypoints;
                if (waypoints === undefined) return;

                const editedWaypointIndexes =
                  props.type === "single"
                    ? [props.waypoint.index]
                    : props.waypoints.map((waypoint) => waypoint.index);
                waypoints = waypoints.map((waypoint, index) => {
                  if (editedWaypointIndexes.includes(index)) {
                    return {
                      ...waypoint,
                      height: height!,
                      curveRadius: curveRadius!,
                      speed: flightSpeed!,
                      orientation: orientation!,
                      ...selectedActionToDroneAndReleaser(
                        selectedAction,
                        props.releasersConfiguration
                      ),
                    };
                  }

                  return waypoint;
                });

                plannedArea = plannedRouteControlUpdate(plannedArea, {
                  waypoints: waypoints,
                  homePoint: plannedArea.homePoint,
                });

                dispatch(
                  updateArea({
                    id: props.areaId,
                    projectId: props.projectId,
                    planned: plannedArea,
                  })
                );
              }}
            >
              {savingEdition ? (
                <CircularProgress size={24.5} />
              ) : (
                intl.formatMessage({ id: "action.save" })
              )}
            </Button>
          </Actions>
        </Fields>
      </ModalStyle>
    </Modal>
  );
}

export enum TypeAction {
  releaser,
  drone,
}

type SelectAction =
  | {
      type: TypeAction.drone;
      value: DroneAction;
    }
  | {
      type: TypeAction.releaser;
      value: ReleaserAction;
    }
  | undefined;

export const listOfActions: Array<{ action: SelectAction; intlTag: string }> = [
  {
    action: {
      type: TypeAction.releaser,
      value: ReleaserAction.release,
    },
    intlTag: "routePlanning.param.waypoint.action.enableEquipment",
  },
  {
    action: {
      type: TypeAction.releaser,
      value: ReleaserAction.stopRelease,
    },
    intlTag: "routePlanning.param.waypoint.action.disableEquipment",
  },
  // {
  //   action: {
  //     type: TypeAction.drone,
  //     value: DroneAction.enableLeds,
  //   },
  //   intlTag: "routePlanning.param.waypoint.action.enableEquipment",
  // },
  // {
  //   action: {
  //     type: TypeAction.drone,
  //     value: DroneAction.disableLeds,
  //   },
  //   intlTag: "routePlanning.param.waypoint.action.disableEquipment",
  // },
  {
    action: undefined,
    intlTag: "routePlanning.param.waypoint.action.none",
  },
];

export function extractActualWaypointAction(waypoint: Waypoint): number {
  let index = listOfActions.length - 1;
  const droneAction: DroneAction | undefined =
    waypoint.droneActions.length > 0 ? waypoint.droneActions[0].action : undefined;
  const releasersWithAction = Object.keys(waypoint.releaserActions);
  for (let i = 0; i < listOfActions.length; i++) {
    const compareAction = listOfActions[i];
    if (compareAction.action !== undefined) {
      if (droneAction !== undefined) {
        if (
          compareAction.action.type === TypeAction.drone &&
          compareAction.action.value === droneAction
        )
          return i;
      } else if (releasersWithAction.length > 0) {
        if (
          compareAction.action.type === TypeAction.releaser &&
          waypoint.releaserActions[releasersWithAction[0]].type === compareAction.action.value
        )
          return i;
      }
    }
  }

  return index;
}

type DroneAndReleaserActions = {
  droneActions: {
    action: DroneAction;
    params: any[];
  }[];
  releaserActions: {
    [releaserId: string]: {
      type: ReleaserAction;
      params: any[];
    };
  };
};

function selectedActionToDroneAndReleaser(
  selectedAction: number | undefined,
  releasers: ReleaserConfiguration[]
): DroneAndReleaserActions {
  const actions: DroneAndReleaserActions = { droneActions: [], releaserActions: {} };

  if (selectedAction === undefined) return actions;

  const selectedActionValue = listOfActions[selectedAction].action;
  if (selectedActionValue === undefined) {
    actions.droneActions = [];
    actions.releaserActions = {};
  } else if (selectedActionValue.type === TypeAction.drone) {
    actions.droneActions = [
      {
        action: selectedActionValue.value,
        params: [],
      },
    ];
    actions.releaserActions = {};
  } else if (selectedActionValue.type === TypeAction.releaser) {
    releasers.forEach((releaser) => {
      const id = releaser.releaserId;
      actions.releaserActions[id] = {
        type: selectedActionValue.value,
        params: [],
      };
    });
  }

  return actions;
}
