/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  RadioGroup,
  TextField,
  Button,
} from "@material-ui/core";
import Image from "../../BasicComponents/Image";
import Text from "../../BasicComponents/Text";

import { maskCNPJ, maskCPF, maskRG, maskZipcode } from "../../../../services/FormMasksService";
import { searchZipCode } from "../../../../services/SearchPlaces";
import { Country, loadLocales, getCountries } from "../../../../services/NationalityService";
import {
  isValidCNPJ,
  isValidCPF,
  isValidFormField,
  isValidPhone,
} from "../../../../services/FormValidatorsService";

import { addInfoCompany, addInfoRepresentative } from "../../../../store/actions/cartActions";
import { SystemState } from "../../../../store/reducers/systemReducer";

import { CardForm, Form, InputContainer, TitleCardForm, PhoneInput } from "./styles";

// Images
import iconSearchZipcode from "../../../../assets/icon/icon_search_zipcode.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Constants from "../../../../Constants";
interface CompanyDataProps {
  countries: Country[];
  locale: string;
  errorsValidators?: any;
  radioValue: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
}

export default (props: CompanyDataProps) => {
  const intl = useIntl();
  const localeSystem = useSelector((state: SystemState) => state.locale.localeCode);

  const dispatch = useDispatch();
  const company = useSelector((state: SystemState) => state.cart.company);
  const [countriesNationality, setCountriesNationality] = React.useState<Country[]>([]);
  const representative = useSelector((state: SystemState) => state.cart.representative);

  // terms
  const termTitleStep1 = intl.formatMessage({ id: "cart.title.step1" });
  const termTitleGeneral = intl.formatMessage({ id: "cart.title.general" });
  const termTitleAddress = intl.formatMessage({ id: "cart.title.address" });
  const termTitleAddressCompany = intl.formatMessage({ id: "cart.title.address.company" });
  const termTitleRepresentative = intl.formatMessage({ id: "cart.title.representative" });
  const termTitleCompany = intl.formatMessage({ id: "menu.title" });
  const termLabelCPF = intl.formatMessage({ id: "label.document.CPF" });
  const termLabelCNPJ = intl.formatMessage({ id: "label.document.CNPJ" });
  const termLabelID = intl.formatMessage({ id: "label.document.ID" });
  const termLabelRG = intl.formatMessage({ id: "label.document.RG" });
  const termLabelIM = intl.formatMessage({ id: "label.document.IM" });
  const termLabelNationality = intl.formatMessage({ id: "info.profile.nationality" });
  const termLabelNameCompany = intl.formatMessage({ id: "label.name.company" });
  const termLabelTradeName = intl.formatMessage({ id: "label.trade.name" });
  const termLabelAddress = intl.formatMessage({ id: "placeholder.address" });
  const termLabelCodePostal = intl.formatMessage({ id: "label.code.postal" });
  const termLabelFullname = intl.formatMessage({ id: "label.fullname" });
  const termLabelPhone = intl.formatMessage({ id: "label.phone" });
  const termLabelCellphone = intl.formatMessage({ id: "label.cellphone" });
  const termLabelSelectCountry = intl.formatMessage({ id: "label.select.country" });
  const termPlaceholderNameStreet = intl.formatMessage({ id: "placeholder.name.street" });
  const termPlaceholderStreetExamples = intl.formatMessage({ id: "placeholder.street.examples" });
  const termPlaceholderCountry = intl.formatMessage({ id: "placeholder.country" });
  const termPlaceholderState = intl.formatMessage({ id: "placeholder.state.department" });
  const termPlaceholderCity = intl.formatMessage({ id: "placeholder.city" });
  const termPlaceholderCommercialPhone = intl.formatMessage({ id: "placeholder.comercial.phone" });
  const termPlaceholderPhoneMask = intl.formatMessage({ id: "placeholder.phone.mask" });
  const termPlaceholderCPFMask = intl.formatMessage({ id: "placeholder.CPF.mask" });
  const termPlaceholderCNPJMask = intl.formatMessage({ id: "placeholder.CNPJ.mask" });
  const termPlaceholderEmail = intl.formatMessage({ id: "placeholder.email" });
  const termPlaceholderZipcode = intl.formatMessage({ id: "placeholder.zip" });

  // const [radioValue, setRadioValue] = React.useState("");
  const [dataForm, setDataForm] = React.useState({
    company: {
      name: company.name !== "" ? company.name : "",
      tradeName: company.tradeName !== "" ? company.tradeName : "",
      document: company.document !== "" ? company.document : "",
      address: {
        zipcode: company.address.zipcode !== "" ? company.address.zipcode : "",
        street: company.address.street !== "" ? company.address.street : "",
        complement: company.address.complement !== "" ? company.address.complement : "",
        country: company.address.country !== "" ? company.address.country : "",
        state: company.address.state !== "" ? company.address.state : "",
        city: company.address.city !== "" ? company.address.city : "",
      },
      commercialPhoneNumber:
        company.commercialPhoneNumber !== "" ? company.commercialPhoneNumber : "",
    },
    representative: {
      nationality: representative.nationality !== "" ? representative.nationality : "",
      name: representative.name !== "" ? representative.name : "",
      email: representative.email !== "" ? representative.email : "",
      cpf: representative.cpf !== "" ? representative.cpf : "",
      idPerson: representative.idPerson !== "" ? representative.idPerson : "",
      rg: representative.rg !== "" ? representative.rg : "",
      im: representative.im !== "" ? representative.im : "",
      address: {
        zipcode: representative.address.zipcode !== "" ? representative.address.zipcode : "",
        street: representative.address.street !== "" ? representative.address.street : "",
        complement:
          representative.address.complement !== "" ? representative.address.complement : "",
        country: representative.address.country !== "" ? representative.address.country : "",
        state: representative.address.state !== "" ? representative.address.state : "",
        city: representative.address.city !== "" ? representative.address.city : "",
      },
      landlineNumber: representative.landlineNumber !== "" ? representative.landlineNumber : "",
      cellphoneNumber: representative.cellphoneNumber !== "" ? representative.cellphoneNumber : "",
    },
  });

  const [errors, setErrors] = React.useState({
    company: {
      name: false,
      tradeName: false,
      document: false,
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
      commercialPhoneNumber: false,
    },
    representative: {
      nationality: false,
      name: false,
      email: false,
      cpf: false,
      idPerson: false,
      rg: false,
      im: false,
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
      landlineNumber: false,
      cellphoneNumber: false,
    },
  });

  const loadCountries = async () => {
    await loadLocales();
    if (localeSystem.length > 2) {
      setCountriesNationality(await getCountries(localeSystem.split("-")[0]));
    } else {
      if (localeSystem === "fr") {
        setCountriesNationality(await getCountries("en"));
        return;
      }
      setCountriesNationality(await getCountries(localeSystem));
    }
  };

  React.useEffect(() => {
    loadCountries();
  }, [localeSystem]);

  const handleChangeCompany = (e: React.ChangeEvent<any>, isAddress?: boolean) => {
    setDataForm(
      !isAddress
        ? {
            ...dataForm,
            company: {
              ...dataForm.company,
              [e.target.name]: e.target.value,
            },
          }
        : {
            ...dataForm,
            company: {
              ...dataForm.company,
              address: {
                ...dataForm.company.address,
                [e.target.name]: e.target.value,
              },
            },
          }
    );
    setErrors({
      ...errors,
      company: {
        ...errors.company,
        [e.target.name]:
          e.target.name === "document"
            ? (props.radioValue === "CPF" && !isValidCPF(e.target.value)) ||
              (props.radioValue === "CNPJ" && !isValidCNPJ(e.target.value)) ||
              (props.radioValue === "ID" && isValidFormField(e.target.name, e.target.value))
            : isValidFormField(e.target.name, e.target.value),
      },
    });
  };

  const handleChangeRepresentative = (e: React.ChangeEvent<any>, isAddress?: boolean) => {
    setDataForm(
      !isAddress
        ? {
            ...dataForm,
            representative: {
              ...dataForm.representative,
              [e.target.name]: e.target.value,
            },
          }
        : {
            ...dataForm,
            representative: {
              ...dataForm.representative,
              address: {
                ...dataForm.representative.address,
                [e.target.name]: e.target.value,
              },
            },
          }
    );
    setErrors({
      ...errors,
      representative: {
        ...errors.representative,
        [e.target.name]:
          e.target.name === "im"
            ? false
            : (e.target.name === "cpf" && !isValidCPF(e.target.value)) ||
              isValidFormField(e.target.name, e.target.value),
      },
    });
  };

  const handleChangePhoneNumber = (
    value: any,
    type: "company" | "representative",
    name?: "landlineNumber" | "cellphoneNumber"
  ) => {
    if (type === "company") {
      setDataForm({
        ...dataForm,
        company: {
          ...dataForm.company,
          commercialPhoneNumber: value,
        },
      });
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          commercialPhoneNumber: isValidPhone(String(value)),
        },
      });
    } else {
      if (name === "landlineNumber") {
        setDataForm({
          ...dataForm,
          representative: {
            ...dataForm.representative,
            landlineNumber: value,
          },
        });
        setErrors({
          ...errors,
          representative: {
            ...errors.representative,
            landlineNumber: isValidPhone(String(value)),
          },
        });
      } else {
        setDataForm({
          ...dataForm,
          representative: {
            ...dataForm.representative,
            cellphoneNumber: value,
          },
        });
        setErrors({
          ...errors,
          representative: {
            ...errors.representative,
            cellphoneNumber: isValidPhone(String(value)),
          },
        });
      }
    }
  };

  async function handleSearchZipcode(zipcode: string, type: string) {
    if (zipcode !== "") {
      if (type === "company") {
        let dataCompany = {
          ...dataForm,
          company: {
            ...dataForm.company,
          },
        };
        const result = await searchZipCode(zipcode);
        if (result.success && result.data.length > 0) {
          dataCompany = {
            ...dataForm,
            company: {
              ...dataForm.company,
              address: {
                ...dataForm.company.address,
                street: result.data[0].address,
                city: result.data[0].city,
                country: result.data[0].country,
                state: result.data[0].state,
                complement: result.data[0].district,
              },
            },
          };
          setDataForm(dataCompany);
          setErrors({
            ...errors,
            company: {
              ...errors.company,
              street: isValidFormField("street", result.data[0].address),
              city: isValidFormField("city", result.data[0].city),
              country: isValidFormField("country", result.data[0].country),
              state: isValidFormField("state", result.data[0].state),
              complement: isValidFormField("complement", result.data[0].district),
            },
          });
          dispatch(addInfoCompany(dataCompany.company));
        }
      } else {
        let dataRepresentative = {
          ...dataForm,
          representative: {
            ...dataForm.representative,
          },
        };
        const result = await searchZipCode(zipcode);
        if (result.success && result.data.length > 0) {
          dataRepresentative = {
            ...dataForm,
            representative: {
              ...dataForm.representative,
              address: {
                ...dataForm.representative.address,
                street: result.data[0].address,
                city: result.data[0].city,
                country: result.data[0].country,
                state: result.data[0].state,
                complement: result.data[0].district,
              },
            },
          };
          setDataForm(dataRepresentative);
          setErrors({
            ...errors,
            representative: {
              ...errors.representative,
              street: isValidFormField("street", result.data[0].address),
              city: isValidFormField("city", result.data[0].city),
              country: isValidFormField("country", result.data[0].country),
              state: isValidFormField("state", result.data[0].state),
              complement: isValidFormField("complement", result.data[0].district),
            },
          });
          dispatch(addInfoRepresentative(dataRepresentative.representative));
        }
      }
    }
  }

  React.useEffect(() => {
    if (props.errorsValidators) {
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          ...props.errorsValidators.company,
        },
        representative: {
          ...errors.representative,
          ...props.errorsValidators.representative,
        },
      });
    }
  }, [props.errorsValidators]);

  React.useEffect(() => {
    if (company.document) {
      const regexCPF = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(company.document);
      const regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/.test(company.document);
      if (regexCPF) {
        props.setRadioValue("CPF");
      } else if (regexCNPJ) {
        props.setRadioValue("CNPJ");
      } else {
        props.setRadioValue("ID");
      }
    }
  }, []);

  const onBlurValidCPF = () => {
    const valid = isValidCPF(dataForm.company.document);
    if (!valid) {
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          document: true,
        },
      });
    } else {
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          document: false,
        },
      });
    }
  };

  const onBlurValidCNPJ = () => {
    const valid = isValidCNPJ(dataForm.company.document);
    if (!valid) {
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          document: true,
        },
      });
    } else {
      setErrors({
        ...errors,
        company: {
          ...errors.company,
          document: false,
        },
      });
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={Constants.GOOGLE_MAPS_API_KEY}
      // language={props.locale === "fr" ? "en" : props.locale}
    >
      <Text text={termTitleStep1} appearance={"title-semibold"} color={"primary"} size={"medium"} />
      <Form>
        <Grid container spacing={3}>
          {/* Company Data */}
          <Grid item lg={6} md={6} sm={12} xs={12} className={"cardForm-container"}>
            <Text
              text={termTitleCompany}
              appearance={"title-semibold"}
              color={"primary"}
              size={"medium"}
            />
            <CardForm>
              <TitleCardForm>
                <Text text={termTitleGeneral} appearance={"info-semibold"} />
              </TitleCardForm>
              <InputContainer>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <RadioGroup
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
                      props.setRadioValue(e.target.value),
                      setDataForm({
                        ...dataForm,
                        company: { ...dataForm.company, document: "" },
                      }),
                      dispatch(addInfoCompany({ ...dataForm.company, document: "" }))
                    )}
                    value={props.radioValue}
                  >
                    <FormControlLabel
                      value={termLabelCPF}
                      label={termLabelCPF}
                      control={<Radio color={"primary"} />}
                    />
                    <FormControlLabel
                      value={termLabelCNPJ}
                      label={termLabelCNPJ}
                      control={<Radio color={"primary"} />}
                    />
                    <FormControlLabel
                      value={termLabelID}
                      label={termLabelID}
                      control={<Radio color={"primary"} />}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  {props.radioValue !== "" && (
                    <TextField
                      fullWidth
                      placeholder={
                        (props.radioValue === "CPF" && termPlaceholderCPFMask) ||
                        (props.radioValue === "CNPJ" && termPlaceholderCNPJMask) ||
                        (props.radioValue === "ID" && "") ||
                        ""
                      }
                      name={"document"}
                      value={
                        (props.radioValue === "CNPJ" && maskCNPJ(dataForm.company.document)) ||
                        (props.radioValue === "CPF" && maskCPF(dataForm.company.document)) ||
                        dataForm.company.document
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeCompany(e)}
                      onBlur={() => (
                        dispatch(addInfoCompany(dataForm.company)),
                        (props.radioValue === "CPF" && onBlurValidCPF()) ||
                          (props.radioValue === "CNPJ" && onBlurValidCNPJ())
                      )}
                      error={errors.company.document}
                    />
                  )}
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text
                    text={termLabelNameCompany}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeCompany(e)}
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.name}
                    error={errors.company.name}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text text={termLabelTradeName} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    name="tradeName"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeCompany(e)}
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.tradeName}
                    error={errors.company.tradeName}
                  />
                </Grid>
              </InputContainer>
              <TitleCardForm>
                <Text text={termTitleAddressCompany} appearance={"info-semibold"} />
              </TitleCardForm>
              <InputContainer>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Text
                    text={termPlaceholderZipcode}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={7} md={7} sm={7} xs={7}>
                  <TextField
                    fullWidth
                    name="zipcode"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeCompany(e, true)
                    }
                    onBlur={() => (
                      dispatch(addInfoCompany(dataForm.company)),
                      handleSearchZipcode(dataForm.company.address.zipcode, "company")
                    )}
                    value={maskZipcode(dataForm.company.address.zipcode)}
                    error={errors.company.zipcode}
                  />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Button
                    onClick={() => handleSearchZipcode(dataForm.company.address.zipcode, "company")}
                  >
                    <Image src={iconSearchZipcode} alt={""} />
                  </Button>
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text text={termLabelAddress} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={"companyForm-complement"}>
                  <TextField
                    fullWidth
                    name="street"
                    placeholder={termPlaceholderNameStreet}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeCompany(e, true)
                    }
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.address.street}
                    error={errors.company.street}
                  />
                  <TextField
                    fullWidth
                    name="complement"
                    placeholder={termPlaceholderStreetExamples}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeCompany(e, true)
                    }
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.address.complement}
                    error={errors.company.complement}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="companyForm-select companyForm-spacing"
                >
                  <Text
                    text={termPlaceholderCountry}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                  <FormControl variant={"outlined"} fullWidth>
                    <Select
                      fullWidth
                      key={"country"}
                      name="country"
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        handleChangeCompany(e, true)
                      }
                      onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                      value={
                        company.address.country !== ""
                          ? company.address.country
                          : dataForm.company.address.country === ""
                          ? "0"
                          : dataForm.company.address.country
                      }
                      error={
                        dataForm.company.address.country === "0" ? true : errors.company.country
                      }
                    >
                      <MenuItem value={"0"}>{termLabelSelectCountry}</MenuItem>
                      {props.countries.map((item) => (
                        <MenuItem value={item.countryName}>{item.countryName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="companyForm-select companyForm-spacing"
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text
                      text={termPlaceholderState}
                      color={"primary"}
                      appearance={"info-semibold"}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      name="state"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeCompany(e, true)
                      }
                      onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                      value={dataForm.company.address.state}
                      error={errors.company.state}
                    />
                  </Grid>
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={12} xs={12} className="companyForm-spacing">
                  <Text text={termPlaceholderCity} color={"primary"} appearance={"info-semibold"} />
                  <TextField
                    fullWidth
                    name="city"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeCompany(e, true)
                    }
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.address.city}
                    error={errors.company.city}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Text
                    text={termPlaceholderCommercialPhone}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <PhoneInput
                    international
                    placeholder={termPlaceholderPhoneMask}
                    onChange={(value: any) => handleChangePhoneNumber(value, "company")}
                    onBlur={() => dispatch(addInfoCompany(dataForm.company))}
                    value={dataForm.company.commercialPhoneNumber}
                    error={errors.company.commercialPhoneNumber}
                  />
                </Grid>
              </InputContainer>
            </CardForm>
          </Grid>
          {/* Representative Data */}
          <Grid item lg={6} md={6} sm={12} xs={12} className={"cardForm-container"}>
            <Text
              text={termTitleRepresentative}
              appearance={"title-semibold"}
              color={"primary"}
              size={"medium"}
            />
            <CardForm>
              <TitleCardForm>
                <Text text={termTitleGeneral} appearance={"info-semibold"} />
              </TitleCardForm>
              <InputContainer>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Text
                    text={termLabelNationality}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Autocomplete<Country>
                    id="nationality"
                    componentName="nationality"
                    className="companyForm-select"
                    fullWidth
                    options={countriesNationality}
                    value={countriesNationality.find(
                      (c) => c.alpha2Code === representative.nationality
                    )}
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        handleChangeRepresentative({
                          ...e,
                          target: { ...e.target, name: "nationality", value: newValue.alpha2Code },
                        });
                      }
                    }}
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    getOptionLabel={(option) => option.countryName}
                    renderOption={(option) => option.countryName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={errors.representative.nationality}
                      />
                    )}
                  />
                </Grid>
              </InputContainer>
              {representative.nationality === "BR" ? (
                <>
                  <InputContainer>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Text text={termLabelCPF} color={"primary"} appearance={"info-semibold"} />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <TextField
                        fullWidth
                        name={"cpf"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeRepresentative(e)
                        }
                        onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                        value={maskCPF(dataForm.representative.cpf)}
                        error={errors.representative.cpf}
                      />
                    </Grid>
                  </InputContainer>
                  <InputContainer>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Text text={termLabelRG} color={"primary"} appearance={"info-semibold"} />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <TextField
                        fullWidth
                        name="rg"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeRepresentative(e)
                        }
                        onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                        value={maskRG(dataForm.representative.rg)}
                        error={errors.representative.rg}
                      />
                    </Grid>
                  </InputContainer>
                  <InputContainer>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Text text={termLabelIM} color={"primary"} appearance={"info-semibold"} />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <TextField
                        fullWidth
                        name="im"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeRepresentative(e)
                        }
                        onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                        value={dataForm.representative.im}
                        error={errors.representative.im}
                      />
                    </Grid>
                  </InputContainer>
                </>
              ) : (
                <InputContainer>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Text text={termLabelID} color={"primary"} appearance={"info-semibold"} />
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <TextField
                      fullWidth
                      name={"idPerson"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeRepresentative(e)
                      }
                      onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                      value={dataForm.representative.idPerson}
                      error={errors.representative.idPerson}
                    />
                  </Grid>
                </InputContainer>
              )}
              <InputContainer>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Text text={termLabelFullname} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <TextField
                    fullWidth
                    name="name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e)
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.name}
                    error={errors.representative.name}
                  />
                </Grid>
              </InputContainer>
              <TitleCardForm>
                <Text text={termTitleAddress} appearance={"info-semibold"} />
              </TitleCardForm>
              <InputContainer>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Text
                    text={termPlaceholderZipcode}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={7} md={7} sm={7} xs={7}>
                  <TextField
                    fullWidth
                    name="zipcode"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e, true)
                    }
                    onBlur={() => (
                      dispatch(addInfoRepresentative(dataForm.representative)),
                      handleSearchZipcode(dataForm.representative.address.zipcode, "representative")
                    )}
                    value={maskZipcode(dataForm.representative.address.zipcode)}
                    error={errors.representative.zipcode}
                  />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Button
                    onClick={() =>
                      handleSearchZipcode(dataForm.representative.address.zipcode, "representative")
                    }
                  >
                    <Image src={iconSearchZipcode} alt={""} />
                  </Button>
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text text={termLabelAddress} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={"companyForm-complement"}>
                  <TextField
                    fullWidth
                    name="street"
                    placeholder={termPlaceholderNameStreet}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e, true)
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.address.street}
                    error={errors.representative.street}
                  />
                  <TextField
                    fullWidth
                    name="complement"
                    placeholder={termPlaceholderStreetExamples}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e, true)
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.address.complement}
                    error={errors.representative.complement}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="companyForm-select companyForm-spacing"
                >
                  <Text
                    text={termPlaceholderCountry}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                  <FormControl variant={"outlined"} fullWidth>
                    <Select
                      fullWidth
                      key={"country"}
                      name="country"
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        handleChangeRepresentative(e, true)
                      }
                      onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                      value={
                        representative.address.country !== ""
                          ? representative.address.country
                          : dataForm.representative.address.country === ""
                          ? "0"
                          : dataForm.representative.address.country
                      }
                      error={
                        dataForm.representative.address.country === "0"
                          ? true
                          : errors.representative.country
                      }
                    >
                      <MenuItem value={"0"}>{termLabelSelectCountry}</MenuItem>
                      {props.countries.map((item) => (
                        <MenuItem value={item.countryName}>{item.countryName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="companyForm-select companyForm-spacing"
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text
                      text={termPlaceholderState}
                      color={"primary"}
                      appearance={"info-semibold"}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      name="state"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeRepresentative(e, true)
                      }
                      onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                      value={dataForm.representative.address.state}
                      error={errors.representative.state}
                    />
                  </Grid>
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={12} xs={12} className="companyForm-spacing">
                  <Text text={termPlaceholderCity} color={"primary"} appearance={"info-semibold"} />
                  <TextField
                    fullWidth
                    name="city"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e, true)
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.address.city}
                    error={errors.representative.city}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Text text={termLabelPhone} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <PhoneInput
                    international
                    placeholder={termPlaceholderPhoneMask}
                    onChange={(value: any) =>
                      handleChangePhoneNumber(value, "representative", "landlineNumber")
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.landlineNumber}
                    error={errors.representative.landlineNumber}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Text text={termLabelCellphone} color={"primary"} appearance={"info-semibold"} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <PhoneInput
                    international
                    placeholder={termPlaceholderPhoneMask}
                    onChange={(value: any) =>
                      handleChangePhoneNumber(value, "representative", "cellphoneNumber")
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.cellphoneNumber}
                    error={errors.representative.cellphoneNumber}
                  />
                </Grid>
              </InputContainer>
              <InputContainer>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Text
                    text={termPlaceholderEmail}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    fullWidth
                    name="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeRepresentative(e)
                    }
                    onBlur={() => dispatch(addInfoRepresentative(dataForm.representative))}
                    value={dataForm.representative.email}
                    error={errors.representative.email}
                  />
                </Grid>
              </InputContainer>
            </CardForm>
          </Grid>
        </Grid>
      </Form>
    </LoadScript>
  );
};
