import {
  Box,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Paper,
  Popper,
  PopperPlacementType,
} from "@material-ui/core";
import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "../Field/styles";
import SearchImage from "../../../../assets/icon/icon_search_thick_without_circle.svg";

interface Props<T> {
  label: string;
  popperPlacementType?: PopperPlacementType;
  formatOption: (option: T) => string;
  onSelect: (address: T) => void;
  onCloseEdition?: () => void;
  renderOption: (option: T) => JSX.Element;
  searchOption: (option: string) => Array<T> | Promise<Array<T>>;
  searchOptionsBackgroundColor?: string;
}

// Solution to change the text field color
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ef7622",
    },
  },
});

export default function SearchComponent<T>(props: Props<T>) {
  const [value, setValue] = React.useState<T | undefined>(undefined);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [options, setOptions] = React.useState<Array<T>>([]);

  const [focused, setFocused] = useState<boolean>(true);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={props.formatOption}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      noOptionsText={""}
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      PopperComponent={(popperProps) => (
        <Popper {...popperProps} placement={props.popperPlacementType} />
      )}
      size="small"
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue);
          props.onSelect(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
          const search = props.searchOption(newInputValue);
          if (search instanceof Promise) {
            search.then((result) => {
              setOptions(result);
            });
          } else {
            setOptions(search);
          }
        } else {
          setOptions([]);
        }
      }}
      onBlur={props.onCloseEdition}
      PaperComponent={({ children }) => (
        <div style={{ position: "relative", paddingBottom: 15 }}>
          {options.length > 0 && (
            <Paper
              style={{
                background: props.searchOptionsBackgroundColor ?? "var(--dashboard-background)",
              }}
            >
              {children}
            </Paper>
          )}{" "}
        </div>
      )}
      renderInput={(params) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            backgroundColor="var(--dashboard-background)"
            autoFocus
            label={inputValue.length !== 0 && focused === false ? "" : props.label}
            variant="outlined"
            type={"text"}
            {...params}
            focused={focused}
            onFocus={(e) => setFocused(true)}
            onBlur={(e) => setFocused(false)}
            inputProps={{
              ...params.inputProps,
              style: { color: "black" },
            }}
            InputLabelProps={{
              shrink: focused,
              style: { color: "var(--dashboard-secondary)" },
            }}
            SelectProps={{
              style: { color: "black" },
            }}
            notSelectedPlaceholderPadding={!focused ? 30 : 0}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <div style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 2 }}>
                  <img src={SearchImage} style={{ height: 18, width: 18 }} />
                </div>
              ),
            }}
            color="secondary"
          />
        </MuiThemeProvider>
      )}
      renderOption={props.renderOption}
    />
  );
}
