import { useSelector } from "react-redux";
import FlightRoute from "../../components/flight_route";
import FlightReleaseTracks from "../../components/flight_release_tracks";
import React from "react";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { FlightInProjectTree } from "../../../../../store/reducers/projectTreeReducer";
import L from "leaflet";

export default (props: {
  projectId: string;
  areaId: string;
  flightId: string;
  setPolygonOrPolylineRef: (id: string, ref: L.Polyline | L.Polygon | null, zIndex: number) => void;
  onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
}): JSX.Element => {
  const flightStartedAt = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.startedAt
  );

  const isVisible = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.visible
  );

  if (isVisible === undefined || !isVisible || flightStartedAt === undefined) return <></>;

  const flightStartedAtInMs = flightStartedAt.getTime();
  const releaseTracksZIndex = flightStartedAtInMs;
  const flightRouteBorderZIndex = flightStartedAtInMs + 1;
  const flightRouteZIndex = flightStartedAtInMs + 2;

  return (
    <>
      <FlightReleaseTracksFromState
        {...props}
        releaseTracksBaseId={`${props.flightId}-release-tracks`}
        setReleaseTracksRef={(id, ref) =>
          props.setPolygonOrPolylineRef(id, ref, releaseTracksZIndex)
        }
        onRenderPolygonOrPolyline={(id) => props.onRenderPolygonOrPolyline(id, releaseTracksZIndex)}
      />
      <FlightRouteFromState
        {...props}
        setReleaseRouteBorderRef={(ref) =>
          props.setPolygonOrPolylineRef(
            `${props.flightId}-route-border`,
            ref,
            flightRouteBorderZIndex
          )
        }
        setReleaseRouteRef={(ref) =>
          props.setPolygonOrPolylineRef(`${props.flightId}-route`, ref, flightRouteZIndex)
        }
        onRender={() => {
          props.onRenderPolygonOrPolyline(`${props.flightId}-route`, flightRouteZIndex);
        }}
      />
    </>
  );
};

const getFlightFromState = (
  state: SystemState,
  projectId: string,
  areaId: string,
  flightId: string
): FlightInProjectTree | undefined => {
  const project = state.projectTree.projectList?.find((project) => project.id === projectId);
  if (project === undefined) return undefined;

  const area = project.areas?.find((area) => area.id === areaId);
  if (area === undefined) return undefined;

  return area.flightList?.find((flight) => flight.id === flightId);
};

const FlightRouteFromState = (props: {
  setReleaseRouteBorderRef: (ref: L.Polyline | null) => void;
  setReleaseRouteRef: (ref: L.Polyline | null) => void;
  projectId: string;
  areaId: string;
  flightId: string;
  onRender: () => void;
}): JSX.Element => {
  const flowRoute = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.realData
        ?.completeRoute
  );

  if (flowRoute === undefined) return <></>;

  return (
    <FlightRoute
      flowRoute={flowRoute}
      setReleaseRouteBorderRef={props.setReleaseRouteBorderRef}
      setReleaseRouteRef={props.setReleaseRouteRef}
      onRender={props.onRender}
    />
  );
};

const FlightReleaseTracksFromState = (props: {
  releaseTracksBaseId: string;
  setReleaseTracksRef: (id: string, ref: L.Polyline | null) => void;
  projectId: string;
  areaId: string;
  flightId: string;
  onRenderPolygonOrPolyline: (id: string) => void;
}): JSX.Element => {
  const trackWidth = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)
        ?.flightEnvironmentSnapshot.trackWidth
  );
  const flightReleaseSegments = useSelector((state: SystemState) =>
    getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.realData?.logData.map(
      (log) => log.releaseRoute
    )
  );

  if (trackWidth === undefined || flightReleaseSegments === undefined) return <></>;

  return (
    <FlightReleaseTracks
      mapScaleSource="state"
      releaseTracksBaseId={props.releaseTracksBaseId}
      setReleaseTracksRef={props.setReleaseTracksRef}
      trackWidth={trackWidth}
      flightReleaseSegments={flightReleaseSegments}
      onRenderReleaseTrack={props.onRenderPolygonOrPolyline}
    />
  );
};
