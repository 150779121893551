import React from "react";
import { Modal, Button as MaterialButton, Icon } from "@material-ui/core";
import { Area, Role, UnitSystem } from "biohub-model";
import { Actions, Fields, ModalStyle, ModalTitle } from "./styles";
import NumericInputColor from "../../../components/Atomic/BasicComponents/NumericInputColor";
import { useIntl } from "react-intl";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";

type Props = {
  area: Area;
  onClose: () => void;
};

export default function HomePointModal(props: Props) {
  const intl = useIntl();
  const noneTerm = intl.formatMessage({ id: "generic.N/A" });
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    const currentProfile = state.profile.userProfile;
    if (currentProfile !== null && currentProfile.role !== Role.external)
      return currentProfile.preferences.unitSystem;
    return UnitSystem.metric;
  });

  const termLat = intl.formatMessage({ id: "routePlanning.param.waypoint.location.legend.lat" });
  const termLon = intl.formatMessage({ id: "routePlanning.param.waypoint.location.legend.lng" });
  const termElevation = intl.formatMessage({
    id: "routePlanning.param.waypoint.location.legend.elevation",
  });
  return (
    <Modal open={true} hideBackdrop>
      <ModalStyle width={450} height={280}>
        <MaterialButton onClick={props.onClose} size="small" className="close-modal">
          <Icon fontSize="small">close</Icon>
        </MaterialButton>
        <ModalTitle>Home Point</ModalTitle>
        <Fields>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px", marginBottom: "10px" }}
          >
            <NumericInputColor
              disabled={true}
              label={termLat}
              unit=""
              value={props.area.planned.homePoint?.lat.toString() ?? noneTerm}
              onChange={(n) => {}}
            />
            <NumericInputColor
              disabled={true}
              label={termLon}
              unit=""
              value={props.area.planned.homePoint?.lng.toString() ?? noneTerm}
              onChange={(n) => {}}
            />
            <NumericInputColor
              disabled={true}
              label={termElevation}
              unit={UnitConversionHelper.distanceUnit(unitSystem)}
              value={
                props.area.planned.homePointElevation
                  ? UnitConversionHelper.distanceValue(
                      props.area.planned.homePointElevation,
                      unitSystem
                    )
                  : noneTerm
              }
              onChange={(n) => {}}
            />
            <Actions>
              <MaterialButton variant="contained" color="primary" onClick={props.onClose}>
                {intl.formatMessage({ id: "button.close", defaultMessage: "Close" })}
              </MaterialButton>
            </Actions>
          </div>
        </Fields>
      </ModalStyle>
    </Modal>
  );
}
