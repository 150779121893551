/// Utility functions for geometric computations.

import {
  Area,
  BoundingBox,
  FlightEnvironmentSnapshot,
  Location,
  PlannedRoute,
  UnitSystem,
  Waypoint,
} from "biohub-model";
import haversine from "haversine";
import * as geolib from "geolib";
import { max, min } from "lodash";

var memoizedAreaKey: Array<Location> | null = null;
var memoizedAreaValue: number | null = null;
/**
 * Calculate the area of a polygon, in hectares.
 */
export function calculateAreaHa(polygon: Array<Location>): number {
  // Avoid calculating if the value is the same as the previous.
  if (memoizedAreaKey) {
    if (memoizedAreaKey.length === polygon.length) {
      var match = true;
      for (var i = 0; i < polygon.length; i++) {
        if (
          polygon[i].lat === memoizedAreaKey[i].lat &&
          polygon[i].lng === memoizedAreaKey[i].lng
        ) {
          // Do nothing.
        } else {
          match = false;
        }
      }
      if (match) {
        return memoizedAreaValue!;
      }
    }
  }

  const result = _calculateAreaHa(polygon);

  memoizedAreaKey = polygon;
  memoizedAreaValue = result;

  return result;
}

/**
 * Take the average of two longitudes.
 */
export function averageLongitudes(l1: number, l2: number): number {
  // Longitudes can't be simply averaged normally because they wrap around at +/- 180.

  // Add an offset so that we're working with angles.
  const offsetL1 = l1 + 180;
  const offsetL2 = l2 + 180;

  // https://stackoverflow.com/a/1159336
  const diff = ((offsetL1 - offsetL2 + 540) % 360) - 180;
  const offsetAvg = (360 + offsetL2 + diff / 2) % 360;

  return offsetAvg - 180;
}

/**
 * Return a new point that's in the middle of two others.
 */
export function midpoint(p1: Location, p2: Location): Location {
  return {
    lat: (p1.lat + p2.lat) / 2,
    lng: averageLongitudes(p1.lng, p2.lng),
  };
}

export function subtractLongitudes(long1: number, long2: number): number {
  return ((long1 - long2 + 540) % 360) - 180;
}

/**
 * Returns the distance between two coordinates, always in meters.
 */
export function distanceBetween(l1: Location, l2: Location): number {
  return haversine(l1, l2, {
    unit: "meter",
    format: "{lat,lng}",
  });
}

/**
 * Adds up the total length of a path, in m or ft, depending on the unit system.
 */
export function totalPathLength(locations: Location[], unitSystem: UnitSystem): number {
  var runningTotal = 0.0;
  for (var i = 0; i < locations.length - 1; i++) {
    const loc = locations[i];
    const nextLoc = locations[i + 1];
    const distance = haversine(loc, nextLoc, {
      unit: "meter",
      format: "{lat,lng}",
    });
    runningTotal += distance;
  }
  if (unitSystem === UnitSystem.imperial) {
    return runningTotal * 3.28084;
  }
  return runningTotal;
}

export function polygonPerimeter(polygon: Location[]): number {
  if (polygon.length === 0) return 0;

  const locations = [...polygon, polygon[0]];

  var runningTotal = 0.0;
  for (var i = 0; i < locations.length - 1; i++) {
    const loc = locations[i];
    const nextLoc = locations[i + 1];
    const distance = haversine(loc, nextLoc, {
      unit: "meter",
      format: "{lat,lng}",
    });
    runningTotal += distance;
  }
  return runningTotal;
}

const earthMeanRadius = 6.371e6;
const earthMeanCircumference = earthMeanRadius * 2 * Math.PI;

/**
 * Calculates the northing (meters north, negative for south) and easting (meters
 * east, negative for west) from an origin coordinate to a target coordinate.
 */
export function northingEastingBetween(
  origin: Location,
  target: Location
): {
  northing: number;
  easting: number;
} {
  // For northing, it's only necessary to calculate the arc-length between both
  // coordinates, and then multiply by Earth's circumference. Dividing by
  // 360 converts a value in degrees to one that's expressed as a fraction
  // of the Earth's circumference.
  // (Using circumference directly instead of converting to radians first is
  // just for a cleaner formula.)
  const y = ((target.lat - origin.lat) / 360) * earthMeanCircumference;
  // Easting has the additional problem of scaling the arc-length depending
  // on the point's latitude. The scaling factor is simply the latitude's
  // cosine (convert to radians first).
  // Additionally, since easting wraps around, we use a separate formula
  // to get the difference.
  // Don't forget to divide by 360, to convert from degrees to Earth
  // circumferences.
  const x =
    (subtractLongitudes(target.lng, origin.lng) *
      Math.cos(target.lat * (Math.PI / 180)) *
      earthMeanCircumference) /
    360;
  return {
    northing: y,
    easting: x,
  };
}

/**
 * Calculates the angle in degrees clockwise from north from an origin coordinate
 * to a target coordinate.
 */
export function bearingBetween(origin: Location, target: Location): number {
  // https://www.igismap.com/formula-to-find-heading-or-heading-angle-between-two-points-latitude-longitude/

  // Most of the time, this delta will be a small number, positive or negative.
  var longitudeDelta = target.lng - origin.lng;
  if (longitudeDelta > 180) {
    // If the delta is larger than half a circle, there's a shorter path around
    // the other side of the Earth. In practice this should only catch the cases of
    // waypoints around the 0˚ meridian.
    longitudeDelta = 360 - longitudeDelta;
    longitudeDelta *= -1;
  }

  // Get values as radians
  const originLat = (origin.lat * Math.PI) / 180;
  const targetLat = (target.lat * Math.PI) / 180;
  const lngDelta = (longitudeDelta * Math.PI) / 180;

  // This is the heart of the formula. I don't really know where this comes from,
  // but there's a link for reference at the top of this function.
  const x = Math.cos(targetLat) * Math.sin(lngDelta);
  const y =
    Math.cos(originLat) * Math.sin(targetLat) -
    Math.sin(originLat) * Math.cos(targetLat) * Math.cos(lngDelta);

  // I think we calculate atan2(x,y) instead of atan2(y,x) (which is more common)
  // in order to calculate degrees from north instead of from the x-axis. Anyway,
  // take this value modulo 1 circle (with an extra circle because javascript modulo
  // doesn't wrap) to get a positive number always, then return in degrees.
  const angle = (Math.atan2(x, y) + 2 * Math.PI) % (2 * Math.PI);
  return (angle * 180) / Math.PI;
}

/**
 * Copies an array of waypoints, changing only each waypoint's heading so that it
 * points to the next. Optionally, a home point location can be informed, and the
 * last waypoint will point to it.
 */
export function fixHeadingForWaypoints(waypoints: Waypoint[], homePoint?: Location): Waypoint[] {
  // This is special handling for empty paths, but it's not for performance, because
  // we don't even expect empty paths. It's to avoid an invalid indexing later.
  if (waypoints.length === 0) {
    return [];
  }

  // We'll copy the waypoints one by one and place them here.
  const result: Waypoint[] = [];

  // Loop through all waypoints, except the last one. The last waypoint is handled
  // separately.
  for (var i = 0; i < waypoints.length - 1; i++) {
    const thisWaypoint = waypoints[i];
    const nextWaypoint = waypoints[i + 1];
    const bearing = bearingBetween(thisWaypoint.location, nextWaypoint.location);
    const newWaypoint: Waypoint = {
      ...thisWaypoint,
      orientation: bearing,
    };
    result.push(newWaypoint);
  }

  // Now do the same for the last waypoint; create a copy that points toward the home
  // point if a home point exists, or toward the first waypoint otherwise.
  const lastWaypoint = waypoints[waypoints.length - 1];
  const nextLocation = homePoint ? homePoint : waypoints[0].location;
  const newLastWaypoint: Waypoint = {
    ...lastWaypoint,
    orientation: bearingBetween(lastWaypoint.location, nextLocation),
  };
  result.push(newLastWaypoint);

  return result;
}

/**
 * Returns the center coordinate of a bounding box.
 */
export function centerOfBoundingBox(boundingBox: BoundingBox): Location {
  // For latitude, finding the center is simple (as long as we disallow bounding boxes that contain
  // a geographic pole).
  const avgLatitude = (boundingBox.north + boundingBox.south) / 2;
  // For longitude, we use a special formula that correctly handles a pair of points on each side
  // of the anti-meridian.
  const avgLongitude = averageLongitudes(boundingBox.east, boundingBox.west);

  return {
    lat: avgLatitude,
    lng: avgLongitude,
  };
}

export function boundingBoxForLocations(locations: Location[]): BoundingBox | null {
  if (locations.length === 0) return null;

  const latitudeArray = locations.map((location) => location.lat);
  const longitudeArray = locations.map((location) => location.lng);

  return {
    north: max(latitudeArray)!,
    south: min(latitudeArray)!,
    east: max(longitudeArray)!,
    west: min(longitudeArray)!,
  };
}

export function boundingBoxDiagonalSize(bounds: BoundingBox): number {
  const upRight: Location = { lat: bounds.north, lng: bounds.east };
  const downLeft: Location = { lat: bounds.south, lng: bounds.west };

  return distanceBetween(upRight, downLeft);
}

/**
 * Calculates the smallest box that contains all the informed areas.
 *
 * Either an area or a flight environment snapshot can be used because the function uses properties
 * that are common to both
 */
export function boundingBoxForAreas(polygons: Location[][]): BoundingBox | null {
  return boundingBoxForLocations(polygons.reduce((a, b) => [...a, ...b]));
}

/**
 * Obtains a coordinate that's at the specified location, but a certain distance away in a certain
 * angle. The angle must be given in radians counterclockwise from the positive x-axis.
 */
export function moveCoordinate(coord: Location, distance: number, angle: number): Location {
  // Sine of the angle obtains the percentage of the distance that we should move up (north).
  // Cosine of the angle obtains the percentage of the distance that we should move right (east).
  // Moving up or down (toward higher or lower latitude) is approx. constant anywhere.
  // Moving east or west (toward higher or lower longitude) requires a correction based on latitude.
  // This algorithm works fine as long as the distance is small relative to the world.

  const degreesToRadians = Math.PI / 180;
  const radiansToDegrees = 180 / Math.PI;

  const dLat = distance * Math.sin(angle);
  const dLng = distance * Math.cos(angle);

  // Multiply by this value. It decreases for higher latitudes because degrees of longitude are
  // smaller near the poles.
  const longitudeCorrectionFactor = 1 / Math.cos(coord.lat * degreesToRadians);

  // Converting from radians to degrees is necessary before adding the difference to the original
  // value, because latitude and longitude are always given in degrees.
  const newLat = coord.lat + (dLat / earthMeanRadius) * radiansToDegrees;
  const newLng =
    coord.lng + (dLng / earthMeanRadius) * radiansToDegrees * longitudeCorrectionFactor;

  return {
    lat: newLat,
    lng: newLng,
  };
}

/**
 * Utility function copied from BioMAPs.
 * @returns -1 if lng2 is to the west of lng1 and 1 if lng2 is to the east of lng1;
 * returns 0 if both values are the same.
 */
export function compareLongitudes(lng1: number, lng2: number): number {
  if (lng1 > lng2) {
    return -compareLongitudes(lng2, lng1);
  }

  const diff = lng2 - lng1;
  if (diff > 180) return -1;
  if (diff < 180) return 1;
  return 0;
}

/**
 * Produces a home point for an area that already has a planned path. The produced home point will
 * be located 15m away from the first waypoint, more or less away from the polygon.
 *
 * This should not be called on an area that doesn't have a planned path. The produced location in
 * that case is not guaranteed to be anywhere specific.
 */
export function chooseDefaultHomePoint(
  polygon: Location[],
  route: Waypoint[] | undefined
): Location {
  // First, get the planned path's first waypoint. If there's no such point (or no such path), just
  // return the first polygon's point.
  const plannedPath: Waypoint[] | undefined = route;
  if (!plannedPath || plannedPath.length === 0) {
    return polygon[0];
  }

  const firstWaypoint: Waypoint = plannedPath[0];

  // Now, we want to get a point that's 15m away from the polygon. "Away from the polygon" doesn't
  // have to be precise, since we're only finding a default home point anyway. What we'll do is
  // get a vector from the first waypoint to each polygon vertex, and then average the vectors and
  // invert it. Then we'll move the first waypoint in that direction to find our home point.
  const vectors: { x: number; y: number }[] = [];
  for (var i = 1; i < polygon.length; i++) {
    const vertex = polygon[i];
    // This vector is actually northing and easting (distance in meters from one coordinate to
    // another). We use northing as y and easting as x.
    const northingEasting = northingEastingBetween(firstWaypoint.location, vertex);
    vectors.push({ y: northingEasting.northing, x: northingEasting.easting });
  }
  // Average all the vectors, which is simple. The resulting vector points to the general direction
  // of the polygon. Because we don't care about the vector's magnitude, we actually sum (not average)
  // the vectors, since that's faster.
  const sumVector = vectors.reduce((prev, next) => ({ y: prev.y + next.y, x: prev.x + next.x }), {
    y: 0,
    x: 0,
  });

  // The direction is easily obtainable with atan2. The result is in radians, and is counterclockwise
  // from the positive x-axis. We also invert the vector to get the result already pointing the
  // other way, toward where the home point will be.
  const direction = Math.atan2(-sumVector.y, -sumVector.x);
  const newPoint = moveCoordinate(firstWaypoint.location, 15, direction);

  return newPoint;
}

function _calculateAreaHa(polygon: Array<Location>): number {
  const result = geolib.getAreaOfPolygon(polygon) / 10000;

  return result;
}

function isPointInsideBoundingBox(point: Location, boundingBox: BoundingBox): boolean {
  return (
    point.lat >= boundingBox.south &&
    point.lat <= boundingBox.north &&
    point.lng >= boundingBox.west &&
    point.lng <= boundingBox.east
  );
}

export function isPointsInsideBoundingBox(points: Location[], mapBounds: BoundingBox): boolean {
  if (points.length === 0) return false;
  return points.map((point) => isPointInsideBoundingBox(point, mapBounds)).reduce((a, b) => a || b);
}

export function couldMapElementBeVisibleAccordingMapBounds(
  elementBoundingBox: BoundingBox,
  mapBounds: BoundingBox
): boolean {
  return intersectionBetweenBoundingBoxes(elementBoundingBox, mapBounds) !== null;
}

function intersectionBetweenBoundingBoxes(a: BoundingBox, b: BoundingBox): BoundingBox | null {
  const north = min([a.north, b.north])!;
  const south = max([a.south, b.south])!;
  const east = min([a.east, b.east])!;
  const west = max([a.west, b.west])!;

  if (south >= north || west >= east) return null;

  return {
    north: north,
    south: south,
    east: east,
    west: west,
  };
}

export function isPointInsidePolygon(polygon: Location[], point: Location): boolean {
  const polygonPoints = polygon.map((point) => ({ lat: point.lat, lon: point.lng }));
  return geolib.isPointInPolygon({ lat: point.lat, lon: point.lng }, polygonPoints);
}

export function boundingBoxOfBoundingBoxes(boundingBoxes: BoundingBox[]): BoundingBox | undefined {
  if (boundingBoxes.length === 0) return undefined;

  return {
    north: max(boundingBoxes.map((boundingBox) => boundingBox.north))!,
    south: min(boundingBoxes.map((boundingBox) => boundingBox.south))!,
    east: max(boundingBoxes.map((boundingBox) => boundingBox.east))!,
    west: min(boundingBoxes.map((boundingBox) => boundingBox.west))!,
  };
}
