import { Button } from "@material-ui/core";
import { CompletePlannedArea } from "biohub-model";
import React from "react";
import { useIntl } from "react-intl";

type Props = {
  viewingOldPlannedArea: CompletePlannedArea;
  onSelect: (plannedArea: CompletePlannedArea) => void;
  onCancel: () => void;
};

export default function (props: Props): JSX.Element {
  const intl = useIntl();
  const { viewingOldPlannedArea } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        pointerEvents: "all",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          pointerEvents: "all",
        }}
      >
        {intl.formatMessage({ id: "map.plannedRoutes.viewingOldPlan" })}
      </div>

      <Button
        onClick={() => {
          props.onCancel();
        }}
        color="default"
        variant="contained"
      >
        {intl.formatMessage({ id: "map.planedRoute.exit" })}
      </Button>
      <Button
        onClick={() => {
          props.onSelect(viewingOldPlannedArea);
        }}
        color="primary"
        variant="contained"
      >
        {intl.formatMessage({ id: "map.plannedRoute.select" })}
      </Button>
    </div>
  );
}
