import styled from "styled-components";

type Props = {
  isVisible: boolean;
  noSpace: boolean;
  color?: "light" | "dark" | null;
  rotate: boolean;
  width?: number;
  height?: number;
  position?: "relative";
};

export const Map = styled.div.attrs((props: Props) => ({
  color: props.color,
}))`
  overflow-y: hidden;
  overflow-x: hidden;
  width: calc(100% + 13px);
  height: calc(100% - 2px);
  position: relative;

  #google_map {
    top: -5px;
    height: calc(100% + 8px) !important;
  }

  .weather {
    width: auto;
    margin: 20px;
    bottom: -3px;
    position: absolute;
    right: 30px;
    text-align: right;
    border: 1px solid black;
    background: var(--overlay-90);
    border-radius: 5px;

    @media screen and (max-width: 680px) {
      width: 100%;
      bottom: 2px;
      right: 22px;

      span {
        margin: -5px 8px;
        padding: 0;
        img {
          width: 24px;
          height: 24px;
        }
        p {
          line-height: 0.8;
        }
      }
    }

    @media screen and (max-width: 579px) {
      width: 70%;
    }

    .MuiGrid-item {
      padding: 1px;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
    }

    p {
      font-size: 12px;
      align-self: center;

      @media screen and (max-width: 1200px) {
        max-height: 16px;
        overflow-y: hidden;
      }
    }
  }

  .MuiDrawer-paperAnchorLeft {
    border: 1px solid var(--black);
    background: rgb(0 0 0 / 80%);
    color: var(--white);
    position: absolute;
    padding: 1rem;
    width: 220px;
    height: calc(100% - 32px);
    border-radius: 0;
    top: 35px;
    animation: opacity 1s ease-in-out;

    .add-project {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: var(--dashboard-secondary);
      border: 1px solid var(--white);
      box-shadow: 3px 5px 6px var(--black);
      z-index: -1;
    }

    .refresh-projects {
      position: absolute;
      right: 57px;
      bottom: 10px;
      background: black;
      border: 1px solid white;
    }
  }

  .gm-style-mtc {
    button {
      border: 1px solid var(--black) !important;
      background: var(--overlay-65) !important;
      color: var(--white) !important;
      padding: 0 12px 10px !important;
      height: 30px !important;
      font-size: 14px !important;
      border-radius: 50px !important;
      margin: 0 2px !important;
    }
    ul {
      background-color: transparent !important;
      padding: 0 !important;
      width: 136px !important;
      right: -1px !important;

      li {
        color: white !important;
        font-size: 12px !important;
        background-color: var(--overlay-90) !important;
        padding: 5px !important;

        img {
          background: white !important;
          border-radius: 2px;
        }

        label {
          margin-left: 5px !important;
        }
      }
    }
  }

  .gm-bundled-control-on-bottom {
    bottom: 75px !important;
    right: 30px !important;

    > div {
      border: 1px solid var(--black) !important;
      background: var(--overlay-65) !important;
      color: var(--white) !important;
      button {
        padding: 0 !important;
        height: 30px !important;
        width: 30px !important;
      }
      > div:first-of-type {
        background: var(--overlay-65) !important;
        width: 30px !important;
        height: 60px !important;
      }
      > div > div {
        background: var(--dashboard-hr) !important;
        width: 20px !important;
      }
      img {
        filter: invert(1);
        width: 12px !important;
        height: 12px !important;
      }
    }
  }

  button {
    color: var(--white);
  }

  @media screen and (max-width: 1440px) {
  }

  ${(props: Props) =>
    props.color === "dark" &&
    `
      .fields {
        .MuiTypography-colorTextSecondary {
          color: var(--dashboard-light-text);
        }
      }
  `}

  ${(props: Props) =>
    props.color === "light" &&
    `
    .project-info {
      border: 0;
      text-align: right;
      background: transparent;
      padding-right: 25px;
    }

    .weather {
      background: transparent;
      border: 0;
    }


    .gm-style-mtc {
      button {
        border: 1px solid var(--dashboard-menu-bg)!important;
        background: var(--dashboard-primary-bg) !important;
        color: var(--white) !important;
        padding: 0 12px 6px!important;
        height: 30px !important;
        font-size: 14px !important;
        border-radius: 50px!important;
        margin: 0 2px!important;
      }
      ul {
        background-color: transparent !important;
        padding: 0 !important;
        width: 140px !important;
        right: 0 !important;
        border-radius: 50px!important;

        li {
          color: var(--dashboard-primary-bg) !important;
          font-size: 12px !important;
          background-color: var(--white) !important;
          padding: 5px !important;
          border-radius: 50px;

          img {
            background: white !important;
            border-radius: 2px;
          }

          label {
            margin-left: 2px !important;
          }
        }
      }
    }

    .MuiDrawer-paperAnchorLeft {
      border: 1px solid var(--dashboard-primary-bg);
      background: var(--dashboard-background);
      padding: 1rem 5px;

      button .MuiButton-label,
      span > div,
      span > .material-icons {
        color: var(--dashboard-text-default);
        padding-right: 5px;
      }

      .refresh-projects,
      .add-project {
        .material-icons {
          color: var(--white) !important;
        }
      }

      * { 
        text-shadow: none; 
      }

      .add-project {
        box-shadow: none; 
      }

      .refresh-projects {
        background: var(--dashboard-separator-title);
        box-shadow: none;
        right: 52px; 
      }
    }

    .gm-bundled-control-on-bottom {
      bottom: 90px !important;
      right: 35px !important;

      > div {
        border: 0 !important;
        background: transparent !important;
        color: var(--white) !important;
        button {
          padding: 0 !important;
          height: 32px !important;
          width: 32px !important;
          border-radius: 50%!important;
          background: var(--dashboard-primary-bg)!important;
          margin: 2px!important;

          :hover, :focus {
            background: var(--dashboard-secondary)!important;
          }
        }
        > div:first-of-type {
          background: transparent!important;
          width: 32px !important;
          height: 60px !important;
          border: 0!important;
          box-shadow: none!important;
        }
        > div > div {
          background: transparent !important;
          width: 20px !important;
        }
        img {
          filter: invert(1);
          width: 12px !important;
          height: 12px !important;
          display: block;
        }
      }
    }
    
  `};
`;

// Many of these subclasses don't work anymore, because some components changed.
export const Toolbar = styled.div.attrs((props: Props) => ({
  isVisible: props.isVisible,
  color: props.color,
  positon: props.position,
}))`
  animation: opacity 1s ease-in-out;
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  top: -5px;
  bottom: -5px;

  ${(props) =>
    props.position !== undefined &&
    `
    position: ${props.position};
  `}

  @media screen and (max-width: 1600px) {
  }

  @media screen and (max-width: 1024px) {
  }

  .MuiOutlinedInput-root {
    border-radius: 25px;

    fieldset {
      border-color: var(--input-border-focus);
    }
    fieldset:hover {
      border-color: var(--input-border-focus) !important;
    }
  }

  input {
    border-radius: 25px;
    background: transparent;
    color: var(--white);
    padding: 8px 12px;
    :hover {
      border-color: var(--input-border-focus);
    }
  }

  input:-internal-autofill-selected {
    background: var(--white) !important;
  }

  .toggle-menu {
    justify-content: space-between;
    right: 0;
    top: -4px;
    bottom: -4px;
    color: var(--white);
    background: var(--dashboard-menu-bg);
    border-left: 1px solid var(--dashboard-primary-bg);
    border-top: 1px solid var(--dashboard-primary-bg);
    min-width: 100%;
    border-radius: 0;
    box-shadow: 0 3px 5px var(--overlay-90);

    :hover {
      background: var(--dashboard-secondary);
    }

    small {
      font-size: 10px;
      font-weight: 300;
      padding-right: 3px;
    }
  }

  ${(props) =>
    !props.isVisible &&
    `
    .MuiDrawer-docked {
      opacity: 0;
      z-index: -1;
    }

    .toggle-menu {
      right: 20px;
      position: absolute;
      min-width: 35px;
      z-index: 5;
      border-radius: 0;
      box-shadow: none;
      bottom: -4px;
      top: -4px;      
      background: linear-gradient(180deg, var(--dashboard-menu-bg) 80%, transparent 100%);

      small {
        display: none;
      }

    }


  `}

  ${(props: Props) =>
    props.color === "light" &&
    `
      .toggle-menu {
        background: linear-gradient(180deg, var(--dashboard-menu-bg) 80%,transparent 100%);
        border-left: 1px solid var(--dashboard-primary-bg);
      }

      .new-area {
        background: var(--dashboard-background);

        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background: var(--dashboard-hr);
          border-radius: 10px;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 10px;
          box-shadow: inset 5px 10px 12px var(--gray);
        }

        > ul > li {
          background: var(--dashboard-secondary);
          border-bottom: 1px solid var(--dashboard-box-shadow);
        }

        > ul ul {
          background: var(--icon-text);
          color: var(--dashboard-text-default);
        }

        .button-close .material-icons {
          color: var(--white)!important;
        }

        .add-area:hover, 
        .add-area:focus {
          font-weight: 600;
        }
      }
  `};

  ${(props) =>
    !props.isVisible &&
    props.color === "light" &&
    `
      .toggle-menu {
        right: -43px;    
      }

  `};
`;

export const ProjectName = styled.span.attrs((props: Props) => ({}))`
  border-bottom: 1px solid var(--dashboard-hr);
  padding-bottom: 1rem;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;

  span {
    background: transparent;
    color: var(--white);
    font-size: 14px !important;
    line-height: 1.4 !important;
    text-transform: initial;
    :hover {
      color: var(--dashboard-highlighted);
      background: transparent;
    }
  }
`;

export const ProjectList = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1;
  height: 90%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--dashboard-hr);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 5px 10px 12px var(--gray);
  }
`;

export const InternalItems = styled.span.attrs((props: Props) => ({}))`
  width: 100%;
  font-size: 12px;
  padding-left: 10px;

  div {
    height: auto;
    overflow-y: hidden;
  }
`;

export const ItemProject = styled.span.attrs((props: Props) => ({
  noSpace: props.noSpace,
}))`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;

  button {
    min-width: 100%;
    background: transparent;
    color: var(--white);
    font-size: 14px !important;
    line-height: 1.4 !important;
    text-transform: initial;
    display: flex;
    justify-content: space-between;
    padding: 0;
    :hover {
      color: var(--dashboard-highlighted);
    }
  }

  ${(props) =>
    props.noSpace &&
    `
    margin-bottom: 5px;
    button {
      text-align: left;
      b { margin: 0 10px; }
      .material-icons { margin-right: 5px; }
    }
  `}
`;

export const ResultProject = styled.span.attrs((props: Props) => ({}))`
  ul {
    padding: 10px 5px 15px 10px;
    margin: 15px 0 !important;
    background: var(--overlay-20);
    > div {
      justify-content: space-between;
    }
    b {
      margin: 0 10px 5px;
    }
    button {
      min-width: 24px;
      float: right;
    }
  }
`;

export const UnloadedProject = styled.span.attrs((props: Props) => ({}))`
  position: absolute;
  bottom: 15px;
  font-size: 12px;
  align-items: flex-end;
  display: flex;
  column-gap: 5px;
  text-shadow: 0 4px 4px black;
  letter-spacing: 0.35px;
  width: 115px;
  line-height: 1.1;
`;

export const LoadedProject = styled.span.attrs((props: Props) => ({
  isVisible: props.isVisible,
  className: "new-area",
}))`
  position: fixed;
  top: 75px;
  left: 220px;
  font-size: 12px;
  padding: 0;
  height: 100%;
  max-height: calc(100vh - 75px);
  column-gap: 5px;
  letter-spacing: 0.35px;
  width: 220px;
  line-height: 1.1;
  background: var(--dashboard-top-toolbar);
  overflow: hidden;
  overflow-y: auto;
  animation: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;

  .button-close {
    position: absolute;
    right: -13px;
    top: 5px;
    color: var(--dashboard-secondary);
  }

  ul {
    margin: 0;
  }

  li {
    background: var(--black);
    list-style-type: none;
    border-bottom: 1px solid var(--dashboard-hr);
    padding: 10px;
    font-weight: 100;
    color: var(--white);
    margin-bottom: 10px;

    b {
      display: block;
      margin: 0;
      margin-bottom: 5px;
      color: var(--white);
      text-transform: uppercase;
      font-weight: 600;
    }

    ul {
      padding-left: 10px;

      li {
        list-style-type: "- ";
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 5px;
        color: var(--dashboard-highlighted);
      }
    }
  }

  b {
    font-weight: 100;
    margin: 10px;
    display: block;
  }

  @media screen and (max-width: 1024px) {
    left: 135px;
  }

  @media screen and (max-width: 680px) {
    left: 100px;
  }

  @media screen and (max-width: 579px) {
    left: 0;
  }

  ${(props) =>
    !props.isVisible &&
    `
      opacity: 0;
      display: none;
    `}
`;

export const Loading = styled.span.attrs((props: Props) => ({}))`
  position: absolute;
  bottom: 60px;
  right: 15px;
  .MuiCircularProgress-root {
    width: 28px !important;
    height: 28px !important;
  }
  * {
    stroke: var(--dashboard-highlighted);
    color: var(--dashboard-highlighted);
  }
`;

export const ProjectInfo = styled.div.attrs((props: Props) => ({
  className: "project-info",
}))`
  border-radius: 5px;
  border: 1px solid black;
  position: absolute;
  background: var(--overlay-90);
  left: 250px;
  height: 64px;
  display: block;
  bottom: 20px;
  width: 320px;
  z-index: 10;
  padding: 0 10px;
  font-size: 90%;
  color: white;

  b,
  small {
    display: block;
    margin-top: -8px;
    width: 100%;
  }
  [role="progressbar"] {
    margin: 7px 5px 7px auto;
    float: right;
    color: var(--dashboard-secondary);
  }
  span {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 5px;
    height: 96%;
    width: auto;
    flex-flow: row wrap;
    margin-right: 10px;
    div {
      padding-bottom: 5px;
    }
  }

  .button-close {
    position: absolute;
    right: -20px;
    top: 0;
    color: var(--dashboard-secondary);
  }

  @media screen and (max-width: 680px) {
    bottom: 100px;
  }
`;

export const EditInfo = styled.div.attrs((props: Props) => ({
  height: props.height,
}))`
  white-space: pre-line;
  background: var(--overlay-90);
  border-radius: 5px;
  border: 1px solid var(--black);
  padding: 12px;
  padding: 12px;
  padding: 12px;
  width: 45%;
  ${(props) =>
    props.height !== undefined
      ? `
    height: ${props.height} px;
    `
      : `
      height: 62px;
      `}

  position: absolute;
  display: flex;
  align-items: center;
  right: 200px;
  top: 50px;
  color: var(--white);
  font-size: 10px;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.75px;
  button {
    border-radius: 25px;
    margin: 0 3px;
    min-width: 100px;
    max-height: 28px;
    align-self: center;
    padding-bottom: 10px;
    font-size: 12px;
    background: var(--dashboard-danger);

    :hover,
    :focus {
      background: var(--dashboard-hr);
    }

    &.MuiButton-containedSecondary {
      background: var(--dashboard-primary-bg);
    }

    &.MuiButton-containedPrimary {
      background: var(--dashboard-secondary);
    }
  }

  @media screen and (max-width: 1280px) {
    width: 48vw;
  }

  @media screen and (max-width: 1024px) {
    width: 55vw;
  }
`;

export const Image = styled.img.attrs((props: { angle: number }) => ({
  angle: props.angle,
}))`
  width: 32px;
  height: 32px;

  ${(props) =>
    props.angle !== undefined &&
    `
    transform: rotate(${props.angle}deg);
  `}
`;

export const Info = styled.span.attrs((props: Props) => ({}))`
  display: inline-block;
  color: var(--white);
  font-weight: 600;
  margin: 5px 5px 0;
  text-align: center;
  filter: drop-shadow(1px 1px 2px black);
}


`;
