import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import MapTilesFromProps, { MapType } from "../../components/MapTilesFromProps";

export default (): JSX.Element => {
  const mapType: MapType = useSelector((state: SystemState): MapType => {
    const stateMapType = state.projectTree.mapState.mapTypeId;
    const showingMapLabels = state.projectTree.mapState.showingMapLabels;

    if (stateMapType === "roadmap") {
      return "roadmap";
    } else {
      if (showingMapLabels) {
        return "hybrid";
      }
      return "satellite";
    }
  });
  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);

  return <MapTilesFromProps localeCode={localeCode} mapType={mapType} />;
};
