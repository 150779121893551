import { Typography } from "@material-ui/core";
import React from "react";
import { Role, UnitSystem, WeatherInfo } from "biohub-model";
import { ContainerMapInfosOnTheMap, DataInfoContainer, Image, Info } from "../../modals/styles";

import imageTemperature from "../../../../assets/icon/icon_temperature_border.svg";
import imageHumidity from "../../../../assets/icon/icon_humidity_border.svg";
import imagePressure from "../../../../assets/icon/icon_pressure.svg";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { ReactComponent as ImageSpeedWindIcon } from "../../../../assets/icon/speed_wind_icon.svg";

import UnitConversionHelper from "../../../../core/helper/UnitConversionHelper";

export default function WeatherOverlay(): JSX.Element {
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const mapRotation = useSelector((state: SystemState) => state.projectTree.mapState.rotation);

  const weatherData = useSelector((state: SystemState) => state.weather.weatherInfo);

  const elementsSize = {
    height: 25,
    width: 25,
  };

  return (
    <ContainerMapInfosOnTheMap>
      <DataInfoContainer style={{ zIndex: 2 }}>
        <Image
          src={imageTemperature}
          style={{
            filter: "invert(100%)",
            height: elementsSize.height,
            width: elementsSize.width,
          }}
        />
        <Typography style={{ color: "white" }}>
          {formatTemperature(weatherData, unitSystem)}
        </Typography>
      </DataInfoContainer>

      <DataInfoContainer style={{ zIndex: 2 }}>
        <Image
          src={imageHumidity}
          alt={"humidity"}
          style={{
            filter: "invert(100%)",
            height: elementsSize.height,
            width: elementsSize.width,
          }}
        />
        <Typography style={{ color: "white" }}>{formatHumidity(weatherData)}</Typography>
      </DataInfoContainer>
      <DataInfoContainer style={{ zIndex: 2 }}>
        <Image
          src={imagePressure}
          alt={"pressure"}
          style={{
            filter: "invert(100%)",
            height: elementsSize.height,
            width: elementsSize.width,
          }}
        />
        <Typography style={{ color: "white" }}>
          {formatPressure(weatherData, unitSystem)}
        </Typography>
      </DataInfoContainer>
      <DataInfoContainer style={{ zIndex: 2 }}>
        <div
          style={{
            width: 20,
            height: 20,
            marginLeft: 5,
            marginRight: 5,
            transform: `rotate(${getWindDirectionAngle(
              weatherData?.windDegree ?? 0,
              mapRotation
            )}deg)`,
          }}
        >
          <ImageSpeedWindIcon height={20} width={20} style={{ color: "white" }} />
        </div>
        <Typography style={{ color: "white" }}>{formatWind(weatherData, unitSystem)}</Typography>
      </DataInfoContainer>
    </ContainerMapInfosOnTheMap>
  );
}

function getWindDirectionAngle(direction: number, mapRotation: number): number {
  // The input wind direction is represented in meteorological form
  // (see: https://apollo.nvu.vsc.edu/classes/met130/notes/chapter8/wdir.html).
  // Here we convert that direction to a angular representation considering
  // that the image we need to rotate won't have rotation when the direction is 0º.

  return (direction + 180 + mapRotation) % 360;
}

function formatTemperature(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.temperature === undefined) {
    return `N/A`;
  }
  return (
    UnitConversionHelper.temperatureValue(data.temperature, unitSystem) +
    UnitConversionHelper.temperatureUnit(unitSystem)
  );
}

function formatHumidity(data: WeatherInfo | null): string {
  if (!data || data.humidity === undefined) {
    return `N/A`;
  }
  return UnitConversionHelper.humidityValue(data.humidity) + UnitConversionHelper.humidityUnit();
}

function formatPressure(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.pressure === undefined) {
    return `N/A`;
  }
  return (
    UnitConversionHelper.pressureValue(data.pressure, unitSystem) +
    UnitConversionHelper.pressureUnit(unitSystem)
  );
}

function formatWind(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.windSpeed === undefined || data.windDegree === undefined) {
    return `N/A`;
  }

  return (
    UnitConversionHelper.speedValue(data.windSpeed, unitSystem) +
    UnitConversionHelper.speedUnit(unitSystem)
  );
}
