import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";
import { SystemState } from "../../../../store/reducers/systemReducer";
import List from "../../BasicComponents/List";
import { Nav, Logo, NavItems } from "./panelstyles";

interface Props {
  open?: boolean;
  onToggleOpen?: () => void;
  retractedTop?: boolean;
}

export default (props: Props) => {
  const intl = useIntl();
  const location = useLocation();

  const menuItems = [
    {
      link: "/dashboard",
      icon: "icon-home",
      text: intl.formatMessage({ id: "menu.home" }),
      // TODO: Add state control to "active" property, with that
      // we can set the highlighted icon when a page is open, on the side bar
      active: false,
      active_icon: "icon-active-home",
    },
    {
      link: "/dashboard/map",
      icon: "icon-biomaps",
      text: intl.formatMessage({ id: "menu.biomaps" }),
      active: false,
      active_icon: "icon-active-biomaps",
    },
  ];

  const menuBottomItems = [
    // {
    //   link: "/dashboard/profile/licenses",
    //   icon: "icon-plans",
    //   text: intl.formatMessage({ id: "menu.licenses" }),
    // },
    {
      link: "/dashboard/profile",
      icon: "icon-profile",
      text: intl.formatMessage({ id: "menu.profile" }),
    },
    {
      link: "/dashboard/profile/settings",
      icon: "icon-settings",
      text: intl.formatMessage({ id: "menu.settings" }),
    },
    {
      link: "/dashboard/release-notes",
      icon: "icon-updates",
      text: intl.formatMessage({ id: "menu.updates" }),
      active: false,
      active_icon: "icon-updates",
    },
    {
      action: () => {
        props.onToggleOpen?.();
      },
      icon: props.open ? (
        <ChevronLeft style={{ marginLeft: 3 }} />
      ) : (
        <ChevronRight style={{ marginLeft: 3 }} />
      ),
      text: "",
    },
  ];

  // This is extremely hard-coded, thats because if the width is auto for the nav bar
  // The transition can not be aplyed. So, to maintain the width transition, we hard-code
  // Each width for each language
  // ! Find a better solution in the future
  ////////////////////////////////////////////////////////////////////////////////
  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);

  function navBarSizePerLocale(localeCode: BiohubLocale): Array<string> {
    switch (localeCode) {
      case "pt-BR":
        return ["220px", "75px"];
      case "en":
        return ["170px", "75px"];
      case "es":
        return ["180px", "75px"];
      case "fr":
        return ["190px", "75px"];
      default:
        return ["220px", "75px"];
    }
  }

  const localeSizes = navBarSizePerLocale(localeCode);
  ////////////////////////////////////////////////////////////////////////////////

  function enableOrDiableActiveButton(): void {
    switch (location.pathname) {
      case "/dashboard/map":
        menuItems[0].active = !menuItems[0].active;
        break;
      default:
        break;
    }
  }

  return (
    <>
      {/* // TODO: Fix dynamic sidebar size based on language */}
      <Nav open={props.open} widthOpen={"220px"} widthClose={"75px"}>
        <Logo open={props.open} localeCode={localeCode !== undefined ? localeCode : "pt-BR"} />
        {/* <NavItems> */}
        <List
          items={menuItems}
          orientation={"vertical"}
          style={{ paddingTop: 110, marginLeft: 14 }}
        />
        <List items={menuBottomItems} orientation={"vertical"} style={{ marginLeft: 14 }} />
        {/* </NavItems> */}
      </Nav>
    </>
  );
};
