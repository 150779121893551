import { CombinedState, combineReducers } from "redux";
import { localeReducer, LocaleState } from "./localeReducer";
import { loginReducer, LoginState } from "./loginReducer";
import { plansReducer, PlansState } from "./plansReducer";
import { projectTreeReducer, ProjectTreeState } from "./projectTreeReducer";
import { profileReducer, ProfileState } from "./profileReducer";
import { collectionsReducer, CollectionsState } from "./collectionsReducer";
import { dashboardReducer, DashboardState } from "./dashboardReducer";
import { weatherReducer, WeatherState } from "./weatherReducer";
import { masterReducer, MasterState } from "./masterReducer";
import { cartReducer, CartState } from "./cartReducer";
import { provisoryCartReducer, ProvisoryCartState } from "./provisoryCartReducer";

/// This file combines all reducers into a single root reducer, which we call the "System Reducer".

export type SystemState = {
  plans: PlansState;
  locale: LocaleState;
  login: LoginState;
  projectTree: ProjectTreeState;
  profile: ProfileState;
  collections: CollectionsState;
  dashboard: DashboardState;
  weather: WeatherState;
  master: MasterState;
  cart: CartState;
  provisoryCart: ProvisoryCartState;
};

export const systemReducer = combineReducers({
  plans: plansReducer,
  locale: localeReducer,
  login: loginReducer,
  projectTree: projectTreeReducer,
  profile: profileReducer,
  collections: collectionsReducer,
  dashboard: dashboardReducer,
  weather: weatherReducer,
  master: masterReducer,
  cart: cartReducer,
  provisoryCart: provisoryCartReducer,
});

export type SystemCombinedState = CombinedState<{
  plans: PlansState;
  locale: LocaleState;
  login: LoginState;
  projectTree: ProjectTreeState;
  profile: ProfileState;
  collections: CollectionsState;
  dashboard: DashboardState;
  weather: WeatherState;
  master: MasterState;
  cart: CartState;
  provisoryCart: ProvisoryCartState;
}>;
