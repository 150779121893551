import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  createMuiTheme,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Paper,
  TextField,
} from "@material-ui/core";
import { MyLocation, ZoomIn, ZoomOut } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { searchPlaces } from "../../../../../services/SearchPlaces";
import { useIntl } from "react-intl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchImage from "../../../../../assets/icon/icon_search_thick_without_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../../../components/Atomic/Inputs/SearchField";
import {
  goToUserLocationProcedures,
  moveToCoordinates,
  setUserLocation,
  zoomIn,
  zoomOut,
} from "../../../../../store/actions/projectTreeActions";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { distanceBetween } from "../../../../../core/geometricFunctions";
import MapZoom from "./MapZoom";
import { GoogleMapsSearchInfo } from "../../../../../services/Geocoder";

type Props = {
  openSearch: boolean;
  setOpenSearch: (value: boolean) => void;
};

export default function MapControls(props: Props): JSX.Element {
  const dispatch = useDispatch();

  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false);

  const { openSearch, setOpenSearch } = props;

  const classes = useStyles();

  //terms
  const intl = useIntl();
  const termSearchAddress = intl.formatMessage({ id: "placeholder.search" });

  const userPosition = useSelector((state: SystemState) => state.projectTree.userLocation);

  const mapCenter = useSelector((state: SystemState) => state.projectTree.mapState.center);

  useEffect(() => {
    if (!loadingGeolocation) return;

    if (userPosition === null || userPosition === undefined) return;

    const latitudeDiff = userPosition.lat - mapCenter.lat;
    const longitudeDiff = userPosition.lng - mapCenter.lng;

    const diff = Math.abs(latitudeDiff) + Math.abs(longitudeDiff);
    if (diff > 0.00001) return;

    const userPositionCenterDistance = distanceBetween(userPosition, mapCenter);
    if (userPositionCenterDistance < 1) {
      setLoadingGeolocation(false);
    }
  }, [loadingGeolocation, mapCenter, userPosition]);

  return (
    <ButtonGroup
      variant="outlined"
      color="primary"
      style={{ display: "flex", justifyContent: "center", height: "26px", margin: "3px" }}
    >
      <Button
        disabled={loadingGeolocation}
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
          opacity: 1,
        }}
        onClick={async (_) => {
          setLoadingGeolocation(true);
          dispatch(goToUserLocationProcedures(goToLocationZoom));
        }}
      >
        {loadingGeolocation ? (
          <CircularProgress
            style={{ display: "flex", height: "20px", width: "20px", color: "white" }}
          />
        ) : (
          <MyLocation />
        )}
      </Button>
      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          setOpenSearch(!openSearch);
        }}
      >
        <img
          src={SearchImage}
          style={{ position: "absolute", height: 20, width: 20, filter: "invert(100%)" }}
        />
      </Button>

      {openSearch && (
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 60,
          }}
        >
          <Box
            width={400}
            style={{
              paddingTop: 2,
              paddingBottom: 4,
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            <SearchField<GoogleMapsSearchInfo>
              searchOptionsBackgroundColor="white"
              label={termSearchAddress}
              popperPlacementType={"top"}
              formatOption={(option) => option.formattedAddress}
              onSelect={(option) => {
                dispatch(moveToCoordinates(option.location, goToLocationZoom));
              }}
              onCloseEdition={() => {}}
              renderOption={(option) => {
                return (
                  <Grid container alignItems="center" className={classes.body}>
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <p className={classes.textField}>{option.formattedAddress}</p>
                    </Grid>
                  </Grid>
                );
              }}
              searchOption={async (input: string): Promise<Array<GoogleMapsSearchInfo>> => {
                const result = await searchPlaces(input);
                if (result.success) {
                  return result.data;
                }
                return [];
              }}
            />
          </Box>
        </div>
      )}

      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          dispatch(zoomIn());
        }}
      >
        <ZoomIn />
      </Button>
      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          dispatch(zoomOut());
        }}
      >
        <ZoomOut />
      </Button>

      <Button
        style={{
          borderColor: "white",
          color: "white",
          opacity: 1,
          padding: "0 0 0 0",
        }}
      >
        <MapZoom />
      </Button>
    </ButtonGroup>
  );
}

// Solution to change the text field color
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ef7622",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  body: {
    color: "white",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
  },
  textField: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    color: "black",
  },
  icon: {
    color: "black",
    marginRight: 5,
  },
  grid: {
    color: "white",
    fontSize: "small",
  },
}));

const goToLocationZoom = 17;
