import React from "react";
import { useIntl } from "react-intl";
import { CustomTreeItem } from "./CustomTreeItem";

type ElementsStateProps = "empty" | "loading" | "reloading";
export type ElementsStateText = ElementsStateProps | "regular";

type Props = {
  nodeId: string;
  state: ElementsStateProps;
  type: "projects" | "areas" | "flights";
};

export default function (props: Props): JSX.Element {
  const intl = useIntl();

  const message = intl.formatMessage({
    id: `map.projectTree.${props.type}.${
      props.state === "loading" || props.state === "reloading" ? "loading" : "empty"
    }`,
  });

  return <CustomTreeItem id={props.nodeId} content={message} deleted={false} />;
}
