import { Location } from "biohub-model";
import React from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";
import WaypointsDistance from "./waypoints_distance";

export default (props: {
  mapScaleSource: "map" | "state";
  waypointsLocations: Location[];
  showDistances?: false;
  onClick?: (previousPointIndex: number, location: Location) => void;
  map: L.Map;
  polylineBaseId: string;
  setPlannedRouteBorderRef: (id: string, ref: L.Polyline | null) => void;
  setPlannedRouteRef: (id: string, ref: L.Polyline | null) => void;
  onRenderPlannedRouteBorder: (id: string) => void;
  onRenderPlannedRoute: (id: string) => void;
}): JSX.Element => {
  const waypointsDistances: JSX.Element[] = [];

  if (props.showDistances !== false) {
    for (
      let waypointIndex = 0;
      waypointIndex < props.waypointsLocations.length - 1;
      waypointIndex++
    ) {
      const waypointLocation = props.waypointsLocations[waypointIndex];
      const nextWaypointLocation = props.waypointsLocations[waypointIndex + 1];

      waypointsDistances.push(
        <WaypointsDistance
          mapScaleSource={props.mapScaleSource}
          locationA={waypointLocation}
          locationB={nextWaypointLocation}
          map={props.map}
          zIndex={3 + waypointIndex}
        />
      );
    }
  }

  const polylines: JSX.Element[] = [];
  for (let i = 0; i < props.waypointsLocations.length - 1; i++) {
    const actualWaypointLocation = props.waypointsLocations[i];
    const nextWaypointLocation = props.waypointsLocations[i + 1];
    const path = [actualWaypointLocation, nextWaypointLocation];

    const plannedRouteBorderId = `${props.polylineBaseId}-route-border-${i}`;

    /* Planned area route border */
    polylines.push(
      <Polyline
        ref={(ref) => props.setPlannedRouteBorderRef(plannedRouteBorderId, ref)}
        positions={path}
        pathOptions={{
          color: "rgba(251, 255, 204, 128)",
          weight: 5,
        }}
        eventHandlers={{
          click: (e: L.LeafletMouseEvent) => {
            const position = e.latlng;

            const location: Location = {
              lat: position.lat,
              lng: position.lng,
            };
            props.onClick?.(i, location);
          },
        }}
      />
    );

    props.onRenderPlannedRouteBorder(plannedRouteBorderId);

    const plannedRouteId = `${props.polylineBaseId}-route-${i}`;

    /* Planned area route */
    polylines.push(
      <Polyline
        ref={(ref) => props.setPlannedRouteRef(plannedRouteId, ref)}
        positions={path}
        pathOptions={{
          color: "rgba(251, 140, 0, 255)",
          weight: 2,
        }}
        eventHandlers={{
          click: (e) => {
            const position = e.latlng;

            const location: Location = {
              lat: position.lat,
              lng: position.lng,
            };
            props.onClick?.(i, location);
          },
        }}
      />
    );

    props.onRenderPlannedRoute(plannedRouteId);
  }

  return (
    <>
      {polylines}
      {/* Distance between waypoints */}
      {waypointsDistances}
    </>
  );
};
