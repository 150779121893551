import React from "react";
import Copyrights from "./Copyrights";
import MapScale from "./MapScale";
import { ContainerMapInfosOnTheMap } from "../../../modals/styles";

export default (): JSX.Element => {
  return (
    <>
      <ContainerMapInfosOnTheMap
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: 10,
          paddingLeft: 10,
          color: "white",
          fontSize: 12,
          height: 12,
          borderRadius: 0,
          backgroundColor: "#F1F3F4",
        }}
      >
        <Copyrights />
        <MapScale />
      </ContainerMapInfosOnTheMap>
    </>
  );
};
