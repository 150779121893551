import React, { FC, useEffect, useMemo, useState } from "react";
import imageClients from "../../../assets/icon/icon_clients.svg";
import imageCoverageArea from "../../../assets/icon/icon_coverage_area.svg";
import imageFlights from "../../../assets/icon/icon_released.svg";
import imageDrone from "../../../assets/icon/icon_report_drone.svg";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LineChart,
  Line,
  Legend,
} from "recharts";
import {
  Grid,
  Icon,
  Card as MaterialCard,
  CardContent,
  Box,
  CircularProgress,
  makeStyles,
  SvgIcon,
  FormControl,
  InputLabel,
  Paper,
} from "@material-ui/core";
import { DashboardSummary, Profile } from "biohub-model";
import { IntlShape, useIntl } from "react-intl";
import DashboardService from "../../../services/DashboardService";
import { Loading } from "../styles";
import DatePicker from "react-date-picker";
import { FiltersGraph } from "../styles";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { format, isValid } from "date-fns";

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
    marginRight: 10,
  },
});

function formatNumber(number: number, intl: IntlShape): string {
  return intl.formatNumber(number, {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  });
}

function loadedDashBoardCardsRight(
  summary: DashboardSummary,
  intl: IntlShape
): Array<DashboardCardProps> {
  const aTot: Array<DashboardCardProps> = [
    {
      svgPath: imageDrone,
      material: false,
      value: formatNumber(summary.droneAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.drones" }),
    },
    {
      svgPath: imageClients,
      material: false,
      value: formatNumber(summary.clientAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.clients" }),
    },
  ];

  return aTot;
}

function loadedDashBoardCardsLeft(
  summary: DashboardSummary,
  intl: IntlShape
): Array<DashboardCardProps> {
  const dashboardCards: Array<DashboardCardProps> = [
    {
      title:
        summary.yearReleaseAmount === 1
          ? intl.formatMessage({ id: "dashboard.total.operation" })
          : intl.formatMessage({ id: "dashboard.total.operations" }),
      svgPath: imageFlights,
      material: false,
      value: `${formatNumber(summary.yearReleaseAmount, intl)}`,
      legend:
        summary.yearReleaseAmount === 1
          ? intl.formatMessage({ id: "dashboard.total.releasedFlight" })
          : intl.formatMessage({ id: "dashboard.total.releasedFlights" }),
      // legend: intl.formatMessage({ id: "dashboard.total.releasedFlights" }),
    },
    {
      title:
        summary.monthReleasedArea === 1
          ? intl.formatMessage({ id: "dashboard.total.monthFlightYield" })
          : intl.formatMessage({ id: "dashboard.total.monthFlightYield" }),
      svgPath: imageCoverageArea,
      material: false,
      value: `${formatNumber(summary.monthReleasedArea, intl)}`,
      legend: `ha`,
    },
  ];
  return dashboardCards;
}

type DashboardCardProps = {
  title?: string;
  value: string;
  legend?: string;
  unit?: string;
  legendSufix?: string;
} & ({ material: true; icon: string } | { material: false; svgPath: string });

function DashboardCardsLeft(props: DashboardCardProps) {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.root} style={{ maxWidth: "20em", minWidth: "5em" }}>
      <CardContent>
        <Box height={"4em"}>
          <Grid container xs={12}>
            <Grid item xs={4}>
              {props.material ? (
                <Icon color={"primary"} fontSize={"large"}>
                  {props.icon}
                </Icon>
              ) : (
                <SvgIcon component="object" color={"primary"} fontSize={"large"}>
                  <embed type="image/svg+xml" src={props.svgPath} style={{ height: "100%" }} />
                </SvgIcon>
              )}
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <div
                style={{
                  fontSize: 20,
                  marginLeft: "1em",
                }}
              >
                {props.value}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box height={20}>
              <>
                <div style={{ fontSize: 12 }}>{props.legend}</div>
                {props.legendSufix !== undefined && (
                  <div style={{ fontSize: 10.5 }}>{props.legendSufix}</div>
                )}
              </>
            </Box>
          </Grid>
        </Box>
      </CardContent>
    </MaterialCard>
  );
}

function DashboardCardsRight(props: DashboardCardProps) {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.root} style={{ minWidth: "6em" }}>
      <CardContent>
        <Box height={100}>
          <Grid container xs={12}>
            <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>{props.title}</h4>
            <Grid item xs={4}></Grid>
            <Grid item alignContent="flex-end">
              <div
                style={{
                  fontSize: 15,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.material ? (
                    <Icon color={"primary"} style={{ fontSize: "4em" }}>
                      {props.icon}
                    </Icon>
                  ) : (
                    <SvgIcon component="object" color={"primary"} style={{ fontSize: "4em" }}>
                      <embed
                        type="image/svg+xml"
                        src={props.svgPath}
                        style={{ height: "100%", paddingBottom: 5 }}
                      />
                    </SvgIcon>
                  )}
                  {props.legendSufix !== undefined && (
                    <div style={{ fontSize: 10.5 }}>{props.legendSufix}</div>
                  )}
                  <div style={{ fontSize: "2.5em", marginTop: "0.7em", marginLeft: "0.2em" }}>
                    {props.value.toString().replace(",", ".")}{" "}
                  </div>
                  <div
                    style={{
                      marginLeft: 2,
                      marginTop: "auto",
                      marginBottom: "0.8em",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    {`${props.legend}`}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </MaterialCard>
  );
}

const generateRandomColor = () => {
  const goldenRatioConjugate = 0.618;

  let hue = Math.random();
  hue += goldenRatioConjugate;
  hue %= 1;

  const saturation = 70;
  const luminosity = 50;
  const alpha = 0.7;

  return `hsla(${Math.floor(hue * 360)}, ${saturation}%, ${luminosity}%, ${alpha})`;
};

/* Gráfico de curvas com Área e Insumo por tempo:
terá curvas para cada um dos insumos com cores independentes e uma linha totalizadora. Opção 
também de selecionar as curvas que está visualizando, seja apenas um insumo, um insumo e a 
linha total, dois insumos diferentes e etc.*/
interface GraphAreaAndInputPerPeriodProps {
  data: {
    [inputName: string]: { hectares: number; flightdate: Date }[];
  };
}

const GraphAreaAndInputPerPeriod: FC<GraphAreaAndInputPerPeriodProps> = ({ data }) => {
  const intl = useIntl();
  const [visibleCurves, setVisibleCurves] = useState<string[]>(Object.keys(data));
  const [totalLineVisible, setTotalLineVisible] = useState(true);

  const handleLegendClick = (entry: any) => {
    const { value } = entry;

    if (value === "Total") {
      setTotalLineVisible((prevTotalLineVisible) => !prevTotalLineVisible);
    } else {
      setVisibleCurves((prevVisibleCurves) => {
        return prevVisibleCurves.includes(value)
          ? prevVisibleCurves.filter((curve) => curve !== value)
          : [...prevVisibleCurves, value];
      });
    }
  };

  const colorsByInput: Record<string, string> = useMemo(() => {
    return Object.keys(data).reduce((acc, inputName) => {
      acc[inputName] = generateRandomColor();
      return acc;
    }, {} as Record<string, string>);
  }, [data]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1em",
          color: "#555",
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          border: "1px solid #ddd",
        }}
      >
        {intl.formatMessage({ id: "dashboard.area.input.time.no.data" })}
      </div>
    );
  }

  const groupedChartData = Object.keys(data).reduce<Record<string, any>>((acc, inputName) => {
    const inputData = data[inputName];

    if (Array.isArray(inputData)) {
      inputData.forEach((item) => {
        const truncatedDate = new Date(item.flightdate);
        truncatedDate.setHours(0, 0, 0, 0);
        const dateString = truncatedDate.getTime();

        if (!acc[dateString]) {
          acc[dateString] = { flightdate: truncatedDate, totalHectares: 0 };
        }

        const hectaresValue = item.hectares;

        if (!acc[dateString][`hectares_${inputName}`]) {
          acc[dateString][`hectares_${inputName}`] = 0;
        }

        acc[dateString][`hectares_${inputName}`] += isNaN(hectaresValue) ? 0 : hectaresValue;
        acc[dateString].totalHectares += isNaN(hectaresValue) ? 0 : hectaresValue;
      });
    } else {
      console.error(`Os dados para ${inputName} não são um array.`);
    }
    return acc;
  }, {});

  const chartData = Object.values(groupedChartData).sort(
    (a: { flightdate: number }, b: { flightdate: number }) => a.flightdate - b.flightdate
  );

  const maxHectaresByInput: Record<string, number> = Object.keys(data).reduce<
    Record<string, number>
  >((acc, inputName) => {
    const inputData = data[inputName];
    if (Array.isArray(inputData)) {
      const maxHectaresForInput = Math.max(...inputData.map((item) => item.hectares));
      acc[inputName] = maxHectaresForInput;
    } else {
      console.error(`Os dados para ${inputName} não são um array.`);
    }
    return acc;
  }, {});

  const maxHectares = Math.max(...Object.values(maxHectaresByInput));
  const yAxisDomain = [0, Math.ceil(maxHectares) + 1];

  const formatDate = (date: Date) => {
    const formattedDate = new Date(date);
    return isValid(formattedDate) ? format(formattedDate, "dd/MM/yy") : "";
  };
  const formatDateDayMonth = (date: Date) => format(new Date(date), "dd/MM");

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="flightdate"
            tickFormatter={(date: Date, index: number) => {
              const isFirstData = index === 0;

              if (index === chartData.length - 1) {
                return "";
              } else if (chartData.length <= 10) {
                return formatDate(date);
              } else if (isFirstData || index % 7 === 0) {
                if (chartData.length > 20) {
                  return formatDateDayMonth(date);
                } else {
                  return formatDate(date);
                }
              } else {
                return "";
              }
            }}
            interval={0}
            tickLine={true}
            fontSize={14.5}
          />

          <YAxis
            domain={yAxisDomain}
            tickFormatter={(yAxisDomain) => {
              if (yAxisDomain === 0) {
                return "";
              } else {
                return `${Math.round(yAxisDomain).toFixed(0)} ha`;
              }
            }}
          />

          <Tooltip
            labelFormatter={(label) => {
              const date = new Date(label);
              return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }}
            formatter={(value: number) => {
              return `${Number(value).toFixed(2)} ha`;
            }}
          />

          {/* Usar a Legend personalizada */}
          <Legend
            onClick={handleLegendClick}
            payload={[
              { value: "Total", id: "total", color: totalLineVisible ? "#A9A9A9" : "gray" },
              ...Object.keys(data)
                .filter(
                  (inputName) => inputName && inputName.trim() !== "" && data[inputName]?.length > 0
                )
                .map((inputName) => ({
                  value: inputName as string,
                  id: inputName,
                  color: !visibleCurves.includes(inputName) ? colorsByInput[inputName] : "gray",
                })),
            ]}
          />

          {Object.keys(data)
            .filter((inputName) => inputName)
            .map((inputName) => {
              const dataKey = `hectares_${inputName}`;
              const isVisible = visibleCurves.includes(inputName);

              return !isVisible ? (
                <Line
                  key={dataKey}
                  dataKey={dataKey}
                  name={inputName}
                  stroke={colorsByInput[inputName]}
                  dot={false}
                  strokeWidth={2.5}
                  type="monotone"
                  animationDuration={2.5}
                />
              ) : null;
            })}

          <Line
            key="total"
            dataKey="totalHectares"
            name="Total"
            stroke={totalLineVisible ? "gray" : "transparent"}
            strokeWidth={1}
            dot={false}
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

/* Gráfico com Área e Drone por tempo:
ter curvas para cada um dos drones com cores independentes e uma linha totalizadora. Opção 
também de selecionar as curvas que está visualizando, seja apenas um drone, um drone e a
linha total, dois drones diferentes e etc.*/
interface GraphAreaAndDronePerPeriodProps {
  data: {
    [droneSerialNumber: string]: { hectares: number; flightdate: Date }[];
  };
}

const GraphAreaAndDronePerPeriod: FC<GraphAreaAndDronePerPeriodProps> = ({ data = {} }) => {
  const intl = useIntl();

  const [visibleCurves, setVisibleCurves] = useState<string[]>(Object.keys(data));
  const [totalLineVisible, setTotalLineVisible] = useState(true);

  const handleLegendClick = (entry: any) => {
    const { value } = entry;

    if (value === "Total") {
      setTotalLineVisible((prevTotalLineVisible) => !prevTotalLineVisible);
    } else {
      setVisibleCurves((prevVisibleCurves) => {
        return prevVisibleCurves.includes(value)
          ? prevVisibleCurves.filter((curve) => curve !== value)
          : [...prevVisibleCurves, value];
      });
    }
  };

  const colorsByInput: { [droneSerialNumber: string]: string } = useMemo(() => {
    return Object.keys(data).reduce((acc: any, droneSerialNumber: string) => {
      acc[droneSerialNumber] = generateRandomColor();
      return acc;
    }, {});
  }, [data]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1em",
          color: "#555",
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          border: "1px solid #ddd",
        }}
      >
        {intl.formatMessage({ id: "dashboard.area.drone.time.no.data" })}
      </div>
    );
  }

  const groupedChartData = Object.keys(data).reduce<Record<string, any>>(
    (acc, droneSerialNumber) => {
      const droneData = data[droneSerialNumber];

      if (Array.isArray(droneData)) {
        droneData.forEach((item) => {
          const truncatedDate = new Date(item.flightdate);
          truncatedDate.setHours(0, 0, 0, 0);
          const dateString = truncatedDate.getTime();

          if (!acc[dateString]) {
            acc[dateString] = { flightdate: truncatedDate, totalHectares: 0 };
          }

          const hectaresValue = parseFloat(item.hectares as unknown as string);

          if (isNaN(hectaresValue)) {
            console.log(`Invalid hectares value for ${droneSerialNumber}: ${item.hectares}`);
          }

          if (!acc[dateString][`hectares_${droneSerialNumber}`]) {
            acc[dateString][`hectares_${droneSerialNumber}`] = 0;
          }

          acc[dateString][`hectares_${droneSerialNumber}`] += isNaN(hectaresValue)
            ? 0
            : hectaresValue;
          acc[dateString].totalHectares += isNaN(hectaresValue) ? 0 : hectaresValue;
        });
      } else {
        console.error(`Os dados para ${droneSerialNumber} não são um array.`);
      }
      return acc;
    },
    []
  );

  const chartData = Object.values(groupedChartData).sort(
    (a: { flightdate: number }, b: { flightdate: number }) => a.flightdate - b.flightdate
  );

  const maxHectaresByDrone: { [droneSerialNumber: string]: number } = Object.keys(data).reduce<{
    [droneSerialNumber: string]: number;
  }>((acc, droneSerialNumber) => {
    const droneData = data[droneSerialNumber];
    if (Array.isArray(droneData)) {
      const maxHectaresForDrone = Math.max(...droneData.map((item) => item.hectares));
      acc[droneSerialNumber] = maxHectaresForDrone;
    } else {
      console.error(`Os dados para ${droneSerialNumber} não são um array.`);
    }
    return acc;
  }, {});

  const maxHectares = Math.max(...Object.values(maxHectaresByDrone));
  const yAxisDomain = [0, Math.ceil(maxHectares) + 1];

  const formatDate = (date: Date) => {
    const formattedDate = new Date(date);
    return isValid(formattedDate) ? format(formattedDate, "dd/MM/yy") : "";
  };
  const formatDateDayMonth = (date: Date) => format(new Date(date), "dd/MM");

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="flightdate"
            tickFormatter={(date: Date, index: number) => {
              const isFirstData = index === 0;

              if (index === chartData.length - 1) {
                return "";
              } else if (chartData.length <= 10) {
                return formatDate(date);
              } else if (isFirstData || index % 7 === 0) {
                if (chartData.length > 20) {
                  return formatDateDayMonth(date);
                } else {
                  return formatDate(date);
                }
              } else {
                return "";
              }
            }}
            interval={0}
            tickLine={true}
            fontSize={14.5}
          />

          <YAxis
            domain={yAxisDomain}
            tickFormatter={(yAxisDomain) => {
              if (yAxisDomain === 0) {
                return "";
              } else {
                return `${Math.round(yAxisDomain).toFixed(0)} ha`;
              }
            }}
          />

          <Tooltip
            labelFormatter={(label) => {
              const date = new Date(label);
              return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }}
            formatter={(value: number) => {
              return `${Number(value).toFixed(2)} ha`;
            }}
          />

          {/* Usar a Legend personalizada */}
          <Legend
            onClick={handleLegendClick}
            payload={[
              { value: "Total", id: "total", color: totalLineVisible ? "#A9A9A9" : "gray" },
              ...Object.keys(data)
                .filter((droneSerialNumber) => data[droneSerialNumber]?.length > 0)
                .map((droneSerialNumber) => ({
                  value: droneSerialNumber as string,
                  id: droneSerialNumber,
                  color: !visibleCurves.includes(droneSerialNumber)
                    ? colorsByInput[droneSerialNumber]
                    : "gray",
                })),
            ]}
          />

          {Object.keys(data).map((droneSerialNumber) => {
            const dataKey = `hectares_${droneSerialNumber}`;
            const isVisible =
              droneSerialNumber &&
              droneSerialNumber !== "" &&
              visibleCurves.includes(droneSerialNumber);

            return !isVisible ? (
              <Line
                key={droneSerialNumber}
                dataKey={dataKey}
                name={droneSerialNumber}
                stroke={colorsByInput[droneSerialNumber]}
                dot={false}
                strokeWidth={2.5}
                type="monotone"
                animationDuration={2.5}
              />
            ) : null;
          })}

          <Line
            key="total"
            dataKey="totalHectares"
            name="Total"
            stroke={totalLineVisible ? "gray" : "transparent"}
            strokeWidth={1}
            dot={false}
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const DashboardGraphComponent = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 15);

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  useState(today);
  const [endDate, setEndDate] = useState(today);

  const intl = useIntl();

  const [totSummary, setTotSummary] = useState<DashboardSummary | undefined | null>(undefined);

  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary | undefined | null>(
    undefined
  );

  interface Entry {
    flightdate: Date;
    hectares: number;
  }

  type DashboardData = {
    [modelName: string]: Entry[];
  };

  const [areaInputData, setAreaInputData] = useState<DashboardData | null>(null);
  const [areaDroneData, setAreaDroneData] = useState<DashboardData | null>(null);

  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const initialDate = startDate;
        const finalDate = endDate;

        const totSummary = await DashboardService.getDashboardSummary(initialDate, finalDate);

        if (!totSummary.success) {
          setDashboardSummary(null);
          setTotSummary(null);
        } else {
          setTotSummary(totSummary.data);
          setDashboardSummary(totSummary.data);
        }

        const dataAreaInput = await DashboardService.readDataAreaAndInputPerTime(
          systemProfile?.directClientId as string,
          initialDate,
          finalDate
        );

        if (dataAreaInput.success) {
          if (typeof dataAreaInput.data.dashboardInfos === "object") {
            const transformedData: DashboardData = {};

            Object.entries(dataAreaInput.data.dashboardInfos).forEach(([modelName, entries]) => {
              if (Array.isArray(entries)) {
                transformedData[modelName] = entries.map((entry: any) => ({
                  flightdate: new Date(entry.flightdate),
                  hectares: entry.hectares,
                }));
              }
            });

            setAreaInputData(transformedData);
          }
        } else {
          setAreaInputData(null);
        }

        const dataAreaDrone = await DashboardService.readDataAreaAndDronePerTime(
          systemProfile?.directClientId as string,
          initialDate,
          finalDate
        );

        if (dataAreaDrone.success) {
          if (typeof dataAreaDrone.data.dashboardInfos === "object") {
            const transformedData: DashboardData = {};
            Object.entries(dataAreaDrone.data.dashboardInfos).forEach(([modelName, entries]) => {
              if (Array.isArray(entries)) {
                transformedData[modelName] = entries.map((entry: any) => ({
                  flightdate: new Date(entry.flightdate),
                  hectares: entry.hectares,
                }));
              }
            });
            setAreaDroneData(transformedData);
          }
        } else {
          setAreaDroneData(null);
        }
      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, systemProfile]);

  const dashboardCards =
    dashboardSummary !== undefined && dashboardSummary !== null
      ? loadedDashBoardCardsLeft(dashboardSummary, intl)
      : [];

  const totCards =
    totSummary !== undefined && totSummary !== null
      ? loadedDashBoardCardsRight(totSummary, intl)
      : [];

  const hrStyle = {
    backgroundColor: "rgba(29, 79, 144, 0.3)",
    height: "2.5px",
    border: "none",
    width: "100%",
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const graphContainerStyle = {
    backgroundColor: "#FFF",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    width: "100%",
    marginBottom: "20px",
  };

  const graphTitleStyle = {
    fontSize: "20px",
    marginBottom: "15px",
    color: "#333",
  };

  const columnRightStyle = {
    backgroundColor: "#FFF",
    borderRadius: "3px",
    padding: "20px",
  };

  return (
    <div>
      <div style={{ width: "100%", marginTop: 0 }}>
        <FiltersGraph>
          <Grid
            container
            spacing={2}
            style={{ padding: 0, backgroundColor: "#fff", marginBottom: "1em" }}
          >
            {/* Coluna à esquerda */}
            <Grid item xs={12} md={4}>
              <div style={{ padding: 5, backgroundColor: "#fff" }}>
                <h2 style={{ fontSize: "20px", marginBottom: "15px", color: "#333" }}>
                  {/* Totalizadores */}
                  {intl.formatMessage({ id: "dashboard.total" })}
                </h2>
                {totCards.length !== 0 ? (
                  <Grid container xs={12}>
                    {totCards.map((cardInfo, index) => {
                      const isLastCard = index === totCards.length - 1;
                      return (
                        <Grid item xs={6} sm={6} md={6} style={{ marginRight: isLastCard ? 0 : 0 }}>
                          <div style={{ height: "10em" }}>
                            {" "}
                            <DashboardCardsLeft {...cardInfo} />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Loading>
                    <CircularProgress />
                  </Loading>
                )}
              </div>
            </Grid>

            {/* Coluna à direita */}
            <Grid item xs={12} md={8}>
              <Paper style={columnRightStyle}>
                <Grid container style={{ marginBottom: 20, marginTop: 10 }}>
                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: "1em", marginTop: "5px" }}>
                    <InputLabel>{intl.formatMessage({ id: "dashboard.start.date" })}</InputLabel>
                    <FormControl variant="outlined" style={{ marginTop: 11 }}>
                      <DatePicker
                        name="initialDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: "1em", marginTop: "5px" }}>
                    <InputLabel>{intl.formatMessage({ id: "dashboard.end.date" })}</InputLabel>
                    <FormControl variant="outlined" style={{ marginTop: 11 }}>
                      <DatePicker
                        name="initialDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {dashboardCards.length !== 0 ? (
                  <Grid container>
                    {dashboardCards.map((cardInfo, index) => (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <DashboardCardsRight {...cardInfo} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Loading>
                    <CircularProgress />
                  </Loading>
                )}
              </Paper>
            </Grid>
          </Grid>
        </FiltersGraph>
      </div>

      <hr style={hrStyle} />

      <Grid
        container
        spacing={2}
        style={{ padding: 0, backgroundColor: "transparent", marginBottom: "1em" }}
      >
        <Grid item xs={12} md={6}>
          <div style={graphContainerStyle}>
            <h2 style={graphTitleStyle}>
              {intl.formatMessage({ id: "dashboard.area.drone.time" })}
            </h2>
            {areaDroneData ? (
              <GraphAreaAndDronePerPeriod data={areaDroneData} />
            ) : (
              <Loading>
                <CircularProgress />
              </Loading>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ ...graphContainerStyle }}>
            <h2 style={graphTitleStyle}>
              {intl.formatMessage({ id: "dashboard.area.input.time" })}
            </h2>
            {areaInputData ? (
              <GraphAreaAndInputPerPeriod data={areaInputData} />
            ) : (
              <Loading>
                <CircularProgress />
              </Loading>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardGraphComponent;
