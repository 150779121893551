import axios, { AxiosError, AxiosTransformer } from "axios";
import { parseISO } from "date-fns";
import Constants from "../../Constants";
import history from "../../navigation/history";
import { logOut } from "../../store/actions/loginActions";

const transformRequest: AxiosTransformer = (data) => {
  return replaceExistentUndefinedFieldsWithNull(data);
};

function replaceExistentUndefinedFieldsWithNull<T>(value: T): T | null {
  if (typeof value === "object" && value !== null) {
    if (Object.keys(value).length === 0) {
      return value;
    }

    for (const key in value) {
      value[key] = replaceExistentUndefinedFieldsWithNull<any>(value[key]);
    }

    return value;
  } else {
    return value ?? null;
  }
}

/**
 * Object used for all calls to Biohub.
 * This project is undergoing a refactor; all usages of this object directly from React components
 * should be extracted into services.
 */
const BiohubApi = axios.create({
  // This is where an instance of the server will be running locally. Change this to use a server
  // instance from somewhere else.
  baseURL: `${Constants.BACKEND_URL}`,
  transformRequest: [transformRequest].concat(axios.defaults.transformRequest ?? []),
});

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

BiohubApi.interceptors.request.use(
  async (config) => {
    if (config.headers.Authorization === null || config.headers.Authorization === undefined) {
      await delay(0);
      config.headers.Authorization = BiohubApi.defaults.headers.Authorization;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BiohubApi.interceptors.response.use(
  (response) => {
    // console.log(`Response: ${response}`);
    // This function will mutate the object.

    return {
      ...response,
      data: interceptDate(response.data),
    };
  },
  (_error) => {
    console.log(`Error: ${_error}`);

    const error = _error as AxiosError;
    if (error.response?.status === 401 && history.location.pathname !== "/login") {
      clearBiohubAuthorizationToken();
    }

    // if (error.response.status === 401) {
    //   // The service layer *must not* depend on anything outside of it. For example, we can't
    //   // simply reset a route because that would require accessing component logic. We expose
    //   // callbacks instead. Please, read the architecture page in the documentation for more info.
    //   _unauthorizedErrorCallbacks?.forEach((entry) => {
    //     entry.f.call(null);
    //   });
    // }
    return Promise.reject(error);
  }
);

function removeTimeStamp(date: any) {
  if (typeof date === "object") {
    const isoString = date.toISOString();
    if (isoString !== undefined) {
      return new Date(isoString);
    }
    return date;
  }
  return new Date(date.toString());
}
/**
 * Function to check if an object is a Date, so maybe it's a date of a string with date format.
 */
function isDate(obj: any) {
  const regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:Z|(?:(?:[+]|[-])\d{2}|(?:[+]|[-])\d{2}[:]\d{2}))?$/;
  return obj instanceof Date || (typeof obj === "string" && regex.test(obj));
}
/**
 * Function to intercept a date inside a context variable to remove the timestamp that might be applied.
 */
export function interceptDate(obj: any) {
  if (isDate(obj)) {
    return removeTimeStamp(obj);
  } else if (typeof obj === "object") {
    let k;
    for (k in obj) {
      obj[k] = interceptDate(obj[k]);
    }
  }
  return obj;
}

/**
 * A standard response type that should be used for all Biohub calls. Avoid throwing; we reserve
 * throwing errors for runtime exceptions only, not api failures.
 *
 * The failure type of always a `BiohubError`. The type of S will vary.
 *
 * Use instances of this object by checking its `success` property. The instance can be inferred to
 * be S if `success` is true, and an instance of BiohubError otherwise.
 * typescript
 * if (response.success) {
 *   // response.data is accessible and is of type S
 * } else {
 *   // response.error is accessible and is of type BiohubError
 * }
 *
 * @param S Type of the success response.
 */
export type BiohubResponse<S> =
  | {
      success: true;
      data: S;
    }
  | {
      success: false;
      error: BiohubError;
    };

/**
 * Shorthand for creating a success object.
 *
 * Note that when calling this from a return statement in a function with return type
 * BiohubResponse<S>, the generic type S can be inferred and may be ommited:
 * typescript
 * return newBiohubSuccess(...); // <- Generic type can be inferred from the function's return type.
 *
 */
export function newBiohubSuccess<S>(value: S): BiohubResponse<S> {
  return {
    success: true,
    data: value,
  };
}

/**
 * Shorthand for creating a failure object.
 *
 * Note that when calling this from a return statement in a function with return type
 * BiohubResponse<S>, the generic type S can be inferred and may be ommited.
 * typescript
 * return newBiohubFailure(...); // <- Generic type can be inferred from function's return type.
 *
 *
 * Note also that `extraData` is not validated. That would be possible, but would require adding types
 * for every single possible error, which is probably more trouble than it's worth.
 */
export function newBiohubFailure<S>(
  errorCode: BiohubErrorCode,
  extraData: Record<string, any> = {}
): BiohubResponse<S> {
  return {
    success: false,
    error: {
      ...extraData,
      errorCode: errorCode,
    },
  };
}

/**
 * Attemps to extract an error message from a failed Axios request. This will try different ways of
 * looking for a message in the error object. These messages are not meant to be shown directly to
 * the end user. The pages should inspect the message, check if it's a known error, and display
 * localized error messages accordingly.
 * @param error An object thrown while making an Axios request. Possible values include
 * "INTERNAL SERVER ERROR", "VALIDATION ERROR: <...>".
 */
export function extractBiohubError(_error: any): BiohubError {
  // Case 1: The error itself is a string.
  // Might happen if something other than axios threw an error.
  if (typeof _error === "string") {
    return {
      errorCode: BiohubErrorCode.UNKNOWN_ERROR,
      message: `${_error}`,
    };
  }
  const error = _error as AxiosError;
  // Case 2: Response is an internal server error.
  if (`${error.response?.status}` === "500") {
    // Return just the code for internal server error, and no extra information.
    return {
      errorCode: BiohubErrorCode.INTERNAL_SERVER_ERROR,
    };
  }

  // Case 3: Response is a validation error
  if (error.response?.data?.error && typeof error.response?.data?.error === "string") {
    const errorName: string = error.response?.data?.error ?? "";
    if (errorName.toLowerCase().includes("validation_error")) {
      // Get the reason from an additional field
      const reason = `${error.response?.data?.reason}`;
      return {
        errorCode: BiohubErrorCode.VALIDATION_ERROR,
        reason: reason,
      };
    }

    // Sms validation error
    if (
      `${error.response?.status}` === "400" &&
      errorName.toLowerCase().includes("invalid_sms_code")
    ) {
      return {
        errorCode: BiohubErrorCode.SMS_VALIDATION_ERROR,
      };
    }
  }

  if (error.response?.data?.error === "EMAIL_ALREADY_REGISTERED") {
    return {
      errorCode: BiohubErrorCode.PREREGISTER_EMAIL_ALREADY_REGISTERED,
    };
  }

  if (error.response?.data?.error === "PHONE_NUMBER_ALREADY_REGISTERED") {
    return {
      errorCode: BiohubErrorCode.PREREGISTER_PHONE_NUMBER_ALREADY_REGISTERED,
    };
  }

  if (error.response?.data?.error === "UNRECOGNIZED_CLIENT") {
    return {
      errorCode: BiohubErrorCode.LOGIN_UNRECOGNIZED_CLIENT,
    };
  }
  if (error.response?.data?.error === "WRONG_EMAIL_OR_PASSWORD") {
    return {
      errorCode: BiohubErrorCode.LOGIN_INCORRECT_USER_PASSWORD,
    };
  }

  return {
    errorCode: BiohubErrorCode.UNKNOWN_ERROR,
  };
}

/**
 * Version of `extractBiohubError` that returns a `BiohubResponse` instead of a `BiohubError`.
 * This is meant to be used inside services.
 */
export function extractBiohubErrorResponse<S>(error: any): BiohubResponse<S> {
  return {
    success: false,
    error: extractBiohubError(error),
  };
}

/**
 * Standard return type for api calls that don't have a return. This represents a success with no
 * additional information.
 */
export type BiohubUnit = null;

/**
 * Instance of the unit type.
 */
export const biohubUnit: BiohubUnit = null;

/**
 * Standard Biohub error received from the backend.
 * All errors received from the backend have an error code, and optionally some extra data.
 *
 * This type defines "errorCode" as an enum instead of a string for safer comparison.
 */
export type BiohubError = {
  errorCode: BiohubErrorCode;
  /** Only present sometimes, like when the `errorCode` is `UNKNOWN_ERROR`. */
  message?: string;
  /** Only present when `errorCode` is `VALIDATION_ERROR`. */
  reason?: string;
};

/**
 * Possible errors returned from the backend code. Additional values should be added as necessary.
 *
 * These errors don't have to correspond directly to backend errors. New errors can be declared for
 * problems that may happen locally, for example, or when a successful response returned unexpected
 * data.
 *
 * The integer values for each error are not important, and they're only used to keep the values
 * constant as new ones are added. When adding items, make sure not to repeat numbers, and do not
 * change the values of existing items.
 */
export enum BiohubErrorCode {
  /** The feature has not yet been implemented (client-side). */
  UNIMPLEMENTED = -1,
  /**
   * We could not determine the reason for this failure. The page should display a generic error
   * message.
   * It is recommended to include an additional field named "message" with any additional information.
   * That message is not to be shown to the end user.
   */
  UNKNOWN_ERROR = 1,
  /**
   * An error occurred in the server. This is an error in the backend code. If this happens, a
   * backend developer should be informed.
   */
  INTERNAL_SERVER_ERROR = 2,
  /**
   * Indicates that the request was done incorrectly (this is an error in the code). An additional
   * field "reason" contains a string explaining what went wrong. The "reason" should not be
   * displayed to the user.
   */
  VALIDATION_ERROR = 3,
  /** Either username or password are incorrect. */
  LOGIN_INCORRECT_USER_PASSWORD = 4,
  PREREGISTER_EMAIL_ALREADY_REGISTERED = 5,
  PREREGISTER_PHONE_NUMBER_ALREADY_REGISTERED = 6,
  LOGIN_TOKEN_NOT_RECEIVED = 7,
  /** The server did not recognize the value passed in the "client" field. */
  LOGIN_UNRECOGNIZED_CLIENT = 8,
  /** The user tried to open a project that doesn't exist, or belongs to another user. */
  PROJECT_NOT_FOUND = 9,
  /** Elevation request failed because the request was not formatted correctly. */
  ELEVATION_INVALID_REQUEST = 10,
  /** Elevation request failed because of daily limit */
  ELEVATION_OVER_DAILY_LIMIT = 11,
  /** Elevation request failed because of query limit. */
  ELEVATION_OVER_QUERY_LIMIT = 12,
  /** Elevation request failed for authentication reasons (likely the api key was wrong). */
  ELEVATION_REQUEST_DENIED = 13,
  /** Elevation request failed for an unknown reason */
  ELEVATION_UNKNOWN_ERROR = 14,

  GEOCODER_UNKNOWN_ERROR = 15,
  /** Sms validation error */
  SMS_VALIDATION_ERROR = 16,

  /** Kml file processing error */
  KML_ROUTE_OUT_OF_AREA_BOUNDS = 17,
  KML_IMPORT_INVALID_SELECTED_AREA_BOUNDS = 18,

  TO_BIG_AREA = 19,
}

/* ----------------------------------------------------------------------
 * The following functions expose setters and callbacks to allow the service
 * layer to not depend on anything outside of it. The store should use
 * these functions to control the behavior of the service layer. Do not add
 * dependencies from here to other parts of the application. Please read the
 * architecture page in the documentation for more details.
 -------------------------------------------------------------------------*/

/**
 * Sets an authorization token to be used on all subsequent calls.
 *
 * Calling this when a token is already set will replace the previous token.
 * */
export function setBiohubAuthorizationToken(token: string): void {
  BiohubApi.defaults.headers.Authorization = token;
}

/**
 * Clears the authorization token.
 */
export function clearBiohubAuthorizationToken(): void {
  BiohubApi.defaults.headers.Authorization = undefined;
}

/**
 * (private) list of callbacks that should be invoked when
 */
let _unauthorizedErrorCallbacks: {
  id: number;
  f: () => void;
}[] = [];

/** A counter for the unauthorized error callback list. */
let _unauthorizedErrorCallbacksIncrement: number = 1;

/**
 * Register a callback which will be invoked any time a request fails because of an authorization
 * problem (that is, the authorization token is not valid). No action is taken by default, but it is
 * recommended to register at least one callback that clears the authorization token.
 *
 * @param f The callback that will be called when any unauthorized error happens. Note the `this`
 * object will be null inside the callback, so don't use it.
 * @returns An id that can be used to unregister this callback.
 */
export function registerUnauthorizedErrorCallback(f: () => void): number {
  _unauthorizedErrorCallbacks.push({
    id: _unauthorizedErrorCallbacksIncrement,
    f,
  });
  _unauthorizedErrorCallbacksIncrement++;
  return _unauthorizedErrorCallbacksIncrement - 1;
}

/**
 * Unregister an unauthorized error callback.
 *
 * @param string The id that you got when registering this callback.
 * @returns Whether the callback existed (but no error is thrown in either case).
 */
export function unregisterUnauthorizedErrorCallback(id: number): boolean {
  let index = _unauthorizedErrorCallbacks.findIndex((element) => element.id === id);
  if (index > -1) {
    // Remove one item from the array at the specified index.
    _unauthorizedErrorCallbacks.splice(index, 1);
    return true;
  } else {
    return false;
  }
}

export default BiohubApi;
