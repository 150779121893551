import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { Role, UnitSystem } from "biohub-model";
import UnitConversionHelper from "../../../../../core/helper/UnitConversionHelper";

export default (): JSX.Element => {
  const visibleRegionInMeters = useSelector(
    (state: SystemState) => state.projectTree.mapState.boundingBoxDiagonalSize,
    (a, b) => a.toFixed(3) === b.toFixed(3)
  );
  const visibleRegionInPixels = useSelector(
    (state: SystemState) => state.projectTree.mapState.visibleRegionDiagonalInPixels,
    (a, b) => a.toFixed(3) === b.toFixed(3)
  );

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  let scaleWidth = 50;

  let screenProportion = scaleWidth / visibleRegionInPixels;

  const mapScaleInfo = getMapScale(visibleRegionInMeters, screenProportion, unitSystem);

  const mapScale = mapScaleInfo.formattedNumber;

  scaleWidth = scaleWidth * mapScaleInfo.roundCorrectionFactor;
  screenProportion = screenProportion * mapScaleInfo.roundCorrectionFactor;

  const scaleRuleWidth = 1;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        color: "black",
      }}
    >
      {mapScale}

      <div
        style={{
          height: 5,
          width: scaleRuleWidth,
          backgroundColor: "black",
          borderRadius: 2,
          marginLeft: 5,
        }}
      />
      <div
        style={{
          height: scaleRuleWidth,
          width: visibleRegionInPixels * screenProportion - 2 * scaleRuleWidth,
          backgroundColor: "black",
        }}
      />
      <div
        style={{
          height: 5,
          width: scaleRuleWidth,
          backgroundColor: "black",
          borderRadius: 2,
        }}
      />
    </div>
  );
};

const getMapScale = (
  visibleRegionInMeters: number,
  screenProportion: number,
  unitSystem: UnitSystem
): {
  formattedNumber: string;
  roundCorrectionFactor: number;
} => {
  const sizeInMeters = visibleRegionInMeters * screenProportion;

  if (unitSystem === UnitSystem.metric) {
    if (sizeInMeters < 1000) {
      const roundAndCorrectionFactor = getRoundValueAndCorrectionFactor(sizeInMeters);

      return {
        formattedNumber: roundAndCorrectionFactor.roundedValue.toFixed(0) + "m",
        roundCorrectionFactor: roundAndCorrectionFactor.correctionFactor,
      };
    } else {
      const roundAndCorrectionFactor = getRoundValueAndCorrectionFactor(sizeInMeters / 1000);

      return {
        formattedNumber: roundAndCorrectionFactor.roundedValue.toFixed(0) + "km",
        roundCorrectionFactor: roundAndCorrectionFactor.correctionFactor,
      };
    }
  } else {
    const sizeInFt = sizeInMeters * 3.28084;
    const ftInAMile = 5280;

    if (sizeInFt > ftInAMile) {
      const roundAndCorrectionFactor = getRoundValueAndCorrectionFactor(sizeInFt / ftInAMile);

      return {
        formattedNumber: roundAndCorrectionFactor.roundedValue.toFixed(0) + "mi",
        roundCorrectionFactor: roundAndCorrectionFactor.correctionFactor,
      };
    } else {
      const roundAndCorrectionFactor = getRoundValueAndCorrectionFactor(sizeInFt);

      return {
        formattedNumber: roundAndCorrectionFactor.roundedValue.toFixed(0) + "ft",
        roundCorrectionFactor: roundAndCorrectionFactor.correctionFactor,
      };
    }
  }
};

const getRoundValueAndCorrectionFactor = (
  n: number
): {
  roundedValue: number;
  correctionFactor: number;
} => {
  const roundValue = Math.round(n);

  return {
    roundedValue: roundValue,
    correctionFactor: roundValue / n,
  };
};

const numberToNumberOfDecimalPlaces = (n: number, decimalPlaces: number): string => {
  const toString = n.toString();

  let fields = toString.split(".");
  let result = fields.splice(0, 1)[0];

  if (fields.length > 0 && decimalPlaces > 0) {
    fields = fields[0].split("");

    result = result + ".";

    let count = 0;
    while (fields.length > 0 && count < decimalPlaces) {
      result = result + fields.splice(0, 1)[0];
      count++;
    }
  }

  return result;
};
