/**
 * Português Brasileiro (pt-BR)
 */
const messagesMap: { [key: string]: string } = {
  "app.title": "bioHUB",
  // --- Página de login ---
  "login.title.login": "Logar",
  "login.form.email": "Email",
  "login.form.password": "Senha",
  "login.resetPassword": "Esqueci minha senha",
  "login.resetPassword.success":
    "Processo de recuperação de senha feito com sucesso, verifique sua caixa de email para progressuir.",
  "login.resetPassword.error":
    "Erro ao recuperar sua conta. Por favor, confira seus dados ou tente novamente em alguns instantes.",

  "profile.login.currentPassword": "Senha atual",
  "profile.login.newPassword": "Nova senha",
  "profile.login.confirmNewPassword": "Confirmar nova senha",

  "profile.registrationStatus.name": "Situação cadastral",
  "profile.registrationStatus.completed": "Completa",
  "profile.registrationStatus.pending": "Pendente",

  // --- Overlays do mapa ---
  "map.overlays.weather.temperature": "Temperatura",
  "map.overlays.weather.pressure": "Pressão",
  "map.overlays.weather.humidity": "Umidade",
  "map.overlays.weather.wind": "Vento",
  "map.overlays.weather.windSpeed": "Velocidade do vento",
  "map.overlays.weather.windDirection": "Direção do vento",
  "map.overlays.weather.percentageClouds": "Nuvens",
  "map.overlays.weather.searchTime": "Horário da busca",
  "map.overlays.weather.visibility": "Visibilidade",
  "map.overlays.weather.sunrise": "Nascer do sol",
  "map.overlays.weather.sunset": "Por-do-sol",
  "map.overlays.weather.timezone": "Fuso-horário",
  "map.overlays.weather.optimizePolygon": "Otimizar Polígono",
  "map.overlays.weather.optimizePolygonTooltip": `Otimiza a geração dos polígonos importados vias Kml / Shp. 
  A OTIMIZAÇÃO PODE NÃO GERAR OS RESULTADOS ESPERADOS, PODENDO CAUSAR DISTORÇÕES NOS POLÍGONOS GERADOS`,
  // --- Textos a serem usados de maneira geral no app (por exemplo, em botões) ---
  "generic.ok": "OK",
  "generic.cancel": "Cancelar",
  "generic.close": "Fechar",
  "generic.yes": "Sim",
  "generic.no": "Não",
  "generic.next": "Próximo",
  "generic.previous": "Anterior",
  "generic.continue": "Continuar",
  "generic.closeConfirmation.title": "Confirmação",
  "generic.closeConfirmation.discardChangesAndClose": "Descartar alterações e fechar?",
  "generic.N/A": "N/A",
  "generic.copy": "Cópia",

  "general.year": "ano",
  "general.month": "mês",
  "general.hectares": "hectares",

  // --- Textos a serem usados em erros ---
  "generic.error.unknown": "Ocorreu um erro.",
  "info.termError": "Sua mensagem não pôde ser enviada no momento",

  //-- Main page info --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Preciso por Natureza",
  "info.title": "O primeiro e único HUB \nde tecnologias para \nControle Biológico de Precisão",
  "info.description": "Em Breve!",
  "info.newsletter": "Assine nossa Newsletter",
  "info.about": "bioHUB por NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Todos os direitos reservados.",
  "info.address":
    "Parque Tecnológico de São José dos Campos - CEI - Sala 207 Estrada Doutor Altino Bondensan, 500 Distrito Eugênio de Mello São José dos Campos - SP - 12.247-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "+55 (41) 98891-4324",
  "info.terms":
    "Ao aceitar o presente termo, o usuário consente e concorda que a empresa NCB, doravante denominada Controlador, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e garantias estabelecidas pela Lei nº 13.709.",
  "info.summary": "Resumo",
  "info.register.information": "Informações de registro",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Informações do representante legal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Informações do plano",
  "info.amount": "Quantidade",
  "info.banner.plan.title": "Planos e preços",
  "info.plan": "Plano",
  "info.plan.name.bioHUB": "bioHUB",
  "info.plan.name.bioMAPS": "bioMAPS",
  "info.plan.name.bioBOT": "bioBOT",
  "info.plan.subTitle.bioMAPS": "Aplicativo de planejamento de missão de voo",
  "info.plan.description.bioMAPS":
    "Planejador de missão para DRONES com foco em controle biológico de precisão. Este produto requer uma assinatura ativa do bioHUB para funcionar.",
  "info.plan.type.monthly": "Mensal",
  "info.plan.type.yearly": "Anual",
  "info.plan.perDevice": "por aparelho",
  "info.plan.ofDiscount": "de desconto",
  "info.mode": "Tipo",
  "info.price": "Preço",
  "info.per.year": "Por Ano",
  "info.total": "Total",
  "info.users": "Usuários",
  "info.preregistrations": "Pré-Registros",
  "info.preregistration": "Pré-Registro",
  "info.preregistrations.viewdetails": "Essas são as informações desse pré-registro.",
  "info.contract": "Contrato",
  "info.add.new": "Insira os dados abaixo para adicionar um novo usuário.",
  "info.clients": "Clientes",
  "info.menuOptions": "Opções do menu",
  "info.addCart": "Adicionar ao carrinho",
  "info.purchase": "Comprar",

  "info.client.add": "Adicionar Cliente",
  "info.directClient.add": "Adicionar cliente direto",
  "info.client.edit": "Editar Cliente",
  "info.client.add.message": "Preencha as informações abaixo para adicionar um novo cliente.",
  "info.client.edit.message": "Edite as informações abaixo para atualizar o seu cliente.",
  "info.client.personalInformations": "Informações pessoais:",
  "info.client.companyInformation": "Informações da empresa:",
  "info.client.documentType": "Tipo de Documento",
  "info.client.monetaryConfiguration": "Configurações de cobrança:",
  "info.client.currencyCode": "Moeda",
  "info.client.nextDueDate": "Próxima data de pagamento:",

  "info.none": "Nenhum",
  "info.releaser": "Liberador",
  "info.releasers": "Liberadores",
  "info.releaser.edit": "Editar Liberador",
  "info.releaser.add": "Adicionar Liberador",
  "info.releaser.add.message": "Preencha as informações abaixo para adicionar um novo liberador.",
  "info.releaser.edit.message": "Edite as informações abaixo para atualizar o seu liberador.",
  "info.releaser.selectdrone": "Selecione o Drone:",
  "info.releaser.selectclient": "Nome do cliente:",
  "info.releaser.liberatormodel": "Modelo do Liberador:",
  "info.releaser.models": "Modelos",

  "info.drone": "Drone",
  "info.drones": "Drones",
  "info.manufacturer": "Fabricante",
  "info.drone.edit": "Editar Drone",
  "info.drone.add": "Adicionar Drone",
  "info.drone.add.message": "Preencha as informações abaixo para adicionar um novo drone.",
  "info.drone.edit.message": "Edite as informações abaixo para atualizar o seu drone.",
  "info.drone.selectdrone": "Selecione o Drone:",
  "info.drone.selectclient": "Nome do cliente:",
  "info.drone.manufacturer": "Fabricante do drone",
  "info.drone.dronemodel": "Modelo do Drone:",

  "info.cpu": "CPU",
  "info.cpus": "CPUs",
  "info.model": "Modelo",
  "info.serialNumber": "Número de série",
  "info.firmwareVersion": "Versão de Firmware",
  "info.lastFirmware": "Último Firmware",
  "info.client": "Cliente",
  "info.cpu.add": "Adicionar CPU",
  "info.cpu.viewing": "Visualização de CPU",
  "info.cpu.model": "Modelo da CPU",
  "info.cpu.addingMessage": "Preencha os campos abaixo para adicionar uma nova CPU",
  "info.cpu.viewingMessage": "Visualizando a CPU adicionada",
  "info.releaser.addDevice": "Adicionar dispositivo",
  "info.releaser.removeDevice": "Remover dispositivo",
  "info.devices": "Dispositivos",
  "info.device.model": "Modelo",

  "info.profiles": "Perfis",
  "info.role.master": "Master",
  "info.role.administrator": "Administrador",
  "info.role.manager": "Gerente",
  "info.role.operator": "Operador",
  "info.role.external": "Externo",

  "info.profile.add": "Adicionar Usuário",
  "info.profile.edit": "Editar Usuário",
  "info.profile.add.message": "Preencha as informações abaixo para adicionar um novo usuário.",
  "info.profile.edit.message": "Edite as informações abaixo para atualizar o usuário.",
  "info.profile.userType": "Tipo de usuário",
  "info.profile.userType.manager": "Gerente",
  "info.profile.userType.operator": "Operador",
  "info.profile.userType.external": "Externo",
  "info.profile.personalInformations": "Informações pessoais:",
  "info.profile.legalInformations": "Informações Legais:",
  "info.profile.agricultureNumber": "Númerico de agricultura",
  "info.profile.crea": "CREA",
  "info.profile.ava": "AVA",
  "info.profile.edit.image": "Alterar foto de perfil",
  "info.profile.edit.image.dialog": "A foto deve ser no formato jpg, png ou jpeg.",
  "info.profile.edit.image.buttom.choose": "Escolher foto",
  "info.profile.edit.image.buttom.send": "Enviar",
  "info.profile.edit.image.buttom.cancel": "Cancelar",
  "info.profile.edit.image.invalid": "Não foi possivel carregar sua foto.",

  "info.profile.edit.logo": "Alterar sua logo",
  "info.profile.edit.logo.dialog": "A logo deve ser no formato jpg, png ou jpeg.",
  "info.profile.edit.logo.buttom.choose": "Escolher logo",
  "info.profile.edit.logo.buttom.send": "Enviar",
  "info.profile.edit.logo.buttom.cancel": "Cancelar",
  "info.profile.edit.logo.invalid": "Não foi possivel carregar sua logo.",

  "info.profile.nationality": "Nacionalidade",

  "info.directClients": "Clientes",

  //-- Pages --//
  "info.subtitleTerms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.pageTerms":
    "Ao aceitar o presente termo, o usuário consente e concorda que a empresa NCB, doravante denominada Controlador, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e garantias estabelecidas pela Lei nº 13.709.",
  "info.subtitlePrivacy": "Lorem ipsum dolor sit amet consecter",
  "info.privacy":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleSupport": "Lorem ipsum dolor sit amet consecter",
  "info.support":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleFaq": "Lorem ipsum dolor sit amet consecter",
  "info.faq":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion1": "Pergunta 1 ?",
  "info.faqQuestion1Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion2": "Pergunta 2 ?",
  "info.faqQuestion2Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion3": "Pergunta ?",
  "info.faqQuestion3Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleWhy": "Lorem ipsum dolor sit amet consecter",
  "info.why":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleResources": "Lorem ipsum dolor sit amet consecter",
  "info.resources":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",

  "info.aboutTitle": "O que é NCB?",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub é uma plataforma digital holística que permite o gerenciamento eficaz de pragas e doenças por meio de drones e softwares com foco em inteligência de negócios.",
  "info.aboutVisionTitle": "Nossa visão",
  "info.aboutVisionSub": "A realidade que se busca",
  "info.aboutVisionText":
    "Transformar o setor agrícola, promovendo a gestão de dados e a aplicação de tecnologia e inovação sustentável para a criação de estratégias e o uso assertivo do controle biológico. Em três anos, queremos que a Bi All seja a referência na simbiose entre agricultura, ciência e tecnologia, após ter operado três milhões de hectares de lavouras.",
  "info.aboutMissionTitle": "Nossa missão",
  "info.aboutMissionSub": "A razão de ser",
  "info.aboutMissionText":
    "Catalisar a natureza através da digitalização de dados que permite criar estratégias para a tomada de decisões garantindo a melhor gestão dos recursos. Fornecer soluções em ecossistemas agrícolas por meio do controle biológico de precisão.",
  "info.aboutValues": "Nossos valores",
  "info.aboutPrecisionTitle": "Precisão",
  "info.aboutPrecisionText":
    "Executamos todos os nossos processos e serviços com rigor e de acordo com a estratégia com que foram planeados.",
  "info.aboutTraceabilityTitle": "Rastreabilidade",
  "info.aboutTraceabilityText":
    "Garantimos visibilidade e controle em cada etapa dos processos para obter os melhores resultados.",
  "info.aboutTeamTitle": "Nossa equipe",
  "info.aboutTeamRoleCEO": "CEO e Fundador",
  "info.aboutTeamRoleManager": "Gestão",
  "info.aboutTeamRoleEmployee": "Engenharia",
  "info.aboutTeamDescriptionCEO":
    "Engenharia Elétrica (FEI 2002)\nMaster Electronics (ITA 2007)\nDoutorado de Sistemas Aeroespaciais (ITA 2013)",
  "info.aboutTeamDescriptionManager":
    "Administração (UNILESTE 2005)\nGestão de Empreendedorismo (UNIPAC)",
  "info.aboutTeamDescriptionEmployee":
    "Hardware e software integrados\nEngenharia Elétrica (UNESP 2005)",
  "info.contactTitle": "Fale Conosco",
  "info.contactSubtitle": "Informações de contato por país",
  "info.contactDescription":
    "Preencha seus dados no formulário abaixo e aguarde. Entraremos em contato assim que possível!",
  "info.termTitle": "O plano bioMAPS",
  "info.coin": "R$",
  "info.termPriceMonth": "por mês / por aparelho",
  "info.termPriceYear": "por ano / por aparelho (15% de desconto)",
  "info.termSpecifications": "Especificações: ",
  "info.termSpecificationsDesc":
    "É o primeiro planejador de missão da DRONES com foco no controle de precisão biológica. É possível planejar rotas de qualquer lugar do mundo através da nuvem e sincronizadas com um aplicativo móvel para fazer lançamentos de insumos biológicos 100% rastreados e controlados por GPS.",
  "info.termClassifications": "Classificações: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Escolher Plano",
  "info.termComparisonTitle": "Comparação das caracteristicas",
  "info.termComparisonMonth": "mês",
  "info.termComparisonYear": "ano",
  "info.termComparisonEquipment": "equipamento",
  "info.termComparisonPerEquipment": "por equipamento",
  "info.termComparisonMonthDevice": "mês por aparelho",
  "info.termComparisonYearDevice": "ano por aparelho",
  "info.termComparisonHectare": "hectar por mês",
  "info.termComparisonDescription": "Inclui licença para 1 dispositivo.",
  "info.termComparison.accessTitleBioHUB": "Acesso a plataforma",
  "info.termComparison.accessTitleBioMAPS": "Acesso ao aplicativo",
  "info.termComparison.accessTitleBioBOT": "Equipamento",
  "info.termComparisonDescriptionBioHUB":
    "Inclui 5 licenças de operadora ou 4 licenças de operadora e 1 licença de gerente. R$15 para cada operador extra.",
  "info.introducing": "Apresentando",
  "info.cameraQuality": "Qualidade da câmera",
  "info.flightTime": "Tempo de voo",
  "info.capacity": "Capacidade",
  "info.minutes": "minutos",
  "info.verificationTitle": "Verificação de e-mail",
  "info.verificationDescription":
    "A verificação requer um endereço de e-mail válido. \nEnviaremos um código de confirmação para o endereço de e-mail informado para iniciarmos o processo de validação. \nSe o endereço informado abaixo estiver incorreto, clique no ícone e altere seus dados.",
  "info.emailAddress": "Endereço de e-mail",
  "info.verificationCodeDescription": "Informe o código enviado para seu e-mail:",
  "info.confirmationCode": "Código de verificação",
  "info.newPassword": "Nova Senha",
  "info.newPasswordDescription": "Por favor, defina uma nova senha para acessar nossa plataforma.",
  "info.newPasswordDetails": "Informe a nova senha",
  "info.newPasswordConfirm": "Confirme a nova senha",

  "setupAccount.title": "Finalize seu Registro",
  "setupAccount.created": "Conta criada com sucesso",
  "setupAccount.error":
    "Algo de errado aconteceu no processo de finalização de seu cadastro. Por favor, tente de novo.",
  "setupAccount.error.tryAgain": "Tente de novo",
  "setupAccount.error.wrongSmsCode": "Código SMS errado",
  "setupAccount.verifiedEmail": "Email verificado com sucesso",
  "setupAccount.verification.code": "Código SMS",
  "setupAccount.verification.description":
    "A verificação requer um número de telefone válido. \nEnviaremos um código de verificação para o seu telefone e você deverá inserir corretamente esse código para finalizar a validação e criar sua senha.",
  "setupAccount.verification.codeDescription":
    "Entre com o código de 6 dígitos que enviamos ao seu telefone",
  "setup.password": "Insira sua senha",
  "setup.confirmPassword": "Confirme sua senha",
  "setup.password.different": "As senhas não batem",
  "setup.password.needUpperCase": "Necessário um elemento em caixa alta",
  "setup.password.needSpecialElement": "Necessário um caractere especial",
  "setup.password.lenght": "Necessário um comprimento mínimo de 6 letras",

  "drone.usagePhantom4": "Phantom 4",
  "drone.usageDroneMatrix100": "Matrix 100",
  "drone.usageDroneMatrix200": "Matrix 200",

  "prod.productTitle": "Produtos",
  "prod.bioBotSubTitle": "Lançador de insumos biológicos",
  "prod.bioBotDescription":
    "É um parasita em massa e liberador de ovos, usado principalmente para o controle de pragas. Com ele é possível lançar insumos biológicos como Trichogramma, Telenomus e Chrysopa (todos testados e em operação comercial com DRONES no Brasil, Colômbia e América Central).",
  "prod.bioBotWorksBioMaps": "Funciona com o aplicativo bioMAPS",
  "prod.bioBotWeatherProof": "À prova de intempéries",
  "prod.bioBotAutonomy": "+5 horas de autonomia",
  "prod.equipmentTitle": "O equipamento mais versátil",
  "prod.equipmentDescription":
    "Com ele é possível lançar insumos biológicos como Trichogramma, Telenomus e Chrysopa (todos testados e em operação comercial com DRONES no Brasil, Colômbia e América Central).",
  "prod.biocoteDescription":
    "É um liberador de xícara biodegradável com massa de cotesia utilizado principalmente no controle populacional de lagartas. A cana-de-açúcar é a principal cultura tratada. (Desenvolvimento exclusivo do Biocontrol). 100% operacional e comercial. Sua versão de massas únicas parasitadas foi congelada por nós não podemos continuar os testes de campo, retomaremos a partir de janeiro de 2021.",

  "prod.cultivationEquipmentTitle": "Principais safras beneficiadas",
  "prod.cultivationSugarCane": "Cana de Açucar",
  "prod.cultivationSoy": "Soja",
  "prod.cultivationBeans": "Feijões",
  "prod.cultivationCorn": "Milho",
  "prod.cultivationBanana": "Banana",
  "prod.cultivationAvocado": "Abacate",
  "prod.cultivationOilPalm": "Óleo de Palma",
  "prod.cultivationCoffee": "Café",
  "prod.cultivationForest": "Floresta",
  "prod.cultivationStrawberry": "Morango",
  "prod.cultivationMelon": "Melão",
  "prod.cultivationPapaya": "Mamão",
  "prod.cultivationTulip": "Tulipa",
  "prod.cultivationCotton": "Algodão",
  "prod.cultivationOthers": "Outros",
  "prod.cultivationContact":
    "Se o cultivo de sua empresa não é mostrado aqui, entre em contato conosco para um aconselhamento personalizado",

  "prod.usageSugarcane": "Cana de açucar",

  "prod.usageForest": "Floresta",
  "prod.usageCotton": "Algodão",
  "prod.usageCorn": "Milho",
  "prod.usageSoybeans": "Soja",
  "prod.usageCoffee": "Café",
  "prod.usageStrawberry": "Morango",
  "prod.usageMelon": "Melão",
  "prod.usagePapaya": "Mamão",
  "prod.usageTulip": "Tulipa",
  "prod.usageAvocado": "Abacate",
  "prod.usageBeans": "Feijões",
  "prod.usageBanana": "Banana",
  "prod.usageOilPalm": "Óleo de Palma",

  "prod.specificationTitle": "Especificações Técnicas",
  "prod.specificationSubTitle": "Acesso à plataforma bioHUB",
  "prod.specificationInfo":
    "Inclui 5 licenças de operadora ou 4 licenças de operadora e 1 licença de gerente; $ 15 para cada operador extra.",

  "bio.usageTrichogrammaExiguum": "Trichogramma Exiguum",
  "bio.usageTrichogrammaGalloi": "Trichogramma Galloi",
  "bio.usageTrichogrammaAtopovirilia": "Trichogramma Atopovirilia",
  "bio.usageTrichogrammaBrassicae": "Trichogramma Brassicae",
  "bio.usageTrichogrammaPretiosum": "Trichogramma Pretiosum",
  "bio.usageChrysoperlaCarnea": "Chrysoperla Carnea",
  "bio.usageChrysoperlaExterna": "Chrysoperla Externa",
  "bio.usageStratiolaelapsScimitus": "Stratiolaelaps Scimitus",
  "bio.usageNeoseiulusCalifornicus": "Neoseiulus Californicus",
  "bio.usageTelenomusPodisi": "Telenomus Podisi",
  "bio.usageTelenomusRemus": "Telenomus Remus",
  "bio.usagePhytoseiulusPersimilis": "Phytoseiulus Persimilis",
  "bio.usageCotesiaFlavipes": "Cotesia Flavipes",
  "bio.usageCeraeochrysaCincta": "Ceraeochrysa Cincta",
  "bio.usageTetrastichusHowardi": "Tetrastichus Howardi",
  "bio.usageAmblyseiusCalifornicus": "Amblyseius Californicus",
  "bio.usageMetarhiziumAnisopliae": "Metarhizium Anisopliae",
  "bio.usageBeauveriaBassiana": "Beauveria Bassiana",

  "cart.title": "Meu carrinho",
  "cart.title.general": "Em geral",
  "cart.title.address": "Endereço pessoal e contato",
  "cart.title.address.company": "Endereço da empresa e contato",
  "cart.title.representative": "Representante legal",
  "cart.title.step1": "Passo 1: Dados sobre a empresa",
  "cart.title.step2": "Passo 2: Termos",
  "cart.title.step3": "Passo 3: Pagar",
  "cart.title.number.order": "Nº do pedido: ",
  "cart.title.billing.address": "Endereço de cobrança",
  "cart.title.shipping.address": "Endereço de envio",
  "cart.title.payments.methods": "Métodos de pagamento",
  "cart.title.modal.billing.address": "Adicionar endereço de cobrança",
  "cart.title.modal.shipping.address": "Adicionar endereço de envio",
  "cart.title.discount.coupon": "Cupom de Desconto: ",
  "cart.title.modal.add.discount.coupon": "Adicionar novo cupom de desconto",
  "cart.title.subtotal": "Sub-Total",
  "cart.title.discounts": "Descontos",
  "cart.title.taxes": "Impostos",
  "cart.button.download": "Baixar",
  "cart.terms.information.use": "Uso de informações pessoais",
  "cart.terms.mandatory.contract": "Contratos Obrigatórios",
  "cart.info.payments.paypal": "Paypal",
  "cart.info.payments.pix": "Pix",
  "cart.info.payments.ticket": "Boleto",
  "cart.action.empty": "Esvaziar carrinho",
  "cart.message.error.CPF": "CPF Inválido",
  "cart.message.error.CNPJ": "CNPJ Inválido",
  "cart.message.error.fields.filled":
    "Verifique se todos os campos foram preenchidos corretamente!",
  "cart.message.error.checked": "Verifique se todos os Termos foram selecionados!",
  "cart.message.error.extra.address": "Adicione pelo menos 1 endereço para entrega ou cobrança",
  "cart.message.error.item.cart": "Não possui nenhum item ao carrinho!",
  "cart.message.error.document.type": "Selecione algum tipo de documento! (CPF, CNPJ, ID)",
  "cart.message.error.coupon.invalid": "Cupom inválido, tente outro!",
  "cart.message.error.coupon.add.exists": "Este cupom já foi adicionado!",
  "cart.alreadyIncluded": "Já incluso:",
  "cart.chooseOneVersionOfTheProductMessage": "Escolha um versão para adicionar ao carrinho:",
  "cart.product": "Produto:",

  //-- Landing Page --//
  "page.title": "Digitalize seu campo, \no primeiro passo para \no futuro da agricultura",
  "page.description":
    "bioHUB é uma plataforma digital holística que permite o manejo eficaz de pragas e doenças através de drones e softwares focados em inteligência empresarial.",
  // Sections
  "section.about.title": "Nossos produtos",
  "section.about.category": "Software",
  "section.about.info.title": "Sobre o bioHUB",
  "section.about.info.subtitle": "BioHub é um sistema de gestão de controle biológico de precisão",
  "section.about.info.description":
    "É uma plataforma de nuvem integrada que auxilia toda a cadeia de controle biológico a monitorar pragas, liberar com precisão, gerar relatórios e monitorar a tomada de decisões...",
  "section.biomaps.title": "Sobre BioMAPS:",
  "section.biomaps.subtitle": "BioMAPS é um planejador de missões de voo autônomo",
  "section.biomaps.description":
    "É o primeiro planejador de missão de drones focado em controle biológico de precisão. É usado para planejar rotas de voo para identificação e tratamento de pragas.",
  "section.biomaps.required": "BioMAPS requer uma assinatura do bioHUB ativa",
  "section.how.works.title": "Como funciona?",
  "section.products.category": "Hardware",
  "section.products.description":
    "Cada cultura pode sofrer de diferentes pragas que precisam de tratamento especializado. É por isso que criamos soluções biológicas ideais para seu cultivo e seu modelo de negócio.",
  "section.products.subDescriptionCultivation": "Eu cultivo ",
  "section.products.subDescriptionDrone": "Tenho o drone ",
  "section.products.subDescriptionBiological": "Quero aplicar ",
  "section.card.product.description.bioBOT":
    "É um parasita em massa e liberador de ovos usado principalmente para 𝐜𝐨𝐧𝐭𝐫𝐨𝐥𝐞 𝐝𝐞 𝐩𝐫𝐚𝐠𝐚𝐬. Com ele é possível liberar insumos biológicos como Trichogramma, Telenomus e Chrysopa",
  "section.card.product.description.bioCOTE":
    "É um liberador de vasos biodegradável com insumos biológicos utilizados no controle populacional de 𝐥𝐚𝐠𝐚𝐫𝐭𝐚𝐬.",
  "section.card.product.description.PHERObio":
    "É um lançador de cápsulas com feromônios. É usado para controlar 𝐌𝐢𝐠𝐝𝐨𝐥𝐮𝐬 por meio de confusão sexual.",
  "section.products.required": "Todos os nossos produtos de hardware exigem",
  "section.prices.category": "Preços",
  "section.prices.title": "O plano certo para o seu negócio",
  "section.prices.description":
    "Temos vários planos poderosos para mostrar o seu negócio e ser descoberto como um empreendedor criativo.",
  "section.clients.category": "Clientes",
  "section.clients.title": "Opiniões de nossos clientes",
  "section.clients.opinions.men":
    "“Recebi um ótimo atendimento dos especialistas que me ajudaram. Eu recomendaria para qualquer pessoa que deseja um ótimo painel de grande qualidade”",
  "section.clients.opinions.women":
    "“Realmente se resume a isto: que toda a vida está inter-relacionada. Estamos todos presos em uma rede inescapável de reciprocidade, amarrados em uma única vestimenta de destino”",
  "section.clients.role.men": "Designer de produto",
  "section.clients.role.women": "Desenvolvedor Web",
  "section.partners.category": "Parceiros",
  "section.buy": "Comprar",

  // Section Product
  "section.product.benefited.crops.title": "Safras beneficiadas",
  "section.product.compatible.drone.title": "Drones compatíveis",
  "section.product.highlight": "Destaques",
  "section.product.biological.title": "Biológicos",

  // Sections Product [bioBOT]
  "section.product.bioBOT.title": "bioBOT",
  "section.product.bioBOT.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioBOT.description":
    "É um liberador de ovos parasitados a granel utilizado principalmente para o controle da natalidade das pragas. Com ele é possível liberar insumos biológicos dos gêneros Trichograma, Telenomus e Chrysopa. Cana-de-açúcar, milho, feijão, soja, algodão, café, banana, palma, abacate são as principais culturas tratadas.",
  "section.product.bioBOT.how_it_works.title": "Como funciona o BioBOT?",

  // Sections Product [bioCOTE]
  "section.product.bioCOTE.title": "bioCOTE",
  "section.product.bioCOTE.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioCOTE.description":
    " É um liberador de massas do gênero Cotesia utilizado principalmente no controle populacional de lagartas. Cana-de-açúcar é a principal cultura tratada.",
  "section.product.bioCOTE.how_it_works.title": "Como funciona o BioCOTE?",

  // Sections Product [bioMITE]
  "section.product.bioMITE.title": "bioMITE",
  "section.product.bioMITE.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioMITE.description":
    "É um liberador dos ácaros predadores Phytoseiulus e Neoseiulus.  Após a liberação eles atacam e controlam as infestações em campo de outros ácaros praga. Morango, tomate, flores, tabaco e cogumelo são as principais culturas.",
  "section.product.bioMITE.how_it_works.title": "Como funciona o BioMITE?",

  //-- Dashboard Information --//
  "dashboard.welcome": "Bem-vindo(a) de volta, \n",
  "dashboard.clients": "Clientes",
  "dashboard.users": "Usuários",
  "dashboard.total.users": "Total de Usuários",
  "dashboard.total.reports": "Relatórios",
  "dashboard.total.releasedFlights": "Voos",
  "dashboard.total.releasedFlight": "Voo",
  "dashboard.total.operations": "Operações",
  "dashboard.total.operation": "Operação",
  "dashboard.total.monthFlightYield": "Área liberada",
  "dashboard.leggend.plannedReleased": "Planejado / Liberado",
  "dashboard.total.clients": "Total de Clientes",
  "dashboard.total.drones": "Total de Drones",
  "dashboard.total.projects": "Total de Projetos",
  "dashboard.total.sales": "Liberações Para Clientes",
  "dashboard.total.area": "Total de Hectares Cobertos",
  "dashboard.total.yearMonthLegend": "Mês / Ano",
  "dashboard.hectares.covered": "Hectares Cobertos",
  "dashboard.new.company": "Novas empresas",
  "dashboard.flight.client": "Vôos por cliente",
  "dashboard.coverage.graph": "Gráfico de área de cobertura",
  "dashboard.scale": "Janelamento",
  "dashboard.graphmodeview": "Visualizar por",
  "dashboard.filter": "Filtro",
  "dashboard.time.30minutes": "30 minutos",
  "dashboard.time.1hour": "1 hora",
  "dashboard.time.8hours": "8 horas",
  "dashboard.time.1day": "1 dia",
  "dashboard.time.1week": "1 semana",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Perfil",
  "dashboard.view.client": "Cliente",
  "dashboard.view.drone": "Drone",

  "dashboard.total": "Totalizadores",
  "dashboard.start.date": "Início",
  "dashboard.end.date": "Fim",
  "dashboard.area.drone.time": "Área e Drone por Tempo",
  "dashboard.area.drone.time.no.data": "Selecione um intervalo com dados de drone disponíveis",
  "dashboard.area.input.time": "Área e Insumo por Tempo",
  "dashboard.area.input.time.no.data": "Selecione um intervalo com dados de insumo disponíveis",

  //-- Menu Information --//
  "menu.about": "Sobre a NCB",
  "menu.products": "Produtos",
  "menu.solutions": "Soluções",
  "menu.pricing": "Preços",
  "menu.contact": "Contato",
  "menu.privacy": "Política de Privacidade",
  "menu.terms": "Termos e Condições",
  "menu.title": "Empresa",
  "menu.support": "Suporte",
  "menu.faq": "FAQ's",
  "menu.resources": "Recursos",
  "menu.why": "Por que bioHUB?",
  "menu.home": "Início",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Pefil",
  "menu.plans": "Planos",
  "menu.settings": "Configurações",
  "menu.updates": "Atualizações",
  "menu.open.project": "Abrir Projeto",
  "menu.new.project": "Novo Projeto",
  "menu.new.monitoring": "Novo Monitoramento",
  "menu.flight.monitoring": "Monitorar Voo",
  "menu.trap.monitoring": "Monitorar Armadilha",
  "menu.biomapsInfo":
    "O BioMAP visualiza as áreas onde os controladores biológicos são liberados por meio do planejador de missão.",
  "menu.licenses": "Licenças",
  "menu.traps": "Armadilhas",

  //-- Generic Actions --//
  "action.usageChoose": "Selecionar...",
  "action.ContactUs": "Quero solicitar um hardware específico",
  "action.login": "Entrar",
  "action.signUp": "Inscreva-se",
  "action.subscribe": "Assinar",
  "action.register": "Cadastrar",
  "action.makeRegistration": "Faça aqui seu pré cadastro!",
  "action.success": "Sucesso",
  "action.error": "Desculpe",
  "action.msgSuccess":
    "Seu pré-cadastro foi realizado com sucesso em nossa base! \nEm breve entraremos em contato ✓",
  "action.msgError":
    "Tivemos um problema ao realizar seu pré-cadastro! Por favor, tente novamente em instantes.",
  "action.back": "voltar",
  "action.getStarted": "Iniciar",
  "action.learnMore": "Saiba mais",
  "action.learnMore.prices": "Conheça os planos e preços",
  "action.more": "Mais informações...",
  "action.keep.connected": "Mantenha-me conectado",
  "action.login.message": "Informe seus dados para fazer login",
  "action.login.msgError":
    "Erro ao fazer login. Por favor, confira seus dados ou tente novamente em instantes.",
  "action.continue": "Continuar",
  "action.accept": "Estou de acordo",
  "action.indicator": "Cadastrar-se como: ",
  "action.signup.company": "Pessoa Jurídica",
  "action.signup.single": "Pessoa Física",
  "action.accept.signup.terms": "com os termos gerais da lei de proteção de dados LGPD.",
  "action.pay.now": "Pagar agora",
  "action.add.new": "Adicionar novo",
  "action.edit": "Editar",
  "action.clean": "Limpar",
  "action.edit.picture": "Alterar foto",
  "action.edit.logo": "Alterar logo",
  "action.remove": "Remover",
  "aciton.restore": "Restaurar",
  "action.save": "Salvar",
  "action.add": "Adicionar",
  "action.cancel": "Cancelar",
  "action.undo": "Desfazer",
  "action.redo": "Refazer",
  "action.finish": "OK",
  "action.settings": "Configurações",
  "action.income": "Renda",
  "action.send": "Enviar",
  "action.confirm": "Confirmar",
  "action.sendVerification": "Enviar código de verificação",
  "action.saveData": "Salvar dados",
  "action.confirmAction": "Tem certeza que deseja completar essa ação?",
  "action.warn": "Aviso",
  "action.cart.add": "Item adicionado ao carrinho.",
  "action.cart.remove": "Item removido do carrinho.",
  "action.cart.changedQuantity": "Quantidade Alterada.",

  //-- Placeholders --//
  "placeholder.profile": "Perfil",
  "placeholder.email": "E-mail",
  "placeholder.name": "Nome",
  "placeholder.phone": "Telefone",
  "placeholder.ladlineNumber": "Número de telefone fixo",
  "placeholder.company": "Empresa",
  "placeholder.isCompanyQuestion": "É empresa?",
  "placeholder.data.collect": "Como conheceu a NCB?",
  "placeholder.password": "Senha",
  "placeholder.search": "Buscar",
  "placeholder.iva": "IVA",
  "placeholder.company.name": "Nome",
  "placeholder.zip": "CEP",
  "placeholder.address": "Endereço",
  "placeholder.number": "Número",
  "placeholder.cellphone": "Celular",
  "placeholder.complement": "Complemento",
  "placeholder.country": "País",
  "placeholder.state.department": "Estado",
  "placeholder.city": "Cidade",
  "placeholder.street": "Rua",
  "placeholder.comercial.phone": "Telefone Comercial",
  "placeholder.aviation.reg": "Registro de Aviação",
  "placeholder.agriculture.reg": "Registro de Agricultura",
  "placeholder.role": "Função",
  "placeholder.document": "Documento",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nome Comercial",
  "placeholder.type": "Tipo de usuário",
  "placeholder.contact": "Contato",
  "placeholder.description": "Bio",
  "placeholder.message": "Mensagem",
  "placeholder.subject": "Assunto",
  "placeholder.project": "Projeto",
  "placeholder.area": "Área",
  "placeholder.aircraft": "Aeronave",
  "placeholder.code.country": "Código do País",
  "placeholder.name.street": "Nome da Rua:",
  "placeholder.street.examples": "Apartamento, unidade, edifício, piso, etc",
  "placeholder.phone.mask": "(000) 0000-0000",
  "placeholder.CPF.mask": "XXX.XXX.XXX-XX",
  "placeholder.CNPJ.mask": "XX.XXX.XXX/0001-XX",

  "placeholder.releaser.serialnumber": "Numero de Serie",
  "placeholder.releaser.nickname": "Apelido",
  "placeholder.releaser.releasermodels": "Modelos",
  "placeholder.releaser.tablemodel": "Modelo",
  "placeholder.releaser.tableserialnumber": "Nº de Serie",
  "placeholder.releaser.searchclient": "Digite o nome do cliente",
  "placeholder.profile.select.picture": "Nenhuma imagem selecionada...",

  "placeholder.drone.selectclient": "Cliente...",
  "placeholder.drone.selectcpu": "CPU...",
  "placeholder.drone.selectmanufacturer": "Fabricante do drone...",
  "placeholder.drone.selectdronemodel": "Modelo do Drone...",
  "placeholder.drone.clientcpu": "CPU do cliente",

  "placeholder.settings.insufficientpermissions":
    "Você não possui permissões suficientes para alterar essas configurações.",

  "profile.account": "Minha Conta",
  "profile.profile": "Meu Perfil",
  "profile.settings.updated": "Preferências de perfil atualizadas",

  "profile.userTableComponent.selection.selected": "selecionado",
  "profile.userTableComponent.selection.clear": "Limpar seleção",
  "profile.userTableComponent.selection.remove": "Remover dados selecionados",

  //-- Labels --//
  "label.document.ID": "ID",
  "label.document.CPF": "CPF",
  "label.document.CNPJ": "CNPJ",
  "label.document.RG": "Registro Geral (RG)",
  "label.document.IM": "Inscrição Municipal",
  "label.name.company": "Nome da empresa",
  "label.trade.name": "Razão social",
  "label.direction": "Endereço",
  "label.code.postal": "Código Postal",
  "label.fullname": "Nome completo",
  "label.phone": "Telefone Fixo",
  "label.cellphone": "Telefone Celular",
  "label.select.country": "Selecione um País",

  //-- Options --//
  "option.preferences.defaultCurvePercentage": "Curvatura (%)",
  "option.preferences.mustReleaseEntireArea":
    "Ativar equipamento no primeiro waypoint e desativar no último",
  "option.preferences.generalinfo": "Informações gerais",
  "option.preferences.unitsystem": "Sistema de unidades",
  "option.preferences.twofactorauth": "Autenticação em dois fatores",
  "option.preferences.sexagesimalcoordinates": "Usar Coordenadas Sexagesimais",
  "option.preferences.separatelybatteries": "Mostrar baterias separadamente",
  "option.preferences.metric": "Métrico",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Mecanismos de Busca",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recomendações",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Eventos",
  "option.others": "Outros",
  "option.which": "Quais?",

  //-- Map --//
  "map.title": "Mapa",
  "map.defaultRegion": "BR",
  "map.editMode.enterManualRoute": "Rota Manual",
  "map.editMode.editRoute": "Editar Rota",
  "map.editMode.manualRoute":
    "Toque na área para inserir um novo waypoint ou arraste aqueles que já foram inseridos para modificar a rota. ",
  "map.editMode.addPolygon":
    "Toque no mapa para inserir um ponto. Mantenha um marcador pressionado para mudar sua posição.",
  "map.editMode.error.theePointsMinimum": "Por favor, insira ao menos três pontos.",
  "map.editMode.error.nearbyPoints":
    "Por favor, cada waypoint tem que estar a ao menos 1 metro de distância dos outros.",
  "map.editMode.error.interesection":
    "A área desenhada possui uma ou mais intersecções. Por favor, desenhe um polígono simples.",
  "map.editMode.reverseRoute": "Rota reversa",
  "map.removeArea": "Deletar esta area?",
  "map.newArea": "Nova área",
  "map.loadingList": "carregando...",
  "map.loadingFlights": "carregando...",
  "map.noFlightsToShow": "Nenhum voo.",
  "map.editPolygonPoints": "Editar pontos",
  "map.editPlan": "Editar planejamento da área",
  "map.importRouteKml": "Importar rota via KML",
  "map.goToArea": "Ir para a área",
  "map.replanRoute": "Gerar nova rota",
  "map.editAreaProperties": "Configurações",
  "map.deleteArea": "Remoção de área",
  "map.restoreArea": "Restaurar área",
  "map.duplicateArea": "Criar cópia da área",
  "map.kml.exportArea": "Exportar área como KML",
  "map.kml.exportRoute": "Exportar rota como KML",
  "map.kml.exportFlight": "Exportar voo como KML",
  "map.copyArea": "Copiar área",
  "map.pasteCopiedArea": "Colar área copiada",
  "map.deletingArea": "Removendo área",
  "map.restoringArea": "Restaurando área",
  "map.deletingArea.question": "Continuar?",
  "map.restoringArea.question": "Continuar?",
  "map.deleteProject": "Remoção de projeto",
  "map.restoreProject": "Restauração de projeto",
  "map.deletingProject": "Removendo projeto",
  "map.restoringProject": "Restaurando projeto",
  "map.deletingProject.question": "Continuar?",
  "map.restoringProject.question": "Continuar?",
  "map.importAreas.importAreasAction": "Importar áreas",
  "map.newArea.createAreaModalTitle": "Criar nova área",
  "map.newArea.curveMode": "Modo de Curva",
  "map.newArea.curveMode.curved": "Curvado",
  "map.newArea.curveMode.normal": "Normal",
  "map.newArea.headingMode": "Modo de Orientação",
  "map.newArea.headingMode.nextWaypoint": "Próximo Waypoint",
  "map.newArea.headingMode.manual": "Manual",
  "map.newArea.importAreaModalTitle": "Importar áreas",
  "map.newArea.editProjectModalTitle": "Editar projeto",
  "map.newRoute.importKmlModalTitle": "Importar rota via KML",
  "map.newArea.areaName": "Nome da área",
  "map.newProject.title": "Criar projeto",
  "map.newProject.indirectClientName": "Cliente",
  "map.newProject.projectName": "Nome do projeto",
  "map.newProject.optionalImportKml": "Importar KML",
  "map.newProject.optionalImportShapefile": "Importar shapefile",
  "map.newProject.skipImportingFiles": "Desenhar área manualmente",
  "map.newProject.kmlFileChoose": "Abrir...",
  "map.newProject.shapefileFileChoose": "Abrir...",
  "map.newProject.releaser": "Liberador",
  "map.newProject.input": "Insumo",
  "map.newProject.releaseRate": "Taxa de liberação",
  "map.newProject.calibrationFactor": "Fator de calibração",
  "map.newProject.instantaneousReleaseRate": "Taxa de liberação instantânea",
  "map.type.roadmap": "Padrão",
  "map.type.satellite": "Satélite",
  "map.type.hybrid": "Híbrido",
  "map.areasAmountSing": "{amount} área",
  "map.areasAmountPl": "{amount} áreas",
  "map.enableLabels": "Marcadores",
  "map.closeProject": "Fechar Projeto",
  "map.closeArea": "Fechar área",
  "map.toggleVisibility": "Alternar visibilidade",
  "map.toggleVisibility.disabled": "Desabilitado",
  "map.openFlightReport": "Abrir relatório de voo",
  "map.moreOptions": "Mais opções",

  "map.search": "Pesquisa de Endereço",
  "map.search.noOptions": "Sem opção de endereço",

  "map.plannedRoutes.title": "Rotas planejadas da Área",
  "map.plannedRoutes.flightAmount": "Quantidade de Voos",
  "map.plannedRoutes.plannedData": "Data do planejamento",
  "map.plannedRoutes.untilToday": " - Hoje",
  "map.plannedRoutes.flightDate": "Data do voo",
  "map.plannedRoutes.termPerformedFlights": "Voos Realizados",
  "map.plannedRoutes.areaSize": "Tamanho da Área",
  "map.plannedRoutes.flightTime": "Tempo de Voo",
  "map.plannedRoutes.viewingOldPlan":
    'Você está visualizando um planejamento antigo da área. \nClique em "Sair" para voltar a visualizar o planejamento atual. \nClique em "Substituir" para substituir o planejamento atual pelo planejamento que está visualizando agora.',
  "map.plannedRoute.select": "Substituir",
  "map.planedRoute.exit": "Sair",
  "map.plannedRoute.routeAngle": "Ângulo da rota",
  "map.editMode.routeAngle":
    "Toque para selecionar um ponto para usar o ponto de polígono mais próximo como base para criar a rota. Você também pode alterar o ângulo de rotação.",

  "map.footer.noProjectOpen": "Nenhum projeto aberto",
  "map.footer.possibleCoverageArea": "Área de cobertura possível: {areaSize}",
  "map.footer.coveredArea": "Área coberta: {areaSize} ({percentage}%)",

  "map.unlinkedFlights.unlinkedFlights": "Voos não vinculados",
  "map.unlinkedFlights.cpuSerialNumber": "Arquivo de Log:",
  "map.unlinkedFlights.action.linked": "Vincular a um voo",
  "map.unlinkedFlights.action.linked.for.prefix": "Vincular voo ",
  "map.unlinkedFlights.action.linked.for.sufix": " à área: ",
  "map.unlinkedFlights.modal.project": "Projeto",
  "map.unlinkedFlights.modal.area": "Área",
  "map.unlinkedFlights.modal.drone": "Drone",
  "map.unlinkedFlights.modal.cancel": "Cancelar",
  "map.unlinkedFlights.modal.link": "Vincular",

  // Modal map legends
  "map.legends.modal.marker.cluster.pink":
    "Grupo de 'n' projetos que em um certo nível de zoom são representados próximos uns dos outros",
  "map.legends.modal.marker.cluster.red":
    "Representa um projeto com um nível de zoom distante onde 'n' significa a quantidade de áreas que ele contém",
  "map.legends.modal.marker.cluster.yellow":
    "Grupo de 'n' areas de um projeto que em um certo nível de zoom são representadas próximas umas das outras",
  "map.legends.modal.marker.cluster.blue":
    "Representa uma área com um nível de zoom distante onde o número 'n' significa a quantidade de waypoints",
  "map.legends.modal.icon.homepoint": "Homepoint - Ponto inicial planejado para início do voo",
  "map.legends.modal.icon.marker.thick.blue.green":
    "Waypoint - Ponto de referência para executar o percurso com o equipamento ligado",
  "map.legends.modal.icon.marker.thick.blue.red":
    "Waypoint - Ponto de referência para executar o percurso sem equipamento desligado",
  "map.legends.modal.icon.marker.thick.blue.green.border":
    "Representa o waypoint 'n' no qual o drone passará com o equipamento ligado",
  "map.legends.modal.icon.marker.thick.blue.red.border":
    "Representa o waypoint 'n' no qual o drone passará com o equipamento desligado",
  "map.legends.modal.orange.arrow": "Seta indicando a direção que o drone deve seguir",
  "map.legends.modal.icon.marker.distance.top":
    "Primeira linha: Altura do waypoint em relação ao solo",
  "map.legends.modal.icon.marker.distance.bottom":
    "Segunda linha: Informação opcional - Altura do waypoint a partir do ponto inicial. Quando não aparecer, significa que o waypoint tem a mesma altura do solo e do ponto inicial",
  "map.legends.modal.drawn.planning.area": "Rota planejada para o voo",
  "map.legends.modal.line.area": "Linha entre waypoints indicando a rota",
  "map.legends.modal.green.line": "Rota do voo",
  "map.legends.modal.title": "Legenda dos elementos do mapa",
  "map.legends.modal.paragraph.waypoints": "Distância entre 2 waypoints",
  "map.legends.modal.where.the.drone.passed": "Linha mostrando onde o drone voará",
  "map.legends.modal.drawn.drone.work": "Contorno indicando a área que foi pulverizada",
  "map.legends.modal.drawn.area.selected": "Representação poligonal de uma área selecionada",
  "map.legends.modal.drawn.area.notSelected": "Representação poligonal de uma não área selecionada",

  "map.openedProjectAndAreaActions":
    "Configurações do projeto {projectName} {e da Area {areaName}}",
  "map.editProjectSettings": "Configurações do projeto",
  "map.editAreaSettings": "Configuraçoes da área",

  "map.openedProjectAndAreaActions.reverseRoute": "Rota reversa da área",
  "map.openedProjectAndAreaActions.editPolygonPoints": "Editar polígono da área",
  "map.openedProjectAndAreaActions.editMode.editRoute": "Editar rota da área",
  "map.openedProjectAndAreaActions.editMode.enterManualRoute": "Rota manual da área",
  "map.openedProjectAndAreaActions.editPlan": "Editar planejamento da área",
  "map.openedProjectAndAreaActions.replanRoute": "Gerar nova rota para a área",
  "map.openedProjectAndAreaActions.plannedRoutes.title": "Rotas planejadas da área",
  "map.openedProjectAndAreaActions.importRouteKml": "Importar rota para a área (KML)",

  //-- Route planning --//
  "routePlanning.notFoundReleaser": "Liberador não encontrado",

  "routePlanning.editWaypoint": "Editar Waypoint",
  "routePlanning.param.areaPadding": "Indentação de área",
  "routePlanning.param.flightHeight": "Altura",
  "routePlanning.param.flightSpeed": "Velocidade",
  "routePlanning.param.flightSpeed.legend":
    "Velocidade que o drone irá acelerar para atingir entre os waypoints (já que é necessário desacelerar para fazer curvas não necessariamente será alcançado)",

  "routePlanning.param.trackWidth": "Largura de faixa",
  "routePlanning.param.considerRelief": "Elevação online",
  "routePlanning.param.useHighestPolygonPointElevationAsHomePoint":
    "Encontrar o ponto mais alto dos limites da área para usar como ponto inicial",
  "routePlanning.param.canUseOutsidePolygonSegmentsInRoute":
    "Pode usar segmentos externos ao polígono na rota",

  "routePlanning.param.waypoint.height": "Altura",
  "routePlanning.param.waypoint.curveRadius": "Raio da curva",
  "routePlanning.param.waypoint.speed": "Velocidade",
  "routePlanning.param.waypoint.speed.legend":
    "Velocidade que o drone irá acelerar para chegar ao próximo waypoint (já que é necessário desacelerar para fazer curvas ele não necessariamente será alcançado)",
  "routePlanning.param.waypoint.orientation": "Orientação",
  "routePlanning.param.waypoint.altitudeAboveHome": "{elevation}{unit} acima do homepoint",
  "routePlanning.param.waypoint.actions": "Ações",
  "routePlanning.param.waypoint.action.enableEquipment": "Habilitar Equipamento",
  "routePlanning.param.waypoint.action.disableEquipment": "Desabilitar Equipamento",
  "routePlanning.param.waypoint.action.none": "Nenhuma",
  "routePlanning.param.waypoint.location.legend.lat": "Latitude",
  "routePlanning.param.waypoint.location.legend.lng": "Longitude",
  "routePlanning.param.waypoint.location.legend.elevation": "Elevação",

  // -- Regions -- //
  "region.brazil": "Brasil",
  "region.europe": "Europa",
  "region.other": "Outra",

  //-- LANGUAGES --//
  language: "Idioma",
  "language.pt": "Português - Brasileiro",
  "language.es": "Espanhol",
  "language.en": "Inglês",
  "language.fr": "Francês",
  "language.name": "Português (Brasil)",
  "language.region": "BR",
  "language.decimalSeparator": ",",
  "language.country": "Brasil",

  //- Reports -//
  "report.input": "Insumo",
  "report.issueDate": "Data de Emissão:",
  "report.area": "Área",
  "report.plannedFlight": "Voo Planejado",
  "report.details": "Detalhes",
  "report.dateHour": "Data e Hora:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricante:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Produto Liberado:",
  "report.releasing": "Liberador",
  "report.bandWidth": "Largura da Faixa",
  "report.plannedSpeed": "Velocidade Planejada",
  "report.releaseRate": "Taxa de Liberação",
  "report.realFlight": "Voo Real",
  "report.flightTime": "Tempo de Voo",
  "report.averageReleaseRate": "Taxa de Liberação Média",
  "report.averageSpeed": "Velocidade Média",
  "report.totalReleased": "Total Liberado",
  "report.totalArea": "Área Total",
  "report.traps": "Armadilhas",
  "report.serialNumber": "Número de série",
  "report.coveredHectares": "Hectares cobertos",
  "report.dateMeasurament": "Data Ultima Medição",
  "report.specie": "Espécie",
  "report.adultsNumber": "Número de Adultos",
  "report.noAvailable": "Nenhum item disponível",
  "report.company": "Empresa:",
  "report.mapRecord": "Registro MAPA:",
  "report.contractor": "Contratante:",
  "report.property": "Propriedade:",
  "report.localization": "Localização:",
  "report.county": "Município:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Tipo de serviço:",
  "report.product": "Produto",
  "report.formulation": "Formulação",
  "report.dosage": "Dosagem",
  "report.toxicClass": "Classe Toxic.",
  "report.adjuvant": "Adjuvante",
  "report.culture": "Cultura: ",
  "report.volume": "Volume (litros ou kg/ha):",
  "report.others": "Outros: ",
  "report.agronomicalRecipe": "Receituário Agronomico n:",
  "report.issuedOn": "Emitido em:",
  "report.basicParameter": "Parametros Básicos de Aplicação",
  "report.maxTemperature": "Temperatura Máx.=",
  "report.relativeUnitMin": "Unidade Relativa Min.=",
  "report.equipment": "Equipamento=",
  "report.model": "Modelo=",
  "report.type": "Tipo=",
  "report.angle": "Ângulo=",
  "report.flightHeight": "Altura de voo=",
  "report.bandwidth": "Largura da faixa(m)=",
  "report.date": "Data",
  "report.signatureCREA": "Nome, assinatura e CREA do profissional",
  "report.weatherConditions": "Condições Meteorológicas na Aplicação",
  "report.morning": "Matutino",
  "report.evening": "Vespertino",
  "report.applicationStart": "Início da aplicação: horas e término: horas",
  "report.initial": "Inicial",
  "report.final": "Final",
  "report.temperature": "Temperatura",
  "report.relativeHumidity": "Umid. Relativa",
  "report.aircraftPrefixe": "Prefixo - Aeronave:",
  "report.dgpsUse": "Uso do DGPS: Sim ou Não",
  "report.windSpeed": "Veloc. Vento",
  "report.trackCoordinates": "Coordenadas da Pista:",
  "report.observation": "Observações:",
  "report.signatureEng": "Nome, assinatura e CREA do Eng. Agronomo:",
  "report.print": "Imprimir",
  "report.initialWeatherTitle": "Clima inicial",
  "report.finalWeatherTitle": "Clima final",
  "report.source": "Fonte",
  "report.manualSource": "Manual",
  "report.optimizedUsing": "otimizada usando",
  "report.source.optimized": "Otimizada",

  // Pre-registration research
  "preregistration.interest.question":
    "O que você acha que é o mais importante no\nControle Biológico de Precisão?",
  "preregistration.interest.financial": "Financeiro",
  "preregistration.interest.financial.cost": "Custo de operação",
  "preregistration.interest.financial.savings": "Economia de insumos",
  "preregistration.interest.financial.pest": "Redução de pragas",
  "preregistration.interest.financial.production": "Aumento de produção",

  "preregistration.interest.sustainability": "Sustentabilidade",
  "preregistration.interest.sustainability.carbonfootprint": "Redução da pegada de carbono",
  "preregistration.interest.sustainability.pesticides": "Redução de pesticidas",
  "preregistration.interest.sustainability.environment": "Meio ambiente",
  "preregistration.interest.sustainability.water": "Economia de água",

  "preregistration.interest.reliability": "Confiabilidade",
  "preregistration.interest.reliability.precision": "Precisão",
  "preregistration.interest.reliability.releasingrate": "Taxa de liberação",
  "preregistration.interest.reliability.tracking": "Rastreamento de rota",
  "preregistration.interest.reliability.coverage": "Cobertura de área",

  // Notifications
  "notif.newmessages": "Novas Mensagens",
  "notif.pendencies": "Pendências",
  "notif.error": "Falhas",
  "notif.success": "Confirmações",

  // Header dropdown
  "headerDropdown.logout": "Sair",

  "map.projectTree.projects.loading": "Carregando projetos",
  "map.projectTree.projects.empty": "Lista de projetos vazia",

  "map.projectTree.areas.loading": "Carregando áreas do projeto",
  "map.projectTree.areas.empty": "Lista de áreas vazia",

  "map.projectTree.flights.loading": "Carregando voos da área",
  "map.projectTree.flights.empty": "Lista de voos vazia",

  "map.projectTree.projects": "Projetos",

  "map.projectTree.visualizationMode": "Modo de visualização",
  "map.projectTree.visualizationMode.notDeleted": "Não deletados",
  "map.projectTree.visualizationMode.deleted": "Deletados",
  "map.projectTree.visualizationMode.all": "Todos",
  "map.projectTree.visualizationModeConsideringSubItems": "Modo nos subitens",

  "cpu.block.blocked.general": "CPU bloqueada. Por favor contate os administradores.",
  "cpu.block.blocked.master": "CPU bloqueada",
  "cpu.block.notBlocked.general": "CPU liberada para uso",
  "cpu.block.notBlocked.master": "CPU não está bloqueada",
  "cpu.block.cpuTitle": "Bloqueios da CPU {cpuSerialNumber}",
  "cpu.block.addBlock": "Adicionar bloqueio",
  "cpu.block.reason": "Motivo do bloqueio",
  "cpu.block.createdAt": "Criado em",
  "cpu.block.appliedAt": "Aplicado em",
  "cpu.block.notApplied": "Não aplicado",
  "cpu.block.unblockedAt": "Desbloqueada em",
  "cpu.block.reason.default": "Problemas com pagamento",
  "cpu.block.reason.idleness": "Ociosidade",
  "cpu.block.reason.manually": "NCB",
  "cpu.block.removeBlock": "Desbloquear CPU",

  "biohubError.unimplemented.error": "Erro inesperado.",
  "biohubError.unkown.error": "Erro desconhecido. Por favor verifique sua conexão de internet.",
  "biohubError.internal.server.error": "Problemas de conexão com o servidor.",
  "biohubError.validation.error":
    "Dados incompatíveis. Por favor atualize essa página para carregar a última versão do site.",
  "biohubError.login.incorrect.user.password": "Login: Usuário ou senha incorretos.",
  "biohubError.preregister.email.already.registered": "Pré-cadastro: Email já registrado.",
  "biohubError.preregister.phone.number.already.registered":
    "Pré-cadastro: Telefone já registrado.",
  "biohubError.login.token.not.received": "Erro ao entrar no sistema.",
  "biohubError.logion.unrecgnized.client":
    "Plataforma não autorizada. Verifique se está usando a url oficial.",
  "biohubError.project.not.found": "Projeto não encontrado.",
  "biohubError.elevation.invalid.request": "Erro obtendo dados de elevação.",
  "biohubError.elevation.over.daily.limit":
    "Limite de requisição de dados de elevação diário excedido.",
  "biohubError.elevation.over.query.limit":
    "Quantidade de dados de elevação requisitava excede o limite.",
  "biohubError.elevation.request.denied": "Acesso negado a dados de elevação.",
  "biohubError.elevation.unknown.error": "Erro inesperado.",
  "biohubError.geocoder.unknown.error": "Erro no processo de obtenção de dados de uma localização.",
  "biohubError.sms.validation.error": "Erro no envio de SMS de validação.",
  "biohubError.kml.route.out.of.area.bounds": "Rota fora dos limites da área.",
  "biohubError.kml.import.invalid.selected.area.bounds": "Área selecionada inválida.",
  "biohubError.toBigArea": "Área muito grande",

  "purchase.order.intent.number": "ID intenção de compra",
  "purchase.order.intent.detail": "Detalhes da intenção de compra",
  "purchase.order.intent.message": "Nota adicional",
  "purchase.order.intent.phone": "Telefone",

  "purchase.order.intent.contactTitle": "Submissão de Interesse de Compra",
  "purchase.order.intent.contactDescription":
    "Preencha seus dados no formulário abaixo e aguarde que em breve entraremos em contato.",

  "purchase.order.intent.mail.error":
    "Falha ao enviar os dados de intenção de compra. Tente novamente!",
  "purchase.order.intent.mail.validation.error":
    "Preencha todos os dados obrigatórios do formulário",
  "purchase.order.intent.mail.success": "Inteção de compras enviado com sucesso",

  "purchase.order.mail.content.product": "Produto",
  "purchase.order.mail.content.quantity": "Quantidade",
  "purchase.order.mail.content.unit.prices": "Preços Unitários Praticados",

  "geographic.direction.northAcronym": "N",
  "geographic.direction.southAcronym": "S",
  "geographic.direction.eastAcronym": "L",
  "geographic.direction.westAcronym": "O",

  "geographic.direction.northwestAcronym": "NO",
  "geographic.direction.northeastAcronym": "NL",
  "geographic.direction.southeastAcronym": "SL",
  "geographic.direction.southWestAcronym": "SO",

  "unit.cup": "copo",
};

export default messagesMap;
