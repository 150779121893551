import React from "react";
import RenderInsideStateBounds from "./render_inside_state_bounds";
import RenderInsideMapBounds from "./render_inside_map_bounds";
import { BoundingBox } from "biohub-model";

export default (props: {
  elementBounds: BoundingBox;
  children: JSX.Element;
  mapBoundsSource: "map" | "state";
}): JSX.Element => {
  if (props.mapBoundsSource === "map") {
    return <RenderInsideMapBounds elementBounds={props.elementBounds} children={props.children} />;
  } else {
    return (
      <RenderInsideStateBounds elementBounds={props.elementBounds} children={props.children} />
    );
  }
};
