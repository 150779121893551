import { Location } from "biohub-model";
import React from "react";
import WithPixelsPerMeters from "./with_pixels_per_meters";
import * as geolib from "geolib";

const maxFontSizeHeightInPixels = 12;
const fontSizeInMeters = 5;

export default (props: {
  scaleSource: "map" | "state";
  location: Location;
  getChild: (
    centerLocation: Location,
    fontSizeInPixels: number,
    fontSizeInMeters: number
  ) => JSX.Element;
  northOffsetInMeters?: number;
}): JSX.Element => {
  let centerLocationPoint = {
    latitude: props.location.lat,
    longitude: props.location.lng,
  };

  if (props.northOffsetInMeters !== undefined) {
    centerLocationPoint = geolib.computeDestinationPoint(
      props.location,
      props.northOffsetInMeters,
      0
    );
  }

  return (
    <WithPixelsPerMeters
      source={props.scaleSource}
      getChild={(pixelsPerMeters) => {
        let effectiveFontSizeInMeters = fontSizeInMeters;
        let fontSizeInPixels = fontSizeInMeters * pixelsPerMeters;
        if (fontSizeInPixels > maxFontSizeHeightInPixels) {
          effectiveFontSizeInMeters =
            effectiveFontSizeInMeters / (fontSizeInPixels / maxFontSizeHeightInPixels);
          fontSizeInPixels = maxFontSizeHeightInPixels;
        }

        return props.getChild(
          {
            lat: centerLocationPoint.latitude,
            lng: centerLocationPoint.longitude,
          },
          fontSizeInPixels,
          effectiveFontSizeInMeters
        );
      }}
    />
  );
};
