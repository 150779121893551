import { Location } from "biohub-model";
import { Tooltip } from "react-leaflet";
import L from "leaflet";
import React from "react";
import WithFontSizeInMetersPattern from "./with_font_size_in_meters_pattern";
import Marker from "./marker";

export default (props: {
  mapScaleSource: "map" | "state";
  location: Location;
  labelLines: string[];
  map: L.Map;
  offsetInMeters?: number;
  zIndex: number;
}): JSX.Element => {
  const biggestLine = props.labelLines.reduce((a, b) => (a.length > b.length ? a : b)).length;

  return (
    <WithFontSizeInMetersPattern
      scaleSource={props.mapScaleSource}
      location={props.location}
      northOffsetInMeters={props.offsetInMeters}
      getChild={(centerLocation, fontSizeInPixels, _) => {
        const boxWidthInPixels = ((biggestLine + 2) * fontSizeInPixels * 2) / 3;

        const boxHeightInPixels =
          (props.labelLines.length + 0.5 + 0.4 * (props.labelLines.length - 1)) * fontSizeInPixels;

        return (
          <Marker
            position={centerLocation}
            icon={
              new L.Icon({
                iconUrl: "/icon_marker_distance.png",
                iconSize: [boxWidthInPixels, boxHeightInPixels],
              })
            }
            zIndex={props.zIndex}
          >
            <Tooltip direction="center" opacity={1} permanent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: fontSizeInPixels,
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  alignContent: "center",
                  paddingTop: 3,
                }}
              >
                {props.labelLines.map((line) => (
                  <div>{line}</div>
                ))}
              </div>
            </Tooltip>
          </Marker>
        );
      }}
    />
  );
};
