import { Location, Role, UnitSystem } from "biohub-model";
import L from "leaflet";
import React, { useState } from "react";
import Marker, { DraggableProps } from "./marker";
import WithFontSizeInMetersPattern from "./with_font_size_in_meters_pattern";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { useSelector } from "react-redux";

export default (
  props: {
    mapScaleSource: "map" | "state";
    homePointLocation?: Location;
    homePointElevation?: number;
    onClick?: () => void;
    map: L.Map;
  } & DraggableProps
): JSX.Element => {
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const location = props.homePointLocation;

  if (location === undefined) return <></>;

  return (
    <WithFontSizeInMetersPattern
      scaleSource={props.mapScaleSource}
      location={location}
      getChild={(centerLocation, fontSizeInPixels, fontSizeInMeters) => {
        const size = fontSizeInPixels * 4;

        return (
          <>
            <Marker
              {...props}
              position={centerLocation}
              icon={
                new L.Icon({
                  iconUrl: "/icon_homepoint.png",
                  iconSize: [size, size],
                })
              }
              onClick={props.onClick}
              zIndex={1}
            />
          </>
        );
      }}
    />
  );
};
