import React, { useEffect, useRef, useState } from "react";
import {
  SvgIconProps,
  SvgIcon,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import { Role } from "biohub-model";
import { useDispatch, useSelector } from "react-redux";
import {
  ProjectInProjectTree,
  AreaInProjectTree,
  getProjectsOnProjectTree,
  getAreasOnProjectTree,
} from "../../store/reducers/projectTreeReducer";
import { SystemState } from "../../store/reducers/systemReducer";
import { useIntl } from "react-intl";
import {
  closeProject,
  collapseProject,
  collapseProjects,
  expandProject,
  expandProjects,
  fetchProjectTreeScrollPosition,
  selectProject,
  setItemsInvisible,
  setItemsVisible,
  setProjectInvisible,
  setProjectVisible,
} from "../../store/actions/projectTreeActions";
import { AddNewAreaTreeItem } from "./components/AddNewAreaTreeItem";
import ElementsState from "./components/ElementsState";
import ListAreasInProjectTree from "./ListAreasInProjectTree";
import ProjectInProjectTreeComponent from "./components/ProjectInProjectTreeComponent";
import "./ProjectTreeScroll.css";
import CpuLogList from "./components/CpuLogList";
import CustomExpandIcon from "./components/CustomExpandIcon";
import { ProjectTreeItemTrailingIcon } from "./components/ProjectsTreeItemTrailingIcon";

type Props = {
  onClickToEditProjectSettings: (project: ProjectInProjectTree) => void;
  onClickToAddAreaInProject: (projectId: ProjectInProjectTree) => void;
  onClickToDeleteProject: (project: ProjectInProjectTree) => void;
  onClickToRestoreProject: (project: ProjectInProjectTree) => void;
  onClickToEditAreaSettings: (area: AreaInProjectTree) => void;
  onClickToDeleteArea: (area: AreaInProjectTree) => void;
  onClickToRestoreArea: (area: AreaInProjectTree) => void;
  onClickDuplicateArea: (area: AreaInProjectTree) => void;
  onClickCopyArea: (area: AreaInProjectTree) => void;
  onClickPasteCopiedArea: (project: ProjectInProjectTree) => void;
  height: number;
  searchFilter: string;
};

export default function (props: Props): JSX.Element {
  // TODO: Use that in the render
  const projectListState: "empty" | "loading" | "reloading" | "regular" = useSelector(
    (state: SystemState) => {
      const projectTreeState = state.projectTree;

      const projectList = projectTreeState.projectList;
      const creatingProject = projectTreeState.isCreatingProject;

      if (creatingProject === true) {
        return "loading";
      }
      if (projectTreeState.isLoadingProjectList) {
        if (projectList !== null && projectList !== undefined) {
          return "reloading";
        } else {
          return "loading";
        }
      }

      if (projectList === null || projectList === undefined || projectList.length === 0) {
        return "empty";
      }

      return "regular";
    }
  );

  const isPanelExpanded = useSelector((state: SystemState) => state.projectTree.isExpandedProjects);

  const expandedItems = useSelector((state: SystemState) => {
    const expandedItems: string[] = [];
    const projectTreeState = state.projectTree;

    const projectList = getProjectsOnProjectTree(
      projectTreeState.projectList,
      projectTreeState.visualizationMode,
      projectTreeState.visualizationModeConsideringSubItems,
      props.searchFilter
    );
    projectList.forEach((project) => {
      if (project.expanded) {
        expandedItems.push(project.id);

        const areaList = project.areas ?? [];
        areaList.forEach((area) => {
          if (area.expanded) {
            expandedItems.push(`${project.id}-${area.id}`);
          }
        });
      }
    });

    return expandedItems;
  });

  const profileRole = useSelector(
    (state: SystemState) => state.profile.userProfile?.role ?? Role.external
  );

  const stateProjectList = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const projectList = getProjectsOnProjectTree(
      projectTreeState.projectList,
      projectTreeState.visualizationMode,
      projectTreeState.visualizationModeConsideringSubItems,
      props.searchFilter
    );

    return projectList.map((project) => {
      return {
        ...project,
        areaCount:
          project.areas !== null && project.areas !== undefined
            ? getAreasOnProjectTree(project.areas, projectTreeState.visualizationMode, "").length ??
              project.areaCount
            : project.areaCount,
      };
    });
  });

  const selectedProjectId = useSelector(
    (state: SystemState) => state.projectTree.selectedProjectId
  );

  const hasCopiedArea = useSelector(
    (state: SystemState) => state.projectTree.copiedArea !== undefined
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  const isProjectsVisible = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const projectList = getProjectsOnProjectTree(
      projectTreeState.projectList,
      projectTreeState.visualizationMode,
      projectTreeState.visualizationModeConsideringSubItems,
      props.searchFilter
    );

    return projectList.find((project) => project.visible) !== undefined;
  });

  const scrollRef = useRef(null);

  const stateScrollPosition = useSelector((state: SystemState) => state.projectTree.scrollPosition);

  useEffect(() => {
    (scrollRef.current as any).scrollTo(0, stateScrollPosition);
  }, []);

  const handleScroll = (e: any) => {
    const { scrollTop } = e.target;

    dispatch(fetchProjectTreeScrollPosition(scrollTop));
  };

  return (
    <div className="project-tree">
      <TreeView
        ref={scrollRef}
        style={{
          overflowY: "scroll",
          height: props.height - 60,
          width: 330,
        }}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        expanded={expandedItems}
        onScroll={handleScroll}
      >
        {projectListState !== "regular" && (
          <ElementsState nodeId={"project-tree"} state={projectListState} type={"projects"} />
        )}

        <CpuLogList projectList={stateProjectList}></CpuLogList>

        <Accordion
          expanded={isPanelExpanded}
          onChange={() => {
            if (!isPanelExpanded) {
              dispatch(expandProjects());
            } else {
              dispatch(collapseProjects());
            }
          }}
          style={{
            background: "rgba(237,237,237,1)",
            border: "0.1px solid #d3d3d3",
            padding: 0,
            margin: 0,
            borderRadius: "4px",
          }}
        >
          <AccordionSummary style={{ borderRadius: "4px !important" }}>
            <CustomExpandIcon expanded={isPanelExpanded} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography>
                <div style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                  {intl.formatMessage({ id: "map.projectTree.projects" })}
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  width: "50px",
                  marginRight: "7px",
                }}
              >
                <ProjectTreeItemTrailingIcon
                  isHidden={!isProjectsVisible}
                  onClick={() => {
                    if (isProjectsVisible) {
                      dispatch(setItemsInvisible());
                    } else {
                      dispatch(setItemsVisible());
                    }
                  }}
                />
                <Typography style={{ marginTop: "1.5px" }}>
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: 0,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }}
                  >
                    {stateProjectList.length}
                  </span>
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails
            style={{ marginTop: "0.1em", padding: 0, display: "flex", flexDirection: "column" }}
          >
            <div style={{ marginLeft: "1em" }}>
              {stateProjectList.map((project) => (
                <ProjectInProjectTreeComponent
                  key={`${project.id}-project-in-project-tree`}
                  projectId={project.id}
                  projectName={project.name}
                  deleted={project.deletedAt !== null}
                  areaCount={project.areaCount}
                  isLoadingAreas={project.isLoadingAreas}
                  beingDeleted={project.beingDeleted}
                  isCreatingArea={project.isCreatingArea}
                  isUpdating={project.isUpdating}
                  selectedProjectId={selectedProjectId}
                  isVisible={project.visible}
                  onClickProject={() => {
                    dispatch(selectProject(project.id));
                  }}
                  onOpenOrCloseProject={() => {
                    if (!project.expanded) {
                      dispatch(expandProject(project.id));
                    } else {
                      dispatch(collapseProject(project.id));
                    }
                  }}
                  onChangeProjectVisibility={() => {
                    if (project.visible) {
                      dispatch(setProjectInvisible(project.id));
                    } else {
                      dispatch(setProjectVisible(project.id));
                    }
                  }}
                  profileRole={profileRole}
                  onClickToEditProjectSettings={() => props.onClickToEditProjectSettings(project)}
                  onClickToAddAreaInProject={() => props.onClickToAddAreaInProject(project)}
                  onClickToDeleteProject={() => props.onClickToDeleteProject(project)}
                  onClickToRestoreProject={() => props.onClickToRestoreProject(project)}
                  onClickToCloseProject={() => {
                    dispatch(closeProject(project.id));
                  }}
                  areasAlreadyLoaded={project.areas !== null}
                  hasCopiedArea={hasCopiedArea}
                  onClickPasteCopiedArea={() => props.onClickPasteCopiedArea(project)}
                >
                  <ListAreasInProjectTree
                    projectId={project.id}
                    onClickToEditAreaSettings={props.onClickToEditAreaSettings}
                    onClickToDeleteArea={props.onClickToDeleteArea}
                    onClickToRestoreArea={props.onClickToRestoreArea}
                    onClickDuplicateArea={props.onClickDuplicateArea}
                    onClickCopyArea={props.onClickCopyArea}
                  />

                  {!project.isLoadingAreas &&
                    (profileRole === Role.master ||
                      profileRole === Role.admin ||
                      profileRole === Role.manager) && (
                      <AddNewAreaTreeItem
                        projectId={project.id}
                        onClick={() => props.onClickToAddAreaInProject(project)}
                      />
                    )}
                </ProjectInProjectTreeComponent>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </TreeView>
    </div>
  );
}

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 13, height: 13, color: "var(--black)" }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 13, height: 13, color: "var(--black)" }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}
