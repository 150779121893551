import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditionMode,
  finishAreaPlanningEdition,
  putFlightPlanInAreaPlan,
  quitViewFlightPlan,
  updateEditingArea,
} from "../../../../store/actions/projectTreeActions";
import { SystemState } from "../../../../store/reducers/systemReducer";
import EditingAreaPlanOverlay from "./EditingAreaPlanOverlay";
import ViewOldPlannedAreaOverlay from "./ViewOldPlannedAreaOverlay";
import { Box, Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { EditingArea, EditingAreaType } from "../../../../store/reducers/projectTreeReducer";
import { distanceBetween } from "../../../../core/geometricFunctions";
import _ from "lodash";
import { CompletePlannedArea } from "biohub-model";

export default function (): JSX.Element {
  const editingAreaState = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    return projectTreeState.editingArea;
  });

  const selectedAreaViewingOldFlightPlanState = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return undefined;

    const selectedProject = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (selectedProject === undefined) return undefined;

    const selectedAreaId = selectedProject.selectedAreaId;
    if (selectedAreaId === null) return undefined;

    const selectedArea = selectedProject.areas?.find((area) => area.id === selectedAreaId);
    if (selectedArea === undefined) return undefined;

    if (selectedArea.viewingFlightPlan === null) return undefined;

    return {
      projectId: selectedProjectId,
      areaId: selectedAreaId,
      plan: selectedArea.viewingFlightPlan,
    };
  });

  const dispatch = useDispatch();

  if (editingAreaState === undefined && selectedAreaViewingOldFlightPlanState === undefined) {
    return <></>;
  }

  return (
    <Box
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "flex-end",
        flex: 1,
        alignItems: "flex-end",
        flexDirection: "column",
        zIndex: 20,
        backgroundColor: "transparent",
        pointerEvents: "none",
        top: 0,
        left: 0,
        right: 20,
        bottom: 20,
      }}
    >
      <EditInfo
        height={100}
        style={{
          backgroundColor: "var(--overlay-90)",
        }}
      >
        {editingAreaState !== undefined ? (
          <EditingAreaPlanOverlay
            editingArea={editingAreaState}
            onCancel={() => {
              dispatch(closeEditionMode());
            }}
            onFinish={() => {
              dispatch(finishAreaPlanningEdition());
            }}
            setEditingPlan={(editingArea) => {
              dispatch(updateEditingArea(editingArea));
            }}
          />
        ) : selectedAreaViewingOldFlightPlanState !== undefined ? (
          <ViewOldPlannedAreaOverlay
            viewingOldPlannedArea={selectedAreaViewingOldFlightPlanState.plan}
            onCancel={() => {
              dispatch(
                quitViewFlightPlan(
                  selectedAreaViewingOldFlightPlanState.projectId,
                  selectedAreaViewingOldFlightPlanState.areaId
                )
              );
            }}
            onSelect={() => {
              dispatch(
                putFlightPlanInAreaPlan(
                  selectedAreaViewingOldFlightPlanState.projectId,
                  selectedAreaViewingOldFlightPlanState.areaId,
                  selectedAreaViewingOldFlightPlanState.plan
                )
              );
            }}
          />
        ) : (
          <></>
        )}
      </EditInfo>
    </Box>
  );
}

export const EditInfo = styled.div.attrs((props: { height?: number }) => ({
  height: props.height,
}))`
  white-space: pre-line;

  border-radius: 5px;
  border: 1px solid var(--black);
  padding: 12px;
  padding: 12px;
  padding: 12px;
  width: 45%;
  ${(props) =>
    props.height !== undefined
      ? `
    height: ${props.height} px;
    `
      : `
      height: 62px;
      `}

  position: absolute;
  display: flex;
  align-items: center;
  right: 200px;
  top: 50px;
  color: var(--white);
  font-size: 10px;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.75px;
  button {
    border-radius: 25px;
    margin: 0 3px;
    min-width: 100px;
    max-height: 28px;
    align-self: center;
    padding-bottom: 10px;
    font-size: 12px;
    background: var(--dashboard-danger);

    :hover,
    :focus {
      background: var(--dashboard-hr);
    }

    &.MuiButton-containedSecondary {
      background: var(--dashboard-primary-bg);
    }

    &.MuiButton-containedPrimary {
      background: var(--dashboard-secondary);
    }
  }

  @media screen and (max-width: 1280px) {
    width: 48vw;
  }

  @media screen and (max-width: 1024px) {
    width: 55vw;
  }
`;
