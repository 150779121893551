import React from "react";
import { CustomTreeItem } from "./CustomTreeItem";
import PlusIcon from "../../../assets/icon/icon_orange_plus.svg";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

type AddNewAreaTreeItemProps = {
  projectId: string;
  onClick: () => void;
};

export const AddNewAreaTreeItem = (props: AddNewAreaTreeItemProps) => {
  const intl = useIntl();
  return (
    <>
      <CustomTreeItem
        id={`${props.projectId}-add-new-area`}
        content=""
        deleted={false}
        overrideBody={
          <div
            onClick={(_) => {
              props.onClick();
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "30px",
            }}
          >
            <img
              src={PlusIcon}
              style={{
                height: "16px",
                width: "16px",
                marginRight: "5px",
                marginLeft: "-23px",
              }}
              alt={"plus-icon"}
            />
            <Typography style={{ fontSize: 15, marginTop: "1px" }}>
              {intl.formatMessage({ id: "map.newArea.createAreaModalTitle" })}
            </Typography>
          </div>
        }
        onClick={() => {
          props.onClick();
        }}
      />
    </>
  );
};
