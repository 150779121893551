import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import React from "react";

const cardTheme = createMuiTheme({
  palette: {
    type: "light",
  },
  typography: {
    allVariants: {
      color: "var(--black)",
    },
  },
});

/**
 * Semi-transparent container to be rendered over the map.
 * This component does not float by itself. It needs to be placed inside an absolutely positioned
 * element.
 */
export default function OverlayContainer(props: {
  children: React.ReactNode;
  pointyTopLeft?: boolean;
  pointyTopRight?: boolean;
  pointyBottomLeft?: boolean;
  pointyBottomRight?: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <div
      style={{
        background: props.style?.background != undefined ? props.style?.background : "var(--dashboard-background)",
        borderTopLeftRadius: props.pointyTopLeft ? 0 : 5,
        borderBottomLeftRadius: props.pointyBottomLeft ? 0 : 5,
        borderTopRightRadius: props.pointyTopRight ? 0 : 5,
        borderBottomRightRadius: props.pointyBottomRight ? 0 : 5,
        // borderWidth: 1,
        // borderColor: "#000000",
        // borderStyle: "solid",
        boxShadow: props.style?.boxShadow !== undefined ? props.style.boxShadow : "0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%)",
        padding: 4,
        ...(props.style ?? {}),
      }}
    >
      <MuiThemeProvider theme={cardTheme}>{props.children}</MuiThemeProvider>
    </div>
  );
}
