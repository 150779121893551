import {
  Area,
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Flight,
  Input,
  Project,
  Releaser,
  ReleaserModel,
} from "biohub-model";
import { PersistenceRepository } from "./PersistenceRepository";
import { CacheDataRequestsRepository } from "./CacheDataRequestsRepository";
import naturalCompare from "natural-compare";
import { BiohubError } from "../axios/BiohubApi";

let cacheDataService: CacheDataService | undefined;

export const getCacheDataService = (): CacheDataService | undefined => {
  return cacheDataService;
};

export const instantiateCacheDataService = async (params: {
  appVersion: string;
}): Promise<CacheDataService> => {
  if (cacheDataService === undefined) {
    const service = CacheDataService.getInstance({
      cacheDataRequestsRepository: CacheDataRequestsRepository.getInstance(),
      persistenceRepository: PersistenceRepository.getInstance(),
      ...params,
    });

    await service.init(params.appVersion);

    cacheDataService = service;
  }

  return cacheDataService;
};

export const clearData = async () => {
  if (cacheDataService === undefined) return;

  await Promise.all([
    cacheDataService.clearLoginInfo(),
    cacheDataService.clearProjects(),
    cacheDataService.clearAreas(),
    cacheDataService.clearFlights(),
    cacheDataService.clearCpus(),
    cacheDataService.clearCpuModels(),
    cacheDataService.clearDeviceModels(),
    cacheDataService.clearDroneModels(),
    cacheDataService.clearInputs(),
    cacheDataService.clearDrones(),
    cacheDataService.clearReleasers(),
    cacheDataService.clearReleaserModels(),
  ]);
};

export type LoginInfo = {
  keepLoggedIn: boolean;
  token: string;
  userId: string;
};

export abstract class CacheDataService {
  static getInstance(params: {
    persistenceRepository: PersistenceRepository;
    cacheDataRequestsRepository: CacheDataRequestsRepository;
  }): CacheDataService {
    return new CacheDataServiceImpl(params);
  }

  abstract init(appVersion: string): Promise<void>;

  abstract persistLoginInfo(info: LoginInfo): Promise<void>;

  abstract retrieveLoginInfo(): Promise<LoginInfo | undefined>;

  abstract clearLoginInfo(): Promise<void>;

  abstract fetchProjects(): Promise<{
    projects: Project[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearProjects(): Promise<void>;

  abstract fetchAreas(projectId: string): Promise<{
    areas: Area[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearAreas(): Promise<void>;

  abstract fetchAreaFlights(
    projectId: string,
    areaId: string
  ): Promise<{
    flights: Flight[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearFlights(): Promise<void>;

  abstract fetchCpus(directClientId: string | undefined): Promise<{
    cpus: Cpu[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearCpus(): Promise<void>;

  abstract fetchCpuModels(): Promise<{
    cpuModels: CpuModel[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearCpuModels(): Promise<void>;

  abstract fetchDeviceModels(): Promise<{
    deviceModels: DeviceModel[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearDeviceModels(): Promise<void>;

  abstract fetchDroneModels(): Promise<{
    droneModels: DroneModel[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearDroneModels(): Promise<void>;

  abstract fetchInputs(): Promise<{
    inputs: Input[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearInputs(): Promise<void>;

  abstract fetchDrones(directClientId: string | undefined): Promise<{
    drones: Drone[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearDrones(): Promise<void>;

  abstract fetchReleasers(directClientId: string | undefined): Promise<{
    releasers: Releaser[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearReleasers(): Promise<void>;

  abstract fetchReleaserModels(): Promise<{
    releaserModels: ReleaserModel[] | undefined;
    error?: BiohubError;
  }>;

  abstract clearReleaserModels(): Promise<void>;
}

type SyncedProjectsType = {
  syncMoment: Date;
  projectList: Project[];
};

type SyncedAreasType = {
  [projectId: string]: {
    areas: Area[];
    syncMoment: Date;
  };
};

type SyncedProjectAndAreasFlightsType = {
  [projectId: string]: {
    [areaId: string]: {
      lastSyncMoment: Date | undefined;
      actualFlightList: Flight[] | undefined;
    };
  };
};

type SyncedCpusType = {
  syncMoment: Date;
  cpuList: Cpu[];
};

type SyncedCpuModelsType = {
  syncMoment: Date;
  cpuModelList: CpuModel[];
};

type SyncedDeviceModelsType = {
  syncMoment: Date;
  deviceModelList: DeviceModel[];
};

type SyncedDroneModelsType = {
  syncMoment: Date;
  droneModels: DroneModel[];
};

type SyncedInputsType = {
  syncMoment: Date;
  inputs: Input[];
};

type SyncedDronesType = {
  syncMoment: Date;
  drones: Drone[];
};

type SyncedReleasersType = {
  syncMoment: Date;
  releasers: Releaser[];
};

type SyncedReleaserModelsType = {
  syncMoment: Date;
  releaserModels: ReleaserModel[];
};

class CacheDataServiceImpl implements CacheDataService {
  private persistenceRepository: PersistenceRepository;
  private cacheDataRequestsRepository: CacheDataRequestsRepository;

  static loginInfoReferenceKey = "login-info";

  static appVersionReferenceKey = "persisted-app-version";

  static syncedProjectsReferenceKey = "persisted-projects";
  private syncedProjects: SyncedProjectsType | undefined;

  static syncedAreasReferenceKey = "persisted-areas";
  private syncedAreas: SyncedAreasType | undefined;

  /// For project we will did that different. The sync will be for each area
  static syncedFlightsReferenceKey = "persisted-flights";
  private syncedFlights: SyncedProjectAndAreasFlightsType | undefined = {};

  static syncedCpusReferenceKey = "persisted-cpus";
  private syncedCpus: SyncedCpusType | undefined;

  static syncedCpuModelReferenceKey = "persisted-cpu-models";
  private syncedCpuModels: SyncedCpuModelsType | undefined;

  static syncedDeviceModelsReferenceKey = "persisted-device-models";
  private syncedDeviceModels: SyncedDeviceModelsType | undefined;

  static syncedDroneModelsReferenceKey = "persisted-drone-models";
  private syncedDroneModels: SyncedDroneModelsType | undefined;

  static syncedInputsReferenceKey = "persisted-inputs";
  private syncedInputs: SyncedInputsType | undefined;

  static syncedDronesReferenceKey = "persisted-drones";
  private syncedDrones: SyncedDronesType | undefined;

  static syncedReleasersReferenceKey = "persisted-releasers";
  private syncedReleasers: SyncedReleasersType | undefined;

  static syncedReleaserModelsReferenceKey = "persisted-releaser-models";
  private syncedReleaserModels: SyncedReleaserModelsType | undefined;

  constructor(params: {
    persistenceRepository: PersistenceRepository;
    cacheDataRequestsRepository: CacheDataRequestsRepository;
  }) {
    this.persistenceRepository = params.persistenceRepository;
    this.cacheDataRequestsRepository = params.cacheDataRequestsRepository;
  }

  async init(appVersion: string): Promise<void> {
    const persistedAppVersion = await this.persistenceRepository.getItem<string>(
      CacheDataServiceImpl.appVersionReferenceKey
    );

    if (persistedAppVersion === appVersion) {
      await Promise.all([
        this.initProjects(),
        this.initAreas(),
        this.initFlights(),
        this.initCpus(),
        this.initCpuModels(),
        this.initDeviceModels(),
        this.initDroneModels(),
        this.initInputs(),
        this.initDrones(),
        this.initReleasers(),
        this.initReleaserModels(),
      ]);
    } else {
      await Promise.all([
        this.clearProjects(),
        this.clearAreas(),
        this.clearFlights(),
        this.clearCpus(),
        this.clearCpuModels(),
        this.clearDeviceModels(),
        this.clearDroneModels(),
        this.clearInputs(),
        this.clearDrones(),
        this.clearReleasers(),
        this.clearReleaserModels(),
      ]);
    }

    await this.persistenceRepository.setItem<string>(
      CacheDataServiceImpl.appVersionReferenceKey,
      appVersion
    );
  }

  async persistLoginInfo(info: LoginInfo): Promise<void> {
    await this.persistenceRepository.setItem(CacheDataServiceImpl.loginInfoReferenceKey, info);
  }

  async retrieveLoginInfo(): Promise<LoginInfo | undefined> {
    const info = await this.persistenceRepository.getItem<LoginInfo>(
      CacheDataServiceImpl.loginInfoReferenceKey
    );

    return info;
  }

  async clearLoginInfo(): Promise<void> {
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.loginInfoReferenceKey);
  }

  async initProjects(): Promise<void> {
    this.syncedProjects = await this.persistenceRepository.getItem<SyncedProjectsType>(
      CacheDataServiceImpl.syncedProjectsReferenceKey
    );
  }

  async fetchProjects(): Promise<{
    projects: Project[] | undefined;
    error?: BiohubError;
  }> {
    const getNewProjectsResult = await this.cacheDataRequestsRepository.getProjects(
      "all",
      this.syncedProjects?.syncMoment
    );

    if (!getNewProjectsResult.success) {
      return {
        projects: this.syncedProjects?.projectList,
        error: getNewProjectsResult.error,
      };
    }

    let projects = [...(this.syncedProjects?.projectList ?? [])];

    const getNewProjectsResultData = getNewProjectsResult.data;
    for (const project of getNewProjectsResultData.data) {
      const oldProjectIndex = projects.findIndex((e) => e.id === project.id);
      if (oldProjectIndex < 0) {
        projects.push(project);
      } else {
        projects[oldProjectIndex] = project;
      }
    }

    const remainingIds = getNewProjectsResultData.remainingIdIfThereWasARemotion;
    if (remainingIds !== undefined) {
      projects = projects.filter((project) => remainingIds.includes(project.id));
    }

    projects.sort((a, b) => naturalCompare(a.name.toLowerCase(), b.name.toLowerCase()));

    this.syncedProjects = {
      syncMoment: getNewProjectsResultData.actualTimeReference,
      projectList: projects,
    };

    await this.persistenceRepository.setItem<SyncedProjectsType>(
      CacheDataServiceImpl.syncedProjectsReferenceKey,
      this.syncedProjects
    );

    return {
      projects: projects,
    };
  }

  async clearProjects(): Promise<void> {
    this.syncedProjects = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedProjectsReferenceKey);
  }

  async initAreas(): Promise<void> {
    this.syncedAreas = await this.persistenceRepository.getItem<SyncedAreasType>(
      CacheDataServiceImpl.syncedAreasReferenceKey
    );
  }

  async fetchAreas(projectId: string): Promise<{
    areas: Area[] | undefined;
    error?: BiohubError;
  }> {
    let timeReference: Date | undefined = undefined;

    if (this.syncedAreas !== undefined && this.syncedAreas[projectId] !== undefined) {
      timeReference = this.syncedAreas[projectId].syncMoment;
    }

    const getAreasChange = await this.cacheDataRequestsRepository.getProjectAreas(
      projectId,
      "all",
      timeReference
    );

    if (!getAreasChange.success) {
      let areas: Area[] | undefined = undefined;

      if (this.syncedAreas !== undefined && this.syncedAreas[projectId] !== undefined) {
        areas = this.syncedAreas[projectId].areas;
      }

      return {
        areas: areas,
        error: getAreasChange.error,
      };
    }

    let newAreas: Area[] = [];
    if (this.syncedAreas !== undefined && this.syncedAreas[projectId] !== undefined) {
      newAreas = this.syncedAreas[projectId].areas;
    }

    const areasChangeData = getAreasChange.data;
    for (const area of areasChangeData.data) {
      const oldIndex = newAreas.findIndex((e) => e.id === area.id);
      if (oldIndex < 0) {
        newAreas.push(area);
      } else {
        newAreas[oldIndex] = area;
      }
    }

    newAreas.sort((a, b) => naturalCompare(a.name.toLowerCase(), b.name.toLowerCase()));

    let newSyncedAreas = { ...(this.syncedAreas ?? {}) };

    newSyncedAreas[projectId] = {
      syncMoment: areasChangeData.actualTimeReference,
      areas: newAreas,
    };

    this.syncedAreas = newSyncedAreas;

    await this.persistenceRepository.setItem<SyncedAreasType>(
      CacheDataServiceImpl.syncedAreasReferenceKey,
      this.syncedAreas
    );

    return {
      areas: newAreas,
    };
  }

  async clearAreas(): Promise<void> {
    this.syncedAreas = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedAreasReferenceKey);
  }

  async initFlights(): Promise<void> {
    this.syncedFlights = await this.persistenceRepository.getItem<SyncedProjectAndAreasFlightsType>(
      CacheDataServiceImpl.syncedFlightsReferenceKey
    );
  }

  async fetchAreaFlights(
    projectId: string,
    areaId: string
  ): Promise<{
    flights: Flight[] | undefined;
    error?: BiohubError;
  }> {
    let getFlightsTimeReference: Date | undefined = undefined;
    if (
      this.syncedFlights !== undefined &&
      this.syncedFlights[projectId] !== undefined &&
      this.syncedFlights[projectId][areaId] !== undefined
    ) {
      getFlightsTimeReference = this.syncedFlights[projectId][areaId].lastSyncMoment;
    }

    const getFlightsChange = await this.cacheDataRequestsRepository.getFlights(
      projectId,
      areaId,
      "all",
      getFlightsTimeReference
    );

    if (!getFlightsChange.success) {
      let flights: Flight[] | undefined = undefined;
      if (
        this.syncedFlights !== undefined &&
        this.syncedFlights[projectId] !== undefined &&
        this.syncedFlights[projectId][areaId] !== undefined
      ) {
        flights = this.syncedFlights[projectId][areaId].actualFlightList;
      }

      return {
        flights: flights,
        error: getFlightsChange.error,
      };
    }

    let newSyncedFlights: Flight[] = [];
    if (
      this.syncedFlights !== undefined &&
      this.syncedFlights[projectId] !== undefined &&
      this.syncedFlights[projectId][areaId] !== undefined
    ) {
      newSyncedFlights = [...(this.syncedFlights[projectId][areaId].actualFlightList ?? [])];
    }

    const flightsChangeData = getFlightsChange.data;
    for (const flight of flightsChangeData.data) {
      const oldIndex = newSyncedFlights.findIndex((e) => e.id === flight.id);
      if (oldIndex < 0) {
        newSyncedFlights.push(flight);
      } else {
        newSyncedFlights[oldIndex] = flight;
      }
    }

    newSyncedFlights.sort((a, b) => (a.startedAt.getTime() > b.startedAt.getTime() ? 1 : -1));

    let newFlightsForEachAreaAndProjectSyncData = {
      ...(this.syncedFlights ?? {}),
    };

    if (newFlightsForEachAreaAndProjectSyncData[projectId] === undefined) {
      newFlightsForEachAreaAndProjectSyncData[projectId] = {};
    }
    newFlightsForEachAreaAndProjectSyncData[projectId][areaId] = {
      lastSyncMoment: flightsChangeData.actualTimeReference,
      actualFlightList: newSyncedFlights,
    };

    this.syncedFlights = newFlightsForEachAreaAndProjectSyncData;

    await this.persistenceRepository.setItem<SyncedProjectAndAreasFlightsType>(
      CacheDataServiceImpl.syncedFlightsReferenceKey,
      this.syncedFlights
    );

    return {
      flights: newSyncedFlights,
    };
  }

  async clearFlights(): Promise<void> {
    this.syncedFlights = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedFlightsReferenceKey);
  }

  async initCpus(): Promise<void> {
    this.syncedCpus = await this.persistenceRepository.getItem<SyncedCpusType>(
      CacheDataServiceImpl.syncedCpusReferenceKey
    );
  }

  async fetchCpus(directClientId: string | undefined): Promise<{
    cpus: Cpu[] | undefined;
    error?: BiohubError;
  }> {
    const getCpusChange = await this.cacheDataRequestsRepository.getCpus(
      directClientId,
      this.syncedCpus?.syncMoment
    );

    if (!getCpusChange.success) {
      return {
        cpus: this.syncedCpus?.cpuList,
        error: getCpusChange.error,
      };
    }

    let newCpuList = [...(this.syncedCpus?.cpuList ?? [])];

    const cpusChangeData = getCpusChange.data;
    for (const cpu of cpusChangeData.data) {
      const oldIndex = newCpuList.findIndex((e) => e.id === cpu.id);
      if (oldIndex < 0) {
        newCpuList.push(cpu);
      } else {
        newCpuList[oldIndex] = cpu;
      }
    }

    newCpuList.sort((a, b) => naturalCompare(a.serialNumber, b.serialNumber));

    this.syncedCpus = {
      syncMoment: cpusChangeData.actualTimeReference,
      cpuList: newCpuList,
    };

    await this.persistenceRepository.setItem<SyncedCpusType>(
      CacheDataServiceImpl.syncedCpusReferenceKey,
      this.syncedCpus
    );

    return {
      cpus: newCpuList,
    };
  }

  async clearCpus(): Promise<void> {
    this.syncedCpus = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedCpusReferenceKey);
  }

  async initCpuModels(): Promise<void> {
    this.syncedCpuModels = await this.persistenceRepository.getItem<SyncedCpuModelsType>(
      CacheDataServiceImpl.syncedCpuModelReferenceKey
    );
  }

  async fetchCpuModels(): Promise<{
    cpuModels: CpuModel[] | undefined;
    error?: BiohubError;
  }> {
    const getCpuModelsChange = await this.cacheDataRequestsRepository.getCpuModels(
      this.syncedCpuModels?.syncMoment
    );

    if (!getCpuModelsChange.success) {
      return {
        cpuModels: this.syncedCpuModels?.cpuModelList,
        error: getCpuModelsChange.error,
      };
    }

    let newCpuModelList = [...(this.syncedCpuModels?.cpuModelList ?? [])];

    const cpuModelsChangeData = getCpuModelsChange.data;
    for (const cpuModel of cpuModelsChangeData.data) {
      const oldIndex = newCpuModelList.findIndex((e) => e.id === cpuModel.id);
      if (oldIndex < 0) {
        newCpuModelList.push(cpuModel);
      } else {
        newCpuModelList[oldIndex] = cpuModel;
      }
    }

    newCpuModelList.sort((a, b) => naturalCompare(a.name, b.name));

    this.syncedCpuModels = {
      syncMoment: cpuModelsChangeData.actualTimeReference,
      cpuModelList: newCpuModelList,
    };

    await this.persistenceRepository.setItem<SyncedCpuModelsType>(
      CacheDataServiceImpl.syncedCpuModelReferenceKey,
      this.syncedCpuModels
    );

    return {
      cpuModels: newCpuModelList,
    };
  }

  async clearCpuModels(): Promise<void> {
    this.syncedCpuModels = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedCpuModelReferenceKey);
  }

  async initDeviceModels(): Promise<void> {
    this.syncedDeviceModels = await this.persistenceRepository.getItem<SyncedDeviceModelsType>(
      CacheDataServiceImpl.syncedDeviceModelsReferenceKey
    );
  }

  async fetchDeviceModels(): Promise<{
    deviceModels: DeviceModel[] | undefined;
    error?: BiohubError;
  }> {
    const getDeviceModelsChange = await this.cacheDataRequestsRepository.getDeviceModels(
      this.syncedDeviceModels?.syncMoment
    );

    if (!getDeviceModelsChange.success) {
      return {
        deviceModels: this.syncedDeviceModels?.deviceModelList,
        error: getDeviceModelsChange.error,
      };
    }

    let newDeviceModelList = [...(this.syncedDeviceModels?.deviceModelList ?? [])];

    const deviceModelsChangeData = getDeviceModelsChange.data;
    for (const deviceModel of deviceModelsChangeData.data) {
      const oldIndex = newDeviceModelList.findIndex((e) => e.id === deviceModel.id);
      if (oldIndex < 0) {
        newDeviceModelList.push(deviceModel);
      } else {
        newDeviceModelList[oldIndex] = deviceModel;
      }
    }

    this.syncedDeviceModels = {
      syncMoment: deviceModelsChangeData.actualTimeReference,
      deviceModelList: newDeviceModelList,
    };

    await this.persistenceRepository.setItem<SyncedDeviceModelsType>(
      CacheDataServiceImpl.syncedDeviceModelsReferenceKey,
      this.syncedDeviceModels
    );

    return {
      deviceModels: newDeviceModelList,
    };
  }

  async clearDeviceModels(): Promise<void> {
    this.syncedDeviceModels = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedDeviceModelsReferenceKey);
  }

  async initDroneModels(): Promise<void> {
    this.syncedDroneModels = await this.persistenceRepository.getItem<SyncedDroneModelsType>(
      CacheDataServiceImpl.syncedDroneModelsReferenceKey
    );
  }

  async fetchDroneModels(): Promise<{
    droneModels: DroneModel[] | undefined;
    error?: BiohubError;
  }> {
    const getDroneModelsChange = await this.cacheDataRequestsRepository.getDroneModels(
      this.syncedDroneModels?.syncMoment
    );

    if (!getDroneModelsChange.success) {
      return {
        droneModels: this.syncedDroneModels?.droneModels,
        error: getDroneModelsChange.error,
      };
    }

    let newDroneModelList = [...(this.syncedDroneModels?.droneModels ?? [])];

    const droneModelsChangeData = getDroneModelsChange.data;
    for (const droneModel of droneModelsChangeData.data) {
      const oldIndex = newDroneModelList.findIndex((e) => e.id === droneModel.id);
      if (oldIndex < 0) {
        newDroneModelList.push(droneModel);
      } else {
        newDroneModelList[oldIndex] = droneModel;
      }
    }

    this.syncedDroneModels = {
      syncMoment: droneModelsChangeData.actualTimeReference,
      droneModels: newDroneModelList,
    };

    await this.persistenceRepository.setItem<SyncedDroneModelsType>(
      CacheDataServiceImpl.syncedDroneModelsReferenceKey,
      this.syncedDroneModels
    );

    return {
      droneModels: newDroneModelList,
    };
  }

  async clearDroneModels(): Promise<void> {
    this.syncedDroneModels = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedDroneModelsReferenceKey);
  }

  async initInputs(): Promise<void> {
    this.syncedInputs = await this.persistenceRepository.getItem<SyncedInputsType>(
      CacheDataServiceImpl.syncedInputsReferenceKey
    );
  }

  async fetchInputs(): Promise<{
    inputs: Input[] | undefined;
    error?: BiohubError;
  }> {
    const getInputsChange = await this.cacheDataRequestsRepository.getInputs(
      this.syncedInputs?.syncMoment
    );

    if (!getInputsChange.success) {
      return {
        inputs: this.syncedInputs?.inputs,
        error: getInputsChange.error,
      };
    }

    let newInputList = [...(this.syncedInputs?.inputs ?? [])];

    const inputsChangeData = getInputsChange.data;
    for (const input of inputsChangeData.data) {
      const oldIndex = newInputList.findIndex((e) => e.id === input.id);
      if (oldIndex < 0) {
        newInputList.push(input);
      } else {
        newInputList[oldIndex] = input;
      }
    }

    this.syncedInputs = {
      syncMoment: inputsChangeData.actualTimeReference,
      inputs: newInputList,
    };

    await this.persistenceRepository.setItem<SyncedInputsType>(
      CacheDataServiceImpl.syncedInputsReferenceKey,
      this.syncedInputs
    );

    return {
      inputs: newInputList,
    };
  }

  async clearInputs(): Promise<void> {
    this.syncedInputs = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedInputsReferenceKey);
  }

  async initDrones(): Promise<void> {
    this.syncedDrones = await this.persistenceRepository.getItem<SyncedDronesType>(
      CacheDataServiceImpl.syncedDronesReferenceKey
    );
  }

  async fetchDrones(directClientId: string | undefined): Promise<{
    drones: Drone[] | undefined;
    error?: BiohubError;
  }> {
    const getDronesChange = await this.cacheDataRequestsRepository.getDrones(
      directClientId,
      this.syncedDrones?.syncMoment
    );

    if (!getDronesChange.success) {
      return {
        drones: this.syncedDrones?.drones,
        error: getDronesChange.error,
      };
    }

    let newDronesList = [...(this.syncedDrones?.drones ?? [])];

    const dronesChangeData = getDronesChange.data;
    for (const input of dronesChangeData.data) {
      const oldIndex = newDronesList.findIndex((e) => e.id === input.id);
      if (oldIndex < 0) {
        newDronesList.push(input);
      } else {
        newDronesList[oldIndex] = input;
      }
    }

    newDronesList.sort((a, b) =>
      naturalCompare(a.serialNumber.toLowerCase(), b.serialNumber.toLowerCase())
    );

    this.syncedDrones = {
      syncMoment: dronesChangeData.actualTimeReference,
      drones: newDronesList,
    };

    await this.persistenceRepository.setItem<SyncedDronesType>(
      CacheDataServiceImpl.syncedDronesReferenceKey,
      this.syncedDrones
    );

    return {
      drones: newDronesList,
    };
  }

  async clearDrones(): Promise<void> {
    this.syncedDrones = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedDronesReferenceKey);
  }

  async initReleasers(): Promise<void> {
    this.syncedReleasers = await this.persistenceRepository.getItem<SyncedReleasersType>(
      CacheDataServiceImpl.syncedReleasersReferenceKey
    );
  }

  async fetchReleasers(directClientId: string | undefined): Promise<{
    releasers: Releaser[] | undefined;
    error?: BiohubError;
  }> {
    const getReleasersChange = await this.cacheDataRequestsRepository.getReleasers(
      directClientId,
      this.syncedReleasers?.syncMoment
    );

    if (!getReleasersChange.success) {
      return {
        releasers: this.syncedReleasers?.releasers,
        error: getReleasersChange.error,
      };
    }

    let newReleasersList = [...(this.syncedReleasers?.releasers ?? [])];

    const releasersChangeData = getReleasersChange.data;
    for (const releaser of releasersChangeData.data) {
      const oldIndex = newReleasersList.findIndex((e) => e.id === releaser.id);
      if (oldIndex < 0) {
        newReleasersList.push(releaser);
      } else {
        newReleasersList[oldIndex] = releaser;
      }
    }

    newReleasersList.sort((a, b) =>
      naturalCompare(a.serialNumber.toLowerCase(), b.serialNumber.toLowerCase())
    );

    this.syncedReleasers = {
      syncMoment: releasersChangeData.actualTimeReference,
      releasers: newReleasersList,
    };

    await this.persistenceRepository.setItem<SyncedReleasersType>(
      CacheDataServiceImpl.syncedReleasersReferenceKey,
      this.syncedReleasers
    );

    return {
      releasers: newReleasersList,
    };
  }

  async clearReleasers(): Promise<void> {
    this.syncedReleasers = undefined;
    await this.persistenceRepository.clearItem(CacheDataServiceImpl.syncedReleasersReferenceKey);
  }

  async initReleaserModels(): Promise<void> {
    this.syncedReleaserModels = await this.persistenceRepository.getItem<SyncedReleaserModelsType>(
      CacheDataServiceImpl.syncedReleaserModelsReferenceKey
    );
  }

  async fetchReleaserModels(): Promise<{
    releaserModels: ReleaserModel[] | undefined;
    error?: BiohubError;
  }> {
    const getReleaserModelsChange = await this.cacheDataRequestsRepository.getReleaserModels(
      this.syncedReleaserModels?.syncMoment
    );

    if (!getReleaserModelsChange.success) {
      return {
        releaserModels: this.syncedReleaserModels?.releaserModels,
        error: getReleaserModelsChange.error,
      };
    }

    let newReleaserModelsList = [...(this.syncedReleaserModels?.releaserModels ?? [])];

    const releaserModelsChangeData = getReleaserModelsChange.data;
    for (const releaserModel of releaserModelsChangeData.data) {
      const oldIndex = newReleaserModelsList.findIndex((e) => e.id === releaserModel.id);
      if (oldIndex < 0) {
        newReleaserModelsList.push(releaserModel);
      } else {
        newReleaserModelsList[oldIndex] = releaserModel;
      }
    }

    newReleaserModelsList.sort((a, b) =>
      naturalCompare(a.name.toLowerCase(), b.name.toLowerCase())
    );

    this.syncedReleaserModels = {
      syncMoment: releaserModelsChangeData.actualTimeReference,
      releaserModels: newReleaserModelsList,
    };

    await this.persistenceRepository.setItem<SyncedReleaserModelsType>(
      CacheDataServiceImpl.syncedReleaserModelsReferenceKey,
      this.syncedReleaserModels
    );

    return {
      releaserModels: newReleaserModelsList,
    };
  }

  async clearReleaserModels(): Promise<void> {
    this.syncedReleaserModels = undefined;
    await this.persistenceRepository.clearItem(
      CacheDataServiceImpl.syncedReleaserModelsReferenceKey
    );
  }
}
