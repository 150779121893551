import {
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Input,
  Releaser,
  ReleaserModel,
} from "biohub-model";
import { Action } from "../../pages/Profile/styles";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  CollectionsActionsContext,
  SET_DATA_COLLECTION_CONTEXT,
  SET_DATA_COLLECTION_CONTEXT_ITEM,
  SET_ERROR_COLLECTION_CONTEXT,
  SET_LOADING_COLLECTION_CONTEXT,
  SET_lOADING_COLLECTION_CONTEXT_ITEM,
} from "../actions/collectionsActions";
import { LOG_OUT } from "../actions/loginActions";
import { SystemAction } from "../actions/systemActions";

type ElementsInCollectionState<T> = T & {
  loading: boolean;
};

export type DroneModelInCollectionState = DroneModel & {
  imagePath: string;
  compatibleReleaserModelIds: string[];
};

export type CollectionsState = {
  key: "collections";
  releasers: ElementsInCollectionState<Releaser>[];
  loadingReleasers: boolean;
  releaserModels: ElementsInCollectionState<ReleaserModel>[];
  loadingReleaserModels: boolean;
  drones: ElementsInCollectionState<Drone>[];
  loadingDrones: boolean;
  droneModels: ElementsInCollectionState<DroneModelInCollectionState>[];
  loadingDroneModels: boolean;
  cpus: ElementsInCollectionState<Cpu>[];
  loadingCpus: boolean;
  cpuModels: ElementsInCollectionState<CpuModel>[];
  loadingCpuModels: boolean;
  inputs: Input[];
  loadingInputs: boolean;
  loadingDeviceModels: boolean;
  deviceModels: ElementsInCollectionState<DeviceModel>[];
  error: BiohubError | null;
};

const INITIAL_STATE: CollectionsState = {
  key: "collections",
  releasers: [],
  loadingReleasers: false,
  releaserModels: [],
  loadingReleaserModels: false,
  drones: [],
  loadingDrones: false,
  droneModels: [],
  loadingDroneModels: false,
  cpus: [],
  loadingCpus: false,
  cpuModels: [],
  loadingCpuModels: false,
  inputs: [],
  loadingInputs: false,
  loadingDeviceModels: false,
  deviceModels: [],
  error: null,
};

export function collectionsReducer(state = INITIAL_STATE, action: SystemAction): CollectionsState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case LOG_OUT:
      return INITIAL_STATE;
    case SET_LOADING_COLLECTION_CONTEXT:
      return {
        ...effectiveState,
        [getLoadingField(action.payload.context)]: true,
      };
    case SET_ERROR_COLLECTION_CONTEXT:
      return {
        ...effectiveState,
        [getLoadingField(action.payload.context)]: false,
        error: action.payload.error,
      };
    case SET_DATA_COLLECTION_CONTEXT:
      return {
        ...effectiveState,
        [getLoadingField(action.payload.context)]: false,
        [getSetField(action.payload.context)]: action.payload.data.map((item) => ({
          ...item,
          loading: false,
        })),
      };
    case SET_lOADING_COLLECTION_CONTEXT_ITEM:
      if (action.payload.context === CollectionsActionsContext.cpus) {
        return {
          ...effectiveState,
          cpus: effectiveState.cpus.map((cpu) => ({
            ...cpu,
            loading: action.payload.cpuId === cpu.id ? action.payload.loading : cpu.loading,
          })),
        };
      }
      return effectiveState;
    case SET_DATA_COLLECTION_CONTEXT_ITEM:
      if (action.payload.context === CollectionsActionsContext.cpus) {
        return {
          ...effectiveState,
          cpus: effectiveState.cpus.map((cpu) =>
            cpu.id === action.payload.cpu.id ? { ...action.payload.cpu, loading: cpu.loading } : cpu
          ),
        };
      }
      return effectiveState;

    default:
      return effectiveState;
  }
}

function getLoadingField(actionContext: CollectionsActionsContext): keyof CollectionsState {
  switch (actionContext) {
    case CollectionsActionsContext.cpuModels:
      return "loadingCpuModels";
    case CollectionsActionsContext.cpus:
      return "loadingCpus";
    case CollectionsActionsContext.droneModels:
      return "loadingDroneModels";
    case CollectionsActionsContext.drones:
      return "loadingDrones";
    case CollectionsActionsContext.releaserModels:
      return "loadingReleaserModels";
    case CollectionsActionsContext.releasers:
      return "loadingReleasers";
    case CollectionsActionsContext.inputs:
      return "loadingInputs";
    case CollectionsActionsContext.deviceModels:
      return "loadingDeviceModels";
    default:
      throw Error("Not valid redux collections actions context");
  }
}

function getSetField(actionContext: CollectionsActionsContext): keyof CollectionsState {
  switch (actionContext) {
    case CollectionsActionsContext.cpuModels:
      return "cpuModels";
    case CollectionsActionsContext.cpus:
      return "cpus";
    case CollectionsActionsContext.droneModels:
      return "droneModels";
    case CollectionsActionsContext.drones:
      return "drones";
    case CollectionsActionsContext.releaserModels:
      return "releaserModels";
    case CollectionsActionsContext.releasers:
      return "releasers";
    case CollectionsActionsContext.inputs:
      return "inputs";
    case CollectionsActionsContext.deviceModels:
      return "deviceModels";
    default:
      throw Error("Not valid redux collections actions context");
  }
}
