import React, { useState } from "react";
import FolderIcon from "@material-ui/icons/Folder";
import { OpenInNew } from "@material-ui/icons";

type Props = {
  children: React.ReactNode;
  // overlayProjectInfos: (value: boolean) => void;
  // isExpandedProjectInfosOverlay: boolean;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  open?: boolean;
  style?: React.CSSProperties;
};

export default function FolderOverlayComponent(props: Props): JSX.Element {
  const [_expanded, _setExpanded] = useState(props.open !== undefined ? props.open : false);
  const expanded = props.expanded !== undefined ? props.expanded : _expanded;
  const setExpanded = props.setExpanded !== undefined ? props.setExpanded : _setExpanded;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FolderConfig
        isExpandedProjectInfosOverlay={props.expanded}
        onClosed={() => props.setExpanded(false)}
        onOpen={() => props.setExpanded(true)}
      />
      {props.expanded && <div>{props.children}</div>}
      {/* {props.expanded && <OpenInNew style={{ color: "white", cursor: "pointer" }} />} */}
      {props.expanded && (
        <div
          style={{
            paddingRight: 10,
            paddingLeft: 10,
          }}
        ></div>
      )}
    </div>
  );
}

type FolderConfigProps = {
  isExpandedProjectInfosOverlay: boolean;
  onClosed: () => void;
  onOpen: () => void;
};

function FolderConfig(props: FolderConfigProps) {
  return (
    <div
      onClick={() => {
        if (props.isExpandedProjectInfosOverlay) {
          props.onClosed();
        } else {
          props.onOpen();
        }
      }}
    >
      <FolderIcon
        htmlColor="white"
        style={{
          cursor: "pointer",
          display: "flex",
          pointerEvents: "auto",
          justifyContent: "center",
          height: 38,
          width: 38,
          margin: 5,
        }}
      />
    </div>
  );
}
