import React, { useState } from "react";
import { BoundingBox } from "biohub-model";
import { useMapEvents } from "react-leaflet";
import _ from "lodash";
import { couldMapElementBeVisibleAccordingMapBounds } from "../../../../core/geometricFunctions";

export default (props: { elementBounds: BoundingBox; children: JSX.Element }): JSX.Element => {
  const [mapBounds, setMapBounds] = useState<BoundingBox | undefined>(undefined);

  const map = useMapEvents({
    zoomend: () => fetchMapBounds(),
    moveend: () => fetchMapBounds(),
    resize: () => fetchMapBounds(),
  });

  const fetchMapBounds = () => {
    const bounds = map.getBounds();
    if (bounds === undefined) setMapBounds(undefined);

    const newBounds: BoundingBox = {
      north: bounds.getNorth(),
      south: bounds.getSouth(),
      east: bounds.getEast(),
      west: bounds.getWest(),
    };

    if (!_.isEqual(mapBounds, newBounds)) {
      setMapBounds(newBounds);
    }
  };

  if (mapBounds === undefined) return <></>;

  const isElementInsideBounds = couldMapElementBeVisibleAccordingMapBounds(
    props.elementBounds,
    mapBounds
  );

  if (!isElementInsideBounds) return <></>;

  return props.children;
};
