import React from "react";
import { releaseNotesText } from "./releaseNotesText";
import Markdown from "markdown-to-jsx";
import { useIntl } from "react-intl";

export default (): JSX.Element => {
  const intl = useIntl();

  return (
    <div
      style={{
        marginLeft: 40,
        marginRight: 40,
      }}
    >
      <h1>{intl.formatMessage({ id: "menu.updates" })}</h1>
      <Markdown>{releaseNotesText}</Markdown>
    </div>
  );
};
