import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { Address, Profile, Profile as ProfileModel, ProfilePreferences, Role } from "biohub-model";
import EditProfilePreferences from "../Profile/containers/EditProfilePreferences";
import { Actions, Block, Loading } from "../Profile/styles";
import { updateProfile, updateProfilePreferences } from "../../store/actions/profileActions";
import { Button, Icon, TextField } from "@material-ui/core";
import { BiohubLocale } from "../../store/reducers/localeReducer";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const systemProfileRole = useSelector((state: SystemState) => state.profile.userProfile?.role);
  const systemProfilePreferences = useSelector((state: SystemState) => {
    const userProfile = state.profile.userProfile;
    if (userProfile === null) return undefined;

    if (userProfile.role === Role.external) return undefined;

    return userProfile.preferences;
  });

  const [profilePreferences, setProfilePreferences] = React.useState(systemProfilePreferences);
  React.useEffect(() => {
    setProfilePreferences(systemProfilePreferences);
    setUpdating(false);
  }, [systemProfilePreferences]);

  const termSave = intl.formatMessage({ id: "action.save" });
  const termInsufficientPermissions = intl.formatMessage({
    id: "placeholder.settings.insufficientpermissions",
  });

  const loadingProfile: boolean = useSelector((state: SystemState) => state.profile.loadingProfile);

  const [updating, setUpdating] = useState<boolean>(false);

  React.useEffect(() => {
    if (!loadingProfile) {
      if (updating) {
        notify();
      }
      setUpdating(false);
    }
  }, [loadingProfile]);

  const notify = () => toast(intl.formatMessage({ id: "profile.settings.updated" }));

  const [isValidAreaConfig, setIsValidAreaConfig] = useState<boolean>(false);

  return loadingProfile ? (
    <Loading>
      <CircularProgress />
    </Loading>
  ) : (
    <>
      {profilePreferences !== undefined ? (
        <div
          style={{
            paddingLeft: 130,
            paddingRight: 115,
            paddingTop: 50,
          }}
        >
          <Block elevation={3}>
            <EditProfilePreferences
              preferences={profilePreferences}
              role={systemProfileRole}
              onChange={setProfilePreferences}
              configScreenGridSizes={[3, 8, 1]}
              validateValue={setIsValidAreaConfig}
            />
            <Actions className={"flip-card"}>
              <Button
                disabled={!isValidAreaConfig}
                onClick={() => {
                  setUpdating(true);
                  dispatch(updateProfilePreferences(profilePreferences));
                }}
              >
                <Icon>save</Icon> <h6>{termSave}</h6>
              </Button>
            </Actions>
          </Block>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: 130,
            paddingRight: 115,
            paddingTop: 50,
          }}
        >
          <Block elevation={3}>
            <TextField
              disabled={true}
              fullWidth
              margin="dense"
              variant="standard"
              value={termInsufficientPermissions}
              onChange={(v) => {}}
            />
          </Block>
        </div>
      )}
    </>
  );
};
